import React, { ReactElement, ReactNode } from 'react';

interface CardProps {
  className?: string;
  children: ReactNode;
}

function Card({ className = '', children }: CardProps): ReactElement {
  return <div className={`${className} rounded-lg shadow-md`}>{children}</div>;
}

export default Card;
