import React, { ReactElement } from 'react';
import Pill from './pill';

interface TrapStatePillProps {
  state?: string;
  className?: string;
  isLoading?: boolean;
}

export function TrapStatePill({
  state,
  className = 'ml-5 w-32',
  isLoading = false
}: TrapStatePillProps): ReactElement {
  if (isLoading) {
    return (
      <Pill
        className={` flex items-center justify-center text-white ${className} animate-pulse`}
      />
    );
  }

  if (state === 'trapped') {
    return (
      <Pill
        label="Fang"
        className={`bg-red-500 flex items-center justify-center text-white ${className}`}
      />
    );
  }

  if (state === 'normal') {
    return (
      <Pill
        label="Aktiv"
        className={`bg-green-500 flex items-center justify-center text-white ${className}`}
      />
    );
  }

  if (state === 'failed') {
    return (
      <Pill
        label="Kein Fang"
        className={`bg-yellow-500 flex items-center justify-center text-white ${className}`}
      />
    );
  }

  return (
    <Pill
      label="Falle prüfen!"
      className={`bg-gray-400 flex items-center justify-center text-white ${className}`}
    />
  );
}

export default TrapStatePill;
