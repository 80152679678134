/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useIdentityServiceUpdateGroupMutation } from '../../../api/enhanced/enhancedIdentity';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import FormSubmitButton from '../../../components/form/formSubmitButton';

interface FormValues {
  description: string;
}

interface UpdateGroupModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  groupId: string;
  groupDescription: string;
}

function UpdateGroupModal({
  closeHandler,
  isOpen,
  groupId,
  groupDescription
}: UpdateGroupModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const [updateGroup, { isLoading, isError, isSuccess, reset }] =
    useIdentityServiceUpdateGroupMutation();

  const onSubmit = (data: FormValues): void => {
    updateGroup({
      groupId,
      body: {
        description: data.description
      }
    });
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  useEffect(() => {
    if (groupId !== '' && groupDescription !== '') {
      methods.setValue('description', groupDescription);
    }
  }, [groupDescription, groupId, methods]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [isSuccess, methods, reset, closeHandler]);

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.updateGroup.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('modals.updateGroup.description.label')}
              errorMsg={t('modals.updateGroup.description.error') as string}
              placeholder={t('modals.updateGroup.description.label') as string}
              disabled={isLoading}
              required
            />

            <FormSubmitButton label={t('modals.save')} isLoading={isLoading} />
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default UpdateGroupModal;
