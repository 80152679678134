import React, { ReactElement, useEffect, useState } from 'react';
import Pill from './pill';

interface CarbonDioxidePillProps {
  value: number;
  alarmLimit: number;
  warningLimit: number;
  className?: string;
  isLoading?: boolean;
}

export function CarbonDioxidePill({
  value,
  alarmLimit,
  warningLimit,
  className = 'ml-5 w-24',
  isLoading = false
}: CarbonDioxidePillProps): ReactElement {
  if (isLoading) {
    return (
      <Pill
        className={` flex items-center justify-center text-white ${className} animate-pulse`}
      />
    );
  }

  if (value > warningLimit && value < alarmLimit) {
    return (
      <div
        className={`py-2 text-sm font-medium rounded-full text-center bg-yellow-500 text-white ${className}`}
      >
        {value} ppm
      </div>
    );
  }

  if (value > alarmLimit) {
    return (
      <div
        className={`py-2 text-sm font-medium rounded-full text-center bg-red-500 text-white ${className}`}
      >
        {value} ppm
      </div>
    );
  }

  return (
    <div
      className={`py-2 text-sm font-medium rounded-full text-center bg-green-500 text-white ${className}`}
    >
      {value} ppm
    </div>
  );
}

export default CarbonDioxidePill;
