/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { RiAlertFill } from 'react-icons/ri';
import FormErrorLabel from './formErrorLabel';

interface FormTextInputProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  type?: string;
}

function FormTextInput({
  id,
  label,
  placeholder = '',
  required = false,
  errorMsg = 'Error',
  disabled = false,
  type = 'text'
}: FormTextInputProps): ReactElement {
  const {
    formState: { errors },
    register
  } = useFormContext();

  return (
    <div className="flex flex-col w-full mt-2">
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        type={type}
        className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none"
        placeholder={placeholder}
        disabled={disabled}
        {...register(id, { required })}
      />
      {required && errors?.[id] && (
        <FormErrorLabel icon={<RiAlertFill size={20} />} label={errorMsg} />
      )}
    </div>
  );
}

export default FormTextInput;
