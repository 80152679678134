import React, { ReactElement, useEffect, useState } from 'react';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCheckpointServiceListCheckpointsForBuildingQuery } from '../../../../api/enhanced/enhancedCheckpoints';
import AreaDropdown from '../../../../components/dropdown/areaDropdown';
import TempOverviewTable from '../../../checkpoints/tables/temperature/tempOverviewTable';
import useDebounce from '../../../../hooks/useDebounce';
import SearchBar from '../../../../components/searchBar/searchBar';
import Map, {
  getMarkerForTemperature,
  greyIcon,
  MarkerState
} from '../../../../components/map/map';
import { useFloorPlanServiceDownloadFloorPlanQuery } from '../../../../api/enhanced/enhancedFloorPlan';

interface TemperatureTabProps {
  groupId?: string;
  buildingId?: string;
}

function TemperatureTab({
  groupId,
  buildingId
}: TemperatureTabProps): ReactElement {
  const [areaId, setAreaId] = useState('');
  const [markers, setMarkers] = useState<MarkerState[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const navigate = useNavigate();

  const {
    data: imageData,
    isLoading,
    isFetching
  } = useFloorPlanServiceDownloadFloorPlanQuery(
    buildingId != null && areaId != null && areaId !== '' && groupId != null
      ? {
          buildingId,
          groupId,
          areaId
        }
      : skipToken
  );

  const {
    data: checkpointData,
    isLoading: checkpointLoading,
    isFetching: checkpointIsFetching
  } = useCheckpointServiceListCheckpointsForBuildingQuery(
    buildingId != null &&
      areaId != null &&
      groupId != null &&
      !isLoading &&
      !isFetching
      ? {
          buildingId,
          groupId,
          areaId,
          sensorType: 'SENSOR_TYPE_TEMP',
          searchBy: debouncedSearchTerm != null ? debouncedSearchTerm : ''
        }
      : skipToken
  );

  useEffect(() => {
    if (
      checkpointData != null &&
      checkpointData.checkpoints != null &&
      !isLoading &&
      !isFetching &&
      !checkpointLoading &&
      !checkpointIsFetching
    ) {
      const createdMarkers: MarkerState[] = checkpointData.checkpoints.map(
        (checkpoint): MarkerState => {
          const lat = checkpoint.latitude != null ? checkpoint.latitude : 0;
          const lng = checkpoint.longitude != null ? checkpoint.longitude : 0;
          const currentMarker = L.marker([lat, lng], {
            icon:
              checkpoint.temperaturePayload != null &&
              checkpoint.loraMetadata != null &&
              checkpoint.temperaturePayload.temperature != null &&
              checkpoint.loraMetadata.isOnline
                ? getMarkerForTemperature(
                    checkpoint.temperaturePayload.temperature,
                    checkpoint.loraMetadata.isOnline,
                    checkpoint.temperaturePayload.minLimit,
                    checkpoint.temperaturePayload.maxLimit,
                    checkpoint.loraMetadata.lastSignal
                  )
                : greyIcon
          });

          const trapLink = `/${checkpoint.groupId}/building/${checkpoint.buildingId}/checkpoints/${checkpoint.id}/temperature`;
          currentMarker.bindPopup(
            `Prüfpunkt: ${checkpoint.checkpointNumber}, Beschreibung: ${checkpoint.description} <br/> <a href="${trapLink}">Zum Sensor</a>`
          );

          return {
            marker: currentMarker
          };
        }
      );

      setMarkers(createdMarkers);
    }
  }, [
    checkpointData,
    buildingId,
    isLoading,
    isFetching,
    checkpointLoading,
    checkpointIsFetching
  ]);

  return (
    <div>
      {imageData != null &&
        imageData.data != null &&
        areaId !== '' &&
        imageData.data !== '' &&
        checkpointData != null &&
        checkpointData.checkpoints != null && (
          <div className="h-96 w-full mb-4 z-0">
            <Map
              data={`data:image/png;base64,${imageData.data}`}
              markers={markers}
            />
          </div>
        )}
      <div className="mb-4 w-full flex justify-end">
        <SearchBar
          className="mr-2"
          changeHandler={(value: string) => setSearchTerm(value)}
        />
        {groupId != null && (
          <AreaDropdown
            groupId={groupId}
            buildingId={buildingId != null ? buildingId : ''}
            areaId={areaId}
            changeHandler={(selectedAreaId: string) => {
              setAreaId(selectedAreaId);
            }}
          />
        )}
      </div>

      <TempOverviewTable
        groupId={groupId}
        buildingId={buildingId}
        areaId={areaId}
        searchTerm={debouncedSearchTerm}
        clickHandler={(checkpointId: string) =>
          navigate(`checkpoints/${checkpointId}/temperature`)
        }
      />
    </div>
  );
}

export default TemperatureTab;
