import { Menu, Transition } from '@headlessui/react';

import React, { Fragment, ReactElement, useEffect } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { IoMdCheckmark } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import {
  useIdentityServiceListGroupsQuery,
  V1Alpha1Group
} from '../../api/gen/identity';
import { enhancedBuildingsApi } from '../../api/enhanced/enhancedBuildings';
import { enhancedCheckpointApi } from '../../api/enhanced/enhancedCheckpoints';

interface GroupDropdownProps {
  className?: string;
  selectedGroup?: string;
}

function GroupDropdown({
  className,
  selectedGroup
}: GroupDropdownProps): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isFetching, isLoading } = useIdentityServiceListGroupsQuery({});

  useEffect(() => {
    if (selectedGroup != null) {
      dispatch(
        enhancedBuildingsApi.util.invalidateTags(['Buildings', 'Areas'])
      );
      dispatch(
        enhancedCheckpointApi.util.invalidateTags([
          'SENSOR_TYPE_UNSPECIFIED',
          'SENSOR_TYPE_TRAP',
          'SENSOR_TYPE_TEMP',
          'SENSOR_TYPE_CARBON_DIOXIDE',
          'SENSOR_TYPE_DOOR',
          'SENSOR_TYPE_ANALOG_TRAP'
        ])
      );
    }
  }, [dispatch, selectedGroup]);

  const getSelectedGroup = (): string => {
    if (
      data != null &&
      data?.groups != null &&
      data?.groups.length > 0 &&
      selectedGroup != null
    ) {
      const group = data.groups.find(
        (currentGroup: V1Alpha1Group) => currentGroup.groupId === selectedGroup
      );

      if (group == null && data.groups[0].description != null) {
        return data.groups[0].description;
      }

      if (group != null && group.description != null) {
        return group.description;
      }
    }

    return 'Keine Organisation vorhanden.';
  };

  if (isFetching || isLoading) {
    return <div className="h-10 w-24 bg-gray-200 animate-pulse rounded-md" />;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`${className} inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}
        >
          {getSelectedGroup()}
          {data != null && data?.groups != null && (
            <HiChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          )}
        </Menu.Button>
      </div>
      {data != null && data?.groups != null ? (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 z-50 max-h-72 overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {data?.groups.map((group: V1Alpha1Group) => (
              <div className="py-1 px-1" key={group.groupId}>
                <Menu.Item>
                  {() => (
                    <button
                      type="button"
                      onClick={() => {
                        if (group.groupId != null) {
                          navigate(`/${group.groupId}`);
                        }
                      }}
                      className="text-left text-gray-900 flex px-4 py-2 text-sm w-full outline-0 flex-start hover:bg-gray-100 hover:rounded-md flex-row justify-between"
                    >
                      {group.description}
                      {selectedGroup === group.groupId ? (
                        <IoMdCheckmark size={20} />
                      ) : null}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      ) : null}
    </Menu>
  );
}

export default GroupDropdown;
