import React, { ReactElement } from 'react';
import LoadingSpinner from '../spinner/loadingSpinner';

interface FormSubmitButtonProps {
  isLoading: boolean;
  label: string;
}

function FormSubmitButton({
  isLoading,
  label
}: FormSubmitButtonProps): ReactElement {
  return (
    <div className="flex items-end flex-col w-full mt-5">
      {!isLoading ? (
        <button
          className="rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
          type="submit"
        >
          {label}
        </button>
      ) : (
        <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

export default FormSubmitButton;
