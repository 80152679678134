import React, { ReactElement } from 'react';

export interface MultiselectContent {
  selected: boolean;
  key: string;
  label: string;
}

interface MultiselectListProps {
  content: MultiselectContent[];
  label?: string;
  className?: string;
  selectHandler: (id: string, checkedState: boolean) => void;
}

function MultiselectList({
  content,
  className = '',
  selectHandler,
  label
}: MultiselectListProps): ReactElement {
  return (
    <div className="flex flex-col">
      {label != null && (
        <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          {label}
        </div>
      )}
      <ul
        className={`w-full text-sm font-medium text-gray-900 bg-white rounded-md border border-gray-200 ${className}`}
      >
        {content.map((element) => (
          <li
            key={element.key}
            className="w-full  rounded-t-md border-b border-gray-200"
          >
            <div className="flex items-center pl-3">
              <input
                checked={element.selected}
                type="checkbox"
                onChange={(event) =>
                  selectHandler(element.key, event.target.checked)
                }
                value={element.key}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {element.label}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MultiselectList;
