import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    statisticsServiceGetStatistics: build.query<
      StatisticsServiceGetStatisticsApiResponse,
      StatisticsServiceGetStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/statistics/${queryArg.sensorType}`
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as statisticsApi };
export type StatisticsServiceGetStatisticsApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetStatisticsResponse;
export type StatisticsServiceGetStatisticsApiArg = {
  groupId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
};
export type V1Alpha1SensorType =
  | 'SENSOR_TYPE_UNSPECIFIED'
  | 'SENSOR_TYPE_TRAP'
  | 'SENSOR_TYPE_TEMP'
  | 'SENSOR_TYPE_CARBON_DIOXIDE'
  | 'SENSOR_TYPE_DOOR'
  | 'SENSOR_TYPE_ANALOG_TRAP'
  | 'SENSOR_TYPE_HUMIDITY';
export type V1Alpha1TrapStats = {
  total?: string;
  failed?: string;
  trapped?: string;
  active?: string;
  offline?: string;
};
export type V1Alpha1TempStats = {
  total?: string;
  valid?: string;
  invalid?: string;
  offline?: string;
};
export type V1Alpha1CarbonDioxideStats = {
  total?: string;
  valid?: string;
  invalidWarningLimit?: string;
  invalidAlarmLimit?: string;
  offline?: string;
};
export type V1Alpha1DoorStats = {
  total?: string;
  open?: string;
  closed?: string;
  offline?: string;
};
export type V1Alpha1HumidityStats = {
  total?: string;
  valid?: string;
  invalid?: string;
  offline?: string;
};
export type V1Alpha1GetStatisticsResponse = {
  sensorType?: V1Alpha1SensorType;
  trapStats?: V1Alpha1TrapStats;
  tempStats?: V1Alpha1TempStats;
  carbonDioxideStats?: V1Alpha1CarbonDioxideStats;
  doorStats?: V1Alpha1DoorStats;
  humidityStats?: V1Alpha1HumidityStats;
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export const { useStatisticsServiceGetStatisticsQuery } = injectedRtkApi;
