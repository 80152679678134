import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../../components/title/pageTitle';
import BuildingTable from '../../buildings/tables/buildingTable';

function Alarms(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  return (
    <div className="flex flex-col px-8 w-full">
      <PageTitle title={t('alarms.title')} />
      <BuildingTable
        groupId={groupId}
        clickHandler={(buildingId: string) =>
          navigate(`building/${buildingId}`)
        }
      />
    </div>
  );
}

export default Alarms;
