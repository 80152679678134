import { alarmApi } from '../gen/alarms';

export const enhancedAlarmsApi = alarmApi.enhanceEndpoints({
  addTagTypes: ['Alarms'],
  endpoints: {
    alarmServiceGetAlarm: {
      providesTags: ['Alarms']
    },
    alarmServiceCreateAlarm: {
      invalidatesTags: ['Alarms']
    },
    alarmServiceUpdateAlarm: {
      invalidatesTags: ['Alarms']
    },
    alarmServiceDeleteAlarm: {
      invalidatesTags: ['Alarms']
    },
    alarmServiceListAlarms: {
      providesTags: ['Alarms']
    }
  }
});

export const {
  useAlarmServiceGetAlarmQuery,
  useAlarmServiceCreateAlarmMutation,
  useAlarmServiceUpdateAlarmMutation,
  useAlarmServiceDeleteAlarmMutation,
  useAlarmServiceListAlarmsQuery
} = enhancedAlarmsApi;
