import { identityApi } from '../gen/identity';

export const enhancedIdentityApi = identityApi.enhanceEndpoints({
  addTagTypes: ['User', 'Groups'],
  endpoints: {
    identityServiceListGroups: {
      providesTags: ['User', 'Groups']
    },
    identityServiceCreateGroup: {
      invalidatesTags: ['Groups']
    },
    identityServiceUpdateGroup: {
      invalidatesTags: ['Groups']
    },
    identityServiceListUsers: {
      providesTags: ['User', 'Groups']
    },
    identityServiceRemoveUserFromGroup: {
      invalidatesTags: ['User', 'Groups']
    },
    identityServiceAddUserToGroup: {
      invalidatesTags: ['User', 'Groups']
    },
    identityServiceCreateUser: {
      invalidatesTags: ['User']
    },
    identityServiceGetUserInfo: {
      providesTags: ['User']
    },
    identityServiceDeleteUser: {
      invalidatesTags: ['User']
    },
    identityServiceUpdateUser: {
      invalidatesTags: ['User']
    },
    identityServiceGetUser: {
      providesTags: ['User']
    },
    identityServiceSendInvitationMail: {
      invalidatesTags: []
    }
  }
});

export const {
  useIdentityServiceListGroupsQuery,
  useIdentityServiceCreateGroupMutation,
  useIdentityServiceUpdateGroupMutation,
  useIdentityServiceListUsersQuery,
  useIdentityServiceRemoveUserFromGroupMutation,
  useIdentityServiceAddUserToGroupMutation,
  useIdentityServiceCreateUserMutation,
  useIdentityServiceGetUserInfoQuery,
  useIdentityServiceDeleteUserMutation,
  useIdentityServiceGetUserQuery,
  useIdentityServiceUpdateUserMutation,
  useIdentityServiceSendInvitationMailMutation
} = enhancedIdentityApi;
