import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    notificationServiceGetNotification: build.query<
      NotificationServiceGetNotificationApiResponse,
      NotificationServiceGetNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/notifications/${queryArg.notificationId}/${queryArg.sensorType}`
      })
    }),
    notificationServiceUpdateNotification: build.mutation<
      NotificationServiceUpdateNotificationApiResponse,
      NotificationServiceUpdateNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/notifications/${queryArg.notificationId}/${queryArg.sensorType}`,
        method: 'PATCH',
        body: queryArg.notificationServiceUpdateNotificationBody
      })
    }),
    notificationServiceListNotifications: build.query<
      NotificationServiceListNotificationsApiResponse,
      NotificationServiceListNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/notifications/${queryArg.sensorType}`,
        params: {
          buildingId: queryArg.buildingId,
          areaId: queryArg.areaId,
          limit: queryArg.limit,
          page: queryArg.page,
          sortingOrder: queryArg.sortingOrder
        }
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as notificationApi };
export type NotificationServiceGetNotificationApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetNotificationResponse;
export type NotificationServiceGetNotificationApiArg = {
  groupId: string;
  buildingId: string;
  notificationId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
};
export type NotificationServiceUpdateNotificationApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateNotificationResponse;
export type NotificationServiceUpdateNotificationApiArg = {
  groupId: string;
  buildingId: string;
  notificationId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
  notificationServiceUpdateNotificationBody: NotificationServiceUpdateNotificationBody;
};
export type NotificationServiceListNotificationsApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListNotificationsResponse;
export type NotificationServiceListNotificationsApiArg = {
  groupId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
  buildingId?: string;
  areaId?: string;
  limit?: number;
  page?: number;
  sortingOrder?: string;
};
export type V1Alpha1TrapEvent = {
  state?: string;
};
export type V1Alpha1TemperatureEvent = {
  temperature?: number;
  humidity?: number;
  temperatureMinLimit?: number;
  temperatureMaxLimit?: number;
  humidityMinLimit?: number;
  humidityMaxLimit?: number;
};
export type V1Alpha1CarbonDioxideEvent = {
  temperature?: number;
  humidity?: number;
  carbonDioxide?: number;
  temperatureMinLimit?: number;
  temperatureMaxLimit?: number;
  humidityMinLimit?: number;
  humidityMaxLimit?: number;
  carbonDioxideWarnLimit?: number;
  carbonDioxideAlarmLimit?: number;
};
export type V1Alpha1DoorEvent = {
  status?: number;
  triggerDelay?: number;
};
export type V1Alpha1HumidityEvent = {
  humidity?: number;
  humidityMinLimit?: number;
  humidityMaxLimit?: number;
};
export type V1Alpha1Notification = {
  entryId?: string;
  checkpointId?: string;
  checkpointDescription?: string;
  checkpointNumber?: number;
  groupId?: string;
  timestamp?: string;
  buildingId?: string;
  areaId?: string;
  triggerAlarm?: boolean;
  trapEvent?: V1Alpha1TrapEvent;
  temperatureEvent?: V1Alpha1TemperatureEvent;
  carbonDioxideEvent?: V1Alpha1CarbonDioxideEvent;
  doorEvent?: V1Alpha1DoorEvent;
  humidityEvent?: V1Alpha1HumidityEvent;
};
export type V1Alpha1GetNotificationResponse = {
  notification?: V1Alpha1Notification;
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1UpdateNotificationResponse = {
  notification?: V1Alpha1Notification;
};
export type NotificationServiceUpdateNotificationBody = {
  notification?: V1Alpha1Notification;
};
export type V1Alpha1ListNotificationsResponse = {
  total?: string;
  page?: number;
  limit?: number;
  data?: V1Alpha1Notification[];
};
export const {
  useNotificationServiceGetNotificationQuery,
  useNotificationServiceUpdateNotificationMutation,
  useNotificationServiceListNotificationsQuery
} = injectedRtkApi;
