/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import ReportDownload from '../api/models/reportDownload';
import { tokenRequest } from '../auth/msal/msalConfig';

const useDownloadReportHandler = () => {
  const [requestData, setRequestData] = useState({
    buildingId: '',
    groupId: '',
    reportId: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        if (
          requestData.buildingId !== '' &&
          requestData.groupId !== '' &&
          requestData.reportId !== ''
        ) {
          if (accounts.length > 0) {
            const response = await instance.acquireTokenSilent({
              ...tokenRequest,
              account: accounts[0]
            });

            const options = {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${response.accessToken}`
              }
            };

            setIsLoading(true);

            const pdfResponse = await fetch(
              `${process.env.REACT_APP_GATEWAY_URL}/v1/owners/${requestData.groupId}/buildings/${requestData.buildingId}/reports/${requestData.reportId}/pdf`,
              options
            );

            // Create Blob from Base64 string
            const pdfBlob: ReportDownload = await pdfResponse.json();
            const data = Uint8Array.from(window.atob(pdfBlob.data), (c) =>
              c.charCodeAt(0)
            );
            const blob = new Blob([data], { type: 'application/pdf' });

            // Create Link
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${requestData.reportId}.pdf`);
            document.body.appendChild(link);

            // Download and cleanup
            link.click();
            link.parentNode?.removeChild(link);
          }
        }
      } catch (e: unknown) {
        setIsError(true);
        setError(e);
      }
    };

    fetchData();
  }, [requestData, accounts, instance]);

  return { isLoading, isError, error, setRequestData };
};

export default useDownloadReportHandler;
