import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';
import LabelButton from '../../../components/buttons/labelButton';
import GroupTable from '../tables/groupTable';
import CreateGroupModal from '../modals/createGroupModal';
import UpdateGroupModal from '../modals/updateGroupModal';

function Groups(): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [updateGroupState, setUpdateGroupState] = useState({
    isOpen: false,
    groupId: '',
    description: ''
  });

  return (
    <div>
      <div className="flex flex-col  w-full">
        <LabelButton
          label={t('administration.groups.addGroup')}
          className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
          icon={<IoMdAdd size={20} />}
          onClick={() => setIsOpen(true)}
        />
        <GroupTable
          editHandler={(groupId, groupDescription) =>
            setUpdateGroupState({
              isOpen: true,
              groupId,
              description: groupDescription
            })
          }
        />
      </div>

      <CreateGroupModal
        isOpen={isOpen}
        closeHandler={() => {
          setIsOpen(false);
        }}
      />

      {updateGroupState.groupId !== '' && (
        <UpdateGroupModal
          groupId={updateGroupState.groupId}
          groupDescription={updateGroupState.description}
          isOpen={updateGroupState.isOpen}
          closeHandler={() => {
            setUpdateGroupState({
              isOpen: false,
              groupId: '',
              description: ''
            });
          }}
        />
      )}
    </div>
  );
}

export default Groups;
