import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface DateLabelProps {
  timestamp?: string;
  className?: string;
}

function DateLabel({ timestamp, className }: DateLabelProps): ReactElement {
  const { t } = useTranslation();

  if (timestamp === null || timestamp === '' || timestamp === undefined) {
    return <div className={className}>{t('date.noValue')}</div>;
  }

  const parsedTimestamp = Date.parse(timestamp.replace('CEST', ''));
  if (Number.isNaN(parsedTimestamp) === false) {
    const date = new Date(parsedTimestamp);
    return (
      <div>{`${date.toLocaleDateString('de-DE')}, ${date.toLocaleTimeString(
        'de-DE'
      )}`}</div>
    );
  }
  return <div className={className}>{t('date.invalid')}</div>;
}

export default DateLabel;
