import React, { ReactElement, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useIdentityServiceGetUserInfoQuery } from '../../../api/gen/identity';

import LoadingSpinner from '../../../components/spinner/loadingSpinner';

import { useIdentityServiceListGroupsQuery } from '../../../api/enhanced/enhancedIdentity';

function RootGroupWrapper(): ReactElement {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { data, isSuccess, isFetching, isLoading } =
    useIdentityServiceGetUserInfoQuery(
      accounts.length > 0 && accounts[0].localAccountId != null
        ? { userId: accounts[0].localAccountId }
        : skipToken
    );

  const { data: groupData, isSuccess: groupSuccess } =
    useIdentityServiceListGroupsQuery({});

  useEffect(() => {
    if (
      isSuccess &&
      groupSuccess &&
      data != null &&
      data.user != null &&
      (groupId == null || groupId === '')
    ) {
      if (
        (data.user.isAdmin || data.user.isTechnician) &&
        groupData != null &&
        groupData.groups != null
      ) {
        navigate(
          groupData.groups.length > 0 ? `/${groupData.groups[0].groupId}` : '/'
        );
        return;
      }

      if (data.user.groups != null && data.user.groups.length > 0) {
        navigate(`${data.user.groups[0].groupId}`);
        return;
      }

      navigate('/');
    }
  }, [data, groupData, groupId, navigate]);

  if (isLoading || isFetching) {
    return (
      <div className="bg-gray-100 h-full min-h-screen flex items-center justify-center">
        <LoadingSpinner className="h-20" />
      </div>
    );
  }

  return <Outlet />;
}

export default RootGroupWrapper;
