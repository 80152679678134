import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    inspectionServiceListInspection: build.query<
      InspectionServiceListInspectionApiResponse,
      InspectionServiceListInspectionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}/inspections`,
        params: {
          limit: queryArg.limit,
          page: queryArg.page,
          sortingOrder: queryArg.sortingOrder
        }
      })
    }),
    inspectionServiceCreateInspection: build.mutation<
      InspectionServiceCreateInspectionApiResponse,
      InspectionServiceCreateInspectionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}/inspections`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    inspectionServiceGetInspection: build.query<
      InspectionServiceGetInspectionApiResponse,
      InspectionServiceGetInspectionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}/inspections/${queryArg.inspectionId}`
      })
    }),
    inspectionServiceUpdateInspection: build.mutation<
      InspectionServiceUpdateInspectionApiResponse,
      InspectionServiceUpdateInspectionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}/inspections/${queryArg.inspectionId}`,
        method: 'PATCH',
        body: queryArg.body
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as inspectionApi };
export type InspectionServiceListInspectionApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListInspectionResponse;
export type InspectionServiceListInspectionApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
  limit?: number;
  page?: number;
  sortingOrder?: string;
};
export type InspectionServiceCreateInspectionApiResponse =
  /** status 200 A successful response. */ V1Alpha1CreateInspectionResponse;
export type InspectionServiceCreateInspectionApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
  body: {
    comment?: string;
    inspectionType?: V1Alpha1InspectionType;
    timestamp?: string;
  };
};
export type InspectionServiceGetInspectionApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetInspectionResponse;
export type InspectionServiceGetInspectionApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
  inspectionId: string;
};
export type InspectionServiceUpdateInspectionApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateInspectionResponse;
export type InspectionServiceUpdateInspectionApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
  inspectionId: string;
  body: {
    inspection?: V1Alpha1Inspection;
  };
};
export type V1Alpha1InspectionType =
  | 'INSPECTION_TYPE_UNSPECIFIED'
  | 'INSPECTION_TYPE_QR'
  | 'INSPECTION_TYPE_MANUAL';
export type V1Alpha1Inspection = {
  id?: string;
  checkpointId?: string;
  inspectionType?: V1Alpha1InspectionType;
  inspector?: string;
  comment?: string;
  timestamp?: string;
};
export type V1Alpha1ListInspectionResponse = {
  total?: string;
  page?: number;
  limit?: number;
  inspections?: V1Alpha1Inspection[];
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1CreateInspectionResponse = {
  inspection?: V1Alpha1Inspection;
};
export type V1Alpha1GetInspectionResponse = {
  inspection?: V1Alpha1Inspection;
};
export type V1Alpha1UpdateInspectionResponse = {
  inspection?: V1Alpha1Inspection;
};
export const {
  useInspectionServiceListInspectionQuery,
  useInspectionServiceCreateInspectionMutation,
  useInspectionServiceGetInspectionQuery,
  useInspectionServiceUpdateInspectionMutation
} = injectedRtkApi;
