import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCheckpointServiceGetCheckpointQuery } from '../../../../../api/enhanced/enhancedCheckpoints';
import CheckpointDetails from '../../../components/checkpointDetails';
import PageTitle from '../../../../../components/title/pageTitle';
import CheckpointState from '../../../components/checkpointState';
import TabBar, { TabContent } from '../../../../../components/tabs/tabBar';
import CreateInspectionModal from '../../../modals/createInspectionModal';
import InspectionTable from '../../../tables/traps/inspectionTable';

function AnalogTrapDetailPage(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId, checkpointId } = useParams();
  const [createInspectionState, setCreateInspectionState] = useState(false);

  const tabContent: TabContent[] = [
    {
      key: 0,
      tabLabel: t('trapDetails.tabs.inspection'),
      content: (
        <InspectionTable
          createInspectionModalHandler={(isOpen) =>
            setCreateInspectionState(isOpen)
          }
          sensorType="SENSOR_TYPE_ANALOG_TRAP"
          groupId={groupId}
          buildingId={buildingId}
          checkpointId={checkpointId}
        />
      )
    }
  ];

  const { data, isLoading, isFetching, isError, error, isSuccess } =
    useCheckpointServiceGetCheckpointQuery(
      buildingId != null &&
        checkpointId != null &&
        groupId != null &&
        groupId !== ''
        ? {
            checkpointId,
            buildingId,
            groupId,
            sensorType: 'SENSOR_TYPE_ANALOG_TRAP'
          }
        : skipToken
    );

  return (
    <div className="flex flex-col px-8 w-full">
      <div className="flex flex-row">
        <PageTitle isLoading={isLoading} title={t('trapDetails.title')} />
      </div>
      {data != null && data.checkpoint != null && (
        <div className="flex xl:flex-row md:flex-col my-2">
          <CheckpointDetails checkpoint={data?.checkpoint} />
          <CheckpointState checkpoint={data?.checkpoint} />
        </div>
      )}
      <TabBar className="mt-4" tabs={tabContent} />

      {buildingId != null &&
        groupId != null &&
        groupId !== '' &&
        checkpointId != null && (
          <CreateInspectionModal
            buildingId={buildingId}
            groupId={groupId}
            checkpointId={checkpointId}
            sensorType="SENSOR_TYPE_ANALOG_TRAP"
            isOpen={createInspectionState}
            closeHandler={() => {
              setCreateInspectionState(false);
            }}
          />
        )}
    </div>
  );
}

export default AnalogTrapDetailPage;
