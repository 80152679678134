import React, { ReactElement, ReactNode } from 'react';

interface CustomDataLabelProps {
  isLoading?: boolean;
  label: string;
  data: ReactNode;
  className?: string;
}

function CustomDataLabel({
  isLoading = false,
  label,
  data,
  className = ''
}: CustomDataLabelProps): ReactElement {
  return !isLoading ? (
    <div className={`flex flex-row ${className}`}>
      <div>{label}:</div>
      <div>{data}</div>
    </div>
  ) : (
    <div className={`flex flex-row mb-1 ${className}`}>
      <div className="h-3 bg-gray-300 rounded-lg w-20 font-semibold text-gray-800 animate-pulse" />
      <div className="h-3  bg-gray-300 rounded-lg w-20 font-semibold text-gray-800 animate-pulse " />
    </div>
  );
}

export default CustomDataLabel;
