import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    deviceRegistryServiceListDevicesFromRegistry: build.query<
      DeviceRegistryServiceListDevicesFromRegistryApiResponse,
      DeviceRegistryServiceListDevicesFromRegistryApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registry/revisions/devices`,
        params: { latestEntry: queryArg.latestEntry }
      })
    }),
    deviceRegistryServiceUpdateDeviceRegistry: build.mutation<
      DeviceRegistryServiceUpdateDeviceRegistryApiResponse,
      DeviceRegistryServiceUpdateDeviceRegistryApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registry/revisions/devices`,
        method: 'POST',
        body: queryArg.v1Alpha1UpdateDeviceRegistryRequest
      })
    }),
    deviceRegistryServiceGetDeviceFromRegistry: build.query<
      DeviceRegistryServiceGetDeviceFromRegistryApiResponse,
      DeviceRegistryServiceGetDeviceFromRegistryApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registry/revisions/devices/${queryArg.id}`,
        params: { deviceType: queryArg.deviceType }
      })
    }),
    deviceRegistryServiceCheckIfDeviceExists: build.query<
      DeviceRegistryServiceCheckIfDeviceExistsApiResponse,
      DeviceRegistryServiceCheckIfDeviceExistsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registry/revisions/devices/${queryArg.id}/exists`,
        params: { deviceType: queryArg.deviceType }
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as deviceRegistryApi };
export type DeviceRegistryServiceListDevicesFromRegistryApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListDevicesFromRegistryResponse;
export type DeviceRegistryServiceListDevicesFromRegistryApiArg = {
  latestEntry?: string;
};
export type DeviceRegistryServiceUpdateDeviceRegistryApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateDeviceRegistryResponse;
export type DeviceRegistryServiceUpdateDeviceRegistryApiArg = {
  v1Alpha1UpdateDeviceRegistryRequest: V1Alpha1UpdateDeviceRegistryRequest;
};
export type DeviceRegistryServiceGetDeviceFromRegistryApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetDeviceFromRegistryResponse;
export type DeviceRegistryServiceGetDeviceFromRegistryApiArg = {
  id: string;
  deviceType?:
    | 'DEVICE_TYPE_UNSPECIFIED'
    | 'DEVICE_TYPE_MOUSE_TRAP'
    | 'DEVICE_TYPE_RAT_TRAP';
};
export type DeviceRegistryServiceCheckIfDeviceExistsApiResponse =
  /** status 200 A successful response. */ V1Alpha1CheckIfDeviceExistsResponse;
export type DeviceRegistryServiceCheckIfDeviceExistsApiArg = {
  id: string;
  deviceType?:
    | 'DEVICE_TYPE_UNSPECIFIED'
    | 'DEVICE_TYPE_MOUSE_TRAP'
    | 'DEVICE_TYPE_RAT_TRAP';
};
export type V1Alpha1Device = {
  deviceId?: string;
  serialNumber?: string;
  createdAt?: string;
  updatedAt?: string;
};
export type V1Alpha1ListDevicesFromRegistryResponse = {
  total?: string;
  devices?: V1Alpha1Device[];
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1UpdateDeviceRegistryResponse = object;
export type V1Alpha1UpdateDeviceRegistryRequest = object;
export type V1Alpha1GetDeviceFromRegistryResponse = {
  device?: V1Alpha1Device;
};
export type V1Alpha1CheckIfDeviceExistsResponse = {
  exists?: boolean;
};
export const {
  useDeviceRegistryServiceListDevicesFromRegistryQuery,
  useDeviceRegistryServiceUpdateDeviceRegistryMutation,
  useDeviceRegistryServiceGetDeviceFromRegistryQuery,
  useDeviceRegistryServiceCheckIfDeviceExistsQuery
} = injectedRtkApi;
