import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import { HiSearch } from 'react-icons/hi';
import { skipToken } from '@reduxjs/toolkit/query';
import RowCell from '../../../../components/tables/rowCell';
import DateTableLabel from '../../../../components/date/dateTableLabel';
import TableActionButton from '../../../../components/tables/tableActionButton';
import {
  enhancedCheckpointApi,
  useCheckpointServiceListCheckpointsForBuildingQuery
} from '../../../../api/enhanced/enhancedCheckpoints';
import { V1Alpha1Checkpoint } from '../../../../api/gen/checkpoints';
import DataTable from '../../../../components/tables/dataTable';
import { useBuildingServiceListAreasForBuildingQuery } from '../../../../api/enhanced/enhancedBuildings';

interface OverviewTableProps {
  groupId?: string;
  buildingId?: string;
  areaId?: string;
  searchTerm?: string;
  clickHandler: (checkpointId: string) => void;
}

function AnalogOverviewTable({
  groupId,
  buildingId,
  areaId,
  searchTerm,
  clickHandler
}: OverviewTableProps): ReactElement {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const [sortColumns, setSortColumns] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  useEffect(() => {
    let sortingColumns = 'checkpoint_number:ASC';
    if (sorting.length > 0) {
      sorting.forEach((sortCol) => {
        sortingColumns = `${sortCol.id}:${sortCol.desc ? 'DESC' : 'ASC'}`;
      });
    }
    setSortColumns(sortingColumns);
  }, [sorting, setSortColumns]);

  useEffect(() => {
    enhancedCheckpointApi.util.invalidateTags(['SENSOR_TYPE_ANALOG_TRAP']);
    setPagination((state) => ({ ...state, pageIndex: 0 }));
    setSortColumns('checkpoint_number:ASC');
  }, [areaId, searchTerm, pageSize]);

  const {
    data: areaData,
    isLoading: areaIsLoading,
    isSuccess: areaIsSuccess,
    isError: areaIsError,
    error: areaError
  } = useBuildingServiceListAreasForBuildingQuery(
    buildingId != null && groupId != null
      ? { buildingId, groupId, limit: 200, page: 0 }
      : skipToken
  );

  const columns = useMemo<ColumnDef<V1Alpha1Checkpoint>[]>(() => {
    const getAreaDescriptionById = (id: string): string => {
      if (areaIsError) {
        return 'Fehler beim Laden der Bereiche';
      }

      const area = areaData?.areas?.find(
        (currentArea) => currentArea.id === id
      );
      return area != null && area.description != null
        ? area.description
        : 'Keinem Bereich zugewiesen';
    };

    return [
      {
        header: 'Prüfpunkt',
        id: 'checkpoint_number',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.checkpointNumber,
        footer: (props) => props.column.id
      },
      {
        header: 'Beschreibung',
        id: 'description',
        accessorFn: (row) => row.description,
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        footer: (props) => props.column.id
      },
      {
        header: 'Bereich',
        accessorFn: (row) => row.areaId,
        cell: (info) =>
          RowCell({
            title: getAreaDescriptionById(info.getValue<string>())
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Letzte Sichtprüfung',
        id: 'last_visual_inspection',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.inspectionMetadata?.lastInspection,
        footer: (props) => props.column.id
      },
      {
        header: 'Details',
        accessorFn: (row) => row.id,
        cell: (info) =>
          TableActionButton({
            icon: <HiSearch size={20} />,
            onClick: () => clickHandler(info.getValue<string>())
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];
  }, [areaIsError, areaData?.areas, clickHandler]);

  const { data, isLoading, isSuccess, isFetching, isError } =
    useCheckpointServiceListCheckpointsForBuildingQuery(
      buildingId != null && groupId != null && groupId !== ''
        ? {
            groupId,
            buildingId,
            areaId,
            limit: pageSize,
            page: pageIndex,
            sensorType: 'SENSOR_TYPE_ANALOG_TRAP',
            searchBy: searchTerm != null ? searchTerm : '',
            sortBy: sortColumns
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.checkpoints ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination, sorting },
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={
        isSuccess && data.checkpoints != null && data.checkpoints.length === 0
      }
      pageSize={pageSize}
      table={table}
    />
  );
}

export default AnalogOverviewTable;
