import React, { ReactElement, ReactNode } from 'react';
import { Link, useMatch, useParams, useResolvedPath } from 'react-router-dom';

interface SidebarItemProps {
  className?: string;
  route: string;
  title: string;
  icon?: ReactNode;
}

function SidebarItem({
  className = '',
  route,
  title,
  icon = null
}: SidebarItemProps): ReactElement {
  const resolved = useResolvedPath(route);
  const isMatch = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      to={route}
      className={`${className} my-2 w-11/12 flex rounded-lg items-center p-2 text-gray-600 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-800 
      ${isMatch ? 'bg-gray-100 text-gray-800' : ''}`}
    >
      {icon !== null ? <div className="text-2xl mr-4">{icon}</div> : null}
      <span className="text-lg font-normal">{title}</span>
    </Link>
  );
}

export default SidebarItem;
