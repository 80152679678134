import React, { ReactElement } from 'react';

interface RowCell {
  title: string;
}

function RowCell({ title }: RowCell): ReactElement {
  return <div className='px-6 py-4 whitespace-nowrap"'>{title}</div>;
}

export default RowCell;
