import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: process.env.REACT_APP_AUTHORITY as string,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES as string],
    redirectUri: process.env.REACT_APP_REDIRECT_URI as string
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      }
    }
  }
};

const apiConfig = {
  b2cScopes: []
};

export const loginRequest = {
  prompt: 'login',
  scopes: [
    'openid',
    'offline_access',
    process.env.REACT_APP_CLIENT_ID as string
  ]
};

export const tokenRequest = {
  scopes: [
    'openid',
    'offline_access',
    process.env.REACT_APP_CLIENT_ID as string,
    ...apiConfig.b2cScopes
  ],
  forceRefresh: false
};

export const msalInstance = new PublicClientApplication(msalConfig);
