import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { flexRender, Table as TableState } from '@tanstack/react-table';
import Table, { TableSkeleton } from './table';
import Card from '../card/card';
import HeaderCell from './headerCell';
import PaginationButtons from './paginationButtons';

interface DataTableProps<T> {
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
  pageSize: number;
  table: TableState<T>;
  enablePagination?: boolean;
}

function DataTable<T>({
  isLoading,
  isError,
  isEmpty,
  pageSize,
  table,
  enablePagination = true
}: DataTableProps<T>): ReactElement {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="flex flex-col w-full">
        <TableSkeleton headerCount={5} rowCount={pageSize} />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <Card className="bg-white flex justify-center items-center h-40 mt-2">
        <div className="font-semibold text-xl">{t('table.empty')}</div>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card className="bg-red-200 flex justify-center items-center h-40 mt-2">
        <div className="font-semibold text-xl">{t('table.error')}</div>
      </Card>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <Table>
        <thead className="bg-gray-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          <HeaderCell
                            canSort={header.column.getCanSort()}
                            onClick={header.column.getToggleSortingHandler()}
                            title={header.column.columnDef.header as string}
                            sortDirection={header.column.getIsSorted()}
                          />,
                          header.getContext()
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white">
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {enablePagination && (
        <PaginationButtons
          changePageSize={table.setPageSize}
          previousPage={table.previousPage}
          nextPage={table.nextPage}
          pageSize={table.getState().pagination.pageSize}
          currentPage={table.getState().pagination.pageIndex + 1}
          firstPage={table.setPageIndex}
          lastPage={table.setPageIndex}
          pageCount={table.getPageCount()}
        />
      )}
    </div>
  );
}

export default DataTable;
