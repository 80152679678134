import { skipToken } from '@reduxjs/toolkit/query';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useMemo, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { HiSearch } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { IoMdTrash } from 'react-icons/io';
import { V1Alpha1Area } from '../../../api/gen/buildings';
import RowCell from '../../../components/tables/rowCell';
import TableActionButton from '../../../components/tables/tableActionButton';
import { useBuildingServiceListAreasForBuildingQuery } from '../../../api/enhanced/enhancedBuildings';
import DataTable from '../../../components/tables/dataTable';

interface DetailButtonProps {
  deleteHandler: () => void;
  editHandler: () => void;
  detailsHandler: () => void;
}

function AreaDetailButtons({
  deleteHandler,
  editHandler,
  detailsHandler
}: DetailButtonProps): ReactElement {
  return (
    <div className="flex flex-row">
      <TableActionButton
        icon={<HiSearch size={20} />}
        onClick={() => detailsHandler()}
      />
      <TableActionButton
        icon={<MdEdit size={20} />}
        onClick={() => editHandler()}
      />
      <TableActionButton
        className="bg-red-400 ml-2"
        icon={<IoMdTrash size={20} />}
        onClick={() => deleteHandler()}
      />
    </div>
  );
}

interface AreaTableProps {
  buildingId: string;
  groupId: string;
  deleteHandler: (
    groupId: string,
    buildingId: string,
    areaId: string,
    description: string
  ) => void;
  editHandler: (
    groupId: string,
    buildingId: string,
    areaId: string,
    description: string
  ) => void;
}

function AreaTable({
  groupId,
  buildingId,
  deleteHandler,
  editHandler
}: AreaTableProps): ReactElement {
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<V1Alpha1Area>[]>(
    () => [
      {
        header: 'Gebäude',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.description,
        footer: (props) => props.column.id
      },
      {
        header: 'Details',
        accessorFn: (row) => row.id,
        cell: (info) =>
          AreaDetailButtons({
            deleteHandler: () => {
              deleteHandler(
                groupId,
                buildingId,
                info.getValue() as string,
                info.row.original.description as string
              );
            },
            editHandler: () => {
              editHandler(
                groupId,
                buildingId,
                info.getValue() as string,
                info.row.original.description as string
              );
            },
            detailsHandler: () => {
              navigate(`areas/${info.getValue()}`);
            }
          }),
        footer: (props) => props.column.id
      }
    ],
    [navigate, deleteHandler, buildingId, groupId]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data, isLoading, isSuccess, isError, isFetching } =
    useBuildingServiceListAreasForBuildingQuery(
      buildingId != null && groupId != null
        ? {
            buildingId,
            groupId,
            limit: pageSize,
            page: pageIndex
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.areas ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={isSuccess && (data.areas == null || data.areas.length === 0)}
      pageSize={pageSize}
      table={table}
    />
  );
}

export default AreaTable;
