import React, { ReactElement, useMemo, useState } from 'react';
import {
  getCoreRowModel,
  PaginationState,
  useReactTable,
  ColumnDef
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { HiSearch } from 'react-icons/hi';
import { MdEdit } from 'react-icons/md';
import { V1Alpha1Group } from '../../../api/gen/identity';
import RowCell from '../../../components/tables/rowCell';
import { useIdentityServiceListGroupsQuery } from '../../../api/enhanced/enhancedIdentity';
import DataTable from '../../../components/tables/dataTable';

import TableActionButton from '../../../components/tables/tableActionButton';

interface DetailButtonProps {
  editHandler: () => void;
  detailsHandler: () => void;
}

function GroupDetailButtons({
  editHandler,
  detailsHandler
}: DetailButtonProps): ReactElement {
  return (
    <div className="flex flex-row">
      <TableActionButton
        icon={<MdEdit size={20} />}
        onClick={() => editHandler()}
      />
      <TableActionButton
        icon={<HiSearch size={20} />}
        onClick={() => detailsHandler()}
      />
    </div>
  );
}

interface GroupTableProps {
  editHandler: (groupId: string, groupDescription: string) => void;
}

function GroupTable({ editHandler }: GroupTableProps): ReactElement {
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<V1Alpha1Group>[]>(
    () => [
      {
        header: 'Beschreibung',
        enableSorting: false,
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.description,
        footer: (props) => props.column.id
      },
      {
        header: 'Details',
        accessorFn: (row) => row.groupId,
        cell: (info) =>
          GroupDetailButtons({
            editHandler: () => {
              editHandler(
                info.getValue() as string,
                info.row.original.description as string
              );
            },
            detailsHandler: () => {
              navigate(`groups/${info.getValue()}`);
            }
          }),
        footer: (props) => props.column.id
      }
    ],
    [editHandler, navigate]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data, isLoading, isSuccess, isError, error } =
    useIdentityServiceListGroupsQuery({
      limit: pageSize,
      page: pageIndex
    });

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.groups ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading}
      isError={isError}
      isEmpty={isSuccess && (data.groups == null || data.groups.length === 0)}
      pageSize={pageSize}
      table={table}
    />
  );
}

export default GroupTable;
