import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    identityServiceListGroups: build.query<
      IdentityServiceListGroupsApiResponse,
      IdentityServiceListGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups`,
        params: { limit: queryArg.limit, page: queryArg.page }
      })
    }),
    identityServiceCreateGroup: build.mutation<
      IdentityServiceCreateGroupApiResponse,
      IdentityServiceCreateGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups`,
        method: 'POST',
        body: queryArg.v1Alpha1CreateGroupRequest
      })
    }),
    identityServiceUpdateGroup: build.mutation<
      IdentityServiceUpdateGroupApiResponse,
      IdentityServiceUpdateGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    identityServiceRemoveUserFromGroup: build.mutation<
      IdentityServiceRemoveUserFromGroupApiResponse,
      IdentityServiceRemoveUserFromGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/users/${queryArg.userId}`,
        method: 'DELETE'
      })
    }),
    identityServiceAddUserToGroup: build.mutation<
      IdentityServiceAddUserToGroupApiResponse,
      IdentityServiceAddUserToGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/users/${queryArg.userId}`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    identityServiceListUsers: build.query<
      IdentityServiceListUsersApiResponse,
      IdentityServiceListUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users`,
        params: {
          limit: queryArg.limit,
          page: queryArg.page,
          groupId: queryArg.groupId,
          sortBy: queryArg.sortBy,
          searchBy: queryArg.searchBy
        }
      })
    }),
    identityServiceCreateUser: build.mutation<
      IdentityServiceCreateUserApiResponse,
      IdentityServiceCreateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users`,
        method: 'POST',
        body: queryArg.v1Alpha1CreateUserRequest
      })
    }),
    identityServiceGetUser: build.query<
      IdentityServiceGetUserApiResponse,
      IdentityServiceGetUserApiArg
    >({
      query: (queryArg) => ({ url: `/v1/users/${queryArg.userId}` })
    }),
    identityServiceDeleteUser: build.mutation<
      IdentityServiceDeleteUserApiResponse,
      IdentityServiceDeleteUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}`,
        method: 'DELETE'
      })
    }),
    identityServiceUpdateUser: build.mutation<
      IdentityServiceUpdateUserApiResponse,
      IdentityServiceUpdateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    identityServiceSendInvitationMail: build.mutation<
      IdentityServiceSendInvitationMailApiResponse,
      IdentityServiceSendInvitationMailApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/invitation`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    identityServiceGetUserInfo: build.query<
      IdentityServiceGetUserInfoApiResponse,
      IdentityServiceGetUserInfoApiArg
    >({
      query: (queryArg) => ({ url: `/v1/users/${queryArg.userId}/userinfo` })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as identityApi };
export type IdentityServiceListGroupsApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListGroupsResponse;
export type IdentityServiceListGroupsApiArg = {
  limit?: number;
  page?: number;
};
export type IdentityServiceCreateGroupApiResponse =
  /** status 200 A successful response. */ V1Alpha1CreateGroupResponse;
export type IdentityServiceCreateGroupApiArg = {
  v1Alpha1CreateGroupRequest: V1Alpha1CreateGroupRequest;
};
export type IdentityServiceUpdateGroupApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateGroupResponse;
export type IdentityServiceUpdateGroupApiArg = {
  groupId: string;
  body: {
    description?: string;
  };
};
export type IdentityServiceRemoveUserFromGroupApiResponse =
  /** status 200 A successful response. */ V1Alpha1RemoveUserFromGroupResponse;
export type IdentityServiceRemoveUserFromGroupApiArg = {
  groupId: string;
  userId: string;
};
export type IdentityServiceAddUserToGroupApiResponse =
  /** status 200 A successful response. */ V1Alpha1AddUserToGroupResponse;
export type IdentityServiceAddUserToGroupApiArg = {
  groupId: string;
  userId: string;
  body: object;
};
export type IdentityServiceListUsersApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListUsersResponse;
export type IdentityServiceListUsersApiArg = {
  limit?: number;
  page?: number;
  groupId?: string;
  sortBy?: string;
  searchBy?: string;
};
export type IdentityServiceCreateUserApiResponse =
  /** status 200 A successful response. */ V1Alpha1CreateUserResponse;
export type IdentityServiceCreateUserApiArg = {
  v1Alpha1CreateUserRequest: V1Alpha1CreateUserRequest;
};
export type IdentityServiceGetUserApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetUserResponse;
export type IdentityServiceGetUserApiArg = {
  userId: string;
};
export type IdentityServiceDeleteUserApiResponse =
  /** status 200 A successful response. */ V1Alpha1DeleteUserResponse;
export type IdentityServiceDeleteUserApiArg = {
  userId: string;
};
export type IdentityServiceUpdateUserApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateUserResponse;
export type IdentityServiceUpdateUserApiArg = {
  userId: string;
  body: {
    isAdmin?: boolean;
    isTechnician?: boolean;
    isMailUser?: boolean;
    groups?: V1Alpha1GroupAssignmentUpdate[];
  };
};
export type IdentityServiceSendInvitationMailApiResponse =
  /** status 200 A successful response. */ V1Alpha1SendInvitationMailResponse;
export type IdentityServiceSendInvitationMailApiArg = {
  userId: string;
  body: object;
};
export type IdentityServiceGetUserInfoApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetUserResponse;
export type IdentityServiceGetUserInfoApiArg = {
  userId: string;
};
export type V1Alpha1Role =
  | 'ROLE_UNSPECIFIED'
  | 'ROLE_GROUP_ADMIN'
  | 'ROLE_ADMIN'
  | 'ROLE_GROUP_MEMBER';
export type V1Alpha1Group = {
  groupId?: string;
  description?: string;
  role?: V1Alpha1Role;
};
export type V1Alpha1ListGroupsResponse = {
  total?: string;
  limit?: number;
  page?: number;
  groups?: V1Alpha1Group[];
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1CreateGroupResponse = {
  group?: V1Alpha1Group;
};
export type V1Alpha1CreateGroupRequest = {
  description?: string;
};
export type V1Alpha1UpdateGroupResponse = {
  group?: V1Alpha1Group;
};
export type V1Alpha1RemoveUserFromGroupResponse = {
  userId?: string;
};
export type V1Alpha1AddUserToGroupResponse = {
  userId?: string;
};
export type V1Alpha1Building = {
  id?: string;
  description?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  zipCode?: string;
  groupId?: string;
};
export type V1Alpha1User = {
  userId?: string;
  mail?: string;
  userName?: string;
  idpId?: string;
  isAdmin?: boolean;
  isTechnician?: boolean;
  isMailUser?: boolean;
  groups?: V1Alpha1Group[];
  buildings?: V1Alpha1Building[];
  exportEnabled?: boolean;
};
export type V1Alpha1ListUsersResponse = {
  total?: string;
  limit?: number;
  page?: number;
  users?: V1Alpha1User[];
};
export type V1Alpha1CreateUserResponse = {
  user?: V1Alpha1User;
};
export type V1Alpha1CreateUserRequest = {
  mail?: string;
  userName?: string;
  initialPassword?: string;
  isAdmin?: boolean;
  isTechnician?: boolean;
  isMailUser?: boolean;
  groups?: V1Alpha1Group[];
};
export type V1Alpha1GetUserResponse = {
  user?: V1Alpha1User;
};
export type V1Alpha1DeleteUserResponse = {
  userId?: string;
};
export type V1Alpha1UpdateUserResponse = {
  user?: V1Alpha1User;
};
export type V1Alpha1GroupAssignmentUpdate = {
  isAddition?: boolean;
  group?: V1Alpha1Group;
};
export type V1Alpha1SendInvitationMailResponse = object;
export const {
  useIdentityServiceListGroupsQuery,
  useIdentityServiceCreateGroupMutation,
  useIdentityServiceUpdateGroupMutation,
  useIdentityServiceRemoveUserFromGroupMutation,
  useIdentityServiceAddUserToGroupMutation,
  useIdentityServiceListUsersQuery,
  useIdentityServiceCreateUserMutation,
  useIdentityServiceGetUserQuery,
  useIdentityServiceDeleteUserMutation,
  useIdentityServiceUpdateUserMutation,
  useIdentityServiceSendInvitationMailMutation,
  useIdentityServiceGetUserInfoQuery
} = injectedRtkApi;
