import React, { ReactElement, ReactNode } from 'react';

interface IconButtonProps {
  onClick: () => void;
  icon: ReactNode;
  className?: string;
}

function IconButton({
  onClick,
  icon,
  className = ''
}: IconButtonProps): ReactElement {
  return (
    <button
      className={`flex items-center text-center justify-center z-0 ${className}`}
      type="button"
      onClick={() => {
        onClick();
      }}
    >
      {icon}
    </button>
  );
}

export default IconButton;
