import {
  ColumnDef,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import TableDetailButtons from '../../../components/tables/detailButtons';
import DataTable from '../../../components/tables/dataTable';
import { V1OfflineAlarm } from '../../../api/gen/offline-alarms';
import { useOfflineAlarmServiceListOfflineAlarmQuery } from '../../../api/enhanced/enhancedOfflineAlarms';
import RowCell from '../../../components/tables/rowCell';

type SensorType =
  | 'SENSOR_TYPE_UNSPECIFIED'
  | 'SENSOR_TYPE_TRAP'
  | 'SENSOR_TYPE_TEMP'
  | 'SENSOR_TYPE_CARBON_DIOXIDE'
  | 'SENSOR_TYPE_DOOR'
  | 'SENSOR_TYPE_HUMIDITY';

interface OfflineAlarmTableProps {
  sensorType: SensorType;
  deleteAlarmHandler: (isOpen: boolean, alarmId: string) => void;
  editAlarmHandler: (
    isOpen: boolean,
    alarmId: string,
    sensorType: string
  ) => void;
}

function OfflineAlarmTable({
  sensorType,
  deleteAlarmHandler,
  editAlarmHandler
}: OfflineAlarmTableProps): ReactElement {
  const columns = useMemo<ColumnDef<V1OfflineAlarm>[]>(() => {
    return [
      {
        header: 'Beschreibung',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.description,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Bearbeiten',
        accessorFn: (row) => row.id,
        cell: (info) =>
          TableDetailButtons({
            editHandler: () =>
              editAlarmHandler(
                true,
                info.getValue<string>(),
                info.row.original.sensorType as string
              ),
            deleteHandler: () =>
              deleteAlarmHandler(true, info.getValue<string>())
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];
  }, [deleteAlarmHandler, editAlarmHandler]);

  const { data, isLoading, isFetching, isSuccess, isError } =
    useOfflineAlarmServiceListOfflineAlarmQuery(
      sensorType !== 'SENSOR_TYPE_UNSPECIFIED'
        ? {
            sensorType
          }
        : skipToken
    );

  const table = useReactTable({
    data: data != null && data.alarms != null ? data.alarms : [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true
  });

  return (
    <DataTable
      enablePagination={false}
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={
        isSuccess &&
        (data == null || data.alarms == null || data.alarms.length === 0)
      }
      pageSize={100}
      table={table}
    />
  );
}

export default OfflineAlarmTable;
