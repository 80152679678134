/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { useDailyReportServiceDeleteDailyReportMutation } from '../../../api/enhanced/enhancedReports';

interface DeleteReportModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  groupId: string;
  buildingId: string;
  reportId: string;
}

function DeleteDailyReportModal({
  closeHandler,
  isOpen,
  groupId,
  buildingId,
  reportId
}: DeleteReportModalProps): ReactElement {
  const { t } = useTranslation();

  const [deleteReport, { isLoading, isSuccess, isError, reset }] =
    useDailyReportServiceDeleteDailyReportMutation();

  useEffect(() => {
    if (isSuccess) {
      reset();
      closeHandler();
    }
  }, [reset, closeHandler, isSuccess]);

  const close = (): void => {
    closeHandler();
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={() => {
        close();
      }}
      title={t('modals.deleteReport.title')}
    >
      <div className="flex flex-col w-full">
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.deleteReport.error')}
            />
          ) : null}
        </div>
        <p>{t('modals.deleteReport.message')}</p>
        <div className="flex items-end flex-col w-full mt-5">
          {!isLoading && buildingId != null ? (
            <button
              className=" rounded-lg shadow-md w-24 h-10 bg-red-500 text-white"
              type="button"
              onClick={() =>
                deleteReport({
                  groupId,
                  reportId,
                  buildingId
                })
              }
            >
              {t('modals.deleteBuilding.delete')}
            </button>
          ) : (
            <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-red-500 text-white flex justify-center items-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default DeleteDailyReportModal;
