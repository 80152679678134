import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import TabBar, {
  SensorTabContent,
  SensorType
} from '../../../components/tabs/tabBar';
import BuildingTable from '../../buildings/tables/buildingTable';
import TrapNotificationTable from '../tables/traps/trapNotificationTable';

function TrapNotificationsTabs(): ReactElement {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const navigate = useNavigate();

  const tabs: SensorTabContent[] = [
    {
      key: 0,
      sensorType: SensorType.TRAP,
      tabLabel: 'Alle Benachrichtigungen',
      content: <TrapNotificationTable />
    },
    {
      key: 1,
      sensorType: SensorType.TEMP,
      tabLabel: 'Auslösungen mit Fang',
      content: (
        <BuildingTable
          groupId={groupId}
          clickHandler={(buildingId) => navigate(`building/${buildingId}`)}
        />
      )
    }
  ];

  return <TabBar tabs={tabs} />;
}

export default TrapNotificationsTabs;
