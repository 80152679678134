/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { RootState } from '../../../api/store';
import { useBuildingServiceAddAreaToBuildingMutation } from '../../../api/enhanced/enhancedBuildings';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import FormSubmitButton from '../../../components/form/formSubmitButton';

interface CreateAreaFormValues {
  description: string;
}

interface CreateAreaModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  buildingId: string;
  groupId: string;
}

function CreateAreaModal({
  closeHandler,
  isOpen,
  buildingId,
  groupId
}: CreateAreaModalProps): ReactElement {
  const methods = useForm<CreateAreaFormValues>();
  const { t } = useTranslation();

  const [addArea, { isLoading, isError, isSuccess, reset }] =
    useBuildingServiceAddAreaToBuildingMutation();

  const onSubmit = (data: CreateAreaFormValues): void => {
    if (buildingId && groupId) {
      addArea({
        buildingId,
        groupId,
        body: { area: { description: data.description } }
      });
    }
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [isSuccess, methods, reset, closeHandler]);

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createBuilding.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('modals.createBuilding.description.label')}
              errorMsg={t('modals.createBuilding.description.error') as string}
              placeholder={
                t('modals.createBuilding.description.label') as string
              }
              disabled={isLoading}
              required
            />

            <FormSubmitButton label={t('modals.save')} isLoading={isLoading} />
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateAreaModal;
