import React, { ReactElement } from 'react';
import Breadcrumbs from '../nav/breadcrumbs';

interface PageTitleProps {
  isLoading?: boolean;
  title: string;
}

function PageTitle({ isLoading = false, title }: PageTitleProps): ReactElement {
  if (isLoading) {
    return (
      <div className="mb-4 text-4xl bg-gray-300 rounded-lg w-1/3 font-semibold text-gray-800 animate-pulse h-10" />
    );
  }

  return (
    <div className="flex flex-col mb-4 ">
      <h1 className="mb-2 text-4xl font-semibold text-gray-800">{title}</h1>
      <Breadcrumbs />
    </div>
  );
}

export default PageTitle;
