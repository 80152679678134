import React, { ReactElement, ReactNode } from 'react';

interface TableProps {
  children: ReactNode;
}

function Table({ children }: TableProps): ReactElement {
  return (
    <div className="mt-2 flex flex-col ">
      <div className="py-2 align-middle inline-block">
        <div className="shadow-md overflow-hidden border-b border-gray-200 rounded-lg overflow-x-auto relative">
          <table className="table-auto w-full">{children}</table>
        </div>
      </div>
    </div>
  );
}

interface TableSkeletonProps {
  rowCount: number;
  headerCount: number;
}

export function TableSkeleton({
  headerCount,
  rowCount
}: TableSkeletonProps): ReactElement {
  return (
    <div className="mt-2 flex flex-col">
      <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-md overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  {Array.from(Array(headerCount), (_, i) => (
                    <th aria-label="table-row" key={i}>
                      <div className="px-6 py-3 rounded-lg bg-gray-200  animate-pulse m-3" />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {Array.from(Array(rowCount), (_, i) => (
                  <tr key={i} className="mb-10">
                    {Array.from(Array(headerCount), (empty, j) => (
                      <td aria-label="table-row" key={j}>
                        <div className="px-6 py-4 rounded-lg bg-gray-200  animate-pulse m-3" />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
