import { statisticsApi } from '../gen/statistics';

export const enhancedStatisticsApi = statisticsApi.enhanceEndpoints({
  addTagTypes: [
    'SENSOR_TYPE_UNSPECIFIED',
    'SENSOR_TYPE_TRAP',
    'SENSOR_TYPE_ANALOG_TRAP',
    'SENSOR_TYPE_TEMP',
    'SENSOR_TYPE_CARBON_DIOXIDE',
    'SENSOR_TYPE_DOOR',
    'SENSOR_TYPE_HUMIDITY'
  ],
  endpoints: {
    statisticsServiceGetStatistics: {
      providesTags: (result, error, arg) => [arg.sensorType]
    }
  }
});

export const { useStatisticsServiceGetStatisticsQuery } = enhancedStatisticsApi;
