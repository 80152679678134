import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';
import LabelButton from '../../../../../components/buttons/labelButton';
import AdminAnalogTrapTable from '../../../tables/traps/adminAnalogTrapTable';
import CreateCheckpointModal from '../../../modals/createCheckpointModal';
import UpdateCheckpointModal from '../../../modals/updateCheckpointModal';
import AdminTemperatureTable from '../../../tables/temperature/adminTemperatureTable';
import DeleteCheckpointModal from '../../../modals/deleteCheckpointModal';
import useDebounce from '../../../../../hooks/useDebounce';
import SearchBar from '../../../../../components/searchBar/searchBar';

interface TemperatureAdminPageProps {
  buildingId?: string;
  groupId?: string;
}

function TemperatureAdminPage({
  buildingId,
  groupId
}: TemperatureAdminPageProps): ReactElement {
  const { t } = useTranslation();
  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [editState, setEditState] = useState({
    isOpen: false,
    checkpointId: ''
  });

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const [deleteState, setDeleteState] = useState({
    isOpen: false,
    checkpointId: '',
    sensorId: ''
  });

  return (
    <div>
      <div className="flex flex-col  w-full">
        <div className="w-full flex flex-row justify-between">
          <LabelButton
            label={t('administration.devices.addDevice')}
            className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
            icon={<IoMdAdd size={20} />}
            onClick={() => setCreateIsOpen(true)}
          />
          <SearchBar
            className="h-12 rounded-lg"
            changeHandler={(value: string) => setSearchTerm(value)}
          />
        </div>
        <AdminTemperatureTable
          groupId={groupId}
          buildingId={buildingId}
          searchTerm={debouncedSearchTerm}
          editDeviceModalState={(isOpen: boolean, checkpointId: string) => {
            setEditState({ isOpen, checkpointId });
          }}
          deleteDeviceModalState={(
            isOpen: boolean,
            checkpointId: string,
            sensorId: string
          ) => {
            setDeleteState({ isOpen, checkpointId, sensorId });
          }}
        />
      </div>

      <CreateCheckpointModal
        isOpen={createIsOpen}
        closeHandler={() => {
          setCreateIsOpen(false);
        }}
        buildingId={buildingId}
        groupId={groupId}
        sensorType="SENSOR_TYPE_TEMP"
      />

      {buildingId != null &&
        editState.checkpointId !== '' &&
        groupId != null && (
          <UpdateCheckpointModal
            groupId={groupId}
            isOpen={editState.isOpen}
            closeHandler={() => {
              setEditState({ isOpen: false, checkpointId: '' });
            }}
            buildingId={buildingId}
            checkpointId={editState.checkpointId}
            sensorType="SENSOR_TYPE_TEMP"
          />
        )}

      {buildingId != null && groupId != null && (
        <DeleteCheckpointModal
          closeHandler={() =>
            setDeleteState({ checkpointId: '', isOpen: false, sensorId: '' })
          }
          isOpen={deleteState.isOpen}
          checkpointId={deleteState.checkpointId}
          groupId={groupId}
          buildingId={buildingId}
          sensorId={deleteState.sensorId}
          sensorType="SENSOR_TYPE_TEMP"
        />
      )}
    </div>
  );
}

export default TemperatureAdminPage;
