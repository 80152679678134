/* eslint-disable react/jsx-props-no-spreading */
import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormSelect, {
  FormSelectOption
} from '../../../components/form/formSelect';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import FormTextInput from '../../../components/form/formTextInput';
import { V1Alpha1Recipients } from '../../../api/gen/reports';
import MultiselectList, {
  MultiselectContent
} from '../../../components/multiselectList/multiselectList';
import { useIdentityServiceListUsersQuery } from '../../../api/gen/identity';
import { useDailyReportServiceCreateDailyReportMutation } from '../../../api/enhanced/enhancedReports';
import { useBuildingServiceListBuildingsQuery } from '../../../api/enhanced/enhancedBuildings';

interface FormValues {
  buildingId: string;
  description: string;
}

interface CreateDailyReportModalProps {
  groupId: string;
  closeHandler: () => void;
  isOpen: boolean;
}

function CreateDailyTrapReportModal({
  groupId,
  closeHandler,
  isOpen
}: CreateDailyReportModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<MultiselectContent[]>([]);

  const [createReport, { isLoading, isSuccess, isError, reset }] =
    useDailyReportServiceCreateDailyReportMutation();

  useEffect(() => {
    if (isSuccess) {
      methods.reset();
      reset();
      closeHandler();
    }
  }, [reset, methods, closeHandler, isSuccess]);

  const {
    data,
    isLoading: usersIsLoading,
    isSuccess: usersIsSuccess,
    isError: usersIsError
  } = useIdentityServiceListUsersQuery({
    groupId
  });

  useEffect(() => {
    if (data != null && data.users != null && data.users.length > 0) {
      const content: MultiselectContent[] = [];
      data.users.forEach((user) => {
        content.push({
          selected: false,
          key: user.userId as string,
          label: user.userName as string
        });
      });
      setSelectedUsers(content);
    }
  }, [data]);

  const { data: buildingData } = useBuildingServiceListBuildingsQuery(
    groupId !== ''
      ? {
          groupId,
          limit: 200,
          page: 0
        }
      : skipToken
  );

  const onSubmit = (formData: FormValues): void => {
    const recipients: V1Alpha1Recipients[] = [];
    if (data != null && data.users != null && selectedUsers.length > 0) {
      data.users.forEach((user) => {
        const selected = selectedUsers.some(
          (currUser) => currUser.key === user.userId && currUser.selected
        );
        if (selected) {
          recipients.push({
            userId: user.userId,
            mail: user.mail
          });
        }
      });
    }

    createReport({
      groupId,
      buildingId: formData.buildingId,
      dailyReportServiceCreateDailyReportBody: {
        recipients,
        description: formData.description
      }
    });
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createReport.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('features.reports.modals.create.description.label')}
              errorMsg={
                t('features.reports.modals.create.description.error') as string
              }
              placeholder={
                t('features.reports.modals.create.description.label') as string
              }
              disabled={isLoading}
            />

            {buildingData != null &&
              buildingData.buildings != null &&
              buildingData.buildings.length > 0 && (
                <FormSelect
                  id="buildingId"
                  label={t('features.reports.modals.create.building.label')}
                  errorMsg={
                    t('features.reports.modals.create.building.error') as string
                  }
                  placeholder={
                    t('features.reports.modals.create.building.label') as string
                  }
                  disabled={isLoading}
                  options={buildingData.buildings?.map(
                    (option): FormSelectOption => {
                      return {
                        value: option?.id != null ? option.id : '',
                        description:
                          option?.description != null ? option.description : ''
                      };
                    }
                  )}
                  required
                />
              )}

            <div>
              <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                {t('features.alarms.modals.create.users')}
              </div>
              <MultiselectList
                className="max-h-40 overflow-auto"
                selectHandler={(id: string, checkedState: boolean) => {
                  setSelectedUsers(
                    selectedUsers.map((user) => {
                      if (user.key === id) {
                        return { ...user, selected: checkedState };
                      }

                      return user;
                    })
                  );
                }}
                content={selectedUsers}
              />
            </div>

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateDailyTrapReportModal;
