import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    floorPlanServiceDownloadFloorPlan: build.query<
      FloorPlanServiceDownloadFloorPlanApiResponse,
      FloorPlanServiceDownloadFloorPlanApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/owners/${queryArg.groupId}/buildings/${queryArg.buildingId}/areas/${queryArg.areaId}/floorplan`
      })
    }),
    floorPlanServiceSaveFloorPlan: build.mutation<
      FloorPlanServiceSaveFloorPlanApiResponse,
      FloorPlanServiceSaveFloorPlanApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/owners/${queryArg.groupId}/buildings/${queryArg.buildingId}/areas/${queryArg.areaId}/floorplan`,
        method: 'POST',
        body: queryArg.body
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as floorPlanApi };
export type FloorPlanServiceDownloadFloorPlanApiResponse =
  /** status 200 A successful response. */ V1Alpha1DownloadFloorPlanResponse;
export type FloorPlanServiceDownloadFloorPlanApiArg = {
  groupId: string;
  buildingId: string;
  areaId: string;
};
export type FloorPlanServiceSaveFloorPlanApiResponse =
  /** status 200 A successful response. */ V1Alpha1SaveFloorPlanResponse;
export type FloorPlanServiceSaveFloorPlanApiArg = {
  groupId: string;
  buildingId: string;
  areaId: string;
  body: {
    imageType?: string;
    data?: string;
  };
};
export type V1Alpha1DownloadFloorPlanResponse = {
  data?: string;
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1SaveFloorPlanResponse = {
  groupId?: string;
  buildingId?: string;
  areaId?: string;
};
export const {
  useFloorPlanServiceDownloadFloorPlanQuery,
  useFloorPlanServiceSaveFloorPlanMutation
} = injectedRtkApi;
