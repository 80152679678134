/* eslint-disable react/jsx-props-no-spreading */
import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useBuildingServiceListBuildingsQuery } from '../../../api/gen/buildings';
import FormDatePicker from '../../../components/form/formDatePicker';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormSelect, {
  FormSelectOption
} from '../../../components/form/formSelect';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { useHistoryServiceCreateCsvExportMutation } from '../../../api/gen/history';

interface FormValues {
  buildingId: string;
  checkpointId: string;
  startDate: Date;
  endDate: Date;
}

interface CreateReportModalProps {
  groupId: string;
  CSVType: 'CSV_TYPE_TRAPS' | 'CSV_TYPE_TEMPERATURE' | 'CSV_TYPE_DOOR';
  closeHandler: () => void;
  isOpen: boolean;
}

const downloadFile = (responseData: string): void => {
  const data = Uint8Array.from(window.atob(responseData), (c) =>
    c.charCodeAt(0)
  );
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

  // Create Link
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${uuidv4()}.csv`);
  document.body.appendChild(link);

  // Download and cleanup
  link.click();
  link.parentNode?.removeChild(link);
};

function CreateReportModal({
  groupId,
  CSVType,
  closeHandler,
  isOpen
}: CreateReportModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();

  const [formkey, setFormkey] = useState(Date.now());

  const [createExport, { data, isLoading, isSuccess, isError, reset }] =
    useHistoryServiceCreateCsvExportMutation();

  const { data: buildingData } = useBuildingServiceListBuildingsQuery(
    groupId !== ''
      ? {
          groupId
        }
      : skipToken
  );

  useEffect(() => {
    if (isSuccess && data != null && data.data != null) {
      downloadFile(data.data);
      methods.reset();
      reset();
      setFormkey(Date.now());
      closeHandler();
    }
  }, [methods, closeHandler, isSuccess, reset, data]);

  const onSubmit = (formData: FormValues): void => {
    const endTime = new Date(Date.now());
    const startTime = new Date(Date.now());

    startTime.setTime(formData.startDate.setHours(0, 0, 0, 0));
    endTime.setTime(formData.endDate.setHours(23, 59, 59, 59));

    if (startTime != null && endTime != null) {
      createExport({
        groupId,
        buildingId: formData.buildingId,
        csvType: CSVType,
        historyServiceCreateCsvExportBody: {
          startTime: startTime?.toISOString(),
          endTime: endTime?.toISOString()
        }
      });
    }
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
    setFormkey(Date.now());
  };

  return (
    <FormProvider key={formkey} {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createCSVExport.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label="Fehler beim Erstellen der CSV"
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {buildingData != null &&
              buildingData.buildings != null &&
              buildingData.buildings.length > 0 && (
                <FormSelect
                  id="buildingId"
                  label={t('modals.createReport.building.label')}
                  errorMsg={t('modals.createReport.building.error') as string}
                  placeholder={
                    t('modals.createReport.building.label') as string
                  }
                  disabled={isLoading}
                  options={buildingData.buildings?.map(
                    (option): FormSelectOption => {
                      return {
                        value: option?.id != null ? option.id : '',
                        description:
                          option?.description != null ? option.description : ''
                      };
                    }
                  )}
                  required
                />
              )}

            <FormDatePicker
              id="startDate"
              label="Startzeit"
              placeholder="Startzeit auswählen"
              required
            />

            <FormDatePicker
              id="endDate"
              label="Endzeit"
              placeholder="Endzeit auswählen"
              required
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateReportModal;
