import React, { ReactElement, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCheckpointServiceGetCheckpointQuery } from '../../../../../api/enhanced/enhancedCheckpoints';
import CheckpointDetails from '../../../components/checkpointDetails';
import PageTitle from '../../../../../components/title/pageTitle';
import OnlineStatePill from '../../../../../components/pill/onlineStatePill';
import CheckpointState from '../../../components/checkpointState';
import TabBar, { TabContent } from '../../../../../components/tabs/tabBar';
import HistoryTable from '../../../tables/carbonDioxide/historyTable';
import CommentModal from '../../../modals/commentModal';
import CarboneDioxideGraph from './carbonDioxideGraph';
import IntervalComponent from '../../../../../components/interval/intervalComponent';

function CarbonDioxideDetailPage(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId, checkpointId } = useParams();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null
  ]);

  const handleSetDateRange = useCallback(
    (range: [Date | null, Date | null]) => {
      setDateRange(range);
    },
    []
  );

  const [commentModalState, setCommentModalState] = useState({
    isOpen: false,
    entryId: ''
  });

  const { data, isLoading, isFetching, isError, error, isSuccess } =
    useCheckpointServiceGetCheckpointQuery(
      buildingId != null &&
        checkpointId != null &&
        groupId != null &&
        groupId !== ''
        ? {
            checkpointId,
            buildingId,
            groupId,
            sensorType: 'SENSOR_TYPE_CARBON_DIOXIDE'
          }
        : skipToken
    );

  const tabContent: TabContent[] = [
    {
      key: 0,
      tabLabel: t('trapDetails.tabs.history'),
      content: (
        <HistoryTable
          commentModalStateHandler={(isOpen: boolean, id: string) => {
            setCommentModalState({ isOpen, entryId: id });
          }}
          checkpointId={checkpointId}
          groupId={groupId}
          buildingId={buildingId}
          alarmLimit={
            data != null &&
            data.checkpoint != null &&
            data.checkpoint.carbonDioxidePayload != null
              ? data.checkpoint.carbonDioxidePayload.alarmLimit
              : undefined
          }
          warningLimit={
            data != null &&
            data.checkpoint != null &&
            data.checkpoint.carbonDioxidePayload != null
              ? data.checkpoint.carbonDioxidePayload.warningLimit
              : undefined
          }
        />
      )
    },
    {
      key: 1,
      tabLabel: 'Kohlenstoffdioxidverlauf',
      content: (
        <div className="flex flex-col">
          <IntervalComponent setInterval={handleSetDateRange} />
          <CarboneDioxideGraph
            checkpointId={checkpointId}
            buildingId={buildingId}
            groupId={groupId}
            startTime={
              dateRange[0] != null ? dateRange[0]?.toISOString() : undefined
            }
            endTime={
              dateRange[1] != null ? dateRange[1]?.toISOString() : undefined
            }
          />
        </div>
      )
    }
  ];

  return (
    <div className="flex flex-col px-8 w-full">
      <div className="flex flex-row">
        <PageTitle isLoading={isLoading} title={t('trapDetails.title')} />
        <OnlineStatePill
          isLoading={isLoading}
          className="h-10 w-20 ml-2"
          checkpointId={data?.checkpoint?.id}
          state={data?.checkpoint?.loraMetadata?.isOnline}
          timestamp={data?.checkpoint?.loraMetadata?.lastSignal}
        />
      </div>
      {data != null && data.checkpoint != null && (
        <div className="flex xl:flex-row md:flex-col my-2">
          <CheckpointDetails checkpoint={data?.checkpoint} />
          <CheckpointState checkpoint={data?.checkpoint} />
        </div>
      )}
      <TabBar className="mt-4" tabs={tabContent} />

      {buildingId != null &&
        groupId != null &&
        groupId !== '' &&
        checkpointId != null && (
          <CommentModal
            entryId={commentModalState.entryId}
            buildingId={buildingId}
            groupId={groupId}
            checkpointId={checkpointId}
            sensorType="SENSOR_TYPE_CARBON_DIOXIDE"
            defaultComments={[]}
            isOpen={commentModalState.isOpen}
            closeHandler={() => {
              setCommentModalState({ isOpen: false, entryId: '' });
            }}
          />
        )}
    </div>
  );
}

export default CarbonDioxideDetailPage;
