import React, { ReactElement, useMemo, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { MdEdit } from 'react-icons/md';
import { skipToken } from '@reduxjs/toolkit/query';
import DateTableLabel from '../../../../components/date/dateTableLabel';
import TableActionButton from '../../../../components/tables/tableActionButton';
import DataTable from '../../../../components/tables/dataTable';
import UserComment from '../../components/traps/userComment';
import { useHistoryServiceListHistoryQuery } from '../../../../api/enhanced/enhancedHistory';
import { V1Alpha1HistoryEntry } from '../../../../api/gen/history';
import DoorPill from '../../../../components/pill/doorPill';
import { V1Alpha1DoorSensorType } from '../../../../api/gen/checkpoints';
import DoorDistancePill from '../../../../components/pill/doorDistancePill';

interface HistoryTableProps {
  groupId?: string;
  buildingId?: string;
  checkpointId?: string;
  doorSensorType: V1Alpha1DoorSensorType;
  openingDistance?: number;
  onlyStateChanges?: boolean;
  commentModalStateHandler: (isOpen: boolean, entryId: string) => void;
}

function HistoryTable({
  groupId,
  buildingId,
  checkpointId,
  doorSensorType,
  openingDistance,
  commentModalStateHandler,
  onlyStateChanges = false
}: HistoryTableProps): ReactElement {
  const columns = useMemo<
    ColumnDef<V1Alpha1HistoryEntry>[]
  >((): ColumnDef<V1Alpha1HistoryEntry>[] => {
    const cols: ColumnDef<V1Alpha1HistoryEntry>[] = [
      {
        header: 'Türstatus',
        accessorFn: (row) => row.doorPayload?.status,
        cell: (info) => DoorPill({ state: info.getValue<number>() }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Distanz',
        id: 'distance',
        accessorFn: (row) => row.doorPayload?.distance,
        cell: (info) =>
          DoorDistancePill({
            distance: info.getValue<number>(),
            openingDistance
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Zeitpunkt des Signals',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.timestamp,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Kommentar',
        cell: (info) =>
          UserComment({ comment: info.getValue<string>(), className: 'px-6' }),
        accessorFn: (row) => row.comment,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Bearbeiten',
        accessorFn: (row) => row.id,
        cell: (info) =>
          TableActionButton({
            icon: <MdEdit size={20} />,
            onClick: () => {
              commentModalStateHandler(true, info.getValue<string>());
            }
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];

    return cols;
  }, [commentModalStateHandler, openingDistance]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const paramsValid =
    checkpointId != null &&
    groupId != null &&
    buildingId != null &&
    groupId !== '';

  const { data, isLoading, isFetching, isSuccess, isError } =
    useHistoryServiceListHistoryQuery(
      paramsValid
        ? {
            checkpointId,
            buildingId,
            groupId,
            sensorType: 'SENSOR_TYPE_DOOR',
            page: pageIndex,
            limit: pageSize,
            onlyStateChanges
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.entries ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: {
      columnVisibility: {
        distance: doorSensorType === 'DOOR_SENSOR_TYPE_DISTANCE'
      },
      pagination
    },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={isSuccess && (data.entries == null || data.entries.length === 0)}
      pageSize={pageSize}
      table={table}
    />
  );
}

export default HistoryTable;
