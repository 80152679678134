import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    alarmServiceListAlarms: build.query<
      AlarmServiceListAlarmsApiResponse,
      AlarmServiceListAlarmsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/alarms`,
        params: {
          areaId: queryArg.areaId,
          limit: queryArg.limit,
          page: queryArg.page,
          sensorType: queryArg.sensorType
        }
      })
    }),
    alarmServiceCreateAlarm: build.mutation<
      AlarmServiceCreateAlarmApiResponse,
      AlarmServiceCreateAlarmApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/alarms`,
        method: 'POST',
        body: queryArg.alarmServiceCreateAlarmBody
      })
    }),
    alarmServiceGetAlarm: build.query<
      AlarmServiceGetAlarmApiResponse,
      AlarmServiceGetAlarmApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/alarms/${queryArg.alarmId}`,
        params: { sensorType: queryArg.sensorType }
      })
    }),
    alarmServiceDeleteAlarm: build.mutation<
      AlarmServiceDeleteAlarmApiResponse,
      AlarmServiceDeleteAlarmApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/alarms/${queryArg.alarmId}`,
        method: 'DELETE',
        params: { sensorType: queryArg.sensorType }
      })
    }),
    alarmServiceUpdateAlarm: build.mutation<
      AlarmServiceUpdateAlarmApiResponse,
      AlarmServiceUpdateAlarmApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/alarms/${queryArg.alarmId}`,
        method: 'PATCH',
        body: queryArg.alarmServiceUpdateAlarmBody
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as alarmApi };
export type AlarmServiceListAlarmsApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListAlarmsResponse;
export type AlarmServiceListAlarmsApiArg = {
  groupId: string;
  buildingId: string;
  areaId?: string;
  limit?: number;
  page?: number;
  sensorType?:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
};
export type AlarmServiceCreateAlarmApiResponse =
  /** status 200 A successful response. */ V1Alpha1CreateAlarmResponse;
export type AlarmServiceCreateAlarmApiArg = {
  groupId: string;
  buildingId: string;
  alarmServiceCreateAlarmBody: AlarmServiceCreateAlarmBody;
};
export type AlarmServiceGetAlarmApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetAlarmResponse;
export type AlarmServiceGetAlarmApiArg = {
  groupId: string;
  buildingId: string;
  alarmId: string;
  sensorType?:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
};
export type AlarmServiceDeleteAlarmApiResponse =
  /** status 200 A successful response. */ V1Alpha1DeleteAlarmResponse;
export type AlarmServiceDeleteAlarmApiArg = {
  groupId: string;
  buildingId: string;
  alarmId: string;
  sensorType?:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
};
export type AlarmServiceUpdateAlarmApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateAlarmResponse;
export type AlarmServiceUpdateAlarmApiArg = {
  groupId: string;
  buildingId: string;
  alarmId: string;
  alarmServiceUpdateAlarmBody: AlarmServiceUpdateAlarmBody;
};
export type V1Alpha1SensorType =
  | 'SENSOR_TYPE_UNSPECIFIED'
  | 'SENSOR_TYPE_TRAP'
  | 'SENSOR_TYPE_TEMP'
  | 'SENSOR_TYPE_CARBON_DIOXIDE'
  | 'SENSOR_TYPE_DOOR'
  | 'SENSOR_TYPE_HUMIDITY';
export type V1Alpha1Weekday = {
  key?: string;
  checked?: boolean;
};
export type V1Alpha1Recipient = {
  userId?: string;
  mail?: string;
};
export type V1Alpha1AssignedCheckpoint = {
  checkpointId?: string;
  description?: string;
};
export type V1Alpha1Alarm = {
  alarmId?: string;
  groupId?: string;
  buildingId?: string;
  areaId?: string;
  description?: string;
  sensorType?: V1Alpha1SensorType;
  repeatedMessage?: boolean;
  repetitionInterval?: number;
  isActive?: boolean;
  delayInterval?: number;
  isAreaAlarm?: boolean;
  isBuildingAlarm?: boolean;
  startTime?: string;
  endTime?: string;
  weekdays?: V1Alpha1Weekday[];
  recipients?: V1Alpha1Recipient[];
  assignedCheckpoint?: V1Alpha1AssignedCheckpoint[];
  selectedIntervals?: boolean;
  selectedWeekdays?: boolean;
};
export type V1Alpha1ListAlarmsResponse = {
  total?: string;
  page?: number;
  limit?: number;
  alarms?: V1Alpha1Alarm[];
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1CreateAlarmResponse = {
  alarm?: V1Alpha1Alarm;
};
export type AlarmServiceCreateAlarmBody = {
  alarm?: V1Alpha1Alarm;
};
export type V1Alpha1GetAlarmResponse = {
  alarm?: V1Alpha1Alarm;
};
export type V1Alpha1DeleteAlarmResponse = {
  alarmId?: string;
};
export type V1Alpha1UpdateAlarmResponse = {
  alarm?: V1Alpha1Alarm;
};
export type V1Alpha1RecipientUpdate = {
  userId?: string;
  mail?: string;
  selected?: boolean;
};
export type V1Alpha1AssignedCheckpointUpdate = {
  checkpointId?: string;
  description?: string;
  selected?: boolean;
};
export type AlarmServiceUpdateAlarmBody = {
  areaId?: string;
  description?: string;
  sensorType?: V1Alpha1SensorType;
  repeatedMessage?: boolean;
  repetitionInterval?: number;
  isActive?: boolean;
  delayInterval?: number;
  isAreaAlarm?: boolean;
  isBuildingAlarm?: boolean;
  recipients?: V1Alpha1RecipientUpdate[];
  assignedCheckpoint?: V1Alpha1AssignedCheckpointUpdate[];
  startTime?: string;
  endTime?: string;
  weekdays?: V1Alpha1Weekday[];
  selectedIntervals?: boolean;
  selectedWeekdays?: boolean;
};
export const {
  useAlarmServiceListAlarmsQuery,
  useAlarmServiceCreateAlarmMutation,
  useAlarmServiceGetAlarmQuery,
  useAlarmServiceDeleteAlarmMutation,
  useAlarmServiceUpdateAlarmMutation
} = injectedRtkApi;
