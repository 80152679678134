import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import L from 'leaflet';
import TabBar, { TabContent } from '../../../components/tabs/tabBar';
import PlanManagementTrapTable from '../tables/planManagementTrapTable';
import Map, {
  getMarkerForCarbonDioxide,
  getMarkerForDoor,
  getMarkerForTemperature,
  getMarkerForTrap,
  greyIcon,
  MarkerState
} from '../../../components/map/map';
import {
  useCheckpointServiceListCheckpointsForBuildingQuery,
  useCheckpointServiceUpdateCheckpointMutation
} from '../../../api/enhanced/enhancedCheckpoints';
import PageTitle from '../../../components/title/pageTitle';
import LabelButton from '../../../components/buttons/labelButton';
import CardSkeleton from '../../../components/card/cardSkeleton';
import AddFloorPlanModal from '../modals/addFloorPlanModal';
import { useFloorPlanServiceDownloadFloorPlanQuery } from '../../../api/gen/floorPlan';
import { useBuildingServiceGetAreaQuery } from '../../../api/enhanced/enhancedBuildings';
import PlanManagementTemperatureTable from '../tables/planManagementTemperatureTable';
import PlanManagementCarbonDioxideTable from '../tables/planManagementCarbonDioxideTable';
import PlanManagementDoorTable from '../tables/planManagementDoorTable';

function FloorPlanManagement(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId, areaId } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);

  const getSensorType = (
    currentIndex: number
  ):
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR' => {
    switch (currentIndex) {
      case 0:
        return 'SENSOR_TYPE_TRAP';
      case 1:
        return 'SENSOR_TYPE_TEMP';
      case 2:
        return 'SENSOR_TYPE_CARBON_DIOXIDE';
      case 3:
        return 'SENSOR_TYPE_DOOR';
      default:
        return 'SENSOR_TYPE_UNSPECIFIED';
    }
  };

  const tabs: TabContent[] = [
    {
      key: 0,
      tabLabel: t('tabs.traps'),
      content: (
        <PlanManagementTrapTable
          buildingId={buildingId}
          areaId={areaId}
          groupId={groupId}
        />
      )
    },
    {
      key: 1,
      tabLabel: t('tabs.temp'),
      content: (
        <PlanManagementTemperatureTable
          buildingId={buildingId}
          areaId={areaId}
          groupId={groupId}
        />
      )
    },
    {
      key: 2,
      tabLabel: t('tabs.co2'),
      content: (
        <PlanManagementCarbonDioxideTable
          buildingId={buildingId}
          areaId={areaId}
          groupId={groupId}
        />
      )
    },
    {
      key: 3,
      tabLabel: t('tabs.door'),
      content: (
        <PlanManagementDoorTable
          buildingId={buildingId}
          areaId={areaId}
          groupId={groupId}
        />
      )
    }
  ];

  const [isAddFloorPlanOpen, setIsAddFloorPlanOpen] = useState(false);
  const [markers, setMarkers] = useState<MarkerState[]>([]);
  const [updateCheckpoint] = useCheckpointServiceUpdateCheckpointMutation();

  const {
    data: imageData,
    isLoading,
    isFetching
  } = useFloorPlanServiceDownloadFloorPlanQuery(
    buildingId != null && areaId != null && groupId != null
      ? {
          buildingId,
          groupId,
          areaId
        }
      : skipToken
  );

  const {
    data: checkpointData,
    isLoading: checkpointLoading,
    isFetching: checkpointIsFetching
  } = useCheckpointServiceListCheckpointsForBuildingQuery(
    buildingId != null && areaId != null && groupId != null
      ? {
          buildingId,
          groupId,
          areaId,
          includeUnassigned: true,
          sensorType: getSensorType(selectedTab)
        }
      : skipToken
  );

  const { data: areaData } = useBuildingServiceGetAreaQuery(
    buildingId != null &&
      areaId != null &&
      groupId != null &&
      !isLoading &&
      !isFetching
      ? {
          buildingId,
          groupId,
          areaId
        }
      : skipToken
  );

  useEffect(() => {
    if (
      checkpointData != null &&
      checkpointData.checkpoints != null &&
      !isLoading &&
      !isFetching &&
      !checkpointLoading &&
      !checkpointIsFetching
    ) {
      const createdMarkers: MarkerState[] = checkpointData.checkpoints.map(
        (checkpoint): MarkerState => {
          const lat = checkpoint.latitude != null ? checkpoint.latitude : 0;
          const lng = checkpoint.longitude != null ? checkpoint.longitude : 0;

          let currentMarker: L.Marker = L.marker([0, 0]);
          if (checkpoint.trapPayload != null) {
            currentMarker = L.marker([lat, lng], {
              icon:
                checkpoint.loraMetadata != null &&
                checkpoint.trapPayload.state != null &&
                checkpoint.loraMetadata.isOnline
                  ? getMarkerForTrap(
                      checkpoint.trapPayload.state,
                      checkpoint.loraMetadata.isOnline,
                      checkpoint.loraMetadata.lastSignal
                    )
                  : greyIcon,
              draggable: true
            });

            currentMarker = currentMarker.bindTooltip(
              `Prüfpunkt: ${checkpoint.checkpointNumber}, Beschreibung: ${checkpoint.description}`
            );
          }

          if (checkpoint.carbonDioxidePayload != null) {
            currentMarker = L.marker([lat, lng], {
              icon:
                checkpoint.loraMetadata != null &&
                checkpoint.carbonDioxidePayload.carbonDioxide != null &&
                checkpoint.loraMetadata.isOnline
                  ? getMarkerForCarbonDioxide(
                      checkpoint.carbonDioxidePayload.carbonDioxide,
                      checkpoint.loraMetadata.isOnline,
                      checkpoint.carbonDioxidePayload.warningLimit,
                      checkpoint.carbonDioxidePayload.alarmLimit,
                      checkpoint.loraMetadata.lastSignal
                    )
                  : greyIcon,
              draggable: true
            });

            currentMarker = currentMarker.bindTooltip(
              `Prüfpunkt: ${checkpoint.checkpointNumber}, Beschreibung: ${checkpoint.description}`
            );
          }

          if (checkpoint.doorPayload != null) {
            currentMarker = L.marker([lat, lng], {
              icon:
                checkpoint.loraMetadata != null &&
                checkpoint.doorPayload.status != null &&
                checkpoint.loraMetadata.isOnline
                  ? getMarkerForDoor(
                      checkpoint.doorPayload.status,
                      checkpoint.loraMetadata.isOnline,
                      checkpoint.loraMetadata.lastSignal
                    )
                  : greyIcon,
              draggable: true
            });

            currentMarker = currentMarker.bindTooltip(
              `Prüfpunkt: ${checkpoint.checkpointNumber}, Beschreibung: ${checkpoint.description}`
            );
          }

          if (checkpoint.temperaturePayload != null) {
            currentMarker = L.marker([lat, lng], {
              icon:
                checkpoint.loraMetadata != null &&
                checkpoint.temperaturePayload.temperature != null &&
                checkpoint.loraMetadata.isOnline
                  ? getMarkerForTemperature(
                      checkpoint.temperaturePayload.temperature,
                      checkpoint.loraMetadata.isOnline,
                      checkpoint.temperaturePayload.minLimit,
                      checkpoint.temperaturePayload.maxLimit,
                      checkpoint.loraMetadata.lastSignal
                    )
                  : greyIcon,
              draggable: true
            });

            currentMarker = currentMarker.bindTooltip(
              `Prüfpunkt: ${checkpoint.checkpointNumber}, Beschreibung: ${checkpoint.description}`
            );
          }

          return {
            marker: currentMarker,
            moveHandler: (newLat: number, newLng: number) => {
              if (
                checkpoint != null &&
                checkpoint.id != null &&
                buildingId != null &&
                groupId != null
              ) {
                updateCheckpoint({
                  groupId,
                  buildingId,
                  checkpointId: checkpoint.id,
                  sensorType: getSensorType(selectedTab),
                  checkpointServiceUpdateCheckpointBody: {
                    updateLocation: true,
                    checkpoint: {
                      areaId,
                      latitude: newLat,
                      longitude: newLng
                    }
                  }
                });
              }
            }
          };
        }
      );

      setMarkers(createdMarkers);
    }
  }, [
    areaId,
    buildingId,
    checkpointData,
    checkpointIsFetching,
    checkpointLoading,
    groupId,
    isFetching,
    isLoading,
    selectedTab,
    updateCheckpoint
  ]);

  return (
    <div className="flex flex-col p-8 w-full min-h-screen">
      {areaData != null && areaData.area != null && (
        <PageTitle
          title={`${t('administration.building.floorManagement.title')} - ${
            areaData.area.description
          }`}
        />
      )}
      <LabelButton
        label={t('administration.building.floorManagement.addPlan')}
        className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600 mb-4"
        icon={<IoMdAdd size={20} />}
        onClick={() => {
          if (buildingId != null && areaId != null) {
            setIsAddFloorPlanOpen(true);
          }
        }}
      />

      {(isFetching ||
        isLoading ||
        checkpointIsFetching ||
        checkpointLoading) && <CardSkeleton className="h-1/2 w-full" />}
      {imageData != null &&
        imageData.data != null &&
        imageData.data !== '' &&
        checkpointData != null &&
        checkpointData.checkpoints != null && (
          <div className="h-96 w-full mb-4 z-0">
            <Map
              data={`data:image/png;base64,${imageData.data}`}
              markers={markers}
            />
          </div>
        )}

      <TabBar
        index={selectedTab}
        tabs={tabs}
        changeHandler={(tabIndex: number) => {
          setSelectedTab(tabIndex);
        }}
      />

      <AddFloorPlanModal
        isOpen={isAddFloorPlanOpen}
        closeHandler={() => setIsAddFloorPlanOpen(false)}
        groupId={groupId}
        buildingId={buildingId}
        areaId={areaId}
      />
    </div>
  );
}

export default FloorPlanManagement;
