import React, { ReactElement, useState } from 'react';
import de from 'date-fns/locale/de';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RiAlertFill } from 'react-icons/ri';
import FormErrorLabel from './formErrorLabel';

interface FormDateRangePickerProps {
  label: string;
  placeholder?: string;
  required?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  value: [Date | null, Date | null];
  setInterval: (interval: [Date | null, Date | null]) => void;
}

function FormDateRangePicker({
  label,
  placeholder = 'Bitte Zeitraum auswählen',
  required = false,
  errorMsg = 'Error',
  disabled = false,
  value,
  setInterval
}: FormDateRangePickerProps): ReactElement {
  registerLocale('de', de);
  const [startDate, endDate] = value;

  return (
    <div className="flex flex-col w-full mt-2">
      <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
      </div>

      <DatePicker
        className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none"
        selectsRange
        locale="de"
        startDate={startDate}
        endDate={endDate}
        onChange={(rangeUpdate) => {
          setInterval(rangeUpdate);
        }}
        dateFormat="dd.MM.yyyy"
        disabled={disabled}
        placeholderText={placeholder}
      />

      {required && (
        <FormErrorLabel icon={<RiAlertFill size={20} />} label={errorMsg} />
      )}
    </div>
  );
}

export default FormDateRangePicker;
