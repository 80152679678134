import React, { ReactElement, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';
import LabelButton from '../../../components/buttons/labelButton';
import PageTitle from '../../../components/title/pageTitle';
import TabBar, { TabContent } from '../../../components/tabs/tabBar';
import Users from '../../users/pages/users';
import BuildingTable from '../../buildings/tables/buildingTable';
import CreateBuildingModal from '../../buildings/modals/createBuildingModal';

function GroupsAdminPage(): ReactElement {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const tabContent: TabContent[] = [
    {
      key: 0,
      tabLabel: t('features.buildings.pages.admin.tabLabel'),
      content: (
        <div className="flex flex-col">
          <LabelButton
            label={t('administration.building.addBuilding')}
            className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
            icon={<IoMdAdd size={20} />}
            onClick={() => setIsOpen(true)}
          />
          <BuildingTable
            clickHandler={(buildingId: string) =>
              navigate(`buildings/${buildingId}`)
            }
            groupId={groupId}
          />
        </div>
      )
    },
    {
      key: 1,
      tabLabel: t('administration.tabs.user'),
      content: <Users groupId={groupId} />
    }
  ];

  return (
    <div className="flex flex-col px-8  w-full">
      <PageTitle title="Kundenverwaltung" />
      <TabBar tabs={tabContent} />

      {groupId != null && (
        <CreateBuildingModal
          groupId={groupId}
          isOpen={isOpen}
          closeHandler={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default GroupsAdminPage;
