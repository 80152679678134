import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { MdEdit } from 'react-icons/md';
import { skipToken } from '@reduxjs/toolkit/query';
import DateTableLabel from '../../../../components/date/dateTableLabel';
import TableActionButton from '../../../../components/tables/tableActionButton';
import DataTable from '../../../../components/tables/dataTable';
import UserComment from '../../components/traps/userComment';
import { useHistoryServiceListHistoryQuery } from '../../../../api/enhanced/enhancedHistory';
import { V1Alpha1HistoryEntry } from '../../../../api/gen/history';
import TemperaturePill from '../../../../components/pill/temperaturePill';
import HumidityPill from '../../../../components/pill/humidityPill';

interface HistoryTableProps {
  groupId?: string;
  buildingId?: string;
  checkpointId?: string;
  maxLimit?: number;
  minLimit?: number;
  humidityMinLimit?: number;
  humidityMaxLimit?: number;
  commentModalStateHandler: (isOpen: boolean, entryId: string) => void;
}

function HistoryTable({
  groupId,
  buildingId,
  checkpointId,
  maxLimit,
  minLimit,
  humidityMinLimit,
  humidityMaxLimit,
  commentModalStateHandler
}: HistoryTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<ColumnDef<V1Alpha1HistoryEntry>[]>(
    () => [
      {
        header: 'Temperatur',
        accessorFn: (row) => row.temperaturePayload?.temperature,
        cell: (info) =>
          TemperaturePill({
            value: info.getValue<number>(),
            minLimit: minLimit != null ? minLimit : 0,
            maxLimit: maxLimit != null ? maxLimit : 0
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Luftfeuchtigkeit',
        accessorFn: (row) => row.temperaturePayload?.humidity,
        cell: (info) =>
          HumidityPill({
            value: info.getValue<number>(),
            minLimit: humidityMinLimit != null ? humidityMinLimit : 0,
            maxLimit: humidityMaxLimit != null ? humidityMaxLimit : 0
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Zeitpunkt des Signals',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.timestamp,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Kommentar',
        cell: (info) =>
          UserComment({ comment: info.getValue<string>(), className: 'px-6' }),
        accessorFn: (row) => row.comment,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Bearbeiten',
        accessorFn: (row) => row.id,
        cell: (info) =>
          TableActionButton({
            icon: <MdEdit size={20} />,
            onClick: () => {
              commentModalStateHandler(true, info.getValue<string>());
            }
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ],
    [minLimit, maxLimit, commentModalStateHandler]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const paramsValid =
    checkpointId != null &&
    groupId != null &&
    buildingId != null &&
    groupId !== '';

  const { data, isLoading, isFetching, isSuccess, isError } =
    useHistoryServiceListHistoryQuery(
      paramsValid
        ? {
            checkpointId,
            buildingId,
            groupId,
            sensorType: 'SENSOR_TYPE_TEMP',
            page: pageIndex,
            limit: pageSize
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.entries ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={isSuccess && (data.entries == null || data.entries.length === 0)}
      pageSize={pageSize}
      table={table}
    />
  );
}

export default HistoryTable;
