/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useAlarmServiceGetAlarmQuery,
  useAlarmServiceUpdateAlarmMutation
} from '../../../api/enhanced/enhancedAlarms';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';

import FormTextInput from '../../../components/form/formTextInput';
import MultiselectList, {
  MultiselectContent
} from '../../../components/multiselectList/multiselectList';
import { useIdentityServiceListUsersQuery } from '../../../api/gen/identity';
import { useCheckpointServiceListCheckpointsForBuildingQuery } from '../../../api/enhanced/enhancedCheckpoints';
import FormSelect, {
  FormSelectOption
} from '../../../components/form/formSelect';
import { useBuildingServiceListAreasForBuildingQuery } from '../../../api/enhanced/enhancedBuildings';
import {
  V1Alpha1AssignedCheckpointUpdate,
  V1Alpha1RecipientUpdate,
  V1Alpha1Weekday
} from '../../../api/gen/alarms';

interface UpdateAlarmFormValues {
  description: string;
  areaId: string;
  repetitionInterval: number;
  delayInterval: number;
}

interface UpdateAlarmModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  groupId: string;
  buildingId: string;
  alarmId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
}

function UpdateAlarmModal({
  closeHandler,
  isOpen,
  groupId,
  buildingId,
  alarmId,
  sensorType
}: UpdateAlarmModalProps): ReactElement {
  const methods = useForm<UpdateAlarmFormValues>();
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<MultiselectContent[]>([]);
  const [deletedUsers, setDeletedUsers] = useState<MultiselectContent[]>([]);
  const [selectedCheckpoints, setSelectedCheckpoints] = useState<
    MultiselectContent[]
  >([]);
  const [areaSelectOptions, setAreaSelectOptions] = useState<
    FormSelectOption[]
  >([]);

  const [alarmForBuilding, setAlarmForBuilding] = useState(false);
  const [alarmForArea, setAlarmForArea] = useState(false);
  const [isRepeated, setIsRepeated] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [startTime, setStartTime] = useState<TimePickerValue>('00:00');
  const [endTime, setEndTime] = useState<TimePickerValue>('00:00');
  const [isTimeInterval, setIsTimeInterval] = useState(false);
  const [limitWeekdays, setLimitWeekdays] = useState(false);
  const areaId = methods.watch('areaId');
  const [updateAlarm, { isLoading, isError, isSuccess, reset }] =
    useAlarmServiceUpdateAlarmMutation();

  const [selectedWeekdays, setSelectedWeekdays] = useState<
    MultiselectContent[]
  >([
    {
      key: 'monday',
      label: 'Montag',
      selected: true
    },
    {
      key: 'tuesday',
      label: 'Dienstag',
      selected: true
    },
    {
      key: 'wednesday',
      label: 'Mittwoch',
      selected: true
    },
    {
      key: 'thursday',
      label: 'Donnerstag',
      selected: true
    },
    {
      key: 'friday',
      label: 'Freitag',
      selected: true
    },
    {
      key: 'saturday',
      label: 'Samstag',
      selected: true
    },
    {
      key: 'sunday',
      label: 'Sonntag',
      selected: true
    }
  ]);

  const {
    data,
    isLoading: usersIsLoading,
    isSuccess: usersIsSuccess,
    isError: usersIsError
  } = useIdentityServiceListUsersQuery({
    groupId,
    sortBy: 'user_name:ASC'
  });

  const { data: areaData, isSuccess: areaIsSuccess } =
    useBuildingServiceListAreasForBuildingQuery(
      buildingId != null && groupId != null && groupId !== ''
        ? {
            groupId,
            buildingId
          }
        : skipToken
    );

  const { data: checkpointData, isSuccess: checkpointIsSuccess } =
    useCheckpointServiceListCheckpointsForBuildingQuery(
      buildingId != null && groupId != null && groupId !== ''
        ? {
            groupId,
            buildingId,
            areaId,
            sensorType:
              sensorType !== 'SENSOR_TYPE_HUMIDITY'
                ? sensorType
                : 'SENSOR_TYPE_TEMP',
            sortBy: 'checkpoint_number:ASC'
          }
        : skipToken
    );

  const {
    data: alarm,
    isLoading: alarmLoading,
    isError: alarmIsError,
    error: alarmError,
    isSuccess: alarmIsSuccess
  } = useAlarmServiceGetAlarmQuery(
    buildingId != null && groupId != null && groupId !== ''
      ? {
          groupId,
          buildingId,
          alarmId,
          sensorType
        }
      : skipToken
  );

  useEffect(() => {
    if (isOpen && alarm != null && alarm.alarm) {
      methods.setValue(
        'description',
        alarm.alarm.description != null ? alarm.alarm.description : ''
      );

      setIsRepeated(
        alarm.alarm.repeatedMessage ? alarm.alarm.repeatedMessage : false
      );

      setIsActive(alarm.alarm.isActive ? alarm.alarm.isActive : false);

      setAlarmForArea(
        alarm.alarm.isAreaAlarm ? alarm.alarm.isAreaAlarm : false
      );

      setAlarmForBuilding(
        alarm.alarm.isBuildingAlarm ? alarm.alarm.isBuildingAlarm : false
      );

      setLimitWeekdays(
        alarm.alarm.selectedWeekdays ? alarm.alarm.selectedWeekdays : false
      );

      setIsTimeInterval(
        alarm.alarm.selectedIntervals ? alarm.alarm.selectedIntervals : false
      );

      if (
        alarm.alarm != null &&
        alarm.alarm.areaId != null &&
        alarm.alarm.areaId !== ''
      ) {
        methods.setValue('areaId', alarm.alarm.areaId);
      }

      if (alarm.alarm.startTime !== '' && alarm.alarm.startTime !== 'CLEARED') {
        setStartTime(alarm.alarm.startTime as string);
      }

      if (alarm.alarm.endTime !== '' && alarm.alarm.endTime !== 'CLEARED') {
        setEndTime(alarm.alarm.endTime as string);
      }

      methods.setValue(
        'repetitionInterval',
        alarm.alarm.repetitionInterval != null
          ? alarm.alarm.repetitionInterval
          : 0
      );

      methods.setValue(
        'delayInterval',
        alarm.alarm.delayInterval != null ? alarm.alarm.delayInterval : 0
      );
    }
  }, [methods, alarm, closeHandler, isOpen]);

  useEffect(() => {
    if (
      data != null &&
      data.users != null &&
      data.users.length > 0 &&
      alarmIsSuccess &&
      alarm != null &&
      alarm.alarm != null &&
      alarm.alarm.assignedCheckpoint != null
    ) {
      const content: MultiselectContent[] = [];
      data.users.forEach((user) => {
        content.push({
          selected: alarm.alarm!.recipients!.some(
            (currentUser) => user.userId === currentUser.userId
          ),
          key: user.userId as string,
          label: `${user.userName as string} - ${
            user.isMailUser ? 'Nur Mails' : 'Portalnutzer'
          }`
        });
      });
      setSelectedUsers(content);
    }
  }, [data, alarmIsSuccess, alarm]);

  useEffect(() => {
    if (
      data != null &&
      data.users != null &&
      data.users.length > 0 &&
      alarmIsSuccess &&
      alarm != null &&
      alarm.alarm != null &&
      alarm.alarm.recipients != null &&
      alarm.alarm.assignedCheckpoint != null
    ) {
      const content: MultiselectContent[] = [];
      alarm.alarm.recipients.forEach((recipient) => {
        const found = data!.users!.some(
          (user) => user.userId === recipient.userId
        );

        if (!found) {
          content.push({
            selected: true,
            key: recipient.userId as string,
            label: recipient.mail as string
          });
        }
      });
      setDeletedUsers(content);
    }
  }, [data, alarmIsSuccess, alarm]);

  useEffect(() => {
    if (areaData != null && areaData.areas != null && areaIsSuccess) {
      const content: FormSelectOption[] = [
        { value: '', description: 'Alle Bereiche' }
      ];
      areaData.areas.forEach((area) => {
        content.push({
          value: area.id != null ? area.id : '',
          description: area.description != null ? area.description : ''
        });
      });
      setAreaSelectOptions(content);
    }
  }, [areaData, areaId, areaIsSuccess]);

  useEffect(() => {
    if (
      checkpointData != null &&
      checkpointData.checkpoints != null &&
      alarm != null &&
      alarm.alarm != null &&
      alarm.alarm.assignedCheckpoint != null &&
      checkpointIsSuccess
    ) {
      const content: MultiselectContent[] = [];
      checkpointData.checkpoints.forEach((checkpoint) => {
        content.push({
          selected: alarm.alarm!.assignedCheckpoint!.some(
            (currentCheckpoint) =>
              checkpoint.id === currentCheckpoint.checkpointId
          ),
          key: checkpoint.id as string,
          label: `${checkpoint.checkpointNumber} - ${checkpoint.description}`
        });
      });
      setSelectedCheckpoints(content);
    }
  }, [alarm, checkpointData, checkpointIsSuccess, data]);

  useEffect(() => {
    if (
      checkpointData != null &&
      checkpointData.checkpoints != null &&
      alarm != null &&
      alarm.alarm != null &&
      alarm.alarm.selectedWeekdays != null &&
      alarm.alarm.selectedIntervals != null &&
      alarm.alarm.weekdays != null &&
      alarm.alarm.weekdays.length > 0 &&
      checkpointIsSuccess
    ) {
      const content = alarm.alarm.weekdays.map((day): MultiselectContent => {
        return {
          key: day.key as string,
          label: t(`features.alarms.weekdays.${day.key}`),
          selected: day.checked as boolean
        };
      });
      setSelectedWeekdays(content);
    }
  }, [alarm, checkpointData, checkpointIsSuccess, data, t]);

  const onSubmit = (formData: UpdateAlarmFormValues): void => {
    let assignedCheckpoint: V1Alpha1AssignedCheckpointUpdate[] = [];
    if (
      checkpointData != null &&
      checkpointData.checkpoints != null &&
      selectedCheckpoints.length > 0
    ) {
      assignedCheckpoint = checkpointData.checkpoints.map(
        (entry): V1Alpha1AssignedCheckpointUpdate => {
          return {
            checkpointId: entry.id,
            description: entry.description,
            selected: selectedCheckpoints.some(
              (checkpoint) => checkpoint.key === entry.id && checkpoint.selected
            )
          };
        }
      );
    }

    let recipients: V1Alpha1RecipientUpdate[] = [];
    if (data != null && data.users != null && data.users.length > 0) {
      recipients = data.users.map((entry): V1Alpha1RecipientUpdate => {
        return {
          userId: entry.userId,
          mail: entry.mail,
          selected: selectedUsers.some(
            (user) => user.key === entry.userId && user.selected
          )
        };
      });
    }

    if (deletedUsers != null && deletedUsers.length > 0) {
      deletedUsers.forEach((recipient) => {
        recipients.push({
          userId: recipient.key,
          mail: recipient.label,
          selected: recipient.selected
        });
      });
    }

    const weekdays: V1Alpha1Weekday[] = [];
    selectedWeekdays.forEach((value) => {
      weekdays.push({
        key: value.key,
        checked: value.selected
      });
    });

    updateAlarm({
      groupId,
      buildingId,
      alarmId,
      alarmServiceUpdateAlarmBody: {
        areaId,
        description: formData.description,
        sensorType,
        repeatedMessage: isRepeated,
        repetitionInterval: formData.repetitionInterval,
        delayInterval: formData.delayInterval,
        recipients,
        assignedCheckpoint,
        isActive,
        isAreaAlarm: alarmForArea,
        isBuildingAlarm: alarmForBuilding,
        weekdays: limitWeekdays ? weekdays : undefined,
        selectedWeekdays: limitWeekdays,
        selectedIntervals: isTimeInterval,
        startTime: startTime != null ? startTime.toString() : 'CLEARED',
        endTime: endTime != null ? endTime.toString() : 'CLEARED'
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [isSuccess, methods, reset, closeHandler]);

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t(`features.alarms.modals.update.title.${sensorType}`)}
      >
        <div className="w-full flex flex-col">
          {isError && (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('features.alarms.modals.update.error')}
            />
          )}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setIsActive(!isActive);
                }}
                checked={isActive}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('features.alarms.modals.update.isActive')}
              </div>
            </div>

            <FormTextInput
              id="description"
              label={t('features.alarms.modals.update.description.label')}
              errorMsg={
                t('features.alarms.modals.update.description.error') as string
              }
              placeholder={
                t('features.alarms.modals.update.description.label') as string
              }
              disabled={isLoading}
              required
            />
            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setAlarmForBuilding(!alarmForBuilding);
                  setAlarmForArea(false);
                }}
                checked={alarmForBuilding}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('features.alarms.modals.update.alarmForBuilding.label')}
              </div>
            </div>

            {!alarmForBuilding && alarm != null && (
              <FormSelect
                id="areaId"
                label={t('modals.createAlarm.area.label')}
                errorMsg={t('modals.createAlarm.area.error') as string}
                placeholder={t('modals.createAlarm.area.label') as string}
                value={areaId}
                disabled={isLoading}
                options={areaSelectOptions}
                required={alarmForArea}
              />
            )}

            {!alarmForBuilding && areaId !== '' && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  onChange={() => {
                    setAlarmForArea(!alarmForArea);
                    setAlarmForBuilding(false);
                  }}
                  checked={alarmForArea}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
                />
                <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                  {t('features.alarms.modals.update.alarmForArea.label')}
                </div>
              </div>
            )}

            {!alarmForBuilding &&
              !alarmForArea &&
              selectedCheckpoints.length > 0 && (
                <div>
                  <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                    {t('features.alarms.modals.update.checkpoints')}
                  </div>
                  <MultiselectList
                    className="max-h-40 overflow-auto"
                    selectHandler={(id: string, checkedState: boolean) => {
                      setSelectedCheckpoints(
                        selectedCheckpoints.map((checkpoint) => {
                          if (checkpoint.key === id) {
                            return { ...checkpoint, selected: checkedState };
                          }

                          return checkpoint;
                        })
                      );
                    }}
                    content={selectedCheckpoints}
                  />
                </div>
              )}

            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setIsRepeated(!isRepeated);
                }}
                checked={isRepeated}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('modals.createAlarm.isRepeated.label')}
              </div>
            </div>

            {isRepeated && (
              <FormTextInput
                type="number"
                id="repetitionInterval"
                label={t('modals.createAlarm.repetitionInterval.label')}
                errorMsg={
                  t('modals.createAlarm.repetitionInterval.error') as string
                }
                placeholder={
                  t('modals.createAlarm.repetitionInterval.label') as string
                }
                required={isRepeated}
              />
            )}

            {sensorType === 'SENSOR_TYPE_DOOR' && (
              <FormTextInput
                type="number"
                id="delayInterval"
                label={t('modals.createAlarm.delayInterval.label')}
                errorMsg={t('modals.createAlarm.delayInterval.error') as string}
                placeholder={
                  t('modals.createAlarm.delayInterval.label') as string
                }
              />
            )}

            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setIsTimeInterval(!isTimeInterval);
                }}
                checked={isTimeInterval}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('features.alarms.modals.create.activeForInterval')}
              </div>
            </div>

            {isTimeInterval && (
              <div className="flex flex-col w-full mt-2">
                <h2 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  {t('features.alarms.modals.create.startTime')}
                </h2>
                <div className="rounded-lg w-full appearance-none block w-full text-gray-700 border rounded py-2 px-2 mb-1 leading-tight focus:outline-none">
                  <TimePicker
                    disableClock
                    className="w-full border-none"
                    onChange={setStartTime}
                    value={startTime}
                  />
                </div>
              </div>
            )}

            {isTimeInterval && (
              <div className="flex flex-col w-full mt-2">
                <h2 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  {t('features.alarms.modals.create.endTime')}
                </h2>
                <div className="rounded-lg w-full appearance-none block w-full text-gray-700 border rounded py-2 px-2 mb-1 leading-tight focus:outline-none">
                  <TimePicker
                    disableClock
                    className="w-full border-none"
                    onChange={setEndTime}
                    value={endTime}
                  />
                </div>
              </div>
            )}

            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setLimitWeekdays(!limitWeekdays);
                }}
                checked={limitWeekdays}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('features.alarms.modals.create.limitWeekdays')}
              </div>
            </div>

            {limitWeekdays && (
              <div>
                <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                  {t('features.alarms.modals.create.weekdays')}
                </div>
                <MultiselectList
                  className="max-h-40 overflow-auto"
                  selectHandler={(id: string, checkedState: boolean) => {
                    setSelectedWeekdays(
                      selectedWeekdays.map((weekday) => {
                        if (weekday.key === id) {
                          return { ...weekday, selected: checkedState };
                        }

                        return weekday;
                      })
                    );
                  }}
                  content={selectedWeekdays}
                />
              </div>
            )}

            <div>
              <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                {t('features.alarms.modals.update.users')}
              </div>
              <MultiselectList
                className="max-h-40 overflow-auto"
                selectHandler={(id: string, checkedState: boolean) => {
                  setSelectedUsers(
                    selectedUsers.map((user) => {
                      if (user.key === id) {
                        return { ...user, selected: checkedState };
                      }

                      return user;
                    })
                  );
                }}
                content={selectedUsers}
              />
            </div>

            {deletedUsers.length > 0 && (
              <div className="mt-8">
                <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                  {t('features.alarms.modals.update.deletedUsers')}
                </div>
                <MultiselectList
                  className="max-h-40 overflow-auto"
                  selectHandler={(id: string, checkedState: boolean) => {
                    setDeletedUsers(
                      deletedUsers.map((user) => {
                        if (user.key === id) {
                          return { ...user, selected: checkedState };
                        }

                        return user;
                      })
                    );
                  }}
                  content={deletedUsers}
                />
              </div>
            )}

            <div className="flex items-end flex-col w-full mt-5">
              <button
                className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                type="submit"
              >
                {t('modals.save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default UpdateAlarmModal;
