import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    buildingServiceListBuildings: build.query<
      BuildingServiceListBuildingsApiResponse,
      BuildingServiceListBuildingsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings`,
        params: { page: queryArg.page, limit: queryArg.limit }
      })
    }),
    buildingServiceCreateBuilding: build.mutation<
      BuildingServiceCreateBuildingApiResponse,
      BuildingServiceCreateBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    buildingServiceGetBuilding: build.query<
      BuildingServiceGetBuildingApiResponse,
      BuildingServiceGetBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}`
      })
    }),
    buildingServiceRemoveBuilding: build.mutation<
      BuildingServiceRemoveBuildingApiResponse,
      BuildingServiceRemoveBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}`,
        method: 'DELETE'
      })
    }),
    buildingServiceUpdateBuilding: build.mutation<
      BuildingServiceUpdateBuildingApiResponse,
      BuildingServiceUpdateBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    buildingServiceListAreasForBuilding: build.query<
      BuildingServiceListAreasForBuildingApiResponse,
      BuildingServiceListAreasForBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/areas`,
        params: { page: queryArg.page, limit: queryArg.limit }
      })
    }),
    buildingServiceAddAreaToBuilding: build.mutation<
      BuildingServiceAddAreaToBuildingApiResponse,
      BuildingServiceAddAreaToBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/areas`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    buildingServiceGetArea: build.query<
      BuildingServiceGetAreaApiResponse,
      BuildingServiceGetAreaApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/areas/${queryArg.areaId}`
      })
    }),
    buildingServiceRemoveAreaFromBuilding: build.mutation<
      BuildingServiceRemoveAreaFromBuildingApiResponse,
      BuildingServiceRemoveAreaFromBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/areas/${queryArg.areaId}`,
        method: 'DELETE'
      })
    }),
    buildingServiceUpdateArea: build.mutation<
      BuildingServiceUpdateAreaApiResponse,
      BuildingServiceUpdateAreaApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/areas/${queryArg.areaId}`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    buildingServiceListUsersForBuilding: build.query<
      BuildingServiceListUsersForBuildingApiResponse,
      BuildingServiceListUsersForBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/users`,
        params: { limit: queryArg.limit, page: queryArg.page }
      })
    }),
    buildingServiceRemoveUserFromBuilding: build.mutation<
      BuildingServiceRemoveUserFromBuildingApiResponse,
      BuildingServiceRemoveUserFromBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/users`,
        method: 'DELETE',
        params: { userId: queryArg.userId }
      })
    }),
    buildingServiceAssignUsersToBuilding: build.mutation<
      BuildingServiceAssignUsersToBuildingApiResponse,
      BuildingServiceAssignUsersToBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/users`,
        method: 'POST',
        body: queryArg.body
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as buildingsApi };
export type BuildingServiceListBuildingsApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListBuildingsResponse;
export type BuildingServiceListBuildingsApiArg = {
  groupId: string;
  page?: number;
  limit?: number;
};
export type BuildingServiceCreateBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1CreateBuildingResponse;
export type BuildingServiceCreateBuildingApiArg = {
  groupId: string;
  body: {
    building?: V1Alpha1Building;
  };
};
export type BuildingServiceGetBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetBuildingResponse;
export type BuildingServiceGetBuildingApiArg = {
  groupId: string;
  buildingId: string;
};
export type BuildingServiceRemoveBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1RemoveBuildingResponse;
export type BuildingServiceRemoveBuildingApiArg = {
  groupId: string;
  buildingId: string;
};
export type BuildingServiceUpdateBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateBuildingResponse;
export type BuildingServiceUpdateBuildingApiArg = {
  groupId: string;
  buildingId: string;
  body: {
    building?: V1Alpha1Building;
  };
};
export type BuildingServiceListAreasForBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListAreasForBuildingResponse;
export type BuildingServiceListAreasForBuildingApiArg = {
  groupId: string;
  buildingId: string;
  page?: number;
  limit?: number;
};
export type BuildingServiceAddAreaToBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1AddAreaToBuildingResponse;
export type BuildingServiceAddAreaToBuildingApiArg = {
  groupId: string;
  buildingId: string;
  body: {
    area?: V1Alpha1Area;
  };
};
export type BuildingServiceGetAreaApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetAreaResponse;
export type BuildingServiceGetAreaApiArg = {
  groupId: string;
  buildingId: string;
  areaId: string;
};
export type BuildingServiceRemoveAreaFromBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1RemoveAreaFromBuildingResponse;
export type BuildingServiceRemoveAreaFromBuildingApiArg = {
  groupId: string;
  buildingId: string;
  areaId: string;
};
export type BuildingServiceUpdateAreaApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateAreaResponse;
export type BuildingServiceUpdateAreaApiArg = {
  groupId: string;
  buildingId: string;
  areaId: string;
  body: {
    description?: string;
  };
};
export type BuildingServiceListUsersForBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListUsersForBuildingResponse;
export type BuildingServiceListUsersForBuildingApiArg = {
  groupId: string;
  buildingId: string;
  limit?: number;
  page?: number;
};
export type BuildingServiceRemoveUserFromBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1RemoveUserFromBuildingResponse;
export type BuildingServiceRemoveUserFromBuildingApiArg = {
  groupId: string;
  buildingId: string;
  userId?: string;
};
export type BuildingServiceAssignUsersToBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1AssignUsersToBuildingResponse;
export type BuildingServiceAssignUsersToBuildingApiArg = {
  groupId: string;
  buildingId: string;
  body: {
    userIds?: string[];
  };
};
export type V1Alpha1Building = {
  id?: string;
  description?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  zipCode?: string;
  groupId?: string;
};
export type V1Alpha1ListBuildingsResponse = {
  total?: string;
  page?: number;
  limit?: number;
  buildings?: V1Alpha1Building[];
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1CreateBuildingResponse = {
  building?: V1Alpha1Building;
};
export type V1Alpha1GetBuildingResponse = {
  building?: V1Alpha1Building;
};
export type V1Alpha1RemoveBuildingResponse = {
  buildingId?: string;
};
export type V1Alpha1UpdateBuildingResponse = {
  building?: V1Alpha1Building;
};
export type V1Alpha1Area = {
  id?: string;
  buildingId?: string;
  description?: string;
};
export type V1Alpha1ListAreasForBuildingResponse = {
  total?: string;
  page?: number;
  limit?: number;
  areas?: V1Alpha1Area[];
};
export type V1Alpha1AddAreaToBuildingResponse = {
  area?: V1Alpha1Area;
};
export type V1Alpha1GetAreaResponse = {
  area?: V1Alpha1Area;
};
export type V1Alpha1RemoveAreaFromBuildingResponse = {
  areaId?: string;
};
export type V1Alpha1UpdateAreaResponse = {
  area?: V1Alpha1Area;
};
export type V1Alpha1Role =
  | 'ROLE_UNSPECIFIED'
  | 'ROLE_GROUP_ADMIN'
  | 'ROLE_ADMIN'
  | 'ROLE_GROUP_MEMBER';
export type V1Alpha1Group = {
  groupId?: string;
  description?: string;
  role?: V1Alpha1Role;
};
export type V1Alpha1User = {
  userId?: string;
  mail?: string;
  userName?: string;
  idpId?: string;
  isAdmin?: boolean;
  isMailUser?: boolean;
  isTechnician?: boolean;
  groups?: V1Alpha1Group[];
};
export type V1Alpha1ListUsersForBuildingResponse = {
  total?: string;
  page?: number;
  limit?: number;
  users?: V1Alpha1User[];
};
export type V1Alpha1RemoveUserFromBuildingResponse = object;
export type V1Alpha1AssignUsersToBuildingResponse = object;
export const {
  useBuildingServiceListBuildingsQuery,
  useBuildingServiceCreateBuildingMutation,
  useBuildingServiceGetBuildingQuery,
  useBuildingServiceRemoveBuildingMutation,
  useBuildingServiceUpdateBuildingMutation,
  useBuildingServiceListAreasForBuildingQuery,
  useBuildingServiceAddAreaToBuildingMutation,
  useBuildingServiceGetAreaQuery,
  useBuildingServiceRemoveAreaFromBuildingMutation,
  useBuildingServiceUpdateAreaMutation,
  useBuildingServiceListUsersForBuildingQuery,
  useBuildingServiceRemoveUserFromBuildingMutation,
  useBuildingServiceAssignUsersToBuildingMutation
} = injectedRtkApi;
