import React, { ReactElement, useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import yellow from './map_yellow.png';
import red from './map_red.png';
import green from './map_green.png';
import grey from './map_grey.png';

export interface MarkerState {
  marker: L.Marker;
  moveHandler?: (lat: number, lng: number) => void;
}

interface MapProps {
  data: string;
  markers?: MarkerState[];
}

export const yellowIcon = L.icon({
  iconUrl: yellow,
  iconSize: [39, 45],
  iconAnchor: [19, 40],
  popupAnchor: [0, -40]
});

export const redIcon = L.icon({
  iconUrl: red,
  iconSize: [39, 45],
  iconAnchor: [19, 40],
  popupAnchor: [0, -40]
});

export const greenIcon = L.icon({
  iconUrl: green,
  iconSize: [39, 45],
  iconAnchor: [19, 40],
  popupAnchor: [0, -40]
});

export const greyIcon = L.icon({
  iconUrl: grey,
  iconSize: [39, 45],
  iconAnchor: [19, 40],
  popupAnchor: [0, -40]
});

const getIsValid = (timestamp?: string): boolean => {
  let isValid = true;
  if (timestamp != null) {
    const parsedTimestamp = Date.parse(timestamp);
    const now = Date.now();

    if (Math.abs(now - parsedTimestamp) / 36e5 > 200) {
      isValid = false;
    }
  }

  return isValid;
};

export function getMarkerForTrap(
  trapState: string,
  isOnline: boolean,
  timestamp?: string
): L.Icon {
  if (!isOnline || !getIsValid(timestamp)) {
    return greyIcon;
  }

  switch (trapState) {
    case 'failed':
      return yellowIcon;
    case 'trapped':
      return redIcon;
    case 'normal':
      return greenIcon;
    default:
      return greyIcon;
  }
}

export function getMarkerForDoor(
  value: number,
  isOnline: boolean,
  timestamp?: string
): L.Icon {
  if (!isOnline || !getIsValid(timestamp)) {
    return greyIcon;
  }

  if (value === 0) {
    return greenIcon;
  }

  if (value === 1) {
    return redIcon;
  }

  return greenIcon;
}

export function getMarkerForCarbonDioxide(
  value: number,
  isOnline: boolean,
  minValue?: number,
  maxValue?: number,
  timestamp?: string
): L.Icon {
  if (!isOnline || !getIsValid(timestamp)) {
    return greyIcon;
  }

  if (
    minValue != null &&
    maxValue != null &&
    minValue === 0 &&
    maxValue === 0
  ) {
    return greenIcon;
  }

  if (
    minValue != null &&
    maxValue != null &&
    value > minValue &&
    value < maxValue
  ) {
    return yellowIcon;
  }

  if (maxValue != null && value > maxValue) {
    return redIcon;
  }

  return greenIcon;
}

export function getMarkerForTemperature(
  value: number,
  isOnline: boolean,
  minValue?: number,
  maxValue?: number,
  timestamp?: string
): L.Icon {
  if (!isOnline || !getIsValid(timestamp)) {
    return greyIcon;
  }

  if (
    minValue != null &&
    maxValue != null &&
    minValue === 0 &&
    maxValue === 0
  ) {
    return greenIcon;
  }

  if (
    minValue != null &&
    maxValue != null &&
    (value < minValue || value > maxValue)
  ) {
    return redIcon;
  }

  return greenIcon;
}

function Map({ data, markers }: MapProps): ReactElement {
  const mapRef = useRef<L.Map>();
  const overlayRef = useRef<L.ImageOverlay>();
  const markerGroupRef = useRef<L.LayerGroup>();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (mapRef != null && mapRef.current != null && imageLoaded) {
      if (markerGroupRef.current == null) {
        markerGroupRef.current = L.layerGroup();
      }

      if (markerGroupRef.current != null) {
        markerGroupRef.current.clearLayers();
      }

      if (markers != null) {
        markers.forEach((markerState) => {
          markerState.marker.addEventListener('moveend', () => {
            const position = markerState.marker.getLatLng();
            if (markerState.moveHandler != null) {
              markerState.moveHandler(position.lat, position.lng);
            }
          });
          markerState.marker.addTo(markerGroupRef.current as L.LayerGroup);
        });

        if (markerGroupRef.current != null) {
          markerGroupRef.current.addTo(mapRef.current as L.Map);
        }
      }
    }
  }, [markers, mapRef, imageLoaded, data]);

  useEffect(() => {
    const setImage = async (): Promise<void> => {
      setImageLoaded(false);
      const img = new Image();
      img.src = data;
      img.onload = () => {
        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;
        const bounds: L.LatLngBoundsExpression = [
          [0, 0],
          [imgHeight, imgWidth]
        ];

        if (mapRef.current != null) {
          mapRef.current.remove();
          mapRef.current = undefined;
        }

        overlayRef.current = L.imageOverlay(data, bounds);

        const mapOptions: L.MapOptions = {
          crs: L.CRS.Simple,
          minZoom: -2,
          layers: [overlayRef.current]
        };

        mapRef.current = L.map('map', mapOptions);
        mapRef.current.fitBounds(bounds);
        mapRef.current.attributionControl.setPrefix('');
        setImageLoaded(true);
      };

      img.onerror = (e) => {
        return e.toString();
      };
    };

    setImage();
  }, [data]);

  return (
    <div
      id="map"
      className="overflow-hidden w-full h-full shadow-md rounded-lg"
    />
  );
}

export default Map;
