import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useBuildingServiceGetBuildingQuery } from '../../../api/gen/buildings';
import TabBar, { TabContent } from '../../../components/tabs/tabBar';
import PageTitle from '../../../components/title/pageTitle';
import TrapTab from '../components/traps/trapTab';
import TemperatureTab from '../components/temperature/temperatureTab';
import CarbonDioxideTab from '../components/carbonDioxide/carbonDioxideTab';
import DoorTab from '../components/doors/doorTab';
import AnalogTrapTab from '../components/traps/analogTrapTab';

function BuildingOverview(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId } = useParams();

  const { data, isLoading, isError, error } =
    useBuildingServiceGetBuildingQuery(
      buildingId != null && groupId != null
        ? { buildingId, groupId }
        : skipToken
    );

  const tabs: TabContent[] = [
    {
      key: 0,
      tabLabel: t('tabs.traps'),
      content: <TrapTab buildingId={buildingId} groupId={groupId} />
    },
    {
      key: 1,
      tabLabel: t('tabs.temp'),
      content: <TemperatureTab buildingId={buildingId} groupId={groupId} />
    },
    {
      key: 2,
      tabLabel: t('tabs.co2'),
      content: <CarbonDioxideTab buildingId={buildingId} groupId={groupId} />
    },
    {
      key: 3,
      tabLabel: t('tabs.door'),
      content: <DoorTab buildingId={buildingId} groupId={groupId} />
    },
    {
      key: 4,
      tabLabel: t('tabs.analogTraps'),
      content: <AnalogTrapTab buildingId={buildingId} groupId={groupId} />
    }
  ];

  if (isError && error) {
    return <div>error while fetching data</div>;
  }

  return (
    <div className="flex flex-col px-8 w-full">
      <div className="flex flex-row justify-between w-full">
        <PageTitle
          isLoading={isLoading}
          title={t('details.title', {
            buildingDescription: data?.building?.description
          })}
        />
      </div>

      <TabBar tabs={tabs} />
    </div>
  );
}

export default BuildingOverview;
