/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { useInspectionServiceCreateInspectionMutation } from '../../../api/enhanced/enhancedInspection';

interface FormValues {
  comment: string;
}

interface CreateInspectionModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  checkpointId: string;
  buildingId: string;
  groupId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
}

function CreateInspectionModal({
  closeHandler,
  isOpen,
  checkpointId,
  groupId,
  buildingId,
  sensorType
}: CreateInspectionModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();

  const [createInspection, { isLoading, isSuccess, isError, reset }] =
    useInspectionServiceCreateInspectionMutation();

  useEffect(() => {
    if (isSuccess) {
      methods.reset();
      reset();
      closeHandler();
    }
  }, [reset, methods, closeHandler, isSuccess]);

  const onSubmit = (formData: FormValues): void => {
    const currentDate = new Date();
    createInspection({
      checkpointId,
      buildingId,
      groupId,
      sensorType,
      body: {
        comment: formData.comment,
        timestamp: currentDate.toISOString(),
        inspectionType: 'INSPECTION_TYPE_MANUAL'
      }
    });
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createInspection.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.createInspection.error')}
            />
          ) : null}

          <p className="my-2 text-red-500">
            {t('modals.createInspection.description')}
          </p>

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="comment"
              label={t('modals.createInspection.comment.label')}
              errorMsg={t('modals.createInspection.comment.error') as string}
              placeholder={
                t('modals.createInspection.comment.placeholder') as string
              }
              disabled={isLoading}
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateInspectionModal;
