import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { FiSend } from 'react-icons/fi';
import { V1Alpha1User } from '../../../api/gen/identity';
import RowCell from '../../../components/tables/rowCell';
import DataTable from '../../../components/tables/dataTable';
import { useIdentityServiceListUsersQuery } from '../../../api/enhanced/enhancedIdentity';
import TableActionButton from '../../../components/tables/tableActionButton';

interface DetailButtonProps {
  editHandler: () => void;
  deleteHandler: () => void;
  inviteHandler: () => void;
  mailUser?: boolean;
}

function UserDetailButtons({
  editHandler,
  deleteHandler,
  inviteHandler,
  mailUser
}: DetailButtonProps): ReactElement {
  return (
    <div className="flex flex-row">
      <TableActionButton
        icon={<MdEdit size={20} />}
        onClick={() => editHandler()}
      />
      <TableActionButton
        className="bg-red-400 ml-2"
        icon={<IoMdTrash size={20} />}
        onClick={() => deleteHandler()}
      />
      {mailUser != null && !mailUser && (
        <TableActionButton
          className="ml-2 bg-gray-100"
          icon={<FiSend size={20} />}
          onClick={() => inviteHandler()}
        />
      )}
    </div>
  );
}

interface UserTableProps {
  editUserStateHandler: (isOpen: boolean, userId: string) => void;
  deleteUserStateHandler: (
    isOpen: boolean,
    userId: string,
    mail: string
  ) => void;
  inviteUserStateHandler: (
    isOpen: boolean,
    userId: string,
    mail: string
  ) => void;
  searchTerm?: string;
  groupId?: string;
}

function UserTable({
  editUserStateHandler,
  deleteUserStateHandler,
  inviteUserStateHandler,
  searchTerm,
  groupId
}: UserTableProps): ReactElement {
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortColumns, setSortColumns] = useState('user_name:ASC');
  useEffect(() => {
    let sortingColumns = 'user_name:ASC';
    if (sorting.length > 0) {
      sorting.forEach((sortCol) => {
        sortingColumns = `${sortCol.id}:${sortCol.desc ? 'DESC' : 'ASC'}`;
      });
    }
    setSortColumns(sortingColumns);
  }, [sorting, setSortColumns]);

  const columns = useMemo<ColumnDef<V1Alpha1User>[]>(
    () => [
      {
        header: 'Name',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.userName,
        footer: (props) => props.column.id,
        id: 'user_name',
        enableSorting: true
      },
      {
        header: 'Mail',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.mail,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Rolle',
        cell: (info) =>
          RowCell({
            title: info.row.original.isAdmin ? t('role.ROLE_GLOBAL_ADMIN') : ''
          }),
        accessorFn: (row) => row.groups,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Bearbeiten',
        accessorFn: (row) => row.userId,
        cell: (info) =>
          UserDetailButtons({
            editHandler: () =>
              editUserStateHandler(true, info.getValue<string>()),
            deleteHandler: () => {
              if (info.row.original.mail != null) {
                deleteUserStateHandler(
                  true,
                  info.getValue<string>(),
                  info.row.original.mail
                );
              }
            },
            inviteHandler: () => {
              if (info.row.original.mail != null) {
                inviteUserStateHandler(
                  true,
                  info.getValue<string>(),
                  info.row.original.mail
                );
              }
            },
            mailUser: info.row.original.isMailUser
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ],
    [t, editUserStateHandler, deleteUserStateHandler, inviteUserStateHandler]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data, isLoading, isSuccess, isError, error } =
    useIdentityServiceListUsersQuery({
      groupId,
      limit: pageSize,
      page: pageIndex,
      searchBy: searchTerm != null ? searchTerm : '',
      sortBy: sortColumns
    });

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.users ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination, sorting },
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting
  });

  return (
    <DataTable
      isLoading={isLoading}
      isError={isError}
      isEmpty={isSuccess && (data.users == null || data.users.length === 0)}
      pageSize={pageSize}
      table={table}
    />
  );
}

export default UserTable;
