/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useState } from 'react';
import de from 'date-fns/locale/de';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormContext, Controller } from 'react-hook-form';
import { RiAlertFill } from 'react-icons/ri';
import FormErrorLabel from './formErrorLabel';

interface FormDateRangePickerProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  errorMsg?: string;
  disabled?: boolean;
}

function FormDatePicker({
  id,
  label,
  placeholder = 'Bitte ein Datum wählen',
  required = false,
  errorMsg = 'Bitte eine Datum wählen',
  disabled = false
}: FormDateRangePickerProps): ReactElement {
  registerLocale('de', de);
  const [date, setDate] = useState<Date | null>(null);
  const {
    formState: { errors },
    control
  } = useFormContext();

  return (
    <div className="flex flex-col w-full mt-2">
      <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
      </div>
      <Controller
        control={control}
        name={id}
        rules={{ required: true }}
        render={({ field }) => (
          <DatePicker
            placeholderText={placeholder}
            className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none"
            locale="de"
            selected={date}
            onChange={(selectedDate) => {
              if (selectedDate != null) {
                setDate(selectedDate);
                field.onChange(selectedDate);
              }
            }}
            dateFormat="dd.MM.yyyy"
          />
        )}
      />

      {required && errors?.[id] && (
        <FormErrorLabel icon={<RiAlertFill size={20} />} label={errorMsg} />
      )}
    </div>
  );
}

export default FormDatePicker;
