import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload, FiTrash } from 'react-icons/fi';
import { skipToken } from '@reduxjs/toolkit/query';
import TableActionButton from '../../../components/tables/tableActionButton';
import {
  useReportServiceListReportsQuery,
  V1Alpha1Report
} from '../../../api/gen/reports';
import PeriodLabel from '../../../components/date/periodLabel';
import { useBuildingServiceListBuildingsQuery } from '../../../api/enhanced/enhancedBuildings';
import { V1Alpha1Building } from '../../../api/gen/buildings';
import RowCell from '../../../components/tables/rowCell';
import DateTableLabel from '../../../components/date/dateTableLabel';
import DataTable from '../../../components/tables/dataTable';
import useDownloadReportHandler from '../../../hooks/downloadReportHook';

interface InspectionReportTableProps {
  groupId: string;
  deleteReportHandler: (
    buildingId: string,
    reportId: string,
    isOpen: boolean
  ) => void;
}

function InspectionReportTable({
  groupId,
  deleteReportHandler
}: InspectionReportTableProps): ReactElement {
  const { t } = useTranslation();
  const { setRequestData } = useDownloadReportHandler();

  const { data: buildingData } = useBuildingServiceListBuildingsQuery(
    groupId != null && groupId !== ''
      ? {
          groupId
        }
      : skipToken
  );

  const columns = useMemo<ColumnDef<V1Alpha1Report>[]>(() => {
    const getBuilding = (buildingId: string): string => {
      if (buildingData != null && buildingData.buildings != null) {
        const building = buildingData.buildings.find(
          (currentBuilding: V1Alpha1Building) =>
            currentBuilding.id === buildingId
        );

        if (building != null && building.description != null) {
          return building.description;
        }
      }

      return '';
    };

    interface DetailButtonProps {
      downloadHandler: () => void;
      deleteHandler: () => void;
      created: boolean;
    }

    function getDetailButtons({
      downloadHandler,
      deleteHandler,
      created
    }: DetailButtonProps): ReactElement {
      return (
        <div className="flex flex-row">
          {created && (
            <TableActionButton
              icon={<FiDownload size={20} />}
              onClick={() => downloadHandler()}
            />
          )}
          {created && (
            <TableActionButton
              className="bg-red-400"
              icon={<FiTrash size={20} />}
              onClick={() => deleteHandler()}
            />
          )}
        </div>
      );
    }

    function getStateLabel(state: string): string {
      switch (state) {
        case 'REPORT_STATE_UNSPECIFIED':
          return t('reports.state.REPORT_STATE_UNSPECIFIED');
        case 'REPORT_STATE_CREATING':
          return t('reports.state.REPORT_STATE_CREATING');
        case 'REPORT_STATE_CREATED':
          return t('reports.state.REPORT_STATE_CREATED');
        case 'REPORT_STATE_FAILED':
          return t('reports.state.REPORT_STATE_FAILED');
        default:
          return state;
      }
    }

    return [
      {
        header: 'Gebäude',
        cell: (info) =>
          RowCell({ title: getBuilding(info.getValue<string>()) }),
        accessorFn: (row) => row.buildingId,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Erstellungszeitpunkt',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.createdAt,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Zeitraum',
        cell: (info) =>
          PeriodLabel({
            startTimestamp: info.row.original.startTimestamp,
            endTimestamp: info.row.original.endTimestamp,
            className: 'px-6'
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Status',
        cell: (info) =>
          RowCell({ title: getStateLabel(info.getValue<string>()) }),
        footer: (props) => props.column.id,
        accessorFn: (row) => row.state,
        enableSorting: false
      },
      {
        header: 'Details',
        cell: (info) =>
          getDetailButtons({
            downloadHandler: () =>
              setRequestData({
                groupId,
                buildingId:
                  info.row.original.buildingId != null
                    ? info.row.original.buildingId
                    : '',
                reportId:
                  info.row.original.reportId != null
                    ? info.row.original.reportId
                    : ''
              }),
            deleteHandler: () =>
              deleteReportHandler(
                info.row.original.buildingId != null
                  ? info.row.original.buildingId
                  : '',
                info.row.original.reportId != null
                  ? info.row.original.reportId
                  : '',
                true
              ),
            created:
              info.row.original.state != null &&
              info.row.original.state === 'REPORT_STATE_CREATED'
          }),
        accessorFn: (row) => row.reportId,
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];
  }, [buildingData, deleteReportHandler, t, setRequestData, groupId]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data, isLoading, isFetching, isSuccess, isError, error } =
    useReportServiceListReportsQuery(
      groupId != null && groupId !== ''
        ? {
            groupId,
            limit: pageSize,
            page: pageIndex,
            reportType: 'REPORT_TYPE_INSPECTION'
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.totalCount);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.reports ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={isSuccess && (data.reports == null || data.reports.length === 0)}
      pageSize={pageSize}
      table={table}
    />
  );
}

export default InspectionReportTable;
