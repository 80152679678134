import { notificationApi } from '../gen/notifications';

export const enhancedNotificationApi = notificationApi.enhanceEndpoints({
  addTagTypes: [
    'SENSOR_TYPE_UNSPECIFIED',
    'SENSOR_TYPE_TRAP',
    'SENSOR_TYPE_TEMP',
    'SENSOR_TYPE_CARBON_DIOXIDE',
    'SENSOR_TYPE_DOOR',
    'SENSOR_TYPE_HUMIDITY'
  ],
  endpoints: {
    notificationServiceGetNotification: {
      providesTags: (result, error, arg) => [arg.sensorType]
    },
    notificationServiceListNotifications: {
      providesTags: (result, error, arg) => [arg.sensorType]
    },
    notificationServiceUpdateNotification: {
      invalidatesTags: (result, error, arg) => [arg.sensorType]
    }
  }
});

export const {
  useNotificationServiceGetNotificationQuery,
  useNotificationServiceUpdateNotificationMutation,
  useNotificationServiceListNotificationsQuery
} = enhancedNotificationApi;
