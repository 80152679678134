/* eslint-disable */
import React, { ReactElement } from 'react';
import { Link, Params, useMatches, useNavigate } from 'react-router-dom';
import { MdOutlineChevronRight } from 'react-icons/md';

interface MatchType {
  id: string;
  pathname: string;
  params: Params<string>;
  data: any;
  handle: any;
}

function Breadcrumbs(): ReactElement | null {
  const matches = useMatches();
  const navigate = useNavigate();
  const crumbs = matches
    .filter((match: MatchType) => Boolean(match.handle?.crumb))
    .map((match: MatchType) => match.handle.crumb(match.params));

  if (crumbs.length === 0) {
    return null;
  }

  const renderCrumbs: { name: string; steps: number }[] = crumbs[0];
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-1">
        {renderCrumbs.map((crumb, index) => (
          <li key={index} className="inline-flex items-center">
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(crumb.steps);
              }}
            >
              {crumb.name}
            </Link>
            {renderCrumbs.length > 1 && index < renderCrumbs.length - 1 && (
              <MdOutlineChevronRight size={20} />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
export default Breadcrumbs;
