/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useBuildingServiceCreateBuildingMutation } from '../../../api/enhanced/enhancedBuildings';
import { RootState } from '../../../api/store';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';

interface CreateBuildingFormValues {
  description: string;
  street: string;
  houseNumber: string;
  city: string;
  zipCode: string;
}

interface CreateBuildingModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  groupId: string;
}

function CreateBuildingModal({
  closeHandler,
  isOpen,
  groupId
}: CreateBuildingModalProps): ReactElement {
  const methods = useForm<CreateBuildingFormValues>();
  const { t } = useTranslation();

  const [addBuilding, { isLoading, isError, isSuccess, reset }] =
    useBuildingServiceCreateBuildingMutation();

  const onSubmit = (formData: CreateBuildingFormValues): void => {
    addBuilding({
      groupId,
      body: {
        building: {
          groupId,
          description: formData.description,
          street: formData.street,
          houseNumber: formData.houseNumber,
          city: formData.city,
          zipCode: formData.zipCode
        }
      }
    });
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [isSuccess, methods, reset, closeHandler]);

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createBuilding.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('modals.createBuilding.description.label')}
              errorMsg={t('modals.createBuilding.description.error') as string}
              placeholder={
                t('modals.createBuilding.description.label') as string
              }
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="street"
              label={t('modals.createBuilding.address.label')}
              errorMsg={t('modals.createBuilding.address.error') as string}
              placeholder={t('modals.createBuilding.address.label') as string}
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="houseNumber"
              label={t('modals.createBuilding.houseNumber.label')}
              errorMsg={t('modals.createBuilding.houseNumber.error') as string}
              placeholder={
                t('modals.createBuilding.houseNumber.label') as string
              }
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="city"
              label={t('modals.createBuilding.city.label')}
              errorMsg={t('modals.createBuilding.city.error') as string}
              placeholder={t('modals.createBuilding.city.label') as string}
              disabled={isLoading}
              required
            />
            <FormTextInput
              id="zipCode"
              label={t('modals.createBuilding.postCode.label')}
              errorMsg={t('modals.createBuilding.postCode.error') as string}
              placeholder={t('modals.createBuilding.postCode.label') as string}
              disabled={isLoading}
              required
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateBuildingModal;
