import React, { ReactElement, useState } from 'react';
import { MdAdminPanelSettings, MdSpaceDashboard } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Outlet, useParams } from 'react-router-dom';
import { RiAlarmFill } from 'react-icons/ri';
import { FaClipboardList } from 'react-icons/fa';
import { HiBell } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useIdentityServiceGetUserInfoQuery } from '../../api/gen/identity';
import SidebarItem from '../../components/nav/sidebaritem';
import logo from '../../assets/logo.png';
import Sidebar from '../../components/nav/sidebar';
import GroupDropdown from '../../components/dropdown/groupDropdown';
import NotificationMenu from '../../components/menu/notificationMenu';
import UserMenu from '../../components/menu/usermenu';
import Avatar from '../../components/avatar/avatar';
import Drawer from '../../components/nav/drawer';
import useWebsocket from '../../hooks/websocketHook';

function Layout(): ReactElement {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const [isOpen, setIsOpen] = useState(false);
  const { groupId } = useParams();
  useWebsocket(groupId);

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  React.useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  const { data } = useIdentityServiceGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { userId: accounts[0].localAccountId }
      : skipToken
  );

  if (width < breakpoint) {
    return (
      <div className="h-screen w-full bg-gray-200 flex justify-center items-center">
        <div className="shadow-md rounded-lg w-10/12 bg-white flex justify-center items-center p-4 flex-col">
          <img alt="logo" src={logo} className="p-4 h-16" />
          <h1 className="text-lg font-bold text-center">
            Diese Anwendung wird auf dieser Displaygröße noch nicht unterstützt
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex-1 flex flex-col overflow-hidden p-2">
        <header className="flex justify-between bg-white items-center w-full h-16 rounded-lg">
          <img alt="logo" src={logo} className="p-4 h-16" />
          <div className="flex flex-row pr-4">
            <GroupDropdown selectedGroup={groupId} />
            {groupId != null && groupId !== '' && (
              <NotificationMenu groupId={groupId}>
                <div className="flex items-center text-center justify-center rounded-full bg-gray-200 mr-2 w-10 h-10 ml-2">
                  <HiBell size={20} />
                  {/* eventData != null &&
                  eventData.total != null &&
                  Number(eventData.total) > 0 && (
                    <span className="animate-ping absolute top-1 right-2 block h-1.5 w-1.5 rounded-full ring-2 ring-green-400 bg-green-600" />
                  ) */}
                </div>
              </NotificationMenu>
            )}
            {/* <LanguageMenu>
              <div className="flex items-center text-center justify-center rounded-full bg-gray-200 mr-2 w-10 h-10">
                <MdLanguage size={20} />
              </div>
            </LanguageMenu> */}
            {groupId != null && groupId !== '' && (
              <UserMenu groupId={groupId}>
                <Avatar
                  label={data?.user?.userName}
                  className="bg-gray-200 w-10 h-10"
                />
              </UserMenu>
            )}
            <button
              aria-label="Menu"
              type="button"
              className="flex items-center text-center justify-center rounded-full bg-gray-200 ml-2 w-10 h-10 lg:hidden"
              onClick={() => setIsOpen(!isOpen)}
            >
              <GiHamburgerMenu size={20} />
            </button>
          </div>
        </header>
        <div className="flex h-full">
          {data != null && data.user != null && (
            <Drawer
              isOpen={isOpen}
              setIsOpen={(state: boolean) => {
                setIsOpen(state);
              }}
            >
              <Sidebar className=" min-h-screen z-10 bg-white">
                <SidebarItem
                  route=""
                  title="Dashboard"
                  icon={<MdSpaceDashboard />}
                />
                <SidebarItem
                  route="notifications"
                  title={t('navigation.notifications')}
                  icon={<HiBell />}
                />
                {data != null &&
                  data.user != null &&
                  data.user.isAdmin &&
                  data.user.userId !== '0d46d8cd-1dac-4f2d-92bd-501c485e6d62' &&
                  data.user.userId !== 'd85ac9db-fe28-4b4c-b02c-e1d1dfc3d9ce' &&
                  data.user.userId !==
                    'e3ca9237-1977-4d89-bcd7-c3149070183a' && (
                    <SidebarItem
                      route="alarms"
                      title={t('navigation.alarms')}
                      icon={<RiAlarmFill />}
                    />
                  )}
                <SidebarItem
                  route="reports"
                  title={t('navigation.reports')}
                  icon={<FaClipboardList />}
                />
                {data != null &&
                  data.user != null &&
                  data.user.isAdmin &&
                  data.user.userId !== '0d46d8cd-1dac-4f2d-92bd-501c485e6d62' &&
                  data.user.userId !== 'd85ac9db-fe28-4b4c-b02c-e1d1dfc3d9ce' &&
                  data.user.userId !==
                    'e3ca9237-1977-4d89-bcd7-c3149070183a' && (
                    <SidebarItem
                      route="administration"
                      title={t('navigation.administration')}
                      icon={<MdAdminPanelSettings />}
                    />
                  )}
              </Sidebar>
            </Drawer>
          )}

          <aside className="hidden flex w-72 h-full lg:block mt-2 ">
            <div className="w-full flex mx-auto">
              <Sidebar className=" min-h-screen z-10 bg-white rounded-lg">
                <SidebarItem
                  route=""
                  title="Dashboard"
                  icon={<MdSpaceDashboard />}
                />
                <SidebarItem
                  route="notifications"
                  title={t('navigation.notifications')}
                  icon={<HiBell />}
                />
                {data != null &&
                  data.user != null &&
                  data.user.isAdmin &&
                  data.user.userId !== '0d46d8cd-1dac-4f2d-92bd-501c485e6d62' &&
                  data.user.userId !== 'd85ac9db-fe28-4b4c-b02c-e1d1dfc3d9ce' &&
                  data.user.userId !==
                    'e3ca9237-1977-4d89-bcd7-c3149070183a' && (
                    <SidebarItem
                      route="alarms"
                      title={t('navigation.alarms')}
                      icon={<RiAlarmFill />}
                    />
                  )}
                <SidebarItem
                  route="reports"
                  title={t('navigation.reports')}
                  icon={<FaClipboardList />}
                />
                {data != null &&
                  data.user != null &&
                  data.user.isAdmin &&
                  data.user.userId !== '0d46d8cd-1dac-4f2d-92bd-501c485e6d62' &&
                  data.user.userId !== 'd85ac9db-fe28-4b4c-b02c-e1d1dfc3d9ce' &&
                  data.user.userId !==
                    'e3ca9237-1977-4d89-bcd7-c3149070183a' && (
                    <SidebarItem
                      route="administration"
                      title={t('navigation.administration')}
                      icon={<MdAdminPanelSettings />}
                    />
                  )}
              </Sidebar>
            </div>
          </aside>
          <main className="flex flex-col w-full min-h-screen overflow-y-auto  pb-16 h-full mx-auto py-4">
            <div className="overflow-x-auto h-full pb-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Layout;
