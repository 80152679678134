/* eslint-disable react/jsx-props-no-spreading */
import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useCheckpointServiceCreateCheckpointMutation } from '../../../api/enhanced/enhancedCheckpoints';
import { useBuildingServiceListAreasForBuildingQuery } from '../../../api/enhanced/enhancedBuildings';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import FormSelect, {
  FormSelectOption
} from '../../../components/form/formSelect';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import {
  V1Alpha1AnalogTrapType,
  V1Alpha1DoorSensorType,
  V1Alpha1TrapType
} from '../../../api/gen/checkpoints';

interface CreateCheckpointFormValues {
  buildingId: string;
  areaId: string;
  description: string;
  devEui: string;
  checkpointNumber: number;
  analogTrapType: V1Alpha1AnalogTrapType;
  doorSensorType: V1Alpha1DoorSensorType;
  openingDistance: number;
  trapType: V1Alpha1TrapType;
  tempMaxLimit: number;
  tempMinLimit: number;
  humidityMinLimit: number;
  humidityMaxLimit: number;
  co2WarningLimit: number;
  co2AlarmLimit: number;
}

interface CreateCheckpointModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  buildingId?: string;
  groupId?: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
}

function CreateCheckpointModal({
  closeHandler,
  isOpen,
  buildingId,
  groupId,
  sensorType
}: CreateCheckpointModalProps): ReactElement {
  const methods = useForm<CreateCheckpointFormValues>();
  const { t } = useTranslation();
  const doorSensorType = methods.watch('doorSensorType');

  const [createCheckpoint, { isLoading, isError, isSuccess, reset }] =
    useCheckpointServiceCreateCheckpointMutation();

  const { data, isLoading: areaLoading } =
    useBuildingServiceListAreasForBuildingQuery(
      buildingId != null && groupId != null
        ? {
            buildingId,
            groupId,
            limit: 200,
            page: 0
          }
        : skipToken
    );

  const onSubmit = (formData: CreateCheckpointFormValues): void => {
    if (groupId != null && buildingId != null) {
      const request = {
        groupId,
        buildingId,
        sensorType,
        checkpointServiceCreateCheckpointBody: {
          checkpoint: {
            description: formData.description,
            areaId: formData.areaId,
            checkpointNumber: formData.checkpointNumber,
            currentSensorId: formData.devEui,
            trapPayload:
              sensorType === 'SENSOR_TYPE_TRAP'
                ? {
                    trapType: formData.trapType
                  }
                : undefined,
            temperaturePayload:
              sensorType === 'SENSOR_TYPE_TEMP'
                ? {
                    minLimit: formData.tempMinLimit,
                    maxLimit: formData.tempMaxLimit,
                    humidityMinLimit: formData.humidityMinLimit,
                    humidityMaxLimit: formData.humidityMaxLimit
                  }
                : undefined,
            carbonDioxidePayload:
              sensorType === 'SENSOR_TYPE_CARBON_DIOXIDE'
                ? {
                    warningLimit: formData.co2WarningLimit,
                    alarmLimit: formData.co2AlarmLimit
                  }
                : undefined,
            doorPayload:
              sensorType === 'SENSOR_TYPE_DOOR'
                ? {
                    doorSensorType: formData.doorSensorType,
                    openingDistance: formData.openingDistance
                  }
                : undefined,
            analogTrapPayload:
              sensorType === 'SENSOR_TYPE_ANALOG_TRAP'
                ? {
                    analogTrapType: formData.analogTrapType
                  }
                : undefined
          }
        }
      };

      createCheckpoint(request);
    }
  };

  const close = (): void => {
    closeHandler();
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [isSuccess, methods, reset, closeHandler]);

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t(`features.checkpoints.modals.create.title.${sensorType}`)}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('features.checkpoints.modals.create.description.label')}
              errorMsg={
                t(
                  'features.checkpoints.modals.create.description.error'
                ) as string
              }
              placeholder={
                t(
                  'features.checkpoints.modals.create.description.label'
                ) as string
              }
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="devEui"
              label={t('features.checkpoints.modals.create.eui.label')}
              errorMsg={
                t('features.checkpoints.modals.create.eui.error') as string
              }
              placeholder={
                t('features.checkpoints.modals.create.eui.label') as string
              }
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="checkpointNumber"
              label={t(
                'features.checkpoints.modals.create.checkpointNumber.label'
              )}
              errorMsg={
                t(
                  'features.checkpoints.modals.create.checkpointNumber.error'
                ) as string
              }
              placeholder={
                t(
                  'features.checkpoints.modals.create.checkpointNumber.label'
                ) as string
              }
              disabled={isLoading}
              required
              type="number"
            />

            {sensorType === 'SENSOR_TYPE_TEMP' && (
              <div className="flex flex-col">
                <FormTextInput
                  id="tempMinLimit"
                  label={t(
                    'features.checkpoints.modals.create.tempMinLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.tempMinLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.tempMinLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
                <FormTextInput
                  id="tempMaxLimit"
                  label={t(
                    'features.checkpoints.modals.create.tempMaxLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.tempMaxLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.tempMaxLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
                <FormTextInput
                  id="humidityMinLimit"
                  label={t(
                    'features.checkpoints.modals.create.humidityMinLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.humidityMinLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.humidityMinLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
                <FormTextInput
                  id="humidityMaxLimit"
                  label={t(
                    'features.checkpoints.modals.create.humidityMaxLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.humidityMaxLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.humidityMaxLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
              </div>
            )}

            {sensorType === 'SENSOR_TYPE_CARBON_DIOXIDE' && (
              <div className="flex flex-col">
                <FormTextInput
                  id="co2WarningLimit"
                  label={t(
                    'features.checkpoints.modals.create.co2WarningLimit.label'
                  )}
                  errorMsg={
                    t(
                      'mfeatures.checkpoints.modals.create.co2WarningLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.co2WarningLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
                <FormTextInput
                  id="co2AlarmLimit"
                  label={t(
                    'features.checkpoints.modals.create.co2AlarmLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.co2AlarmLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.co2AlarmLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
              </div>
            )}

            {sensorType === 'SENSOR_TYPE_TRAP' && (
              <FormSelect
                id="trapType"
                label={t('features.checkpoints.modals.create.trapType.label')}
                errorMsg={
                  t(
                    'features.checkpoints.modals.create.trapType.error'
                  ) as string
                }
                placeholder={
                  t(
                    'features.checkpoints.modals.create.trapType.label'
                  ) as string
                }
                disabled={isLoading}
                options={[
                  {
                    value: 'TRAP_TYPE_MOUSE',
                    description: t(
                      'features.checkpoints.trapTypes.TRAP_TYPE_MOUSE'
                    )
                  },
                  {
                    value: 'TRAP_TYPE_RAT',
                    description: t(
                      'features.checkpoints.trapTypes.TRAP_TYPE_RAT'
                    )
                  }
                ]}
              />
            )}

            {sensorType === 'SENSOR_TYPE_DOOR' && (
              <FormSelect
                id="doorSensorType"
                label={t(
                  'features.checkpoints.modals.create.doorSensorType.label'
                )}
                errorMsg={
                  t(
                    'features.checkpoints.modals.create.doorSensorType.error'
                  ) as string
                }
                placeholder={
                  t(
                    'features.checkpoints.modals.create.doorSensorType.label'
                  ) as string
                }
                disabled={isLoading}
                options={[
                  {
                    value: 'DOOR_SENSOR_TYPE_OPENING',
                    description: t(
                      'features.checkpoints.doorSensorTypes.DOOR_SENSOR_TYPE_OPENING'
                    )
                  },
                  {
                    value: 'DOOR_SENSOR_TYPE_DISTANCE',
                    description: t(
                      'features.checkpoints.doorSensorTypes.DOOR_SENSOR_TYPE_DISTANCE'
                    )
                  }
                ]}
              />
            )}

            {sensorType === 'SENSOR_TYPE_DOOR' &&
              doorSensorType === 'DOOR_SENSOR_TYPE_DISTANCE' && (
                <FormTextInput
                  id="openingDistance"
                  label={t(
                    'features.checkpoints.modals.create.openingDistance.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.openingDistance.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.openingDistance.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                  required={doorSensorType === 'DOOR_SENSOR_TYPE_DISTANCE'}
                />
              )}

            {sensorType === 'SENSOR_TYPE_ANALOG_TRAP' && (
              <FormSelect
                id="analogTrapType"
                label={t(
                  'features.checkpoints.modals.create.analogTrapType.label'
                )}
                errorMsg={
                  t(
                    'features.checkpoints.modals.create.analogTrapType.error'
                  ) as string
                }
                placeholder={
                  t(
                    'features.checkpoints.modals.create.analogTrapType.label'
                  ) as string
                }
                disabled={isLoading}
                options={[
                  {
                    value: 'ANALOG_TRAP_TYPE_FLIGHT_INSECT_DEVICE',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_FLIGHT_INSECT_DEVICE'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_FLOUR_BEETLE_TRAP',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_FLOUR_BEETLE_TRAP'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_MOTH_TRAP',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_MOTH_TRAP'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_NON_TOX_BOX',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_NON_TOX_BOX'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_OTHERS',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_OTHERS'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_SCRAPE',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_SCRAPE'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_TOX_BOX',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_TOX_BOX'
                    )
                  }
                ]}
              />
            )}

            {data != null && data.areas != null && (
              <FormSelect
                id="areaId"
                label={t('features.checkpoints.modals.create.area.label')}
                errorMsg={
                  t('features.checkpoints.modals.create.area.error') as string
                }
                placeholder={
                  t('features.checkpoints.modals.create.area.label') as string
                }
                disabled={isLoading}
                options={data?.areas.map((option): FormSelectOption => {
                  return {
                    value: option.id != null ? option.id : '',
                    description:
                      option.description != null ? option.description : ''
                  };
                })}
              />
            )}

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading && !areaLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateCheckpointModal;
