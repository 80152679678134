import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { useOfflineAlarmServiceDeleteOfflineAlarmMutation } from '../../../api/enhanced/enhancedOfflineAlarms';

interface DeleteOfflineAlarmModalProps {
  isOpen: boolean;
  alarmId?: string;
  closeHandler: () => void;
}

function DeleteAlarmModal({
  isOpen,
  alarmId,
  closeHandler
}: DeleteOfflineAlarmModalProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteAlarm, { isSuccess, isError, isLoading, reset }] =
    useOfflineAlarmServiceDeleteOfflineAlarmMutation();

  useEffect(() => {
    if (isSuccess) {
      reset();
      closeHandler();
    }
  }, [isSuccess, reset, navigate, closeHandler]);

  const close = (): void => {
    closeHandler();
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={() => {
        close();
      }}
      title={t('modals.deleteAlarm.title')}
    >
      <div className="flex flex-col w-full">
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.deleteAlarm.error')}
            />
          ) : null}
        </div>
        <p>{t('modals.deleteAlarm.message')}</p>
        <div className="flex items-end flex-col w-full mt-5">
          {!isLoading && alarmId != null ? (
            <button
              className=" rounded-lg shadow-md w-36 h-10 bg-red-500 text-white"
              type="button"
              onClick={() => {
                deleteAlarm({ id: alarmId });
              }}
            >
              {t('modals.deleteAlarm.deleteBtn')}
            </button>
          ) : (
            <div className="rounded-lg shadow-md w-36  h-10  p-4 bg-red-500 text-white flex justify-center items-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default DeleteAlarmModal;
