import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import TabBar, {
  SensorTabContent,
  SensorType
} from '../../../components/tabs/tabBar';
import PageTitle from '../../../components/title/pageTitle';
import TemperatureNotificationTable from '../tables/temperature/temperatureNotificationTable';
import CarbonDioxideNotificationTable from '../tables/carbonDioxide/carbonDioxideNotificationTabel';
import DoorNotificationTable from '../tables/doors/doorTable';
import TrapNotificationsTabs from '../components/trapNotificationsTabs';
import HumidityNotificationTable from '../tables/humidity/humidityNotificationTable';

function Notifications(): ReactElement {
  const { t } = useTranslation();

  const tabs: SensorTabContent[] = [
    {
      key: 0,
      sensorType: SensorType.TRAP,
      tabLabel: t('tabs.traps'),
      content: <TrapNotificationsTabs />
    },
    {
      key: 1,
      sensorType: SensorType.TEMP,
      tabLabel: t('tabs.temp'),
      content: <TemperatureNotificationTable />
    },
    {
      key: 2,
      sensorType: SensorType.CO2,
      tabLabel: t('tabs.co2'),
      content: <CarbonDioxideNotificationTable />
    },
    {
      key: 3,
      sensorType: SensorType.DOOR,
      tabLabel: t('tabs.door'),
      content: <DoorNotificationTable />
    },
    {
      key: 4,
      sensorType: SensorType.HUMIDITY,
      tabLabel: t('tabs.humidity'),
      content: <HumidityNotificationTable />
    }
  ];

  return (
    <div className="flex flex-col px-8 w-full">
      <PageTitle title={t('notifications.title')} />
      <TabBar tabs={tabs} />
    </div>
  );
}

export default Notifications;
