import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import TabBar, { TabContent } from '../../../components/tabs/tabBar';
import Users from '../../users/pages/users';
import Groups from '../../groups/pages/groups';
import PageTitle from '../../../components/title/pageTitle';
import DeviceRegistry from '../../devices/pages/devices';
import OfflineAlarmPage from '../../alarms/pages/offline-alarm-page';

function AdministrationPage(): ReactElement {
  const { t } = useTranslation();

  const tabContent: TabContent[] = [
    {
      key: 0,
      tabLabel: t('administration.tabs.groups'),
      content: <Groups />
    },
    {
      key: 1,
      tabLabel: t('administration.tabs.user'),
      content: <Users />
    },
    {
      key: 2,
      tabLabel: t('administration.tabs.devices'),
      content: <DeviceRegistry />
    },
    {
      key: 3,
      tabLabel: t('administration.tabs.offlineAlarms'),
      content: <OfflineAlarmPage />
    }
  ];

  return (
    <div className="flex flex-col px-8 w-full">
      <PageTitle title={t('administration.title')} />
      <TabBar tabs={tabContent} />
    </div>
  );
}

export default AdministrationPage;
