import React, { ReactElement, useMemo, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { MdEdit } from 'react-icons/md';
import { skipToken } from '@reduxjs/toolkit/query';
import TrapStatePill from '../../../../components/pill/trapStatePill';
import DateTableLabel from '../../../../components/date/dateTableLabel';
import TableActionButton from '../../../../components/tables/tableActionButton';
import DataTable from '../../../../components/tables/dataTable';
import UserComment from '../../components/traps/userComment';
import { useHistoryServiceListHistoryForBuildingQuery } from '../../../../api/enhanced/enhancedHistory';
import { V1Alpha1TrapHistoryViewModel } from '../../../../api/gen/history';
import RowCell from '../../../../components/tables/rowCell';

interface HistoryTableProps {
  groupId?: string;
  buildingId?: string;
  startTime?: string;
  endTime?: string;
  commentModalStateHandler: (
    isOpen: boolean,
    entryId: string,
    checkpointId?: string
  ) => void;
}

function FalseAlarmTable({
  groupId,
  buildingId,
  commentModalStateHandler,
  startTime,
  endTime
}: HistoryTableProps): ReactElement {
  const getComment = (label: string): string => {
    switch (label) {
      case 'serviceCleanup':
        return 'Fehlauslösung bei Reinigung';
      case 'service':
        return 'Fehlauslösung bei Service';
      case 'auditor':
        return 'Fehlauslösung durch Auditor';
      case 'destroyed':
        return 'Fehlauslösung durch Zerstörung';
      default:
        return label;
    }
  };

  const columns = useMemo<ColumnDef<V1Alpha1TrapHistoryViewModel>[]>(
    () => [
      {
        header: 'Prüfpunktnummer',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.checkpointNumber,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Description',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.description,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Zustand',
        cell: (info) => TrapStatePill({ state: info.getValue<string>() }),
        accessorFn: (row) => row.trapState,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Zeitpunkt des Signals',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.timestamp,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Fehlauslösung',
        cell: (info) =>
          RowCell({ title: info.getValue<boolean>() ? 'Ja' : 'Nein' }),
        accessorFn: (row) => row.falseAlarm,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Kommentar',
        cell: (info) =>
          UserComment({
            comment: getComment(info.getValue<string>()),
            className: 'px-6'
          }),
        accessorFn: (row) => row.comment,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Bearbeiten',
        accessorFn: (row) => row.id,
        cell: (info) =>
          TableActionButton({
            className: 'bg-red-500 text-white',
            icon: <MdEdit size={20} />,
            onClick: () => {
              commentModalStateHandler(
                true,
                info.getValue<string>(),
                info.row.original.checkpointId
              );
            }
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ],
    [commentModalStateHandler]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const paramsValid = groupId != null && buildingId != null && groupId !== '';
  const { data, isLoading, isFetching, isSuccess, isError } =
    useHistoryServiceListHistoryForBuildingQuery(
      paramsValid
        ? {
            buildingId,
            groupId,
            sensorType: 'SENSOR_TYPE_TRAP',
            startTime,
            endTime,
            sortingOrder: 'desc'
          }
        : skipToken
    );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.entries ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={isSuccess && (data.entries == null || data.entries.length === 0)}
      pageSize={pageSize}
      table={table}
      enablePagination={false}
    />
  );
}

export default FalseAlarmTable;
