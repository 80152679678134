import { deviceRegistryApi } from '../gen/deviceregistry';

export const enhancedDeviceRegistryApi = deviceRegistryApi.enhanceEndpoints({
  addTagTypes: ['DeviceRegistry'],
  endpoints: {
    deviceRegistryServiceUpdateDeviceRegistry: {
      invalidatesTags: ['DeviceRegistry']
    }
  }
});

export const { useDeviceRegistryServiceUpdateDeviceRegistryMutation } =
  enhancedDeviceRegistryApi;
