import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { useMsal } from '@azure/msal-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useBuildingServiceGetBuildingQuery } from '../../../api/gen/buildings';
import TabBar, { TabContent } from '../../../components/tabs/tabBar';
import PageTitle from '../../../components/title/pageTitle';
import CreateAlarmModal from '../modals/createAlarmModal';
import DeleteAlarmModal from '../modals/deleteAlarmModal';
import AlarmTable from '../table/alarmTable';
import LabelButton from '../../../components/buttons/labelButton';
import DoorAlarmTable from '../table/doorAlarmTable';
import UpdateAlarmModal from '../modals/updateAlarmModal';
import { useIdentityServiceGetUserInfoQuery } from '../../../api/enhanced/enhancedIdentity';

type SensorType =
  | 'SENSOR_TYPE_UNSPECIFIED'
  | 'SENSOR_TYPE_TRAP'
  | 'SENSOR_TYPE_TEMP'
  | 'SENSOR_TYPE_CARBON_DIOXIDE'
  | 'SENSOR_TYPE_DOOR'
  | 'SENSOR_TYPE_HUMIDITY';

interface ModalState {
  isOpen: boolean;
  alarmId: string;
  sensorType: SensorType;
}

interface CreateModalState {
  isOpen: boolean;
  sensorType: SensorType;
}

function AlarmOverview(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId } = useParams();
  const { accounts } = useMsal();

  const { data: activeUserData } = useIdentityServiceGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { userId: accounts[0].localAccountId }
      : skipToken
  );

  const isAdmin =
    activeUserData != null &&
    activeUserData.user != null &&
    activeUserData.user.isAdmin;

  const [createAlarmState, setCreateAlarmState] = useState<CreateModalState>({
    isOpen: false,
    sensorType: 'SENSOR_TYPE_UNSPECIFIED'
  });
  const [deleteAlarmState, setDeleteAlarmState] = useState<ModalState>({
    isOpen: false,
    alarmId: '',
    sensorType: 'SENSOR_TYPE_UNSPECIFIED'
  });

  const [editAlarmState, setEditAlarmState] = useState<ModalState>({
    isOpen: false,
    alarmId: '',
    sensorType: 'SENSOR_TYPE_UNSPECIFIED'
  });

  const { data, isLoading, isFetching, isSuccess } =
    useBuildingServiceGetBuildingQuery(
      buildingId != null && groupId != null && groupId !== ''
        ? { buildingId, groupId }
        : skipToken
    );

  const tabs: TabContent[] = [
    {
      key: 0,
      tabLabel: t('tabs.traps'),
      content: (
        <div>
          {isAdmin && (
            <LabelButton
              label={t('alarms.addAlarm')}
              className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
              icon={<IoMdAdd size={20} />}
              onClick={() =>
                setCreateAlarmState({
                  isOpen: true,
                  sensorType: 'SENSOR_TYPE_TRAP'
                })
              }
            />
          )}
          <AlarmTable
            sensorType="SENSOR_TYPE_TRAP"
            deleteAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setDeleteAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
            editAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setEditAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
          />
        </div>
      )
    },
    {
      key: 1,
      tabLabel: t('tabs.temp'),
      content: (
        <div>
          {isAdmin && (
            <LabelButton
              label={t('alarms.addAlarm')}
              className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
              icon={<IoMdAdd size={20} />}
              onClick={() =>
                setCreateAlarmState({
                  isOpen: true,
                  sensorType: 'SENSOR_TYPE_TEMP'
                })
              }
            />
          )}
          <AlarmTable
            sensorType="SENSOR_TYPE_TEMP"
            deleteAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setDeleteAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
            editAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setEditAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
          />
        </div>
      )
    },
    {
      key: 2,
      tabLabel: t('tabs.co2'),
      content: (
        <div>
          {isAdmin && (
            <LabelButton
              label={t('alarms.addAlarm')}
              className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
              icon={<IoMdAdd size={20} />}
              onClick={() =>
                setCreateAlarmState({
                  isOpen: true,
                  sensorType: 'SENSOR_TYPE_CARBON_DIOXIDE'
                })
              }
            />
          )}
          <AlarmTable
            sensorType="SENSOR_TYPE_CARBON_DIOXIDE"
            deleteAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setDeleteAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
            editAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setEditAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
          />
        </div>
      )
    },
    {
      key: 3,
      tabLabel: t('tabs.door'),
      content: (
        <div>
          {isAdmin && (
            <LabelButton
              label={t('alarms.addAlarm')}
              className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
              icon={<IoMdAdd size={20} />}
              onClick={() =>
                setCreateAlarmState({
                  isOpen: true,
                  sensorType: 'SENSOR_TYPE_DOOR'
                })
              }
            />
          )}
          <DoorAlarmTable
            sensorType="SENSOR_TYPE_DOOR"
            deleteAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setDeleteAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
            editAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setEditAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
          />
        </div>
      )
    },
    {
      key: 4,
      tabLabel: t('tabs.humidity'),
      content: (
        <div>
          {isAdmin && (
            <LabelButton
              label={t('alarms.addAlarm')}
              className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
              icon={<IoMdAdd size={20} />}
              onClick={() =>
                setCreateAlarmState({
                  isOpen: true,
                  sensorType: 'SENSOR_TYPE_HUMIDITY'
                })
              }
            />
          )}
          <AlarmTable
            sensorType="SENSOR_TYPE_HUMIDITY"
            deleteAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setDeleteAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
            editAlarmHandler={(
              isOpen: boolean,
              alarmId: string,
              sensorType: SensorType
            ) =>
              setEditAlarmState({
                isOpen,
                alarmId,
                sensorType
              })
            }
          />
        </div>
      )
    }
  ];

  return (
    <div>
      <div className="flex flex-col px-8 w-full">
        <PageTitle
          isLoading={isLoading || isFetching || !isSuccess}
          title={`${t('alarms.title')} - ${data?.building?.description}`}
        />
        <TabBar tabs={tabs} />
      </div>

      {groupId != null &&
        isAdmin &&
        buildingId != null &&
        createAlarmState.sensorType !== 'SENSOR_TYPE_UNSPECIFIED' && (
          <CreateAlarmModal
            groupId={groupId}
            sensorType={createAlarmState.sensorType}
            buildingId={buildingId}
            isOpen={createAlarmState.isOpen}
            closeHandler={() => {
              setCreateAlarmState({
                isOpen: false,
                sensorType: 'SENSOR_TYPE_UNSPECIFIED'
              });
            }}
          />
        )}

      {groupId != null &&
        buildingId != null &&
        isAdmin &&
        deleteAlarmState.alarmId !== '' &&
        deleteAlarmState.sensorType !== 'SENSOR_TYPE_UNSPECIFIED' && (
          <DeleteAlarmModal
            buildingId={buildingId}
            groupId={groupId}
            sensorType={deleteAlarmState.sensorType}
            alarmId={deleteAlarmState.alarmId}
            isOpen={deleteAlarmState.isOpen}
            closeHandler={() =>
              setDeleteAlarmState({
                isOpen: false,
                alarmId: '',
                sensorType: 'SENSOR_TYPE_UNSPECIFIED'
              })
            }
          />
        )}

      {groupId != null &&
        buildingId != null &&
        isAdmin &&
        editAlarmState.alarmId !== '' &&
        editAlarmState.sensorType !== 'SENSOR_TYPE_UNSPECIFIED' && (
          <UpdateAlarmModal
            sensorType={editAlarmState.sensorType}
            buildingId={buildingId}
            groupId={groupId}
            alarmId={editAlarmState.alarmId}
            isOpen={editAlarmState.isOpen}
            closeHandler={() =>
              setEditAlarmState({
                isOpen: false,
                alarmId: '',
                sensorType: 'SENSOR_TYPE_UNSPECIFIED'
              })
            }
          />
        )}
    </div>
  );
}

export default AlarmOverview;
