import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { RiAlertFill } from 'react-icons/ri';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import {
  useHistoryServiceGetHistoryEntryQuery,
  useHistoryServiceUpdateHistoryEntryMutation
} from '../../../api/enhanced/enhancedHistory';
import {
  HistoryServiceUpdateHistoryEntryApiArg,
  V1Alpha1HistoryEntry
} from '../../../api/gen/history';

interface DefaultComment {
  key: string;
  label: string;
}

interface CommentModalProps {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  entryId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR';
  closeHandler: () => void;
  isOpen: boolean;
  defaultComments: DefaultComment[];
}

function CommentModal({
  entryId,
  groupId,
  buildingId,
  checkpointId,
  sensorType,
  closeHandler,
  isOpen,
  defaultComments
}: CommentModalProps): ReactElement {
  const { t } = useTranslation();

  const [comment, setComment] = useState('');
  const [selectedKey, setSelectedKey] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [isFalseAlarm, setIsFalseAlarm] = useState(false);
  const [updateHistory, { isLoading, isError, isSuccess, reset }] =
    useHistoryServiceUpdateHistoryEntryMutation();

  const { data: historyData } = useHistoryServiceGetHistoryEntryQuery({
    entryId,
    checkpointId,
    buildingId,
    groupId,
    sensorType
  });

  useEffect(() => {
    if (
      historyData != null &&
      historyData.historyEntry != null &&
      historyData.historyEntry.comment != null
    ) {
      switch (historyData.historyEntry.comment) {
        case 'serviceCleanup':
          setSelectedKey(historyData.historyEntry.comment);
          break;
        case 'service':
          setSelectedKey(historyData.historyEntry.comment);
          break;
        case 'auditor':
          setSelectedKey(historyData.historyEntry.comment);
          break;
        case 'destroyed':
          setSelectedKey(historyData.historyEntry.comment);
          break;
        case '':
          setSelectedKey('');
          break;
        default:
          setComment(historyData.historyEntry.comment);
          break;
      }
    }

    if (
      historyData != null &&
      historyData.historyEntry != null &&
      historyData.historyEntry.trapPayload != null &&
      historyData.historyEntry.trapPayload.falseAlarm != null
    ) {
      setIsFalseAlarm(historyData.historyEntry.trapPayload.falseAlarm);
    }
  }, [historyData]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      setSelectedKey('');
      setComment('');
      setIsFalseAlarm(false);
      closeHandler();
    }
  }, [isSuccess, setSelectedKey, setComment, closeHandler, reset]);

  const getRequestBody = (finalComment: string): V1Alpha1HistoryEntry => {
    switch (sensorType) {
      case 'SENSOR_TYPE_TRAP':
        return {
          comment: finalComment,
          trapPayload: {
            falseAlarm: isFalseAlarm
          }
        };
      case 'SENSOR_TYPE_TEMP':
        return {
          comment: finalComment,
          temperaturePayload: {}
        };
      case 'SENSOR_TYPE_CARBON_DIOXIDE':
        return {
          comment: finalComment,
          carbonDioxidePayload: {}
        };
      case 'SENSOR_TYPE_DOOR':
        return {
          comment: finalComment,
          doorPayload: {}
        };
      default:
        return {
          comment: finalComment
        };
    }
  };

  const handleSubmit = (): void => {
    if (comment !== '' || selectedKey !== '') {
      const finalComment = selectedKey !== '' ? selectedKey : comment;
      const request: HistoryServiceUpdateHistoryEntryApiArg = {
        groupId,
        buildingId,
        checkpointId,
        entryId,
        sensorType,
        historyServiceUpdateHistoryEntryBody: {
          entry: getRequestBody(finalComment)
        }
      };
      updateHistory(request);
    } else {
      setIsEmpty(true);
    }
  };

  const close = (): void => {
    closeHandler();
    setSelectedKey('');
    setComment('');
    setIsFalseAlarm(false);
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={close}
      title={t('modals.addComment.title')}
    >
      {isError ? (
        <FormErrorLabel
          className="mb-3"
          icon={<RiAlertFill size={20} />}
          label={t('modals.error')}
        />
      ) : null}
      {isEmpty ? (
        <FormErrorLabel
          className="mb-3"
          icon={<RiAlertFill size={20} />}
          label={t('modals.addComment.noValue')}
        />
      ) : null}

      {sensorType === 'SENSOR_TYPE_TRAP' && (
        <div className="flex items-center">
          <input
            type="checkbox"
            onChange={() => {
              setIsFalseAlarm(!isFalseAlarm);
            }}
            checked={isFalseAlarm}
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
          />
          <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
            Handelt es sich um eine Fehlauslösung?
          </div>
        </div>
      )}

      <div className="w-full bg-white rounded-lg shadow">
        <ul className="divide-y-2 divide-gray-100">
          {defaultComments.map((defaultComment) => (
            <li key={defaultComment.key} className="p-3">
              <button
                type="button"
                onClick={() => {
                  setSelectedKey(defaultComment.key);
                  setComment('');
                }}
                className="flex w-full items-center justify-between"
              >
                <span>{defaultComment.label}</span>
                {selectedKey === defaultComment.key ? (
                  <IoCheckmarkCircle size={24} />
                ) : null}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="w-full mt-5">
        <label
          htmlFor="customComment"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          {t('modals.addComment.customComment')}
          <input
            id="customComment"
            value={comment}
            onChange={(e) => {
              setSelectedKey('');
              setComment(e.target.value);
            }}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder={t('modals.addComment.placeholder') as string}
          />
        </label>
      </div>
      <div className="flex items-end flex-col w-full mt-5">
        {!isLoading ? (
          <button
            onClick={() => handleSubmit()}
            disabled={comment === '' && selectedKey === ''}
            className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            type="submit"
          >
            {t('modals.save')}
          </button>
        ) : (
          <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default CommentModal;
