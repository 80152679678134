import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloudSync } from 'react-icons/ai';
import LabelButton from '../../../components/buttons/labelButton';
import UpdateDeviceRegistryModal from '../modals/updateDeviceRegistryModal';

function DeviceRegistry(): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="flex flex-col  w-full">
        <div className="flex flex-row w-full justify-between">
          <LabelButton
            label={t('features.devices.pages.admin.updateRegistry')}
            className="bg-gray-200 rounded-lg h-12 text-gray-600"
            icon={<AiOutlineCloudSync size={20} />}
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>

      <UpdateDeviceRegistryModal
        isOpen={isOpen}
        closeHandler={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
}

export default DeviceRegistry;
