/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import MultiselectList, {
  MultiselectContent
} from '../../../components/multiselectList/multiselectList';
import {
  enhancedOfflineAlarms,
  useOfflineAlarmServiceGetOfflineAlarmQuery,
  useOfflineAlarmServiceUpdateOfflineAlarmMutation
} from '../../../api/enhanced/enhancedOfflineAlarms';
import {
  V1OfflineRecipient,
  V1SensorType
} from '../../../api/gen/offline-alarms';
import {
  useIdentityServiceListGroupsQuery,
  useIdentityServiceListUsersQuery
} from '../../../api/enhanced/enhancedIdentity';
import Select from '../../../components/select/select';
import { FormSelectOption } from '../../../components/form/formSelect';
import { useBuildingServiceListBuildingsQuery } from '../../../api/enhanced/enhancedBuildings';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';

interface EditAlarmFormValues {
  description: string;
}

interface EditOfflineAlarmModalProps {
  alarmId: string;
  sensorType: string;
  closeHandler: () => void;
  isOpen: boolean;
}

function EditOfflineAlarmModal({
  alarmId,
  sensorType,
  closeHandler,
  isOpen
}: EditOfflineAlarmModalProps): ReactElement {
  const methods = useForm<EditAlarmFormValues>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<MultiselectContent[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<
    MultiselectContent[]
  >([]);
  const [groupBuildings, setGroupBuildings] = useState<MultiselectContent[]>(
    []
  );

  const { data: alarmData } = useOfflineAlarmServiceGetOfflineAlarmQuery(
    alarmId != null && alarmId !== ''
      ? {
          id: alarmId
        }
      : skipToken
  );

  const [updateAlarm, { isLoading, isError, isSuccess, reset }] =
    useOfflineAlarmServiceUpdateOfflineAlarmMutation();

  const { data: groupData, isLoading: groupLoading } =
    useIdentityServiceListGroupsQuery({});

  const { data: buildingData } = useBuildingServiceListBuildingsQuery(
    selectedGroup != null && selectedGroup !== ''
      ? {
          groupId: selectedGroup
        }
      : skipToken
  );

  const { data } = useIdentityServiceListUsersQuery({
    sortBy: 'user_name:ASC'
  });

  useEffect(() => {
    if (alarmData != null && alarmData.alarm != null) {
      methods.setValue('description', alarmData.alarm.description as string);
    }
  }, [alarmData, methods, alarmId]);

  useEffect(() => {
    if (
      alarmData != null &&
      alarmData.alarm != null &&
      alarmData.alarm.buildings != null &&
      selectedBuildings.length === 0
    ) {
      const content: MultiselectContent[] = [];
      alarmData.alarm.buildings.forEach((building) => {
        content.push({
          selected: true,
          key: building,
          label: ''
        });
      });
      setSelectedBuildings(content);
    }
  }, [alarmData, selectedBuildings.length]);

  useEffect(() => {
    if (buildingData != null && buildingData.buildings != null) {
      const content: MultiselectContent[] = [];
      buildingData.buildings.forEach((building) => {
        const foundElement = selectedBuildings.find(
          (element) => element.key === (building.id as string)
        );
        content.push({
          selected: foundElement != null ? foundElement.selected : false,
          key: building.id as string,
          label: building.description as string
        });
      });
      setGroupBuildings(content);
    }
  }, [buildingData, selectedBuildings]);

  useEffect(() => {
    if (
      groupData != null &&
      groupData.groups != null &&
      groupData.groups.length > 0
    ) {
      setSelectedGroup(groupData.groups[0].groupId as string);
    }
  }, [groupData, alarmId]);

  useEffect(() => {
    if (
      data != null &&
      data.users != null &&
      data.users.length > 0 &&
      alarmData != null &&
      alarmData.alarm != null &&
      alarmData.alarm.recipients != null
    ) {
      const content: MultiselectContent[] = [];
      data.users.forEach((user) => {
        const foundUser = alarmData?.alarm?.recipients?.find(
          (recipient) => recipient.userId === user.userId
        );
        if (user.isAdmin || user.isTechnician)
          content.push({
            selected: foundUser != null,
            key: user.userId as string,
            label: `${user.userName as string} - ${user.mail as string}`
          });
      });
      setSelectedUsers(content);
    }
  }, [alarmData, data]);

  const onSubmit = (formData: EditAlarmFormValues): void => {
    const recipients: V1OfflineRecipient[] = [];
    const buildingIds: string[] = [];
    if (data != null && data.users != null && selectedUsers.length > 0) {
      data.users.forEach((user) => {
        const selected = selectedUsers.some(
          (currUser) => currUser.key === user.userId && currUser.selected
        );
        const foundUser = alarmData?.alarm?.recipients?.find(
          (recipient) => recipient.userId === user.userId
        );
        if (selected) {
          recipients.push({
            id: foundUser != null ? foundUser.id : uuidv4(),
            alarmId,
            userId: user.userId,
            mail: user.mail
          });
        }
      });
    }

    if (
      alarmData != null &&
      alarmData.alarm != null &&
      alarmData.alarm.buildings != null
    ) {
      selectedBuildings.forEach((building) => {
        const found = alarmData?.alarm?.buildings?.find(
          (alarmBuilding) => building.key === alarmBuilding
        );

        if (found != null) {
          if (building.selected) {
            buildingIds.push(building.key);
          }
        } else {
          buildingIds.push(building.key);
        }
      });
    }

    updateAlarm({
      v1UpdateOfflineAlarmReq: {
        alarm: {
          id: alarmId,
          description: formData.description,
          sensorType: sensorType as V1SensorType,
          recipients,
          buildings: buildingIds
        }
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      methods.reset();
      setSelectedBuildings([]);
      closeHandler();
      dispatch(enhancedOfflineAlarms.util.invalidateTags(['OfflineAlarms']));
    }
  }, [isSuccess, methods, reset, closeHandler, dispatch]);

  const close = (): void => {
    methods.reset();
    setSelectedBuildings([]);
    reset();
    closeHandler();
    dispatch(enhancedOfflineAlarms.util.invalidateTags(['OfflineAlarms']));
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title="Offlinealarm anlegen"
      >
        <div className="w-full flex flex-col">
          {isError && (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('features.alarms.modals.create.error')}
            />
          )}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('features.alarms.modals.create.description.label')}
              errorMsg={
                t('features.alarms.modals.create.description.error') as string
              }
              placeholder={
                t('features.alarms.modals.create.description.label') as string
              }
              disabled={isLoading}
              required
            />

            <div>
              <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                {t('features.alarms.modals.create.users')}
              </div>
              <MultiselectList
                className="max-h-40 overflow-auto"
                selectHandler={(id: string, checkedState: boolean) => {
                  setSelectedUsers(
                    selectedUsers.map((user) => {
                      if (user.key === id) {
                        return { ...user, selected: checkedState };
                      }

                      return user;
                    })
                  );
                }}
                content={selectedUsers}
              />
            </div>

            {groupData != null &&
              groupData.groups != null &&
              groupData.groups.length > 0 && (
                <Select
                  label="Kunde"
                  value={selectedGroup}
                  changeHandler={(e) => setSelectedGroup(e)}
                  disabled={groupLoading}
                  options={groupData.groups.map((group): FormSelectOption => {
                    return {
                      value: group.groupId as string,
                      description: group.description as string
                    };
                  })}
                  placeholder="Kunde"
                />
              )}

            <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
              Gebäude
            </div>
            <MultiselectList
              className="max-h-40 overflow-auto"
              selectHandler={(id: string, checkedState: boolean) => {
                setGroupBuildings(
                  groupBuildings.map((building) => {
                    if (building.key === id) {
                      return { ...building, selected: checkedState };
                    }

                    return building;
                  })
                );

                const itemExists = selectedBuildings.some(
                  (item) => item.key === id
                );
                if (itemExists) {
                  setSelectedBuildings(
                    selectedBuildings.map((building) => {
                      if (building.key === id) {
                        return { ...building, selected: checkedState };
                      }

                      return building;
                    })
                  );
                } else if (
                  buildingData != null &&
                  buildingData.buildings != null &&
                  buildingData.buildings.length > 0
                ) {
                  buildingData.buildings.forEach((building) => {
                    if ((building.id as string) === id) {
                      const content: MultiselectContent = {
                        key: building.id as string,
                        label: building.description as string,
                        selected: checkedState
                      };
                      setSelectedBuildings((prev) => [...prev, content]);
                    }
                  });
                }
              }}
              content={groupBuildings}
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  disabled={isLoading}
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default EditOfflineAlarmModal;
