import React, { ReactElement } from 'react';

interface CardProps {
  className?: string;
}

function CardSkeleton({ className = '' }: CardProps): ReactElement {
  return <div className={`${className} rounded-lg shadow-md animate-pulse`} />;
}

export default CardSkeleton;
