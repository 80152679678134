/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import GroupMultiselectList from '../../../components/multiselectList/groupMultiselectList';
import { DropDownItem } from '../../../components/dropdown/dropdown';
import {
  useIdentityServiceCreateUserMutation,
  useIdentityServiceListGroupsQuery
} from '../../../api/enhanced/enhancedIdentity';
import { V1Alpha1Group } from '../../../api/gen/identity';

interface FormValues {
  role: string;
  mail: string;
  userName: string;
  initialPassword: string;
}

interface CreateUserModalProps {
  closeHandler: () => void;
  isOpen: boolean;
}

export interface SelectedGroupState {
  selected: boolean;
  groupAssignment: V1Alpha1Group;
}

function CreateUserModal({
  closeHandler,
  isOpen
}: CreateUserModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const [selectedGroups, setSelectedGroups] = useState<SelectedGroupState[]>(
    []
  );

  const [isAdmin, setIsAdmin] = useState(false);
  const [isTechnician, setIsTechnician] = useState(false);
  const [isMailUser, setIsMailUser] = useState(false);

  const roles: DropDownItem[] = [
    { key: 'ROLE_GROUP_MEMBER', label: t('role.ROLE_GROUP_MEMBER') }
    // { key: 'ROLE_GROUP_ADMIN', label: t('role.ROLE_GROUP_ADMIN') }
  ];

  const {
    data,
    isLoading: groupLoading,
    isSuccess: groupSuccess,
    isError: groupError
  } = useIdentityServiceListGroupsQuery({});

  useEffect(() => {
    if (data != null && data.groups != null && data.groups.length > 0) {
      setSelectedGroups(
        data.groups.map((group): SelectedGroupState => {
          return { selected: false, groupAssignment: group };
        })
      );
    }
  }, [data]);

  const [createUser, { isLoading, isSuccess, isError, reset }] =
    useIdentityServiceCreateUserMutation();

  useEffect(() => {
    if (isSuccess) {
      methods.reset();
      reset();
      closeHandler();
    }
  }, [reset, methods, closeHandler, isSuccess]);

  const onSubmit = (formData: FormValues): void => {
    const groups = selectedGroups
      .filter((group) => group.selected)
      .map((state) => {
        return {
          ...state.groupAssignment,
          role:
            state.groupAssignment.role !== 'ROLE_UNSPECIFIED'
              ? state.groupAssignment.role
              : 'ROLE_GROUP_MEMBER'
        };
      });

    createUser({
      v1Alpha1CreateUserRequest: {
        userName: formData.userName,
        mail: formData.mail,
        initialPassword: !isMailUser ? formData.initialPassword : '',
        groups: !isAdmin && !isTechnician ? groups : [],
        isMailUser,
        isTechnician,
        isAdmin
      }
    });
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createUser.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {!isAdmin && !isTechnician && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsMailUser(!isMailUser);
                    setIsTechnician(false);
                    setIsAdmin(false);
                  }}
                  checked={isMailUser}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
                />
                <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                  {t('modals.createUser.mailUser.label')}
                </div>
              </div>
            )}

            {!isTechnician && !isMailUser && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsAdmin(!isAdmin);
                    setIsMailUser(false);
                    setIsTechnician(false);
                  }}
                  checked={isAdmin}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
                />
                <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                  {t('modals.createUser.admin.label')}
                </div>
              </div>
            )}

            {!isAdmin && !isMailUser && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsTechnician(!isTechnician);
                    setIsMailUser(false);
                    setIsAdmin(false);
                  }}
                  checked={isTechnician}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
                />
                <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                  {t('modals.createUser.technician.label')}
                </div>
              </div>
            )}

            <FormTextInput
              id="userName"
              label={t('modals.createUser.userName.label')}
              errorMsg={t('modals.createUser.userName.error') as string}
              placeholder={t('modals.createUser.userName.label') as string}
              disabled={isLoading}
              required
            />
            <FormTextInput
              id="mail"
              label={t('modals.createUser.mail.label')}
              errorMsg={t('modals.createUser.mail.error') as string}
              placeholder={t('modals.createUser.mail.label') as string}
              disabled={isLoading}
              required
            />
            {!isMailUser && (
              <FormTextInput
                id="initialPassword"
                label={t('modals.createUser.initialPassword.label')}
                errorMsg={
                  t('modals.createUser.initialPassword.error') as string
                }
                placeholder={
                  t('modals.createUser.initialPassword.description') as string
                }
                disabled={isLoading}
                required={!isMailUser}
              />
            )}

            {!isAdmin && !isTechnician && (
              <div>
                <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                  {t('modals.createUser.groups.label')}
                </div>
                <GroupMultiselectList
                  state={selectedGroups}
                  groupHandler={setSelectedGroups}
                  roles={roles}
                  className="h-40 overflow-auto"
                />
              </div>
            )}

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateUserModal;
