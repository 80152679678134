import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useBuildingServiceGetAreaQuery,
  useBuildingServiceGetBuildingQuery
} from '../../../api/enhanced/enhancedBuildings';
import { V1Alpha1Checkpoint } from '../../../api/gen/checkpoints';
import Card from '../../../components/card/card';
import DataLabel from '../../../components/dataLabel/dataLabel';

interface CheckpointDetailsProps {
  checkpoint?: V1Alpha1Checkpoint;
}

function CheckpointDetails({
  checkpoint
}: CheckpointDetailsProps): ReactElement {
  const { t } = useTranslation();

  const { data: buildingData, isLoading: buildingIsLoading } =
    useBuildingServiceGetBuildingQuery(
      checkpoint != null &&
        checkpoint.buildingId != null &&
        checkpoint.groupId != null &&
        checkpoint.groupId !== ''
        ? { buildingId: checkpoint.buildingId, groupId: checkpoint.groupId }
        : skipToken
    );

  const { data: areaData, isLoading: areaIsLoading } =
    useBuildingServiceGetAreaQuery(
      checkpoint != null &&
        checkpoint.areaId != null &&
        checkpoint.buildingId != null &&
        checkpoint.groupId != null &&
        checkpoint.groupId !== ''
        ? {
            buildingId: checkpoint.buildingId,
            groupId: checkpoint.groupId,
            areaId: checkpoint.areaId
          }
        : skipToken
    );

  return (
    <Card className="flex flex-col items-start xl:w-1/2 w-full bg-white p-6 lg:mr-2  mb-2">
      <h1 className="font-semibold text-xl mb-4">{t('trapDetails.info')}</h1>
      <DataLabel
        isLoading={buildingIsLoading || areaIsLoading || checkpoint == null}
        className="w-full justify-between text-md"
        label={t('trapDetails.checkpoint')}
        data={checkpoint?.checkpointNumber?.toString()}
      />
      <DataLabel
        isLoading={buildingIsLoading || areaIsLoading || checkpoint == null}
        className="w-full justify-between text-md"
        label={t('trapDetails.description')}
        data={checkpoint?.description}
      />
      {checkpoint?.loraMetadata != null &&
        checkpoint.doorPayload != null &&
        checkpoint.doorPayload.doorSensorType ===
          'DOOR_SENSOR_TYPE_DISTANCE' && (
          <DataLabel
            isLoading={checkpoint == null}
            className="w-full justify-between text-md"
            label="Öffnungsdistanz"
            data={`${checkpoint?.doorPayload.openingDistance?.toString()} cm`}
          />
        )}
      <DataLabel
        isLoading={buildingIsLoading || areaIsLoading || checkpoint == null}
        className="w-full justify-between text-md"
        label={t('trapDetails.area')}
        data={areaData?.area?.description}
      />
      <DataLabel
        isLoading={checkpoint == null}
        className="w-full justify-between text-md"
        label={t('trapDetails.serialNumber')}
        data={checkpoint?.currentSensorId}
      />
      {checkpoint != null && checkpoint?.analogTrapPayload != null && (
        <DataLabel
          className="w-full justify-between text-md"
          label={t('trapDetails.trapType')}
          data={
            t(
              `features.checkpoints.analogTrapTypes.${checkpoint.analogTrapPayload.analogTrapType}`
            ) as string
          }
        />
      )}
      <DataLabel
        isLoading={buildingIsLoading}
        className="w-full justify-between text-md"
        label={t('trapDetails.address')}
        data={`${buildingData?.building?.street} ${buildingData?.building?.houseNumber}`}
      />
      <DataLabel
        isLoading={buildingIsLoading}
        className="w-full justify-between text-md"
        label={t('trapDetails.city')}
        data={buildingData?.building?.city}
      />
      <DataLabel
        isLoading={buildingIsLoading}
        className="w-full justify-between text-md"
        label={t('trapDetails.postalCode')}
        data={buildingData?.building?.zipCode}
      />
    </Card>
  );
}

export default CheckpointDetails;
