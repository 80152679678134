/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { useIdentityServiceSendInvitationMailMutation } from '../../../api/enhanced/enhancedIdentity';

interface SendInvitationModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  userId: string;
  mail: string;
}

function SendInvitationUserModal({
  closeHandler,
  isOpen,
  userId,
  mail
}: SendInvitationModalProps): ReactElement {
  const [sendInvitation, { isLoading, isSuccess, isError, reset }] =
    useIdentityServiceSendInvitationMailMutation();

  useEffect(() => {
    if (isSuccess) {
      reset();
      closeHandler();
    }
  }, [reset, closeHandler, isSuccess]);

  const close = (): void => {
    closeHandler();
    reset();
  };

  const onSubmit = (): void => {
    sendInvitation({ userId, body: {} });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={() => {
        close();
      }}
      title={`Einladung an ${mail} versenden?`}
    >
      <div className="w-full flex flex-col">
        <div>Möchten Sie den Nutzer einladen?</div>
        <div className="flex items-end flex-col w-full mt-5">
          {!isLoading ? (
            <button
              className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              type="submit"
              onClick={() => onSubmit()}
            >
              Einladen
            </button>
          ) : (
            <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default SendInvitationUserModal;
