import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import IntervalComponent from '../../../components/interval/intervalComponent';
import FalseAlarmTable from '../../checkpoints/tables/traps/falseAlarmTable';
import PageTitle from '../../../components/title/pageTitle';
import CommentModal from '../../checkpoints/modals/commentModal';

function FalseAlarmsNotificationsPage(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId } = useParams();

  const [commentModalState, setCommentModalState] = useState({
    isOpen: false,
    entryId: '',
    checkpointId: ''
  });

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null
  ]);

  const handleSetDateRange = useCallback(
    (range: [Date | null, Date | null]) => {
      setDateRange(range);
    },
    []
  );

  return (
    <div>
      <div className="flex flex-col px-8 w-full">
        <PageTitle title={t('notifications.title')} />
        <IntervalComponent setInterval={handleSetDateRange} />
        <FalseAlarmTable
          groupId={groupId}
          buildingId={buildingId}
          commentModalStateHandler={(isOpen, entryId, checkpointId) =>
            setCommentModalState({
              isOpen,
              entryId,
              checkpointId: checkpointId != null ? checkpointId : ''
            })
          }
          startTime={
            dateRange[0] != null ? dateRange[0]?.toISOString() : undefined
          }
          endTime={
            dateRange[1] != null ? dateRange[1]?.toISOString() : undefined
          }
        />
      </div>

      {groupId != null &&
        groupId !== '' &&
        buildingId != null &&
        buildingId !== '' && (
          <CommentModal
            buildingId={buildingId}
            groupId={groupId}
            sensorType="SENSOR_TYPE_TRAP"
            isOpen={commentModalState.isOpen}
            closeHandler={() => {
              setCommentModalState({
                isOpen: false,
                entryId: '',
                checkpointId: ''
              });
            }}
            entryId={commentModalState.entryId}
            checkpointId={commentModalState.checkpointId}
            defaultComments={[
              {
                key: 'serviceCleanup',
                label: 'Fehlauslösung bei Reinigung'
              },
              {
                key: 'service',
                label: 'Fehlauslösung beim Service'
              },
              {
                key: 'auditor',
                label: 'Fehlauslösung durch Auditor'
              },
              {
                key: 'destroyed',
                label: 'Fehlauslösung durch Zerstörung'
              }
            ]}
          />
        )}
    </div>
  );
}

export default FalseAlarmsNotificationsPage;
