import React, { ReactElement } from 'react';
import IconButton from '../buttons/iconbutton';

interface TableActionButtonProps {
  icon: ReactElement;
  onClick: () => void;
  className?: string;
}

function TableActionButton({
  icon,
  onClick,
  className = 'bg-gray-100'
}: TableActionButtonProps): ReactElement {
  return (
    <IconButton
      className={`w-10 h-10 rounded-full ml-5 ${className}`}
      icon={icon}
      onClick={() => onClick()}
    />
  );
}

export default TableActionButton;
