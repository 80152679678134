import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { HiSearch } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import RowCell from '../../../../components/tables/rowCell';
import TrapStatePill from '../../../../components/pill/trapStatePill';
import DateTableLabel from '../../../../components/date/dateTableLabel';
import TableActionButton from '../../../../components/tables/tableActionButton';
import {
  useBuildingServiceListBuildingsQuery,
  V1Alpha1Building
} from '../../../../api/gen/buildings';
import DataTable from '../../../../components/tables/dataTable';
import { useNotificationServiceListNotificationsQuery } from '../../../../api/enhanced/enhancedNotification';
import { V1Alpha1Notification } from '../../../../api/gen/notifications';
import CommentModal from '../../../checkpoints/modals/commentModal';

interface DetailButtonProps {
  editHandler: () => void;
  detailsHandler: () => void;
}

function NotificationDetailButtons({
  editHandler,
  detailsHandler
}: DetailButtonProps): ReactElement {
  return (
    <div className="flex flex-row">
      <TableActionButton
        icon={<MdEdit size={20} />}
        onClick={() => editHandler()}
      />
      <TableActionButton
        icon={<HiSearch size={20} />}
        onClick={() => detailsHandler()}
      />
    </div>
  );
}
function TrapNotificationTable(): ReactElement {
  const navigate = useNavigate();
  const { groupId } = useParams();

  const [commentModalState, setCommentModalState] = useState({
    isOpen: false,
    entryId: '',
    buildingId: '',
    groupId: '',
    checkpointId: ''
  });

  const { data: buildingData } = useBuildingServiceListBuildingsQuery(
    groupId != null && groupId !== ''
      ? {
          groupId
        }
      : skipToken
  );

  const columns = useMemo<ColumnDef<V1Alpha1Notification>[]>(() => {
    const getBuilding = (buildingId: string): string => {
      if (buildingData != null && buildingData.buildings != null) {
        const building = buildingData.buildings.find(
          (currentBuilding: V1Alpha1Building) =>
            currentBuilding.id === buildingId
        );

        if (building != null && building.description != null) {
          return building.description;
        }
      }

      return '';
    };

    return [
      {
        header: 'Prüfpunkt',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.checkpointNumber,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Beschreibung',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.checkpointDescription,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Event',
        cell: (info) => TrapStatePill({ state: info.getValue<string>() }),
        accessorFn: (row) => row.trapEvent?.state,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Gebäude',
        cell: (info) =>
          RowCell({ title: getBuilding(info.getValue<string>()) }),
        accessorFn: (row) => row.buildingId,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Zeitpunkt',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.timestamp,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Details',
        cell: (info) =>
          NotificationDetailButtons({
            editHandler: () => {
              setCommentModalState({
                buildingId: info.row.original.buildingId as string,
                checkpointId: info.row.original.checkpointId as string,
                entryId: info.row.original.entryId as string,
                groupId: info.row.original.groupId as string,
                isOpen: true
              });
            },
            detailsHandler: () => {
              navigate(
                `/${info.row.original.groupId}/building/${info.row.original.buildingId}/checkpoints/${info.row.original.checkpointId}/trap?notification=${info.row.original.entryId}`
              );
            }
          }),
        accessorFn: (row) => row.timestamp,
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];
  }, [buildingData, navigate]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data, isLoading, isFetching, isSuccess, isError, error } =
    useNotificationServiceListNotificationsQuery(
      groupId != null && groupId !== ''
        ? {
            groupId,
            limit: pageSize,
            page: pageIndex,
            sensorType: 'SENSOR_TYPE_TRAP'
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <div className="w-full">
      <DataTable
        isLoading={isLoading || isFetching}
        isError={isError}
        isEmpty={isSuccess && (data.data == null || data.data.length === 0)}
        pageSize={pageSize}
        table={table}
      />

      {commentModalState.buildingId != null &&
        commentModalState.groupId != null &&
        commentModalState.groupId &&
        commentModalState.checkpointId != null &&
        commentModalState.entryId !== '' && (
          <CommentModal
            entryId={commentModalState.entryId}
            buildingId={commentModalState.buildingId}
            groupId={commentModalState.groupId}
            checkpointId={commentModalState.checkpointId}
            sensorType="SENSOR_TYPE_TRAP"
            defaultComments={[
              {
                key: 'serviceCleanup',
                label: 'Fehlauslösung bei Reinigung'
              },
              {
                key: 'service',
                label: 'Fehlauslösung beim Service'
              },
              {
                key: 'auditor',
                label: 'Fehlauslösung durch Auditor'
              },
              {
                key: 'destroyed',
                label: 'Fehlauslösung durch Zerstörung'
              }
            ]}
            isOpen={commentModalState.isOpen}
            closeHandler={() => {
              setCommentModalState({
                isOpen: false,
                entryId: '',
                groupId: '',
                checkpointId: '',
                buildingId: ''
              });
            }}
          />
        )}
    </div>
  );
}

export default TrapNotificationTable;
