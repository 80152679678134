import React, { ReactElement, useEffect, useState } from 'react';
import Pill from './pill';

interface TemperaturePillProps {
  value: number;
  minLimit: number;
  maxLimit: number;
  className?: string;
  isLoading?: boolean;
}

export function TemperaturePill({
  value,
  minLimit,
  maxLimit,
  className = 'ml-5 w-24',
  isLoading = false
}: TemperaturePillProps): ReactElement {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (
      minLimit !== 0 &&
      maxLimit !== 0 &&
      (value < minLimit || value > maxLimit)
    ) {
      setIsValid(false);
    }
  }, [maxLimit, minLimit, value]);

  if (isLoading) {
    return (
      <Pill
        className={` flex items-center justify-center text-white ${className} animate-pulse`}
      />
    );
  }

  return isValid || isValid == null ? (
    <div
      className={`py-2 text-sm font-medium rounded-full text-center bg-green-500 text-white ${className}`}
    >
      {value} &#8451;
    </div>
  ) : (
    <div
      className={`py-2 text-sm font-medium rounded-full text-center bg-red-500 text-white ${className}`}
    >
      {value} &#8451;
    </div>
  );
}

export default TemperaturePill;
