import { floorPlanApi } from '../gen/floorPlan';

export const enhancedFloorPlanApi = floorPlanApi.enhanceEndpoints({
  addTagTypes: ['Buildings', 'Areas', 'FloorPlans'],
  endpoints: {
    floorPlanServiceSaveFloorPlan: {
      invalidatesTags: ['Buildings', 'Areas', 'FloorPlans']
    },
    floorPlanServiceDownloadFloorPlan: {
      providesTags: ['Buildings', 'Areas', 'FloorPlans']
    }
  }
});

export const {
  useFloorPlanServiceSaveFloorPlanMutation,
  useFloorPlanServiceDownloadFloorPlanQuery
} = enhancedFloorPlanApi;
