import { reportApi } from '../gen/reports';

export const enhancedReportsApi = reportApi.enhanceEndpoints({
  addTagTypes: ['Reports'],
  endpoints: {
    reportServiceCreateReport: {
      invalidatesTags: ['Reports']
    },
    reportServiceGetReport: {
      providesTags: ['Reports']
    },
    reportServiceDeleteReport: {
      invalidatesTags: ['Reports']
    },
    reportServiceListReports: {
      providesTags: ['Reports']
    },
    dailyReportServiceCreateDailyReport: {
      invalidatesTags: ['Reports']
    },
    dailyReportServiceDeleteDailyReport: {
      invalidatesTags: ['Reports']
    },
    dailyReportServiceListReports: {
      providesTags: ['Reports']
    },
    dailyReportServiceUpdateReportMetadata: {
      invalidatesTags: ['Reports']
    }
  }
});

export const {
  useReportServiceCreateReportMutation,
  useReportServiceGetReportQuery,
  useReportServiceDeleteReportMutation,
  useReportServiceListReportsQuery,
  useDailyReportServiceCreateDailyReportMutation,
  useDailyReportServiceDeleteDailyReportMutation,
  useDailyReportServiceListReportsQuery,
  useDailyReportServiceUpdateReportMetadataMutation
} = enhancedReportsApi;
