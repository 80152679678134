import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface DateLabelProps {
  timestamp?: string;
  className?: string;
}

function DateTableLabel({
  timestamp,
  className
}: DateLabelProps): ReactElement {
  const { t } = useTranslation();

  if (timestamp === null || timestamp === '' || timestamp === undefined) {
    return <div className={className}>{t('date.noValue')}</div>;
  }

  const parsedTimestamp = Date.parse(timestamp.replace('CEST', ''));
  if (Number.isNaN(parsedTimestamp) === false) {
    const date = new Date(parsedTimestamp);
    return (
      <div className={`flex flex-col ${className}`}>
        <div className="font-semibold">{date.toLocaleDateString('de-DE')}</div>
        <div className="text-sm">{date.toLocaleTimeString('de-DE')}</div>
      </div>
    );
  }
  return <div className={className}>{t('date.invalid')}</div>;
}

export default DateTableLabel;
