import React, { ReactElement } from 'react';
import { BiSearchAlt } from 'react-icons/bi';

interface SearchBarProps {
  changeHandler: (value: string) => void;
  className?: string;
}

function SearchBar({
  changeHandler,
  className = 'h-10'
}: SearchBarProps): ReactElement {
  return (
    <div className={className}>
      <div className="absolute mt-3 flex items-center pl-3 pointer-events-none">
        <BiSearchAlt size={20} className="text-gray-400" />
      </div>
      <input
        onChange={(event) => {
          changeHandler(event.target.value);
        }}
        type="text"
        className="h-full bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-2.5"
        placeholder="Suchbegriff eingeben"
        required
      />
    </div>
  );
}

export default SearchBar;
