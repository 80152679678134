import { Updater } from '@tanstack/react-table';
import React, { ReactElement } from 'react';
import {
  HiOutlineChevronDoubleRight,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronRight,
  HiOutlineChevronLeft
} from 'react-icons/hi';
import { Button, PageButton } from './buttons';

interface PaginationButtonProps {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  nextPage: () => void;
  previousPage: () => void;
  firstPage: (updater: Updater<number>) => void;
  lastPage: (updater: Updater<number>) => void;
  changePageSize: (updater: Updater<number>) => void;
}

function PaginationButtons({
  currentPage,
  pageCount,
  pageSize,
  nextPage,
  previousPage,
  firstPage,
  lastPage,
  changePageSize
}: PaginationButtonProps): ReactElement {
  return (
    <div className="py-3 flex items-center justify-between z-0">
      <div className="flex-1 flex justify-between sm:hidden">
        <Button onClick={previousPage}>Previous</Button>
        <Button onClick={nextPage}>Next</Button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex gap-x-2 items-center">
          <span className="text-sm text-gray-700 ">
            Seite <span className="font-medium">{currentPage}</span> von{' '}
            <span className="font-medium">{pageCount}</span>
          </span>
          <select
            className="mt-1 block h-10 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={pageSize}
            onChange={(e) => changePageSize(Number(e.target.value))}
          >
            {[5, 10, 20, 50, 100].map((pageSizeOption) => (
              <option key={pageSizeOption} value={pageSizeOption}>
                Elemente pro Seite: {pageSizeOption}
              </option>
            ))}
          </select>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <PageButton
              onClick={() => firstPage(Number(0))}
              className="rounded-l-md"
            >
              <span className="sr-only">First</span>
              <HiOutlineChevronDoubleLeft
                className="h-5 w-5"
                aria-hidden="true"
              />
            </PageButton>
            <PageButton onClick={previousPage}>
              <span className="sr-only">Previous</span>
              <HiOutlineChevronLeft className="h-5 w-5" aria-hidden="true" />
            </PageButton>
            <PageButton onClick={nextPage}>
              <span className="sr-only">Next</span>
              <HiOutlineChevronRight className="h-5 w-5" aria-hidden="true" />
            </PageButton>
            <PageButton
              onClick={() => lastPage(Number(pageCount))}
              className="rounded-r-md"
            >
              <span className="sr-only">Last</span>
              <HiOutlineChevronDoubleRight
                className="h-5 w-5"
                aria-hidden="true"
              />
            </PageButton>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default PaginationButtons;
