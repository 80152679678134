import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TabBar, { TabContent } from '../../../components/tabs/tabBar';
import PageTitle from '../../../components/title/pageTitle';
import BuildingDetails from './buildingDetails';
import AreaDetails from '../../areas/pages/areaDetails';
import CheckpointAdminPage from '../../checkpoints/pages/details/checkpointAdminPage';

function BuildingManagement(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId } = useParams();

  const tabContent: TabContent[] = [
    {
      key: 0,
      tabLabel: t('administration.building.tabs.details'),
      content: <BuildingDetails buildingId={buildingId} groupId={groupId} />
    },
    /* {
      key: 1,
      tabLabel: t('administration.building.tabs.assignedUsers'),
      content: <AssignedUserDetails buildingId={buildingId} groupId={groupId} />
    }, */
    {
      key: 1,
      tabLabel: t('administration.building.tabs.area'),
      content: <AreaDetails />
    },
    {
      key: 2,
      tabLabel: t('administration.building.tabs.devices'),
      content: <CheckpointAdminPage buildingId={buildingId} groupId={groupId} />
    }
    /* {
      key: 3,
      tabLabel: t('administration.building.tabs.falseAlarm'),
      content: <FalseAlarmsPage buildingId={buildingId} groupId={groupId} />
    } */
  ];

  return (
    <div className="flex flex-col px-8 w-full">
      <PageTitle title={t('administration.building.title')} />
      <TabBar tabs={tabContent} />
    </div>
  );
}

export default BuildingManagement;
