import { V1Alpha1Group, V1Alpha1Role } from '../api/gen/identity';

export default function getUserRole(
  groupId: string,
  groupAssignments: V1Alpha1Group[]
): V1Alpha1Role {
  const assignedRole = groupAssignments.find((assignment: V1Alpha1Group) => {
    if (assignment.groupId === groupId) {
      return assignment;
    }
    return undefined;
  });

  if (assignedRole != null && assignedRole.role != null) {
    return assignedRole.role;
  }

  return 'ROLE_UNSPECIFIED';
}
