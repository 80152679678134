import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, ReactElement } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { IoMdCheckmark } from 'react-icons/io';
import { useSelector } from 'react-redux';
import {
  useBuildingServiceListAreasForBuildingQuery,
  V1Alpha1Area
} from '../../api/gen/buildings';

import { RootState } from '../../api/store';

interface AreaDropdownProps {
  className?: string;
  groupId: string;
  buildingId: string;
  areaId: string;
  changeHandler: (areaId: string) => void;
}

function AreaDropdown({
  className,
  groupId,
  buildingId,
  areaId,
  changeHandler
}: AreaDropdownProps): ReactElement {
  const { data, isFetching, isLoading } =
    useBuildingServiceListAreasForBuildingQuery({
      groupId,
      buildingId,
      limit: 200,
      page: 0
    });

  const getSelectedArea = (): string => {
    if (data != null && data?.areas != null && data?.areas.length > 0) {
      const area = data.areas.find(
        (currentArea: V1Alpha1Area) => currentArea.id === areaId
      );

      if (area != null && area.description != null && areaId !== '') {
        return area.description;
      }
    }

    return 'Alle Bereiche';
  };

  if (isFetching || isLoading) {
    return <div className="h-10 w-24 bg-gray-200 animate-pulse rounded-md" />;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`${className} inline-flex z-0 items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}
        >
          {getSelectedArea()}
          {data != null && data?.areas != null && (
            <HiChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          )}
        </Menu.Button>
      </div>
      {data != null && data?.areas != null ? (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-h-72 z-50 overflow-y-auto">
            <div className="py-1 px-1">
              <Menu.Item>
                {() => (
                  <button
                    type="button"
                    onClick={() => {
                      changeHandler('');
                    }}
                    className="text-left text-gray-900 flex px-4 py-2 text-sm w-full outline-0 flex-start hover:bg-gray-100 hover:rounded-md flex-row justify-between"
                  >
                    Alle Bereiche
                    {areaId === '' ? <IoMdCheckmark size={20} /> : null}
                  </button>
                )}
              </Menu.Item>
            </div>
            {data?.areas.map((area: V1Alpha1Area) => (
              <div className="py-1 px-1" key={area.id}>
                <Menu.Item>
                  {() => (
                    <button
                      type="button"
                      onClick={() => {
                        if (area.id != null) {
                          changeHandler(area.id);
                        }
                      }}
                      className=" text-gray-900 flex px-4 py-2 text-sm w-full outline-0 flex-start hover:bg-gray-100 hover:rounded-md flex-row justify-between"
                    >
                      {area.description}
                      {areaId === area.id ? <IoMdCheckmark size={20} /> : null}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      ) : null}
    </Menu>
  );
}

export default AreaDropdown;
