import React, { Fragment, ReactElement, ReactNode, useState } from 'react';
import { Tab } from '@headlessui/react';

export enum SensorType {
  TRAP = 'traps',
  TEMP = 'temperature',
  HUMIDITY = 'humidity',
  DOOR = 'door',
  CO2 = 'carbondioxide'
}

export interface TabContent {
  key: number;
  tabLabel: string;
  content: ReactNode;
}

export interface SensorTabContent extends TabContent {
  sensorType: SensorType;
}

interface TabBarProps {
  className?: string;
  tabs: TabContent[];
  index?: number;
  changeHandler?: (index: number) => void;
}

function TabBar({
  className = '',
  tabs,
  index = 0,
  changeHandler
}: TabBarProps): ReactElement {
  const [selectedIndex, setSelectedIndex] = useState(index);

  return (
    <div className={`flex flex-col ${className}`}>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(currentIndex: number) => {
          setSelectedIndex(currentIndex);
          if (changeHandler != null) {
            changeHandler(currentIndex);
          }
        }}
      >
        <Tab.List className="flex flex-no-wrap text-gray-500 border-b border-gray-200">
          {tabs.map((tab: TabContent) => (
            <Tab as={Fragment} key={tab.key}>
              {({ selected }) => {
                return (
                  <button
                    className={`pr-4 pl-4  h-10 rounded-t-lg font-medium text-sm text-gray-600  ${
                      selected
                        ? ' bg-gray-200 active focus:outline-none focus:border-none'
                        : 'hover:text-gray-600 hover:bg-gray-200'
                    }}`}
                    type="button"
                  >
                    {tab.tabLabel}
                  </button>
                );
              }}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4">
          {tabs.map((tab: TabContent) => (
            <Tab.Panel key={tab.key}>{tab.content}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default TabBar;
