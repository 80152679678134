import React, { ReactElement } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

interface StatePieChartProps {
  className?: string;
  label: string;
  data: PieData[];
  total: number;
}

export interface PieData {
  name: string;
  value: number;

  color: string;
}

function StatePieChart({
  label,
  className,
  data,
  total
}: StatePieChartProps): ReactElement {
  return (
    <div className={`flex flex-col w-full h-full justify-center ${className}`}>
      <h1 className="font-semibold p-4">{label}</h1>
      <div className="flex flex-col h-96 items-start">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={700} height={700}>
            <Legend verticalAlign="bottom" align="center" fontSize={10} />
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={75}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry) => (
                <Cell key={`cell-${entry.name}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="flex flex-col w-full pr-4 pl-4 pb-4">
          <div className="flex flex-row justify-between">
            <p className="font-semibold mr-8">Total:</p>
            <p>{total}</p>
          </div>
          {data.map((entry) => (
            <div key={entry.name} className="flex flex-row justify-between">
              <p className="font-semibold mr-8">{entry.name}:</p>
              <p>{entry.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StatePieChart;
