import { checkpointApi } from '../gen/checkpoints';

export const enhancedCheckpointApi = checkpointApi.enhanceEndpoints({
  addTagTypes: [
    'SENSOR_TYPE_UNSPECIFIED',
    'SENSOR_TYPE_TRAP',
    'SENSOR_TYPE_TEMP',
    'SENSOR_TYPE_CARBON_DIOXIDE',
    'SENSOR_TYPE_DOOR',
    'SENSOR_TYPE_ANALOG_TRAP',
    'SENSOR_TYPE_HUMIDITY'
  ],
  endpoints: {
    checkpointServiceGetCheckpoint: {
      providesTags: (result, error, arg) => [arg.sensorType]
    },
    checkpointServiceDeleteCheckpoint: {
      invalidatesTags: (result, error, arg) => [arg.sensorType]
    },
    checkpointServiceUpdateCheckpoint: {
      invalidatesTags: (result, error, arg) => [arg.sensorType]
    },
    checkpointServiceCreateCheckpoint: {
      invalidatesTags: (result, error, arg) => [arg.sensorType]
    },
    checkpointServiceListCheckpointsForBuilding: {
      providesTags: (result, error, arg) => [arg.sensorType]
    },
    checkpointServiceListCheckpointsForGroup: {
      providesTags: (result, error, arg) => [arg.sensorType]
    }
  }
});

export const {
  useCheckpointServiceGetCheckpointQuery,
  useCheckpointServiceDeleteCheckpointMutation,
  useCheckpointServiceUpdateCheckpointMutation,
  useCheckpointServiceCreateCheckpointMutation,
  useCheckpointServiceListCheckpointsForBuildingQuery,
  useCheckpointServiceListCheckpointsForGroupQuery
} = enhancedCheckpointApi;
