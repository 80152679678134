/* eslint-disable react/button-has-type */
import { useMsal } from '@azure/msal-react';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { loginRequest } from '../../auth/msal/msalConfig';
import logo from '../../assets/logo.png';

function LoginPage(): ReactElement {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  React.useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  const handleLogin = (): void => {
    instance.loginRedirect(loginRequest);
  };

  if (width < breakpoint) {
    return (
      <div className="h-screen w-full bg-gray-200 flex justify-center items-center">
        <div className="shadow-md rounded-lg w-10/12 bg-white flex justify-center items-center p-4 flex-col">
          <img alt="logo" src={logo} className="p-4 h-16" />
          <h1 className="text-lg font-bold text-center">
            Diese Anwendung wird auf dieser Displaygröße noch nicht unterstützt
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-full bg-gray-200 flex justify-center items-center">
      <div className="rounded-lg bg-white shadow-lg items-center xl:w-1/3 lg:w-2/3 md:w-2/3 w-11/12  flex flex-col ">
        <img alt="logo" src={logo} className="p-4 h-20 mt-8" />
        <h1 className="font-semibold text-2xl mt-4 mb-8">
          {t('loginPage.welcome')}
        </h1>

        <p className="text-lg mb-8 px-16">
          Für einen sicheren Login werden Sie im nächsten Schritt
          weitergeleitet. Die initialen Zugangsdaten erhalten Sie sobald Ihr
          Konto von uns angelegt und aktiviert wurde.
        </p>

        <button
          className="w-2/3 h-12 mb-10 bg-gray-100 flex justify-center items-center rounded-lg shadow-md"
          onClick={() => handleLogin()}
        >
          <span className="ml-2">{t('loginPage.login')}</span>
        </button>
      </div>
    </div>
  );
}

export default LoginPage;
