import { skipToken } from '@reduxjs/toolkit/query';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { V1Alpha1Building } from '../../../api/gen/buildings';
import RowCell from '../../../components/tables/rowCell';
import TableActionButton from '../../../components/tables/tableActionButton';
import { useBuildingServiceListBuildingsQuery } from '../../../api/enhanced/enhancedBuildings';
import DataTable from '../../../components/tables/dataTable';

interface BuildingTable {
  groupId?: string;
  clickHandler: (buildingId: string) => void;
}

function BuildingTable({ groupId, clickHandler }: BuildingTable): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<ColumnDef<V1Alpha1Building>[]>(
    () => [
      {
        header: 'Gebäude',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.description,
        footer: (props) => props.column.id
      },
      {
        header: 'Straße',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => `${row.street} ${row.houseNumber}`,
        footer: (props) => props.column.id
      },
      {
        header: 'Stadt',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.city,
        footer: (props) => props.column.id
      },
      {
        header: 'Postleitzahl',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.zipCode,
        footer: (props) => props.column.id
      },
      {
        id: 'id',
        header: 'Details',
        cell: (info) =>
          TableActionButton({
            icon: <HiSearch size={20} />,
            onClick: () => clickHandler(info.getValue<string>())
          }),
        accessorFn: (row) => row.id,
        footer: (props) => props.column.id
      }
    ],
    [clickHandler]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data, isLoading, isSuccess, isFetching, isError } =
    useBuildingServiceListBuildingsQuery(
      groupId != null && groupId !== ''
        ? {
            groupId,
            limit: pageSize,
            page: pageIndex
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.buildings ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true,
    enableSorting: false
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching || groupId === ''}
      isError={isError}
      isEmpty={
        isSuccess && (data.buildings == null || data.buildings.length === 0)
      }
      pageSize={pageSize}
      table={table}
    />
  );
}
export default BuildingTable;
