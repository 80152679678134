import React, { ReactElement, ReactNode } from 'react';

interface SidebarProps {
  className?: string;
  children: ReactNode;
}

function Sidebar({ children, className = '' }: SidebarProps): ReactElement {
  return (
    <div
      className={`${className} h-full w-full flex justify-start flex-col items-center`}
    >
      {children}
    </div>
  );
}

export default Sidebar;
