import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSelectOption } from '../form/formSelect';
import FormDateRangePicker from '../form/formDateRangePicker';
import Select from '../select/select';

interface GraphComponentProps {
  setInterval: (interval: [Date | null, Date | null]) => void;
}

function IntervalComponent({ setInterval }: GraphComponentProps): ReactElement {
  const { t } = useTranslation();

  const [currentInterval, setCurrentInterval] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [selectedInterval, setSelectedInterval] = useState('currentDay');

  const periods: FormSelectOption[] = [
    {
      value: 'currentDay',
      description: t('modals.createReport.periodSelection.currentDay')
    },
    {
      value: 'currentWeek',
      description: t('modals.createReport.periodSelection.currentWeek')
    },
    {
      value: 'currentMonth',
      description: t('modals.createReport.periodSelection.currentMonth')
    },
    /* {
      value: 'lastDay',
      description: t('modals.createReport.periodSelection.lastDay')
    },
    {
      value: 'lastWeek',
      description: t('modals.createReport.periodSelection.lastWeek')
    },
    {
      value: 'lastMonth',
      description: t('modals.createReport.periodSelection.lastMonth')
    }, */
    {
      value: 'userdefined',
      description: t('modals.createReport.periodSelection.userdefined')
    }
  ];

  useEffect(() => {
    const endTime = new Date(Date.now());
    const startTime = new Date(Date.now());

    switch (selectedInterval) {
      case 'currentDay':
        startTime.setHours(endTime.getHours() - 24);
        break;
      case 'currentWeek':
        startTime.setHours(endTime.getHours() - 168);
        break;
      case 'currentMonth':
        startTime.setMonth(endTime.getMonth() - 1);
        break;
      case 'userdefined':
        if (currentInterval[0] != null && currentInterval[1] != null) {
          startTime.setTime(currentInterval[0].setHours(0, 0, 0, 0));
          endTime.setTime(currentInterval[1].setHours(23, 59, 59, 59));
        }
        break;
      default:
        endTime.setTime(endTime.setHours(23, 59, 59, 59));
    }

    setInterval([startTime, endTime]);
  }, [currentInterval, selectedInterval, setInterval]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mb-4 space-x-4">
        {selectedInterval === 'userdefined' && (
          <FormDateRangePicker
            label="Zeitraum"
            setInterval={setCurrentInterval}
            value={currentInterval}
          />
        )}
        <Select
          label="Bitte das gewünschte Intervall wählen"
          placeholder="Zeitraum"
          disabled={false}
          value={selectedInterval}
          changeHandler={setSelectedInterval}
          options={periods}
        />
      </div>
    </div>
  );
}

export default IntervalComponent;
