import React, { ReactElement } from 'react';

interface AvatarProps {
  label?: string;
  imgSrc?: string;
  className?: string;
}

function Avatar({
  label,
  imgSrc = '',
  className = ''
}: AvatarProps): ReactElement {
  const getInitials = (fullName: string): string => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      let accVal = acc;
      if (index === 0 || index === allNames.length - 1) {
        accVal = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return accVal;
    }, '');
    return initials;
  };

  return label != null && label !== '' ? (
    <div
      className={`rounded-full flex items-center text-center justify-center ${className}`}
    >
      <span className="font-semibold">{getInitials(label)}</span>
    </div>
  ) : (
    <div
      className={`rounded-full flex items-center text-center justify-center animate-pulse ${className}`}
    />
  );
}

export default Avatar;
