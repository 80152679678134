import React, { ReactElement, ReactNode } from 'react';

interface LabelButtonProps {
  icon?: ReactNode;
  label: string;
  className?: string;
  onClick: () => void;
}

function LabelButton({
  icon,
  label,
  className,
  onClick
}: LabelButtonProps): ReactElement {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={`${className} flex w-52 items-center justify-center`}
    >
      <div className="flex flex-row p-3 font-semibold text-sm">
        <div className="mr-2">{icon || null}</div>
        <span>{label}</span>
      </div>
    </button>
  );
}

export default LabelButton;
