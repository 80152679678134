import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import LabelButton from '../../../components/buttons/labelButton';
import AreaTable from '../tables/areaTable';
import CreateAreaModal from '../modals/createAreaModal';
import RemoveAreaFromBuildingModal from '../modals/removeAreaFromBuildingModal';
import UpdateAreaModal from '../modals/updateAreaModal';

interface RemoveAreaState {
  description: string;
  groupId: string;
  buildingId: string;
  areaId: string;
  isOpen: boolean;
}

interface UpdateAreaState {
  description: string;
  groupId: string;
  buildingId: string;
  areaId: string;
  isOpen: boolean;
}

function AreaDetails(): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { groupId, buildingId } = useParams();
  const [removeAreaState, setRemoveAreaState] = useState<RemoveAreaState>({
    description: '',
    buildingId: '',
    areaId: '',
    groupId: '',
    isOpen: false
  });

  const [updateAreaState, setUpdateAreaState] = useState<UpdateAreaState>({
    description: '',
    buildingId: '',
    areaId: '',
    groupId: '',
    isOpen: false
  });

  return (
    <div>
      <div className="flex flex-col w-full">
        <div className="flex flex-row">
          <LabelButton
            label={t('administration.building.addArea')}
            className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
            icon={<IoMdAdd size={20} />}
            onClick={() => setIsOpen(true)}
          />
        </div>
        {groupId != null && buildingId != null && (
          <div>
            <AreaTable
              groupId={groupId}
              buildingId={buildingId}
              editHandler={(
                currentGroupId: string,
                currentBuildingId: string,
                currentAreaId: string,
                description: string
              ) => {
                setUpdateAreaState({
                  groupId: currentGroupId,
                  buildingId: currentBuildingId,
                  areaId: currentAreaId,
                  description,
                  isOpen: true
                });
              }}
              deleteHandler={(
                currentGroupId: string,
                currentBuildingId: string,
                currentAreaId: string,
                description: string
              ) => {
                setRemoveAreaState({
                  groupId: currentGroupId,
                  buildingId: currentBuildingId,
                  areaId: currentAreaId,
                  description,
                  isOpen: true
                });
              }}
            />
          </div>
        )}
      </div>

      {buildingId != null && groupId != null && (
        <CreateAreaModal
          groupId={groupId}
          buildingId={buildingId}
          isOpen={isOpen}
          closeHandler={() => {
            setIsOpen(false);
          }}
        />
      )}

      {buildingId != null && groupId != null && (
        <RemoveAreaFromBuildingModal
          description={removeAreaState.description}
          groupId={removeAreaState.groupId}
          buildingId={removeAreaState.buildingId}
          areaId={removeAreaState.areaId}
          isOpen={removeAreaState.isOpen}
          closeHandler={() => {
            setRemoveAreaState({
              description: '',
              buildingId: '',
              areaId: '',
              groupId: '',
              isOpen: false
            });
          }}
        />
      )}

      {buildingId != null && groupId != null && (
        <UpdateAreaModal
          description={updateAreaState.description}
          groupId={updateAreaState.groupId}
          buildingId={updateAreaState.buildingId}
          areaId={updateAreaState.areaId}
          isOpen={updateAreaState.isOpen}
          closeHandler={() => {
            setUpdateAreaState({
              description: '',
              buildingId: '',
              areaId: '',
              groupId: '',
              isOpen: false
            });
          }}
        />
      )}
    </div>
  );
}

export default AreaDetails;
