import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkpointServiceGetCheckpoint: build.query<
      CheckpointServiceGetCheckpointApiResponse,
      CheckpointServiceGetCheckpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}`
      })
    }),
    checkpointServiceDeleteCheckpoint: build.mutation<
      CheckpointServiceDeleteCheckpointApiResponse,
      CheckpointServiceDeleteCheckpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}`,
        method: 'DELETE'
      })
    }),
    checkpointServiceUpdateCheckpoint: build.mutation<
      CheckpointServiceUpdateCheckpointApiResponse,
      CheckpointServiceUpdateCheckpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}`,
        method: 'PATCH',
        body: queryArg.checkpointServiceUpdateCheckpointBody
      })
    }),
    checkpointServiceCreateCheckpoint: build.mutation<
      CheckpointServiceCreateCheckpointApiResponse,
      CheckpointServiceCreateCheckpointApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.sensorType}`,
        method: 'POST',
        body: queryArg.checkpointServiceCreateCheckpointBody
      })
    }),
    checkpointServiceListCheckpointsForBuilding: build.query<
      CheckpointServiceListCheckpointsForBuildingApiResponse,
      CheckpointServiceListCheckpointsForBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.sensorType}/buildings`,
        params: {
          areaId: queryArg.areaId,
          limit: queryArg.limit,
          page: queryArg.page,
          includeUnassigned: queryArg.includeUnassigned,
          sortBy: queryArg.sortBy,
          searchBy: queryArg.searchBy
        }
      })
    }),
    checkpointServiceListCheckpointsForGroup: build.query<
      CheckpointServiceListCheckpointsForGroupApiResponse,
      CheckpointServiceListCheckpointsForGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/checkpoints/${queryArg.sensorType}/groups`,
        params: {
          limit: queryArg.limit,
          page: queryArg.page,
          sortBy: queryArg.sortBy,
          searchBy: queryArg.searchBy
        }
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as checkpointApi };
export type CheckpointServiceGetCheckpointApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetCheckpointResponse;
export type CheckpointServiceGetCheckpointApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
};
export type CheckpointServiceDeleteCheckpointApiResponse =
  /** status 200 A successful response. */ V1Alpha1DeleteCheckpointResponse;
export type CheckpointServiceDeleteCheckpointApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
};
export type CheckpointServiceUpdateCheckpointApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateCheckpointResponse;
export type CheckpointServiceUpdateCheckpointApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  checkpointServiceUpdateCheckpointBody: CheckpointServiceUpdateCheckpointBody;
};
export type CheckpointServiceCreateCheckpointApiResponse =
  /** status 200 A successful response. */ V1Alpha1CreateCheckpointResponse;
export type CheckpointServiceCreateCheckpointApiArg = {
  groupId: string;
  buildingId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  checkpointServiceCreateCheckpointBody: CheckpointServiceCreateCheckpointBody;
};
export type CheckpointServiceListCheckpointsForBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListCheckpointsForBuildingResponse;
export type CheckpointServiceListCheckpointsForBuildingApiArg = {
  groupId: string;
  buildingId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  areaId?: string;
  limit?: number;
  page?: number;
  includeUnassigned?: boolean;
  sortBy?: string;
  searchBy?: string;
};
export type CheckpointServiceListCheckpointsForGroupApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListCheckpointsForGroupResponse;
export type CheckpointServiceListCheckpointsForGroupApiArg = {
  groupId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  limit?: number;
  page?: number;
  sortBy?: string;
  searchBy?: string;
};
export type V1Alpha1LoraMetadata = {
  isOnline?: boolean;
  lastSignal?: string;
  rssi?: number;
  channelRssi?: number;
  spreadingFactor?: number;
  snr?: number;
};
export type V1Alpha1InspectionMetadata = {
  lastInspection?: string;
};
export type V1Alpha1TrapType =
  | 'TRAP_TYPE_UNSPECIFIED'
  | 'TRAP_TYPE_MOUSE'
  | 'TRAP_TYPE_RAT';
export type V1Alpha1TrapPayload = {
  state?: string;
  batteryVoltage?: number;
  trapType?: V1Alpha1TrapType;
};
export type V1Alpha1DoorSensorType =
  | 'DOOR_SENSOR_TYPE_UNSPECIFIED'
  | 'DOOR_SENSOR_TYPE_DISTANCE'
  | 'DOOR_SENSOR_TYPE_OPENING';
export type V1Alpha1DoorPayload = {
  batteryVoltage?: number;
  status?: number;
  doorSensorType?: V1Alpha1DoorSensorType;
  openingDistance?: number;
  currentDistance?: number;
};
export type V1Alpha1CarbonDioxidePayload = {
  humidity?: number;
  temperature?: number;
  carbonDioxide?: number;
  batteryVoltage?: number;
  warningLimit?: number;
  alarmLimit?: number;
};
export type V1Alpha1TemperaturePayload = {
  humidity?: number;
  temperature?: number;
  batteryVoltage?: number;
  minLimit?: number;
  maxLimit?: number;
  humidityMinLimit?: number;
  humidityMaxLimit?: number;
};
export type V1Alpha1AnalogTrapType =
  | 'ANALOG_TRAP_TYPE_UNSPECIFIED'
  | 'ANALOG_TRAP_TYPE_FLIGHT_INSECT_DEVICE'
  | 'ANALOG_TRAP_TYPE_FLOUR_BEETLE_TRAP'
  | 'ANALOG_TRAP_TYPE_MOTH_TRAP'
  | 'ANALOG_TRAP_TYPE_NON_TOX_BOX'
  | 'ANALOG_TRAP_TYPE_OTHERS'
  | 'ANALOG_TRAP_TYPE_SCRAPE'
  | 'ANALOG_TRAP_TYPE_TOX_BOX';
export type V1Alpha1AnalogTrapPayload = {
  analogTrapType?: V1Alpha1AnalogTrapType;
};
export type V1Alpha1Checkpoint = {
  id?: string;
  buildingId?: string;
  areaId?: string;
  groupId?: string;
  currentSensorId?: string;
  description?: string;
  checkpointNumber?: number;
  createdAt?: string;
  updatedAt?: string;
  latitude?: number;
  longitude?: number;
  loraMetadata?: V1Alpha1LoraMetadata;
  inspectionMetadata?: V1Alpha1InspectionMetadata;
  trapPayload?: V1Alpha1TrapPayload;
  doorPayload?: V1Alpha1DoorPayload;
  carbonDioxidePayload?: V1Alpha1CarbonDioxidePayload;
  temperaturePayload?: V1Alpha1TemperaturePayload;
  analogTrapPayload?: V1Alpha1AnalogTrapPayload;
};
export type V1Alpha1GetCheckpointResponse = {
  checkpoint?: V1Alpha1Checkpoint;
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1DeleteCheckpointResponse = {
  checkpointId?: string;
};
export type V1Alpha1UpdateCheckpointResponse = {
  checkpoint?: V1Alpha1Checkpoint;
};
export type CheckpointServiceUpdateCheckpointBody = {
  updateLocation?: boolean;
  checkpoint?: V1Alpha1Checkpoint;
  isApp?: boolean;
};
export type V1Alpha1CreateCheckpointResponse = {
  checkpoint?: V1Alpha1Checkpoint;
};
export type CheckpointServiceCreateCheckpointBody = {
  checkpointId?: string;
  checkpoint?: V1Alpha1Checkpoint;
  isApp?: boolean;
};
export type V1Alpha1ListCheckpointsForBuildingResponse = {
  total?: string;
  page?: number;
  limit?: number;
  checkpoints?: V1Alpha1Checkpoint[];
};
export type V1Alpha1ListCheckpointsForGroupResponse = {
  total?: string;
  page?: number;
  limit?: number;
  checkpoints?: V1Alpha1Checkpoint[];
};
export const {
  useCheckpointServiceGetCheckpointQuery,
  useCheckpointServiceDeleteCheckpointMutation,
  useCheckpointServiceUpdateCheckpointMutation,
  useCheckpointServiceCreateCheckpointMutation,
  useCheckpointServiceListCheckpointsForBuildingQuery,
  useCheckpointServiceListCheckpointsForGroupQuery
} = injectedRtkApi;
