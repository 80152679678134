import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    historyServiceListHistory: build.query<
      HistoryServiceListHistoryApiResponse,
      HistoryServiceListHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}/history`,
        params: {
          limit: queryArg.limit,
          page: queryArg.page,
          sortingOrder: queryArg.sortingOrder,
          startTime: queryArg.startTime,
          endTime: queryArg.endTime,
          onlyStateChanges: queryArg.onlyStateChanges
        }
      })
    }),
    historyServiceGetHistoryEntry: build.query<
      HistoryServiceGetHistoryEntryApiResponse,
      HistoryServiceGetHistoryEntryApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}/history/${queryArg.entryId}`
      })
    }),
    historyServiceUpdateHistoryEntry: build.mutation<
      HistoryServiceUpdateHistoryEntryApiResponse,
      HistoryServiceUpdateHistoryEntryApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/checkpoints/${queryArg.checkpointId}/${queryArg.sensorType}/history/${queryArg.entryId}`,
        method: 'PATCH',
        body: queryArg.historyServiceUpdateHistoryEntryBody
      })
    }),
    historyServiceCreateCsvExport: build.mutation<
      HistoryServiceCreateCsvExportApiResponse,
      HistoryServiceCreateCsvExportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/csv/${queryArg.csvType}`,
        method: 'POST',
        body: queryArg.historyServiceCreateCsvExportBody
      })
    }),
    historyServiceListHistoryForBuilding: build.query<
      HistoryServiceListHistoryForBuildingApiResponse,
      HistoryServiceListHistoryForBuildingApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/${queryArg.sensorType}/history`,
        params: {
          limit: queryArg.limit,
          page: queryArg.page,
          sortingOrder: queryArg.sortingOrder,
          startTime: queryArg.startTime,
          endTime: queryArg.endTime,
          onlyStateChanges: queryArg.onlyStateChanges,
          trapState: queryArg.trapState
        }
      })
    }),
    historyServiceUpdateHistoryEntries: build.mutation<
      HistoryServiceUpdateHistoryEntriesApiResponse,
      HistoryServiceUpdateHistoryEntriesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/${queryArg.sensorType}/history`,
        method: 'PATCH',
        body: queryArg.historyServiceUpdateHistoryEntriesBody
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as historyApi };
export type HistoryServiceListHistoryApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListHistoryResponse;
export type HistoryServiceListHistoryApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  limit?: number;
  page?: number;
  sortingOrder?: string;
  startTime?: string;
  endTime?: string;
  onlyStateChanges?: boolean;
};
export type HistoryServiceGetHistoryEntryApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetHistoryEntryResponse;
export type HistoryServiceGetHistoryEntryApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  entryId: string;
};
export type HistoryServiceUpdateHistoryEntryApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateHistoryEntryResponse;
export type HistoryServiceUpdateHistoryEntryApiArg = {
  groupId: string;
  buildingId: string;
  checkpointId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  entryId: string;
  historyServiceUpdateHistoryEntryBody: HistoryServiceUpdateHistoryEntryBody;
};
export type HistoryServiceCreateCsvExportApiResponse =
  /** status 200 A successful response. */ V1Alpha1CsvExportResponse;
export type HistoryServiceCreateCsvExportApiArg = {
  groupId: string;
  buildingId: string;
  csvType:
    | 'CSV_TYPE_UNSPECIFIED'
    | 'CSV_TYPE_TRAPS'
    | 'CSV_TYPE_TEMPERATURE'
    | 'CSV_TYPE_DOOR';
  historyServiceCreateCsvExportBody: HistoryServiceCreateCsvExportBody;
};
export type HistoryServiceListHistoryForBuildingApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListHistoryForBuildingResponse;
export type HistoryServiceListHistoryForBuildingApiArg = {
  groupId: string;
  buildingId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  limit?: number;
  page?: number;
  sortingOrder?: string;
  startTime?: string;
  endTime?: string;
  onlyStateChanges?: boolean;
  trapState?: string;
};
export type HistoryServiceUpdateHistoryEntriesApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateHistoryEntriesResponse;
export type HistoryServiceUpdateHistoryEntriesApiArg = {
  groupId: string;
  buildingId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP'
    | 'SENSOR_TYPE_HUMIDITY';
  historyServiceUpdateHistoryEntriesBody: HistoryServiceUpdateHistoryEntriesBody;
};
export type V1Alpha1TrapHistoryPayload = {
  batteryVoltage?: number;
  trapState?: string;
  falseAlarm?: boolean;
};
export type V1Alpha1DoorHistoryPayload = {
  batteryVoltage?: number;
  status?: number;
  distance?: number;
};
export type V1Alpha1CarbonDioxideHistoryPayload = {
  carbonDioxide?: number;
  temperature?: number;
  humidity?: number;
};
export type V1Alpha1TemperatureHistoryPayload = {
  batteryVoltage?: number;
  humidity?: number;
  temperature?: number;
};
export type V1Alpha1HistoryEntry = {
  id?: string;
  checkpointId?: string;
  timestamp?: string;
  createdAt?: string;
  updatedAt?: string;
  devEui?: string;
  comment?: string;
  commentAuthor?: string;
  trapPayload?: V1Alpha1TrapHistoryPayload;
  doorPayload?: V1Alpha1DoorHistoryPayload;
  carbonDioxidePayload?: V1Alpha1CarbonDioxideHistoryPayload;
  temperaturePayload?: V1Alpha1TemperatureHistoryPayload;
};
export type V1Alpha1ListHistoryResponse = {
  total?: string;
  page?: number;
  limit?: number;
  entries?: V1Alpha1HistoryEntry[];
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1Alpha1GetHistoryEntryResponse = {
  historyEntry?: V1Alpha1HistoryEntry;
};
export type V1Alpha1UpdateHistoryEntryResponse = {
  entry?: V1Alpha1HistoryEntry;
};
export type HistoryServiceUpdateHistoryEntryBody = {
  entry?: V1Alpha1HistoryEntry;
};
export type V1Alpha1CsvExportResponse = {
  data?: string;
};
export type HistoryServiceCreateCsvExportBody = {
  startTime?: string;
  endTime?: string;
};
export type V1Alpha1TrapHistoryViewModel = {
  id?: string;
  checkpointId?: string;
  timestamp?: string;
  description?: string;
  checkpointNumber?: number;
  trapState?: string;
  falseAlarm?: boolean;
  comment?: string;
  commentAuthor?: string;
};
export type V1Alpha1ListHistoryForBuildingResponse = {
  total?: string;
  page?: number;
  limit?: number;
  entries?: V1Alpha1TrapHistoryViewModel[];
};
export type V1Alpha1UpdateHistoryEntriesResponse = object;
export type HistoryServiceUpdateHistoryEntriesBody = {
  checkpointId?: string;
  entryId?: string[];
  comment?: string;
  falseAlarm?: boolean;
};
export const {
  useHistoryServiceListHistoryQuery,
  useHistoryServiceGetHistoryEntryQuery,
  useHistoryServiceUpdateHistoryEntryMutation,
  useHistoryServiceCreateCsvExportMutation,
  useHistoryServiceListHistoryForBuildingQuery,
  useHistoryServiceUpdateHistoryEntriesMutation
} = injectedRtkApi;
