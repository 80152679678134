/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { HiChevronDown } from 'react-icons/hi';
import { RiAlertFill } from 'react-icons/ri';
import FormErrorLabel from './formErrorLabel';

export interface FormSelectOption {
  value: string;
  description: string;
}

interface FormSelectProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  options: FormSelectOption[];
  value?: string;
}

function FormSelect({
  id,
  label,
  placeholder = '',
  required = false,
  errorMsg = 'Error',
  disabled = false,
  options,
  value
}: FormSelectProps): ReactElement {
  const {
    formState: { errors },
    register
  } = useFormContext();

  return (
    <div className="flex flex-col w-full mt-2">
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor={id}
      >
        {label}
      </label>
      <select
        disabled={disabled}
        value={value}
        className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none"
        {...register(id, { required })}
      >
        {options.map((option: FormSelectOption) => (
          <option key={option.value} value={option.value}>
            {option.description}
          </option>
        ))}
      </select>
      {required && errors?.[id] && (
        <FormErrorLabel icon={<RiAlertFill size={20} />} label={errorMsg} />
      )}
    </div>
  );
}

export default FormSelect;
