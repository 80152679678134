/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCheckpointServiceDeleteCheckpointMutation } from '../../../api/enhanced/enhancedCheckpoints';
import Modal from '../../../components/modal/modal';
import FormTextInput from '../../../components/form/formTextInput';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';

interface DeleteCheckpointFormValues {
  sensorId: string;
}

interface DeleteCheckpointModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  checkpointId: string;
  buildingId: string;
  groupId: string;
  sensorId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
}

function DeleteCheckpointModal({
  closeHandler,
  isOpen,
  checkpointId,
  groupId,
  sensorType,
  sensorId,
  buildingId
}: DeleteCheckpointModalProps): ReactElement {
  const methods = useForm<DeleteCheckpointFormValues>();
  const { t } = useTranslation();
  const watchSensorId = methods.watch('sensorId');

  const [deleteCheckpoint, { isLoading, isSuccess, reset }] =
    useCheckpointServiceDeleteCheckpointMutation();

  useEffect(() => {
    if (isSuccess) {
      methods.reset();
      reset();
      closeHandler();
    }
  }, [reset, methods, closeHandler, isSuccess]);

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  const onSubmit = (formData: DeleteCheckpointFormValues): void => {
    if (sensorId === formData.sensorId && groupId != null) {
      deleteCheckpoint({
        groupId,
        buildingId,
        checkpointId,
        sensorType
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t(`features.checkpoints.modals.delete.title.${sensorType}`)}
      >
        <div className="w-full flex flex-col">
          <div>{t('features.checkpoints.modals.delete.description')}</div>

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="sensorId"
              label={t('features.checkpoints.modals.delete.sensorId.label')}
              errorMsg={
                t(
                  'features.checkpoints.modals.delete.sensorId.sensorId.error'
                ) as string
              }
              placeholder={
                t('features.checkpoints.modals.delete.sensorId.label') as string
              }
              disabled={isLoading}
              required
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  disabled={sensorId !== watchSensorId}
                  className=" rounded-lg shadow-md w-24 h-10 bg-red-400 text-white disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  type="submit"
                >
                  {t('modals.delete')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-red-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default DeleteCheckpointModal;
