import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import DropDown, { DropDownItem } from '../dropdown/dropdown';
import { SelectedGroupState } from '../../features/users/modals/createUserModal';
import { V1Alpha1Role } from '../../api/gen/identity';

interface GroupMultiselectListProps {
  roles: DropDownItem[];
  label?: string;
  className?: string;
  state: SelectedGroupState[];
  groupHandler: (group: SelectedGroupState[]) => void;
}

function GroupMultiselectList({
  state,
  groupHandler,
  className = '',
  label,
  roles
}: GroupMultiselectListProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      {label != null && (
        <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          {label}
        </div>
      )}
      <ul
        className={`w-full text-sm font-medium text-gray-900 bg-white rounded-md border border-gray-200 ${className}`}
      >
        {state.map((element) => (
          <li
            key={element.groupAssignment.groupId}
            className="w-full  rounded-t-md border-b border-gray-200"
          >
            <div className="flex items-center pl-3">
              <input
                type="checkbox"
                checked={element.selected}
                onChange={(event) =>
                  groupHandler(
                    state.map((selectedGroupState) => {
                      if (
                        element.groupAssignment.groupId ===
                        selectedGroupState.groupAssignment.groupId
                      ) {
                        return {
                          ...selectedGroupState,
                          selected: event.target.checked
                        };
                      }
                      return selectedGroupState;
                    })
                  )
                }
                value={element.groupAssignment.groupId}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {element.groupAssignment.description}
              </div>
              <div className="mt-2 mb-2 mr-2">
                <DropDown
                  selectedKey={
                    element.groupAssignment.role != null
                      ? element.groupAssignment.role
                      : 'ROLE_UNSPECIFIED'
                  }
                  items={roles}
                  setSelectedKey={(roleKey: string) => {
                    const parsedRoleKey = roleKey as V1Alpha1Role;
                    groupHandler(
                      state.map((selectedGroupState) => {
                        if (
                          element.groupAssignment.groupId ===
                          selectedGroupState.groupAssignment.groupId
                        ) {
                          return {
                            ...selectedGroupState,
                            groupAssignment: {
                              ...selectedGroupState.groupAssignment,
                              role: parsedRoleKey
                            }
                          };
                        }
                        return selectedGroupState;
                      })
                    );
                  }}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GroupMultiselectList;
