import { buildingsApi } from '../gen/buildings';

export const enhancedBuildingsApi = buildingsApi.enhanceEndpoints({
  addTagTypes: ['Buildings', 'Areas', 'User'],
  endpoints: {
    buildingServiceCreateBuilding: {
      invalidatesTags: ['Buildings']
    },
    buildingServiceGetBuilding: {
      providesTags: ['Buildings', 'Areas']
    },
    buildingServiceRemoveBuilding: {
      invalidatesTags: ['Buildings', 'Areas']
    },
    buildingServiceUpdateBuilding: {
      invalidatesTags: ['Buildings', 'Areas']
    },
    buildingServiceListAreasForBuilding: {
      providesTags: ['Buildings', 'Areas']
    },
    buildingServiceAddAreaToBuilding: {
      invalidatesTags: ['Buildings', 'Areas']
    },
    buildingServiceGetArea: {
      providesTags: ['Buildings', 'Areas']
    },
    buildingServiceRemoveAreaFromBuilding: {
      invalidatesTags: ['Buildings', 'Areas']
    },
    buildingServiceAssignUsersToBuilding: {
      invalidatesTags: ['Buildings', 'Areas', 'User']
    },
    buildingServiceRemoveUserFromBuilding: {
      invalidatesTags: ['Buildings', 'Areas', 'User']
    },
    buildingServiceListUsersForBuilding: {
      providesTags: ['Buildings', 'Areas']
    },
    buildingServiceListBuildings: {
      providesTags: ['Buildings', 'Areas']
    },
    buildingServiceUpdateArea: {
      invalidatesTags: ['Areas']
    }
  }
});

export const {
  useBuildingServiceCreateBuildingMutation,
  useBuildingServiceGetBuildingQuery,
  useBuildingServiceRemoveBuildingMutation,
  useBuildingServiceUpdateBuildingMutation,
  useBuildingServiceListAreasForBuildingQuery,
  useBuildingServiceAddAreaToBuildingMutation,
  useBuildingServiceGetAreaQuery,
  useBuildingServiceRemoveAreaFromBuildingMutation,
  useBuildingServiceAssignUsersToBuildingMutation,
  useBuildingServiceListBuildingsQuery,
  useBuildingServiceListUsersForBuildingQuery,
  useBuildingServiceRemoveUserFromBuildingMutation,
  useBuildingServiceUpdateAreaMutation
} = enhancedBuildingsApi;
