import React, { ReactElement } from 'react';

interface UserCommentProps {
  comment: string;
  className?: string;
}

function UserComment({
  comment,
  className = ''
}: UserCommentProps): ReactElement {
  let label = 'Kein Kommentar vorhanden';

  if (comment === 'falseAlarm') {
    label = 'Fehlauslösung ohne Fang';
  } else if (comment === 'falseCatch') {
    label = 'Fehlauslösung mit Fang';
  } else if (comment !== '') {
    label = comment;
  }

  return <div className={className}>{label}</div>;
}

export default UserComment;
