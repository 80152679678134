import React, { ReactElement } from 'react';

interface FormErrorLabelProps {
  icon?: ReactElement;
  label: string;
  className?: string;
}

function FormErrorLabel({
  icon,
  label,
  className = ''
}: FormErrorLabelProps): ReactElement {
  return (
    <div
      className={`flex flex-row text-red-500 font-semibold text-md items-center ${className}`}
    >
      {icon != null ? icon : null}
      <div className="ml-2">{label}</div>
    </div>
  );
}

export default FormErrorLabel;
