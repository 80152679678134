import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RiAlertFill } from 'react-icons/ri';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';

import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { useDeviceRegistryServiceUpdateDeviceRegistryMutation } from '../../../api/enhanced/enhancedRegistry';

interface UpdateDeviceRegistryModalProps {
  closeHandler: () => void;
  isOpen: boolean;
}

function UpdateDeviceRegistryModal({
  closeHandler,
  isOpen
}: UpdateDeviceRegistryModalProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [updateRegistry, { isSuccess, isError, isLoading, reset }] =
    useDeviceRegistryServiceUpdateDeviceRegistryMutation();

  useEffect(() => {
    if (isSuccess) {
      reset();
      closeHandler();
    }
  }, [isSuccess, reset, navigate, closeHandler]);

  const close = (): void => {
    closeHandler();
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={() => {
        close();
      }}
      title={t('features.devices.modals.updateRegistry.title')}
    >
      <div className="flex flex-col w-full">
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('features.devices.modals.updateRegistry.error')}
            />
          ) : null}
        </div>
        <p>{t('features.devices.modals.updateRegistry.message')}</p>
        <div className="flex items-end flex-col w-full mt-5">
          {!isLoading ? (
            <button
              className=" rounded-lg shadow-md w-36 h-10 bg-blue-400 text-white"
              type="button"
              onClick={() => {
                updateRegistry({
                  v1Alpha1UpdateDeviceRegistryRequest: {}
                });
              }}
            >
              {t('features.devices.modals.updateRegistry.sync')}
            </button>
          ) : (
            <div className="rounded-lg shadow-md w-36  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default UpdateDeviceRegistryModal;
