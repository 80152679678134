/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { skipToken } from '@reduxjs/toolkit/query';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import GroupMultiselectList from '../../../components/multiselectList/groupMultiselectList';
import { DropDownItem } from '../../../components/dropdown/dropdown';
import { SelectedGroupState } from './createUserModal';
import getUserRole from '../../../utils/roleUtils';
import {
  useIdentityServiceGetUserQuery,
  useIdentityServiceListGroupsQuery,
  useIdentityServiceUpdateUserMutation
} from '../../../api/enhanced/enhancedIdentity';

interface FormValues {
  mail: string;
  userName: string;
  role: string;
}

interface EditUserModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  userId: string;
}

function EditUserModal({
  closeHandler,
  isOpen,
  userId
}: EditUserModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTechnician, setIsTechnician] = useState(false);
  const [isMailUser, setIsMailUser] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<SelectedGroupState[]>(
    []
  );

  const roles: DropDownItem[] = [
    { key: 'ROLE_GROUP_MEMBER', label: t('role.ROLE_GROUP_MEMBER') }
    // { key: 'ROLE_GROUP_ADMIN', label: t('role.ROLE_GROUP_ADMIN') }
  ];

  const {
    data: userData,
    isError: isUserError,
    error: userError,
    isSuccess: isUserSuccess
  } = useIdentityServiceGetUserQuery(
    userId != null && userId !== '' ? { userId } : skipToken
  );

  const [updateUser, { isLoading, isSuccess, isError, reset }] =
    useIdentityServiceUpdateUserMutation();

  const {
    data,
    isLoading: groupLoading,
    isSuccess: groupSuccess,
    isError: groupError
  } = useIdentityServiceListGroupsQuery(
    userData != null && userData.user != null && userData.user.groups != null
      ? {}
      : skipToken
  );

  useEffect(() => {
    if (
      isUserSuccess &&
      !isError &&
      userData != null &&
      userData.user != null
    ) {
      setIsAdmin(userData.user.isAdmin as boolean);
      setIsTechnician(userData.user.isTechnician as boolean);
      setIsMailUser(userData.user.isMailUser as boolean);
    }
  }, [userData, isUserError, userError, isUserSuccess, isError]);

  useEffect(() => {
    if (groupSuccess && !groupError && data != null && data.groups != null) {
      setSelectedGroups(
        data.groups.map((group): SelectedGroupState => {
          if (
            group.groupId != null &&
            userData != null &&
            userData.user != null &&
            userData.user.groups != null
          ) {
            const role = getUserRole(group.groupId, userData.user.groups);
            return {
              selected: role !== 'ROLE_UNSPECIFIED',
              groupAssignment: { ...group, role }
            };
          }
          return { selected: false, groupAssignment: group };
        })
      );
    }
  }, [data, groupError, groupSuccess, userData]);

  useEffect(() => {
    if (isSuccess) {
      methods.reset();
      reset();
      closeHandler();
    }
  }, [reset, methods, closeHandler, isSuccess]);

  const onSubmit = (formData: FormValues): void => {
    updateUser({
      userId,
      body: {
        isAdmin,
        isTechnician,
        isMailUser,
        groups: selectedGroups.map((group) => {
          return {
            isAddition: group.selected,
            group: {
              ...group.groupAssignment,
              role:
                group.groupAssignment.role !== 'ROLE_UNSPECIFIED'
                  ? group.groupAssignment.role
                  : 'ROLE_GROUP_MEMBER'
            }
          };
        })
      }
    });
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.editUser.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {userData != null &&
              userData.user != null &&
              !userData.user.isMailUser &&
              !isTechnician && (
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    onChange={() => {
                      setIsAdmin(!isAdmin);
                      if (isAdmin && isTechnician) {
                        setIsTechnician(false);
                      }
                    }}
                    checked={isAdmin}
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
                  />
                  <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                    {t('modals.createUser.admin.label')}
                  </div>
                </div>
              )}

            {userData != null &&
              userData.user != null &&
              !userData.user.isMailUser &&
              !isAdmin && (
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    onChange={() => {
                      setIsTechnician(!isTechnician);
                    }}
                    checked={isTechnician}
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
                  />
                  <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                    {t('modals.createUser.technician.label')}
                  </div>
                </div>
              )}

            {!isAdmin && !isTechnician && (
              <div>
                <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                  {t('modals.createUser.groups.label')}
                </div>
                <GroupMultiselectList
                  state={selectedGroups}
                  groupHandler={setSelectedGroups}
                  roles={roles}
                  className="h-52 overflow-auto"
                />
              </div>
            )}
            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default EditUserModal;
