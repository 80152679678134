import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';
import LabelButton from '../../../components/buttons/labelButton';
import CreateUserModal from '../modals/createUserModal';
import EditUserModal from '../modals/editUserModal';
import DeleteUserModal from '../modals/deleteUserModal';
import UserTable from '../tables/userTable';
import SendInvitationUserModal from '../modals/sendUserModal';
import useDebounce from '../../../hooks/useDebounce';
import SearchBar from '../../../components/searchBar/searchBar';

interface UsersProps {
  groupId?: string;
}

function Users({ groupId }: UsersProps): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const [editUserState, setEditUserState] = useState({
    isOpen: false,
    userId: ''
  });

  const [deleteUserState, setDeleteUserState] = useState({
    isOpen: false,
    userId: '',
    mail: ''
  });

  const [inviteUserState, setInviteUserState] = useState({
    isOpen: false,
    userId: '',
    mail: ''
  });

  return (
    <div>
      <div className="flex flex-col  w-full">
        <div className="flex flex-row w-full justify-between">
          {groupId == null && (
            <LabelButton
              label={t('administration.users.addUser')}
              className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600"
              icon={<IoMdAdd size={20} />}
              onClick={() => setIsOpen(true)}
            />
          )}
          <SearchBar
            className="mr-2"
            changeHandler={(value: string) => setSearchTerm(value)}
          />
        </div>
        <UserTable
          groupId={groupId}
          editUserStateHandler={(editUserIsOpen: boolean, userId: string) => {
            setEditUserState({ userId, isOpen: editUserIsOpen });
          }}
          deleteUserStateHandler={(
            deleteUserIsOpen: boolean,
            userId: string,
            mail: string
          ) => {
            setDeleteUserState({ userId, isOpen: deleteUserIsOpen, mail });
          }}
          inviteUserStateHandler={(
            deleteUserIsOpen: boolean,
            userId: string,
            mail: string
          ) => {
            setInviteUserState({ userId, isOpen: deleteUserIsOpen, mail });
          }}
          searchTerm={debouncedSearchTerm}
        />
      </div>

      <CreateUserModal
        isOpen={isOpen}
        closeHandler={() => {
          setIsOpen(false);
        }}
      />

      <EditUserModal
        userId={editUserState.userId}
        isOpen={editUserState.isOpen}
        closeHandler={() => {
          setEditUserState({ userId: '', isOpen: false });
        }}
      />

      <DeleteUserModal
        userId={deleteUserState.userId}
        isOpen={deleteUserState.isOpen}
        mail={deleteUserState.mail}
        closeHandler={() => {
          setDeleteUserState({ userId: '', mail: '', isOpen: false });
        }}
      />

      <SendInvitationUserModal
        userId={inviteUserState.userId}
        isOpen={inviteUserState.isOpen}
        mail={inviteUserState.mail}
        closeHandler={() => {
          setInviteUserState({ userId: '', mail: '', isOpen: false });
        }}
      />
    </div>
  );
}

export default Users;
