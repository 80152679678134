import React, { ReactElement } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts';
import CardSkeleton from '../../../../../components/card/cardSkeleton';
import Card from '../../../../../components/card/card';
import { useHistoryServiceListHistoryQuery } from '../../../../../api/enhanced/enhancedHistory';
import { useCheckpointServiceGetCheckpointQuery } from '../../../../../api/enhanced/enhancedCheckpoints';

interface CarboneDioxideGraphProps {
  groupId?: string;
  buildingId?: string;
  checkpointId?: string;
  startTime?: string;
  endTime?: string;
}

function CarboneDioxideGraph({
  checkpointId,
  groupId,
  buildingId,
  startTime,
  endTime
}: CarboneDioxideGraphProps): ReactElement {
  const isValid =
    groupId != null &&
    groupId !== '' &&
    buildingId != null &&
    checkpointId != null;

  const { data: checkpointResponse } = useCheckpointServiceGetCheckpointQuery(
    isValid
      ? {
          checkpointId,
          groupId,
          buildingId,
          sensorType: 'SENSOR_TYPE_CARBON_DIOXIDE'
        }
      : skipToken
  );

  const {
    data: dataResponse,
    isLoading,
    isFetching
  } = useHistoryServiceListHistoryQuery(
    isValid
      ? {
          checkpointId,
          groupId,
          buildingId,
          sensorType: 'SENSOR_TYPE_CARBON_DIOXIDE',
          sortingOrder: 'asc',
          startTime,
          endTime
        }
      : skipToken
  );

  const dateFormatter = (timestamp: string | undefined): string => {
    const date = new Date(timestamp as string);
    return `${date.toLocaleDateString('de-DE')} ${date.toLocaleTimeString(
      'de-DE'
    )}`;
  };

  if (dataResponse == null || isLoading || isFetching) {
    return <CardSkeleton className="bg-white flex w-full h-96" />;
  }

  if (dataResponse.entries != null && dataResponse.entries.length === 0) {
    return (
      <Card className="bg-white flex w-full h-96 font-bold text-2xl items-center justify-center">
        <h1>Keine Einträge vorhanden oder kein Bereich ausgewählt</h1>
      </Card>
    );
  }

  return (
    <Card className="bg-white flex w-full h-96">
      <ResponsiveContainer width="99%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={dataResponse.entries}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            dataKey="timestamp"
            tickFormatter={dateFormatter}
            minTickGap={100}
            tickSize={15}
            interval="preserveStartEnd"
          />
          {checkpointResponse != null &&
            checkpointResponse.checkpoint != null &&
            checkpointResponse.checkpoint.carbonDioxidePayload?.warningLimit !==
              0 && (
              <ReferenceLine
                strokeWidth={2}
                y={
                  checkpointResponse.checkpoint.carbonDioxidePayload
                    ?.warningLimit
                }
                stroke="orange"
              />
            )}
          {checkpointResponse != null &&
            checkpointResponse.checkpoint != null &&
            checkpointResponse.checkpoint.carbonDioxidePayload?.alarmLimit !==
              0 && (
              <ReferenceLine
                strokeWidth={2}
                y={
                  checkpointResponse.checkpoint.carbonDioxidePayload?.alarmLimit
                }
                stroke="red"
              />
            )}

          <YAxis domain={['auto', 'auto']} />
          <Tooltip labelFormatter={dateFormatter} />
          <Legend />
          <Line
            strokeWidth={2}
            name="ppm"
            type="monotone"
            dataKey="carbonDioxidePayload.carbonDioxide"
            stroke="#8884d8"
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default CarboneDioxideGraph;
