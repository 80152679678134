/* eslint-disable react/jsx-props-no-spreading */
import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import {
  useCheckpointServiceGetCheckpointQuery,
  useCheckpointServiceUpdateCheckpointMutation
} from '../../../api/enhanced/enhancedCheckpoints';
import { useBuildingServiceListAreasForBuildingQuery } from '../../../api/enhanced/enhancedBuildings';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import FormSelect, {
  FormSelectOption
} from '../../../components/form/formSelect';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import {
  V1Alpha1AnalogTrapType,
  V1Alpha1DoorSensorType
} from '../../../api/gen/checkpoints';

interface UpdateCheckpointFormValues {
  buildingId: string;
  areaId: string;
  description: string;
  devEui: string;
  checkpointNumber: number;
  analogTrapType: V1Alpha1AnalogTrapType;
  doorSensorType: V1Alpha1DoorSensorType;
  openingDistance: number;
  tempMaxLimit: number;
  tempMinLimit: number;
  humidityMinLimit: number;
  humidityMaxLimit: number;
  co2WarningLimit: number;
  co2AlarmLimit: number;
}

interface UpdateCheckpointModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  buildingId: string;
  checkpointId: string;
  groupId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
}

function UpdateCheckpointModal({
  closeHandler,
  isOpen,
  checkpointId,
  buildingId,
  sensorType,
  groupId
}: UpdateCheckpointModalProps): ReactElement {
  const methods = useForm<UpdateCheckpointFormValues>();
  const { t } = useTranslation();
  const doorSensorType = methods.watch('doorSensorType');

  const [updateCheckpoint, { isLoading, isError, isSuccess, reset }] =
    useCheckpointServiceUpdateCheckpointMutation();

  const { data: checkpointData } = useCheckpointServiceGetCheckpointQuery({
    checkpointId,
    buildingId,
    groupId,
    sensorType
  });

  useEffect(() => {
    if (isOpen && checkpointData != null && checkpointData.checkpoint) {
      methods.setValue(
        'buildingId',
        checkpointData.checkpoint.buildingId != null
          ? checkpointData.checkpoint.buildingId
          : ''
      );
      methods.setValue(
        'areaId',
        checkpointData.checkpoint.areaId != null
          ? checkpointData.checkpoint.areaId
          : ''
      );
      methods.setValue(
        'description',
        checkpointData.checkpoint.description != null
          ? checkpointData.checkpoint.description
          : ''
      );
      methods.setValue(
        'devEui',
        checkpointData.checkpoint.currentSensorId != null
          ? checkpointData.checkpoint.currentSensorId
          : ''
      );
      methods.setValue(
        'checkpointNumber',
        checkpointData.checkpoint.checkpointNumber != null
          ? checkpointData.checkpoint.checkpointNumber
          : 0
      );

      if (sensorType === 'SENSOR_TYPE_CARBON_DIOXIDE') {
        methods.setValue(
          'co2WarningLimit',
          checkpointData.checkpoint.carbonDioxidePayload != null &&
            checkpointData.checkpoint.carbonDioxidePayload.warningLimit != null
            ? checkpointData.checkpoint.carbonDioxidePayload.warningLimit
            : 0
        );

        methods.setValue(
          'co2AlarmLimit',
          checkpointData.checkpoint.carbonDioxidePayload != null &&
            checkpointData.checkpoint.carbonDioxidePayload.alarmLimit != null
            ? checkpointData.checkpoint.carbonDioxidePayload.alarmLimit
            : 0
        );
      }

      if (sensorType === 'SENSOR_TYPE_TEMP') {
        methods.setValue(
          'tempMinLimit',
          checkpointData.checkpoint.temperaturePayload != null &&
            checkpointData.checkpoint.temperaturePayload.minLimit != null
            ? checkpointData.checkpoint.temperaturePayload.minLimit
            : 0
        );

        methods.setValue(
          'tempMaxLimit',
          checkpointData.checkpoint.temperaturePayload != null &&
            checkpointData.checkpoint.temperaturePayload.maxLimit != null
            ? checkpointData.checkpoint.temperaturePayload.maxLimit
            : 0
        );

        methods.setValue(
          'humidityMinLimit',
          checkpointData.checkpoint.temperaturePayload != null &&
            checkpointData.checkpoint.temperaturePayload.humidityMinLimit !=
              null
            ? checkpointData.checkpoint.temperaturePayload.humidityMinLimit
            : 0
        );

        methods.setValue(
          'humidityMaxLimit',
          checkpointData.checkpoint.temperaturePayload != null &&
            checkpointData.checkpoint.temperaturePayload.humidityMaxLimit !=
              null
            ? checkpointData.checkpoint.temperaturePayload.humidityMaxLimit
            : 0
        );
      }

      if (sensorType === 'SENSOR_TYPE_ANALOG_TRAP') {
        methods.setValue(
          'analogTrapType',
          checkpointData.checkpoint.analogTrapPayload != null &&
            checkpointData.checkpoint.analogTrapPayload.analogTrapType != null
            ? checkpointData.checkpoint.analogTrapPayload.analogTrapType
            : 'ANALOG_TRAP_TYPE_UNSPECIFIED'
        );
      }

      if (sensorType === 'SENSOR_TYPE_DOOR') {
        methods.setValue(
          'doorSensorType',
          checkpointData.checkpoint.doorPayload != null &&
            checkpointData.checkpoint.doorPayload.doorSensorType != null
            ? checkpointData.checkpoint.doorPayload.doorSensorType
            : 'DOOR_SENSOR_TYPE_UNSPECIFIED'
        );

        methods.setValue(
          'openingDistance',
          checkpointData.checkpoint.doorPayload != null &&
            checkpointData.checkpoint.doorPayload.openingDistance != null
            ? checkpointData.checkpoint.doorPayload.openingDistance
            : 0
        );
      }
    }

    if (isSuccess && !isError) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [
    methods,
    reset,
    isSuccess,
    isError,
    closeHandler,
    isOpen,
    checkpointData,
    sensorType
  ]);

  const { data, isLoading: areaLoading } =
    useBuildingServiceListAreasForBuildingQuery(
      checkpointData != null &&
        checkpointData.checkpoint != null &&
        checkpointData.checkpoint.buildingId != null &&
        groupId !== ''
        ? {
            buildingId: checkpointData.checkpoint.buildingId,
            groupId,
            limit: 200,
            page: 0
          }
        : skipToken
    );

  const onSubmit = (formData: UpdateCheckpointFormValues): void => {
    if (
      groupId !== '' &&
      checkpointData != null &&
      checkpointData.checkpoint != null &&
      checkpointData.checkpoint.buildingId != null
    ) {
      updateCheckpoint({
        groupId,
        buildingId,
        checkpointId,
        sensorType,
        checkpointServiceUpdateCheckpointBody: {
          checkpoint: {
            areaId: formData.areaId,
            description: formData.description,
            currentSensorId: formData.devEui,
            checkpointNumber: formData.checkpointNumber,
            trapPayload: sensorType === 'SENSOR_TYPE_TRAP' ? {} : undefined,
            temperaturePayload:
              sensorType === 'SENSOR_TYPE_TEMP'
                ? {
                    minLimit: formData.tempMinLimit,
                    maxLimit: formData.tempMaxLimit,
                    humidityMinLimit: formData.humidityMinLimit,
                    humidityMaxLimit: formData.humidityMaxLimit
                  }
                : undefined,
            carbonDioxidePayload:
              sensorType === 'SENSOR_TYPE_CARBON_DIOXIDE'
                ? {
                    warningLimit: formData.co2WarningLimit,
                    alarmLimit: formData.co2AlarmLimit
                  }
                : undefined,
            doorPayload:
              sensorType === 'SENSOR_TYPE_DOOR'
                ? {
                    doorSensorType: formData.doorSensorType,
                    openingDistance: formData.openingDistance
                  }
                : undefined,
            analogTrapPayload:
              sensorType === 'SENSOR_TYPE_ANALOG_TRAP'
                ? {
                    analogTrapType: formData.analogTrapType
                  }
                : undefined
          }
        }
      });
    }
  };

  const close = (): void => {
    closeHandler();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t(`features.checkpoints.modals.update.title.${sensorType}`)}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('features.checkpoints.modals.update.description.label')}
              errorMsg={
                t(
                  'features.checkpoints.modals.update.description.error'
                ) as string
              }
              placeholder={
                t(
                  'features.checkpoints.modals.update.description.label'
                ) as string
              }
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="devEui"
              label={t('features.checkpoints.modals.update.eui.label')}
              errorMsg={
                t('features.checkpoints.modals.update.eui.error') as string
              }
              placeholder={
                t('features.checkpoints.modals.update.eui.label') as string
              }
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="checkpointNumber"
              label={t(
                'features.checkpoints.modals.update.checkpointNumber.label'
              )}
              errorMsg={
                t(
                  'features.checkpoints.modals.update.checkpointNumber.error'
                ) as string
              }
              placeholder={
                t(
                  'features.checkpoints.modals.update.checkpointNumber.label'
                ) as string
              }
              disabled={isLoading}
              required
              type="number"
            />

            {sensorType === 'SENSOR_TYPE_TEMP' && (
              <div className="flex flex-col">
                <FormTextInput
                  id="tempMinLimit"
                  label={t(
                    'features.checkpoints.modals.update.tempMinLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.update.tempMinLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.update.tempMinLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
                <FormTextInput
                  id="tempMaxLimit"
                  label={t(
                    'features.checkpoints.modals.update.tempMaxLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.update.tempMaxLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.update.tempMaxLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
                <FormTextInput
                  id="humidityMinLimit"
                  label={t(
                    'features.checkpoints.modals.create.humidityMinLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.humidityMinLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.humidityMinLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
                <FormTextInput
                  id="humidityMaxLimit"
                  label={t(
                    'features.checkpoints.modals.create.humidityMaxLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.humidityMaxLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.humidityMaxLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
              </div>
            )}

            {sensorType === 'SENSOR_TYPE_CARBON_DIOXIDE' && (
              <div className="flex flex-col">
                <FormTextInput
                  id="co2WarningLimit"
                  label={t(
                    'features.checkpoints.modals.update.co2WarningLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.update.co2WarningLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.update.co2WarningLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
                <FormTextInput
                  id="co2AlarmLimit"
                  label={t(
                    'features.checkpoints.modals.update.co2AlarmLimit.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.update.co2AlarmLimit.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.update.co2AlarmLimit.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                />
              </div>
            )}

            {sensorType === 'SENSOR_TYPE_DOOR' && (
              <FormSelect
                id="doorSensorType"
                label={t(
                  'features.checkpoints.modals.create.doorSensorType.label'
                )}
                errorMsg={
                  t(
                    'features.checkpoints.modals.create.doorSensorType.error'
                  ) as string
                }
                placeholder={
                  t(
                    'features.checkpoints.modals.create.doorSensorType.label'
                  ) as string
                }
                disabled={isLoading}
                options={[
                  {
                    value: 'DOOR_SENSOR_TYPE_OPENING',
                    description: t(
                      'features.checkpoints.doorSensorTypes.DOOR_SENSOR_TYPE_OPENING'
                    )
                  },
                  {
                    value: 'DOOR_SENSOR_TYPE_DISTANCE',
                    description: t(
                      'features.checkpoints.doorSensorTypes.DOOR_SENSOR_TYPE_DISTANCE'
                    )
                  }
                ]}
              />
            )}

            {sensorType === 'SENSOR_TYPE_DOOR' &&
              doorSensorType === 'DOOR_SENSOR_TYPE_DISTANCE' && (
                <FormTextInput
                  id="openingDistance"
                  label={t(
                    'features.checkpoints.modals.create.openingDistance.label'
                  )}
                  errorMsg={
                    t(
                      'features.checkpoints.modals.create.openingDistance.error'
                    ) as string
                  }
                  placeholder={
                    t(
                      'features.checkpoints.modals.create.openingDistance.label'
                    ) as string
                  }
                  disabled={isLoading}
                  type="number"
                  required={doorSensorType === 'DOOR_SENSOR_TYPE_DISTANCE'}
                />
              )}

            {sensorType === 'SENSOR_TYPE_ANALOG_TRAP' && (
              <FormSelect
                id="analogTrapType"
                label={t(
                  'features.checkpoints.modals.update.analogTrapType.label'
                )}
                errorMsg={
                  t(
                    'features.checkpoints.modals.update.analogTrapType.error'
                  ) as string
                }
                placeholder={
                  t(
                    'features.checkpoints.modals.update.analogTrapType.label'
                  ) as string
                }
                disabled={isLoading}
                options={[
                  {
                    value: 'ANALOG_TRAP_TYPE_FLIGHT_INSECT_DEVICE',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_FLIGHT_INSECT_DEVICE'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_FLOUR_BEETLE_TRAP',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_FLOUR_BEETLE_TRAP'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_MOTH_TRAP',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_MOTH_TRAP'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_NON_TOX_BOX',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_NON_TOX_BOX'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_OTHERS',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_OTHERS'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_SCRAPE',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_SCRAPE'
                    )
                  },
                  {
                    value: 'ANALOG_TRAP_TYPE_TOX_BOX',
                    description: t(
                      'features.checkpoints.analogTrapTypes.ANALOG_TRAP_TYPE_TOX_BOX'
                    )
                  }
                ]}
              />
            )}

            {data != null && data.areas != null && (
              <FormSelect
                id="areaId"
                label={t('features.checkpoints.modals.update.area.label')}
                errorMsg={
                  t('features.checkpoints.modals.update.area.error') as string
                }
                placeholder={
                  t('features.checkpoints.modals.update.area.label') as string
                }
                disabled={isLoading}
                options={data?.areas?.map((option): FormSelectOption => {
                  return {
                    value: option?.id != null ? option.id : '',
                    description:
                      option?.description != null ? option.description : ''
                  };
                })}
                required
              />
            )}

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading && !areaLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default UpdateCheckpointModal;
