import React, { useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { msalInstance, tokenRequest } from '../auth/msal/msalConfig';
import WebsocketMessage from '../api/models/websocketMessage';
import { enhancedCheckpointApi } from '../api/enhanced/enhancedCheckpoints';

function useWebsocket(groupId: string | undefined): void {
  const ws = useRef<WebSocket | null>(null);
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  useEffect(() => {
    const connectToWebsocket = async (): Promise<void> => {
      if (accounts.length > 0) {
        const response = await msalInstance.acquireTokenSilent({
          ...tokenRequest,
          account: accounts[0]
        });

        ws.current = new WebSocket(
          `${process.env.REACT_APP_NOTIFICATION_URL}/notifications?groupId=${groupId}&access_token=${response.accessToken}`
        );

        ws.current.onmessage = (message: MessageEvent) => {
          const event: WebsocketMessage = JSON.parse(message.data);

          switch (event.sensorType) {
            case 'SENSOR_TYPE_TRAP':
              dispatch(
                enhancedCheckpointApi.util.invalidateTags([event.sensorType])
              );
              break;
            case 'SENSOR_TYPE_TEMP':
              dispatch(
                enhancedCheckpointApi.util.invalidateTags([event.sensorType])
              );
              break;
            case 'SENSOR_TYPE_CARBON_DIOXIDE':
              dispatch(
                enhancedCheckpointApi.util.invalidateTags([event.sensorType])
              );
              break;
            case 'SENSOR_TYPE_DOOR':
              dispatch(
                enhancedCheckpointApi.util.invalidateTags([event.sensorType])
              );
              break;
            default:
              console.info('unknown sensorType');
          }
        };

        ws.current.onerror = (e) => {
          console.log(e);
        };
      }
    };

    if (ws.current != null) {
      ws.current?.close();
    }

    if (groupId != null) {
      connectToWebsocket().catch((e) => console.error(e));
    }
  }, [accounts, dispatch, groupId]);
}

export default useWebsocket;
