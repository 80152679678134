import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import RowCell from '../../../components/tables/rowCell';
import {
  useAlarmServiceListAlarmsQuery,
  V1Alpha1Alarm
} from '../../../api/gen/alarms';
import BooleanStatePill from '../../../components/pill/booleanStatePill';
import TableDetailButtons from '../../../components/tables/detailButtons';
import DataTable from '../../../components/tables/dataTable';

type SensorType =
  | 'SENSOR_TYPE_UNSPECIFIED'
  | 'SENSOR_TYPE_TRAP'
  | 'SENSOR_TYPE_TEMP'
  | 'SENSOR_TYPE_CARBON_DIOXIDE'
  | 'SENSOR_TYPE_DOOR';

interface AlarmTableProps {
  deleteAlarmHandler: (
    isOpen: boolean,
    alarmId: string,
    sensorType: SensorType
  ) => void;
  editAlarmHandler: (
    isOpen: boolean,
    alarmId: string,
    sensorType: SensorType
  ) => void;
  sensorType: SensorType;
}

function AlarmTable({
  deleteAlarmHandler,
  editAlarmHandler,
  sensorType
}: AlarmTableProps): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId } = useParams();

  const columns = useMemo<ColumnDef<V1Alpha1Alarm>[]>(() => {
    return [
      {
        header: 'Beschreibung',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.description,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Aktiv',
        cell: (info) => BooleanStatePill({ state: info.getValue<boolean>() }),
        accessorFn: (row) => row.isActive,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Wiederholung',
        cell: (info) => BooleanStatePill({ state: info.getValue<boolean>() }),
        accessorFn: (row) => row.repeatedMessage,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Wiederholungsintervall',
        cell: (info) =>
          RowCell({
            title:
              info.getValue<number>() > 0
                ? info.getValue<string>()
                : 'Kein Intervall gesetzt'
          }),
        accessorFn: (row) => row.repetitionInterval,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Alarmverzögerung',
        cell: (info) =>
          RowCell({
            title:
              info.getValue<number>() > 0
                ? info.getValue<string>()
                : 'Keine Verzögerung gesetzt'
          }),
        accessorFn: (row) => row.delayInterval,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Bearbeiten',
        accessorFn: (row) => row.alarmId,
        cell: (info) =>
          TableDetailButtons({
            editHandler: () =>
              editAlarmHandler(true, info.getValue<string>(), sensorType),
            deleteHandler: () =>
              deleteAlarmHandler(true, info.getValue<string>(), sensorType)
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];
  }, [deleteAlarmHandler, editAlarmHandler, sensorType]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data, isLoading, isFetching, isSuccess, isError } =
    useAlarmServiceListAlarmsQuery(
      groupId != null &&
        groupId !== '' &&
        buildingId != null &&
        sensorType !== 'SENSOR_TYPE_UNSPECIFIED'
        ? {
            groupId,
            buildingId,
            limit: pageSize,
            page: pageIndex,
            sensorType
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = data != null && data.total != null ? Number(data.total) : 0;
  const limit = data != null && data.limit != null ? data.limit : 0;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data != null && data.alarms != null ? data.alarms : [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching || groupId === ''}
      isError={isError}
      isEmpty={
        isSuccess &&
        (data == null || data.alarms == null || data.alarms.length === 0)
      }
      pageSize={pageSize}
      table={table}
    />
  );
}

export default AlarmTable;
