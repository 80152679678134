import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OverviewTable from '../../../checkpoints/tables/traps/analogOverviewTable';
import AreaDropdown from '../../../../components/dropdown/areaDropdown';
import SearchBar from '../../../../components/searchBar/searchBar';
import useDebounce from '../../../../hooks/useDebounce';

interface AnalogTrapTabProps {
  groupId?: string;
  buildingId?: string;
}

function AnalogTrapTab({
  groupId,
  buildingId
}: AnalogTrapTabProps): ReactElement {
  const [areaId, setAreaId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const navigate = useNavigate();

  return (
    <div>
      <div className="mb-4 w-full flex justify-end">
        <SearchBar
          className="mr-2"
          changeHandler={(value: string) => setSearchTerm(value)}
        />
        {groupId != null && (
          <AreaDropdown
            groupId={groupId}
            buildingId={buildingId != null ? buildingId : ''}
            areaId={areaId}
            changeHandler={(selectedAreaId: string) => {
              setAreaId(selectedAreaId);
            }}
          />
        )}
      </div>

      <OverviewTable
        groupId={groupId}
        buildingId={buildingId}
        areaId={areaId}
        searchTerm={debouncedSearchTerm}
        clickHandler={(checkpointId: string) =>
          navigate(`checkpoints/${checkpointId}/analogtrap`)
        }
      />
    </div>
  );
}

export default AnalogTrapTab;
