import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCheckpointServiceGetCheckpointQuery } from '../../../../../api/enhanced/enhancedCheckpoints';
import CheckpointDetails from '../../../components/checkpointDetails';
import PageTitle from '../../../../../components/title/pageTitle';
import OnlineStatePill from '../../../../../components/pill/onlineStatePill';
import CheckpointState from '../../../components/checkpointState';
import TabBar, { TabContent } from '../../../../../components/tabs/tabBar';
import HistoryTable from '../../../tables/doors/historyTable';
import DoorPill from '../../../../../components/pill/doorPill';
import CommentModal from '../../../modals/commentModal';
import { V1Alpha1DoorSensorType } from '../../../../../api/gen/checkpoints';

function DoorDetailPage(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId, checkpointId } = useParams();

  const [commentModalState, setCommentModalState] = useState({
    isOpen: false,
    entryId: ''
  });

  const { data, isLoading, isFetching, isError, error, isSuccess } =
    useCheckpointServiceGetCheckpointQuery(
      buildingId != null &&
        checkpointId != null &&
        groupId != null &&
        groupId !== ''
        ? {
            checkpointId,
            buildingId,
            groupId,
            sensorType: 'SENSOR_TYPE_DOOR'
          }
        : skipToken
    );

  const tabContent: TabContent[] = [
    {
      key: 0,
      tabLabel: t('trapDetails.tabs.stateChanges'),
      content: (
        <HistoryTable
          doorSensorType={
            data?.checkpoint?.doorPayload
              ?.doorSensorType as V1Alpha1DoorSensorType
          }
          commentModalStateHandler={(isOpen: boolean, id: string) => {
            setCommentModalState({ isOpen, entryId: id });
          }}
          checkpointId={checkpointId}
          groupId={groupId}
          buildingId={buildingId}
          openingDistance={data?.checkpoint?.doorPayload?.openingDistance}
          onlyStateChanges
        />
      )
    },
    {
      key: 1,
      tabLabel: t('trapDetails.tabs.history'),
      content: (
        <HistoryTable
          doorSensorType={
            data?.checkpoint?.doorPayload
              ?.doorSensorType as V1Alpha1DoorSensorType
          }
          openingDistance={data?.checkpoint?.doorPayload?.openingDistance}
          commentModalStateHandler={(isOpen: boolean, id: string) => {
            setCommentModalState({ isOpen, entryId: id });
          }}
          checkpointId={checkpointId}
          groupId={groupId}
          buildingId={buildingId}
        />
      )
    }
  ];

  return (
    <div className="flex flex-col px-8 w-full">
      <div className="flex flex-row">
        <PageTitle isLoading={isLoading} title={t('trapDetails.title')} />
        <OnlineStatePill
          checkpointId={data?.checkpoint?.id}
          isLoading={isLoading}
          className="h-10 w-20 ml-2"
          state={data?.checkpoint?.loraMetadata?.isOnline}
          timestamp={data?.checkpoint?.loraMetadata?.lastSignal}
        />
        <DoorPill
          isLoading={isLoading}
          className="h-10 w-28 ml-2"
          state={data?.checkpoint?.doorPayload?.status}
        />
      </div>
      {data != null && data.checkpoint != null && (
        <div className="flex xl:flex-row md:flex-col my-2">
          <CheckpointDetails checkpoint={data?.checkpoint} />
          <CheckpointState checkpoint={data?.checkpoint} />
        </div>
      )}
      <TabBar className="mt-4" tabs={tabContent} />

      {buildingId != null &&
        groupId != null &&
        groupId !== '' &&
        checkpointId != null && (
          <CommentModal
            entryId={commentModalState.entryId}
            buildingId={buildingId}
            groupId={groupId}
            checkpointId={checkpointId}
            sensorType="SENSOR_TYPE_DOOR"
            defaultComments={[]}
            isOpen={commentModalState.isOpen}
            closeHandler={() => {
              setCommentModalState({ isOpen: false, entryId: '' });
            }}
          />
        )}
    </div>
  );
}

export default DoorDetailPage;
