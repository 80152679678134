import { historyApi } from '../gen/history';

export const enhancedHistoryApi = historyApi.enhanceEndpoints({
  addTagTypes: [
    'SENSOR_TYPE_UNSPECIFIED',
    'SENSOR_TYPE_TRAP',
    'SENSOR_TYPE_TEMP',
    'SENSOR_TYPE_CARBON_DIOXIDE',
    'SENSOR_TYPE_DOOR',
    'SENSOR_TYPE_ANALOG_TRAP',
    'SENSOR_TYPE_HUMIDITY'
  ],
  endpoints: {
    historyServiceListHistory: {
      providesTags: (result, error, arg) => [arg.sensorType]
    },
    historyServiceListHistoryForBuilding: {
      providesTags: (result, error, arg) => [arg.sensorType]
    },
    historyServiceUpdateHistoryEntry: {
      invalidatesTags: (result, error, arg) => [arg.sensorType]
    },
    historyServiceUpdateHistoryEntries: {
      invalidatesTags: (result, error, arg) => [arg.sensorType]
    },
    historyServiceGetHistoryEntry: {
      providesTags: (result, error, arg) => [arg.sensorType]
    }
  }
});

export const {
  useHistoryServiceListHistoryQuery,
  useHistoryServiceListHistoryForBuildingQuery,
  useHistoryServiceUpdateHistoryEntryMutation,
  useHistoryServiceUpdateHistoryEntriesMutation,
  useHistoryServiceGetHistoryEntryQuery
} = enhancedHistoryApi;
