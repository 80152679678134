import React, { ReactElement } from 'react';
import { MdEdit } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import TableActionButton from './tableActionButton';

interface DetailButtonProps {
  editHandler: () => void;
  deleteHandler: () => void;
}

function TableDetailButtons({
  editHandler,
  deleteHandler
}: DetailButtonProps): ReactElement {
  return (
    <div className="flex flex-row">
      <TableActionButton
        icon={<MdEdit size={20} />}
        onClick={() => editHandler()}
      />
      <TableActionButton
        className="bg-red-400 ml-2"
        icon={<IoMdTrash size={20} />}
        onClick={() => deleteHandler()}
      />
    </div>
  );
}

export default TableDetailButtons;
