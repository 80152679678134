/* eslint-disable react/jsx-props-no-spreading */
import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useReportServiceCreateReportMutation } from '../../../api/enhanced/enhancedReports';
import { useBuildingServiceListBuildingsQuery } from '../../../api/gen/buildings';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormSelect, {
  FormSelectOption
} from '../../../components/form/formSelect';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { V1Alpha1ReportType } from '../../../api/gen/reports';

interface FormValues {
  buildingId: string;
  checkpointId: string;
  year: string;
}

interface CreateTrendAnalyticsReportModalProps {
  groupId: string;
  closeHandler: () => void;
  isOpen: boolean;
  reportType: string;
}

function CreateTrendAnalyticsReportModal({
  groupId,
  closeHandler,
  isOpen,
  reportType
}: CreateTrendAnalyticsReportModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const year = methods.watch('year');

  const [createReport, { isLoading, isSuccess, isError, reset }] =
    useReportServiceCreateReportMutation();

  const { data: buildingData } = useBuildingServiceListBuildingsQuery(
    groupId !== ''
      ? {
          groupId,
          limit: 200,
          page: 0
        }
      : skipToken
  );

  const [years, setYears] = useState<number[]>([]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsList = Array.from(
      new Array(currentYear - 2022),
      (val, index) => currentYear - index
    );
    setYears(yearsList);
    methods.setValue('year', currentYear.toString());
  }, [methods]);

  useEffect(() => {
    if (isSuccess) {
      methods.reset();
      reset();
      closeHandler();
    }
  }, [reset, methods, closeHandler, isSuccess]);

  const onSubmit = (formData: FormValues): void => {
    createReport({
      groupId,
      buildingId: formData.buildingId,
      reportServiceCreateReportBody: {
        checkpointId:
          formData.checkpointId != null ? formData.checkpointId : undefined,
        type: reportType as V1Alpha1ReportType,
        year: Number(formData.year)
      }
    });
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createReport.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {buildingData != null &&
              buildingData.buildings != null &&
              buildingData.buildings.length > 0 && (
                <FormSelect
                  id="buildingId"
                  label={t('modals.createReport.building.label')}
                  errorMsg={t('modals.createReport.building.error') as string}
                  placeholder={
                    t('modals.createReport.building.label') as string
                  }
                  disabled={isLoading}
                  options={buildingData.buildings?.map(
                    (option): FormSelectOption => {
                      return {
                        value: option?.id != null ? option.id : '',
                        description:
                          option?.description != null ? option.description : ''
                      };
                    }
                  )}
                  required
                />
              )}

            <FormSelect
              id="year"
              label={t('modals.createReport.yearSelection.label')}
              errorMsg={t('modals.createReport.yearSelection.error') as string}
              value={year}
              placeholder={
                t('modals.createReport.yearSelection.label') as string
              }
              disabled={isLoading}
              options={years.map((option): FormSelectOption => {
                return {
                  value: option.toString(),
                  description: option.toString()
                };
              })}
              required
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateTrendAnalyticsReportModal;
