import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoginPage from './pages/login/login';
import './i18n';
import { store } from './api/store';
import { msalInstance } from './auth/msal/msalConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <AuthenticatedTemplate>
          <App />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage />
        </UnauthenticatedTemplate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* const ws = useRef<WebSocket | null>(null);
useEffect(() => {
  if (
    data != null &&
    data.user != null &&
    data.user.groups != null &&
    selectedGroup === '' &&
    data.user.groups.length > 0
  ) {
    if (data.user.groups[0].groupId != null) {
      dispatch(selectGroup(data.user?.groups[0]?.groupId));
    }
  }

  const connectToWebsocket = async (): Promise<void> => {
    const account = msalInstance.getAllAccounts();
    if (account.length > 0) {
      const response = await msalInstance.acquireTokenSilent({
        ...tokenRequest,
        account: account[0]
      });

      if (selectedGroup !== '') {
        ws.current = new WebSocket(
          `${process.env.REACT_APP_NOTIFICATION_URL}/notifications?groupId=${selectedGroup}&access_token=${response.accessToken}`
        );

        ws.current.onmessage = (message: MessageEvent) => {
          const event: WebsocketMessage = JSON.parse(message.data);

          switch (event.sensorType) {
            case 'SENSOR_TYPE_TRAP':
              dispatch(enhancedAlarmsApi.util.invalidateTags(['Alarms']));
              dispatch(
                enhancedCheckpointApi.util.invalidateTags([event.sensorType])
              );
              break;
            case 'SENSOR_TYPE_TEMP':
              dispatch(enhancedAlarmsApi.util.invalidateTags(['Alarms']));
              dispatch(
                enhancedCheckpointApi.util.invalidateTags([event.sensorType])
              );
              break;
            case 'SENSOR_TYPE_CARBON_DIOXIDE':
              dispatch(enhancedAlarmsApi.util.invalidateTags(['Alarms']));
              dispatch(
                enhancedCheckpointApi.util.invalidateTags([event.sensorType])
              );
              break;
            case 'SENSOR_TYPE_DOOR':
              dispatch(enhancedAlarmsApi.util.invalidateTags(['Alarms']));
              dispatch(
                enhancedCheckpointApi.util.invalidateTags([event.sensorType])
              );
              break;
            default:
              console.info('unknown sensorType');
          }
        };

        ws.current.onerror = (e) => {
          console.log(e);
        };
      }
    }
  };
  try {
    if (selectedGroup != null && selectedGroup !== '') connectToWebsocket();
  } catch (e) {
    console.log(e);
  }
}, [dispatch, selectedGroup, data]); */
