/* eslint-disable react/no-unstable-nested-components */
import React, { ReactElement } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useIdentityServiceGetUserInfoQuery } from './api/enhanced/enhancedIdentity';
import LoadingSpinner from './components/spinner/loadingSpinner';
import Layout from './pages/layout/layout';
import Dashboard from './features/dashboard/pages/dashboard';
import BuildingOverview from './features/buildings/pages/buildingOverview';
import TrapDetailPage from './features/checkpoints/pages/details/traps/trapDetailPage';
import TemperatureDetailPage from './features/checkpoints/pages/details/temperature/temperatureDetailPage';
import CarbonDioxideDetailPage from './features/checkpoints/pages/details/carbonDioxide/carbonDioxideDetailPage';
import DoorDetailPage from './features/checkpoints/pages/details/doors/doorDetailPage';
import AnalogTrapDetailPage from './features/checkpoints/pages/details/traps/analogTrapDetailPage';
import AdministrationPage from './features/administration/pages/administrationPage';
import BuildingManagement from './features/buildings/pages/buildingManagement';
import FloorPlanManagement from './features/areas/pages/floorPlanManagement';
import Notifications from './features/notifications/pages/notifications';
import AlarmPage from './features/alarms/pages/alarmPage';
import AlarmOverview from './features/alarms/pages/alarmOverview';
import RootGroupWrapper from './features/groups/pages/rootGroupWrapper';
import Reports from './features/reports/pages/reports';
import FalseAlarmsNotificationPage from './features/notifications/pages/falseAlarmsNotificationPage';
import GroupsAdminPage from './features/groups/pages/groupsAdminPage';

function App(): ReactElement {
  const { instance, accounts } = useMsal();

  const { data, isFetching, isLoading, isError } =
    useIdentityServiceGetUserInfoQuery(
      accounts.length > 0 && accounts[0].localAccountId != null
        ? { userId: accounts[0].localAccountId }
        : skipToken
    );

  if (isError) {
    instance.logoutRedirect({
      postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI
    });
  }

  if (isLoading || isFetching) {
    return (
      <div className="bg-gray-100 h-full min-h-screen flex items-center justify-center">
        <LoadingSpinner className="h-20" />
      </div>
    );
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootGroupWrapper />}>
        <Route path=":groupId" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route
            path="building/:buildingId"
            element={<BuildingOverview />}
            handle={{
              crumb: (): { name: string; steps: number }[] => {
                return [
                  {
                    name: 'Dashboard',
                    steps: -1
                  },
                  {
                    name: 'Gebäude',
                    steps: 0
                  }
                ];
              }
            }}
          />
          <Route
            path="building/:buildingId/checkpoints/:checkpointId/trap"
            element={<TrapDetailPage />}
            handle={{
              crumb: (): { name: string; steps: number }[] => {
                return [
                  {
                    name: 'Dashboard',
                    steps: -2
                  },
                  {
                    name: 'Gebäude',
                    steps: -1
                  },
                  {
                    name: 'Sensordetails',
                    steps: 0
                  }
                ];
              }
            }}
          />
          <Route
            path="building/:buildingId/checkpoints/:checkpointId/temperature"
            element={<TemperatureDetailPage />}
            handle={{
              crumb: (): { name: string; steps: number }[] => {
                return [
                  {
                    name: 'Dashboard',
                    steps: -2
                  },
                  {
                    name: 'Gebäude',
                    steps: -1
                  },
                  {
                    name: 'Sensordetails',
                    steps: 0
                  }
                ];
              }
            }}
          />
          <Route
            path="building/:buildingId/checkpoints/:checkpointId/carbondioxide"
            element={<CarbonDioxideDetailPage />}
            handle={{
              crumb: (): { name: string; steps: number }[] => {
                return [
                  {
                    name: 'Dashboard',
                    steps: -2
                  },
                  {
                    name: 'Gebäude',
                    steps: -1
                  },
                  {
                    name: 'Sensordetails',
                    steps: 0
                  }
                ];
              }
            }}
          />
          <Route
            path="building/:buildingId/checkpoints/:checkpointId/door"
            element={<DoorDetailPage />}
            handle={{
              crumb: (): { name: string; steps: number }[] => {
                return [
                  {
                    name: 'Dashboard',
                    steps: -2
                  },
                  {
                    name: 'Gebäude',
                    steps: -1
                  },
                  {
                    name: 'Sensordetails',
                    steps: 0
                  }
                ];
              }
            }}
          />
          <Route
            path="building/:buildingId/checkpoints/:checkpointId/analogtrap"
            element={<AnalogTrapDetailPage />}
            handle={{
              crumb: (): { name: string; steps: number }[] => {
                return [
                  {
                    name: 'Dashboard',
                    steps: -2
                  },
                  {
                    name: 'Gebäude',
                    steps: -1
                  },
                  {
                    name: 'Sensordetails',
                    steps: 0
                  }
                ];
              }
            }}
          />
          <Route path="reports" element={<Reports />} />
          <Route path="notifications" element={<Notifications />} />
          <Route
            path="notifications/building/:buildingId"
            element={<FalseAlarmsNotificationPage />}
            handle={{
              crumb: (): { name: string; steps: number }[] => {
                return [
                  {
                    name: 'Benachrichtigungen',
                    steps: -1
                  },
                  {
                    name: 'Auslösungen mit Fang',
                    steps: 0
                  }
                ];
              }
            }}
          />
          {data != null && data.user != null && data.user.isAdmin && (
            <Route path="alarms" element={<AlarmPage />} />
          )}
          {data != null && data.user != null && data.user.isAdmin && (
            <Route
              path="alarms/building/:buildingId"
              element={<AlarmOverview />}
              handle={{
                crumb: (): { name: string; steps: number }[] => {
                  return [
                    {
                      name: 'Gebäude',
                      steps: -1
                    },
                    {
                      name: 'Alarme',
                      steps: 0
                    }
                  ];
                }
              }}
            />
          )}
          {data != null && data.user != null && data.user.isAdmin && (
            <Route path="administration" element={<AdministrationPage />} />
          )}
          {data != null && data.user != null && data.user.isAdmin && (
            <Route
              path="administration/groups/:groupId"
              element={<GroupsAdminPage />}
              handle={{
                crumb: (): { name: string; steps: number }[] => {
                  return [
                    {
                      name: 'Administration',
                      steps: -1
                    },
                    {
                      name: 'Kundenverwaltung',
                      steps: 0
                    }
                  ];
                }
              }}
            />
          )}
          {data != null && data.user != null && data.user.isAdmin && (
            <Route
              path="administration/groups/:groupId/buildings/:buildingId"
              element={<BuildingManagement />}
              handle={{
                crumb: (): { name: string; steps: number }[] => {
                  return [
                    {
                      name: 'Administration',
                      steps: -2
                    },
                    {
                      name: 'Kundenverwaltung',
                      steps: -1
                    },
                    {
                      name: 'Gebäudedetails',
                      steps: 0
                    }
                  ];
                }
              }}
            />
          )}
          {data != null && data.user != null && data.user.isAdmin && (
            <Route
              path="administration/groups/:groupId/buildings/:buildingId/areas/:areaId"
              element={<FloorPlanManagement />}
              handle={{
                crumb: (): { name: string; steps: number }[] => {
                  return [
                    {
                      name: 'Administration',
                      steps: -3
                    },
                    {
                      name: 'Gebäude',
                      steps: -2
                    },
                    {
                      name: 'Gebäudedetails',
                      steps: -1
                    },
                    {
                      name: 'Bereich',
                      steps: 0
                    }
                  ];
                }
              }}
            />
          )}
        </Route>
      </Route>
    ),
    { basename: process.env.REACT_APP_ROUTER_BASENAME }
  );

  return <RouterProvider router={router} />;
}

export default App;
