import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useMemo, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { useMsal } from '@azure/msal-react';
import { FiDownload, FiTrash } from 'react-icons/fi';
import { skipToken } from '@reduxjs/toolkit/query';
import TableActionButton from '../../../components/tables/tableActionButton';
import { V1Alpha1DailyReport } from '../../../api/gen/reports';
import { useBuildingServiceListBuildingsQuery } from '../../../api/enhanced/enhancedBuildings';
import { V1Alpha1Building } from '../../../api/gen/buildings';
import RowCell from '../../../components/tables/rowCell';
import DateTableLabel from '../../../components/date/dateTableLabel';
import DataTable from '../../../components/tables/dataTable';
import useDownloadReportHandler from '../../../hooks/downloadReportHook';
import { useDailyReportServiceListReportsQuery } from '../../../api/enhanced/enhancedReports';
import { useIdentityServiceGetUserInfoQuery } from '../../../api/gen/identity';

interface TrapReportsTableProps {
  groupId: string;
  deleteReportHandler: (
    buildingId: string,
    reportId: string,
    isOpen: boolean
  ) => void;
  editReportHandler: (
    buildingId: string,
    reportId: string,
    isOpen: boolean
  ) => void;
}

function DailyTrapReportTable({
  groupId,
  deleteReportHandler,
  editReportHandler
}: TrapReportsTableProps): ReactElement {
  const { setRequestData } = useDownloadReportHandler();

  const { accounts } = useMsal();
  const { data: userData } = useIdentityServiceGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { userId: accounts[0].localAccountId }
      : skipToken
  );

  const { data: buildingData } = useBuildingServiceListBuildingsQuery(
    groupId != null && groupId !== ''
      ? {
          groupId
        }
      : skipToken
  );

  const columns = useMemo<ColumnDef<V1Alpha1DailyReport>[]>(() => {
    const getBuilding = (buildingId: string): string => {
      if (buildingData != null && buildingData.buildings != null) {
        const building = buildingData.buildings.find(
          (currentBuilding: V1Alpha1Building) =>
            currentBuilding.id === buildingId
        );

        if (building != null && building.description != null) {
          return building.description;
        }
      }

      return '';
    };

    interface DetailButtonProps {
      downloadHandler: () => void;
      deleteHandler: () => void;
      editHandler: () => void;
      created: boolean;
      admin?: boolean;
    }

    function getDetailButtons({
      downloadHandler,
      deleteHandler,
      editHandler,
      created,
      admin
    }: DetailButtonProps): ReactElement {
      return (
        <div className="flex flex-row">
          {created && (
            <TableActionButton
              icon={<FiDownload size={20} />}
              onClick={() => downloadHandler()}
            />
          )}
          {admin != null && admin && (
            <TableActionButton
              icon={<MdEdit size={20} />}
              onClick={() => editHandler()}
            />
          )}
          {admin != null && admin && (
            <TableActionButton
              className="bg-red-400"
              icon={<FiTrash size={20} />}
              onClick={() => deleteHandler()}
            />
          )}
        </div>
      );
    }

    return [
      {
        header: 'Beschreibung',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.description,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Gebäude',
        cell: (info) =>
          RowCell({ title: getBuilding(info.getValue<string>()) }),
        accessorFn: (row) => row.buildingId,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Erstellungszeitpunkt',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.updatedAt,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Details',
        cell: (info) =>
          getDetailButtons({
            downloadHandler: () =>
              setRequestData({
                groupId,
                buildingId:
                  info.row.original.buildingId != null
                    ? info.row.original.buildingId
                    : '',
                reportId:
                  info.row.original.reportId != null
                    ? info.row.original.reportId
                    : ''
              }),
            editHandler: () =>
              editReportHandler(
                info.row.original.buildingId != null
                  ? info.row.original.buildingId
                  : '',
                info.row.original.reportId != null
                  ? info.row.original.reportId
                  : '',
                true
              ),
            deleteHandler: () =>
              deleteReportHandler(
                info.row.original.buildingId != null
                  ? info.row.original.buildingId
                  : '',
                info.row.original.reportId != null
                  ? info.row.original.reportId
                  : '',
                true
              ),
            created:
              info.row.original.reportState != null &&
              info.row.original.reportState === 'REPORT_STATE_CREATED',
            admin:
              userData != null && userData.user != null && userData.user.isAdmin
          }),
        accessorFn: (row) => row.reportId,
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];
  }, [
    buildingData,
    userData,
    setRequestData,
    groupId,
    editReportHandler,
    deleteReportHandler
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const { data, isLoading, isFetching, isSuccess, isError } =
    useDailyReportServiceListReportsQuery(
      groupId != null && groupId !== ''
        ? {
            groupId,
            limit: pageSize,
            page: pageIndex
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.totalCount);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.reports ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={isSuccess && (data.reports == null || data.reports.length === 0)}
      pageSize={pageSize}
      table={table}
    />
  );
}

export default DailyTrapReportTable;
