import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { MdEdit } from 'react-icons/md';
import { skipToken } from '@reduxjs/toolkit/query';
import TrapStatePill from '../../../../components/pill/trapStatePill';
import DateTableLabel from '../../../../components/date/dateTableLabel';
import TableActionButton from '../../../../components/tables/tableActionButton';
import DataTable from '../../../../components/tables/dataTable';
import UserComment from '../../components/traps/userComment';
import { useHistoryServiceListHistoryQuery } from '../../../../api/enhanced/enhancedHistory';
import { V1Alpha1HistoryEntry } from '../../../../api/gen/history';

interface HistoryTableProps {
  groupId?: string;
  buildingId?: string;
  checkpointId?: string;
  commentModalStateHandler: (isOpen: boolean, entryId: string) => void;
  onlyStateChanges?: boolean;
}

function HistoryTable({
  groupId,
  buildingId,
  checkpointId,
  commentModalStateHandler,
  onlyStateChanges = false
}: HistoryTableProps): ReactElement {
  const { t } = useTranslation();

  const getComment = (label: string): string => {
    switch (label) {
      case 'serviceCleanup':
        return 'Fehlauslösung bei Reinigung';
      case 'service':
        return 'Fehlauslösung bei Service';
      case 'auditor':
        return 'Fehlauslösung durch Auditor';
      case 'destroyed':
        return 'Fehlauslösung durch Zerstörung';
      default:
        return label;
    }
  };

  const columns = useMemo<ColumnDef<V1Alpha1HistoryEntry>[]>(
    () => [
      {
        header: 'Zustand',
        cell: (info) => TrapStatePill({ state: info.getValue<string>() }),
        accessorFn: (row) => row.trapPayload?.trapState,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Zeitpunkt des Signals',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.timestamp,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Kommentar',
        cell: (info) =>
          UserComment({
            comment: getComment(info.getValue<string>()),
            className: 'px-6'
          }),
        accessorFn: (row) => row.comment,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Bearbeiten',
        accessorFn: (row) => row.id,
        cell: (info) =>
          TableActionButton({
            icon: <MdEdit size={20} />,
            onClick: () => {
              commentModalStateHandler(true, info.getValue<string>());
            }
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ],
    [commentModalStateHandler]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const paramsValid =
    checkpointId != null &&
    groupId != null &&
    buildingId != null &&
    groupId !== '';

  const { data, isLoading, isFetching, isSuccess, isError, error } =
    useHistoryServiceListHistoryQuery(
      paramsValid
        ? {
            checkpointId,
            buildingId,
            groupId,
            sensorType: 'SENSOR_TYPE_TRAP',
            page: pageIndex,
            limit: pageSize,
            onlyStateChanges
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.entries ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <DataTable
      isLoading={isLoading || isFetching}
      isError={isError}
      isEmpty={isSuccess && (data.entries == null || data.entries.length === 0)}
      pageSize={pageSize}
      table={table}
    />
  );
}

export default HistoryTable;
