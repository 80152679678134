import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    offlineAlarmServiceCreateOfflineAlarm: build.mutation<
      OfflineAlarmServiceCreateOfflineAlarmApiResponse,
      OfflineAlarmServiceCreateOfflineAlarmApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/offlinealarms`,
        method: 'POST',
        body: queryArg.v1CreateOfflineAlarmReq
      })
    }),
    offlineAlarmServiceUpdateOfflineAlarm: build.mutation<
      OfflineAlarmServiceUpdateOfflineAlarmApiResponse,
      OfflineAlarmServiceUpdateOfflineAlarmApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/offlinealarms`,
        method: 'PATCH',
        body: queryArg.v1UpdateOfflineAlarmReq
      })
    }),
    offlineAlarmServiceGetOfflineAlarm: build.query<
      OfflineAlarmServiceGetOfflineAlarmApiResponse,
      OfflineAlarmServiceGetOfflineAlarmApiArg
    >({
      query: (queryArg) => ({ url: `/v1/offlinealarms/alarm/${queryArg.id}` })
    }),
    offlineAlarmServiceDeleteOfflineAlarm: build.mutation<
      OfflineAlarmServiceDeleteOfflineAlarmApiResponse,
      OfflineAlarmServiceDeleteOfflineAlarmApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/offlinealarms/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    offlineAlarmServiceListOfflineAlarm: build.query<
      OfflineAlarmServiceListOfflineAlarmApiResponse,
      OfflineAlarmServiceListOfflineAlarmApiArg
    >({
      query: (queryArg) => ({ url: `/v1/offlinealarms/${queryArg.sensorType}` })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as offlineAlarmsApi };
export type OfflineAlarmServiceCreateOfflineAlarmApiResponse =
  /** status 200 A successful response. */ V1CreateOfflineAlarmResp;
export type OfflineAlarmServiceCreateOfflineAlarmApiArg = {
  v1CreateOfflineAlarmReq: V1CreateOfflineAlarmReq;
};
export type OfflineAlarmServiceUpdateOfflineAlarmApiResponse =
  /** status 200 A successful response. */ V1UpdateOfflineAlarmResp;
export type OfflineAlarmServiceUpdateOfflineAlarmApiArg = {
  v1UpdateOfflineAlarmReq: V1UpdateOfflineAlarmReq;
};
export type OfflineAlarmServiceGetOfflineAlarmApiResponse =
  /** status 200 A successful response. */ V1GetOfflineAlarmResp;
export type OfflineAlarmServiceGetOfflineAlarmApiArg = {
  id: string;
};
export type OfflineAlarmServiceDeleteOfflineAlarmApiResponse =
  /** status 200 A successful response. */ V1DeleteOfflineAlarmResp;
export type OfflineAlarmServiceDeleteOfflineAlarmApiArg = {
  id: string;
};
export type OfflineAlarmServiceListOfflineAlarmApiResponse =
  /** status 200 A successful response. */ V1ListOfflineAlarmResp;
export type OfflineAlarmServiceListOfflineAlarmApiArg = {
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
};
export type V1SensorType =
  | 'SENSOR_TYPE_UNSPECIFIED'
  | 'SENSOR_TYPE_TRAP'
  | 'SENSOR_TYPE_TEMP'
  | 'SENSOR_TYPE_CARBON_DIOXIDE'
  | 'SENSOR_TYPE_DOOR'
  | 'SENSOR_TYPE_HUMIDITY';
export type V1OfflineRecipient = {
  id?: string;
  alarmId?: string;
  userId?: string;
  mail?: string;
};
export type V1OfflineAlarm = {
  id?: string;
  description?: string;
  sensorType?: V1SensorType;
  recipients?: V1OfflineRecipient[];
  buildings?: string[];
};
export type V1CreateOfflineAlarmResp = {
  alarm?: V1OfflineAlarm;
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type V1CreateOfflineAlarmReq = {
  alarm?: V1OfflineAlarm;
};
export type V1UpdateOfflineAlarmResp = {
  alarm?: V1OfflineAlarm;
};
export type V1UpdateOfflineAlarmReq = {
  alarm?: V1OfflineAlarm;
};
export type V1GetOfflineAlarmResp = {
  alarm?: V1OfflineAlarm;
};
export type V1DeleteOfflineAlarmResp = object;
export type V1ListOfflineAlarmResp = {
  alarms?: V1OfflineAlarm[];
};
export const {
  useOfflineAlarmServiceCreateOfflineAlarmMutation,
  useOfflineAlarmServiceUpdateOfflineAlarmMutation,
  useOfflineAlarmServiceGetOfflineAlarmQuery,
  useOfflineAlarmServiceDeleteOfflineAlarmMutation,
  useOfflineAlarmServiceListOfflineAlarmQuery
} = injectedRtkApi;
