/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useBuildingServiceUpdateAreaMutation } from '../../../api/enhanced/enhancedBuildings';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';

interface UpdateAreaFormValues {
  description: string;
}

interface UpdateAreaModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  areaId: string;
  groupId: string;
  buildingId: string;
  description: string;
}

function UpdateAreaModal({
  closeHandler,
  isOpen,
  areaId,
  buildingId,
  description,
  groupId
}: UpdateAreaModalProps): ReactElement {
  const methods = useForm<UpdateAreaFormValues>();
  const { t } = useTranslation();

  const [updateArea, { isLoading, isError, isSuccess, reset }] =
    useBuildingServiceUpdateAreaMutation();

  const onSubmit = (data: UpdateAreaFormValues): void => {
    if (
      areaId != null &&
      groupId != null &&
      buildingId != null &&
      description != null
    ) {
      updateArea({
        buildingId,
        groupId,
        areaId,
        body: {
          description: data.description
        }
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      methods.setValue('description', description);
    }

    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [methods, reset, isSuccess, closeHandler, isOpen, description]);

  const close = (): void => {
    closeHandler();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.updateArea.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.updateArea.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('modals.updateArea.description.label')}
              errorMsg={t('modals.updateArea.description.error') as string}
              placeholder={t('modals.updateArea.description.label') as string}
              disabled={isLoading}
              required
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-36 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.updateArea.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default UpdateAreaModal;
