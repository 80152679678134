import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { HiSearch } from 'react-icons/hi';
import { IoMdTrash } from 'react-icons/io';
import { validate as isValidUUID } from 'uuid';
import TableActionButton from '../../../components/tables/tableActionButton';
import { V1Alpha1Checkpoint } from '../../../api/gen/checkpoints';
import RowCell from '../../../components/tables/rowCell';
import {
  enhancedCheckpointApi,
  useCheckpointServiceListCheckpointsForBuildingQuery,
  useCheckpointServiceUpdateCheckpointMutation
} from '../../../api/enhanced/enhancedCheckpoints';
import DataTable from '../../../components/tables/dataTable';

interface TrapDetailsProps {
  buildingId?: string;
  areaId?: string;

  groupId?: string;
}

interface DetailButtonProps {
  editHandler: () => void;
  deleteHandler: () => void;
}

function getDetailButtons({
  editHandler,
  deleteHandler
}: DetailButtonProps): ReactElement {
  return (
    <div className="flex flex-row">
      <TableActionButton
        icon={<HiSearch size={20} />}
        onClick={() => editHandler()}
      />
      <TableActionButton
        className="bg-red-400"
        icon={<IoMdTrash size={20} />}
        onClick={() => deleteHandler()}
      />
    </div>
  );
}

function PlanManagementTrapTable({
  buildingId,
  areaId,
  groupId
}: TrapDetailsProps): ReactElement {
  const [updateCheckpoint] = useCheckpointServiceUpdateCheckpointMutation();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const [sortColumns, setSortColumns] = useState('checkpoint_number:ASC');
  const [sorting, setSorting] = useState<SortingState>([]);
  useEffect(() => {
    let sortingColumns = 'checkpoint_number:ASC';
    if (sorting.length > 0) {
      sorting.forEach((sortCol) => {
        sortingColumns = `${sortCol.id}:${sortCol.desc ? 'DESC' : 'ASC'}`;
      });
    }
    setSortColumns(sortingColumns);
  }, [sorting, setSortColumns]);

  useEffect(() => {
    enhancedCheckpointApi.util.invalidateTags(['SENSOR_TYPE_TRAP']);
    setPagination((state) => ({ ...state, pageIndex: 0 }));
    setSortColumns('checkpoint_number:ASC');
  }, [areaId, pageSize]);

  const columns = useMemo<ColumnDef<V1Alpha1Checkpoint>[]>(
    () => [
      {
        header: 'Prüfpunkt',
        id: 'checkpoint_number',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.checkpointNumber,
        footer: (props) => props.column.id
      },
      {
        header: 'Beschreibung',
        accessorFn: (row) => row.description,
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Bereich zugewiesen',
        accessorFn: (row) => row.areaId,
        cell: (info) =>
          RowCell({
            title:
              info.getValue() !== '' &&
              info.getValue() != null &&
              isValidUUID(info.getValue() as string)
                ? 'Ja'
                : 'Nein'
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Marker platziert',
        accessorFn: (row) => row.latitude,
        cell: (info) =>
          RowCell({
            title:
              info.row.original.longitude != null &&
              info.row.original.latitude != null &&
              info.row.original.longitude !== 0 &&
              info.row.original.latitude !== 0
                ? 'Ja'
                : 'Nein'
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Details',
        accessorFn: (row) => row.id,
        cell: (info) =>
          getDetailButtons({
            editHandler: () => console.log('test'),
            deleteHandler: () => {
              if (
                info.row.original.id != null &&
                info.row.original.buildingId != null &&
                info.row.original.groupId != null
              ) {
                updateCheckpoint({
                  buildingId: info.row.original.buildingId,
                  groupId: info.row.original.groupId,
                  sensorType: 'SENSOR_TYPE_TRAP',
                  checkpointId: info.row.original.id,
                  checkpointServiceUpdateCheckpointBody: {
                    updateLocation: true,
                    checkpoint: {
                      latitude: 0,
                      longitude: 0
                    }
                  }
                });
              }
            }
          }),
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ],
    [updateCheckpoint]
  );

  const { data, isLoading, isSuccess, isError, error } =
    useCheckpointServiceListCheckpointsForBuildingQuery(
      buildingId != null && areaId != null && groupId != null && groupId !== ''
        ? {
            groupId,
            buildingId,
            areaId,
            limit: pageSize,
            page: pageIndex,
            includeUnassigned: true,
            sensorType: 'SENSOR_TYPE_TRAP',
            sortBy: sortColumns
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.checkpoints ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: { pagination, sorting },
    manualPagination: true,
    manualSorting: true
  });

  return (
    <DataTable
      isLoading={isLoading}
      isError={isError}
      isEmpty={
        isSuccess && (data.checkpoints == null || data.checkpoints.length === 0)
      }
      pageSize={pageSize}
      table={table}
    />
  );
}

export default PlanManagementTrapTable;
