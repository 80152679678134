import { api } from '../api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    dailyReportServiceCreateDailyReport: build.mutation<
      DailyReportServiceCreateDailyReportApiResponse,
      DailyReportServiceCreateDailyReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/reports/daily`,
        method: 'POST',
        body: queryArg.dailyReportServiceCreateDailyReportBody
      })
    }),
    dailyReportServiceDeleteDailyReport: build.mutation<
      DailyReportServiceDeleteDailyReportApiResponse,
      DailyReportServiceDeleteDailyReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/reports/${queryArg.reportId}/daily`,
        method: 'DELETE'
      })
    }),
    dailyReportServiceUpdateReportMetadata: build.mutation<
      DailyReportServiceUpdateReportMetadataApiResponse,
      DailyReportServiceUpdateReportMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/buildings/${queryArg.buildingId}/reports/${queryArg.reportId}/daily`,
        method: 'PATCH',
        body: queryArg.dailyReportServiceUpdateReportMetadataBody
      })
    }),
    dailyReportServiceListReports: build.query<
      DailyReportServiceListReportsApiResponse,
      DailyReportServiceListReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/reports/daily`,
        params: { limit: queryArg.limit, page: queryArg.page }
      })
    }),
    dailyReportServiceGetDailyReport: build.query<
      DailyReportServiceGetDailyReportApiResponse,
      DailyReportServiceGetDailyReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/groups/${queryArg.groupId}/reports/${queryArg.reportId}/daily`
      })
    }),
    reportServiceCreateReport: build.mutation<
      ReportServiceCreateReportApiResponse,
      ReportServiceCreateReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/owners/${queryArg.groupId}/buildings/${queryArg.buildingId}/reports`,
        method: 'POST',
        body: queryArg.reportServiceCreateReportBody
      })
    }),
    reportServiceGetReport: build.query<
      ReportServiceGetReportApiResponse,
      ReportServiceGetReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/owners/${queryArg.groupId}/buildings/${queryArg.buildingId}/reports/${queryArg.reportId}`
      })
    }),
    reportServiceDeleteReport: build.mutation<
      ReportServiceDeleteReportApiResponse,
      ReportServiceDeleteReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/owners/${queryArg.groupId}/buildings/${queryArg.buildingId}/reports/${queryArg.reportId}`,
        method: 'DELETE'
      })
    }),
    reportServiceDownloadPdf: build.query<
      ReportServiceDownloadPdfApiResponse,
      ReportServiceDownloadPdfApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/owners/${queryArg.groupId}/buildings/${queryArg.buildingId}/reports/${queryArg.reportId}/pdf`
      })
    }),
    reportServiceListReports: build.query<
      ReportServiceListReportsApiResponse,
      ReportServiceListReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/owners/${queryArg.groupId}/reports`,
        params: {
          limit: queryArg.limit,
          page: queryArg.page,
          reportType: queryArg.reportType
        }
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as reportApi };
export type DailyReportServiceCreateDailyReportApiResponse =
  /** status 200 A successful response. */ V1Alpha1CreateDailyReportResponse;
export type DailyReportServiceCreateDailyReportApiArg = {
  groupId: string;
  buildingId: string;
  dailyReportServiceCreateDailyReportBody: DailyReportServiceCreateDailyReportBody;
};
export type DailyReportServiceDeleteDailyReportApiResponse =
  /** status 200 A successful response. */ V1Alpha1DeleteDailyReportResponse;
export type DailyReportServiceDeleteDailyReportApiArg = {
  groupId: string;
  buildingId: string;
  reportId: string;
};
export type DailyReportServiceUpdateReportMetadataApiResponse =
  /** status 200 A successful response. */ V1Alpha1UpdateReportMetadataResponse;
export type DailyReportServiceUpdateReportMetadataApiArg = {
  groupId: string;
  buildingId: string;
  reportId: string;
  dailyReportServiceUpdateReportMetadataBody: DailyReportServiceUpdateReportMetadataBody;
};
export type DailyReportServiceListReportsApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListDailyReportsResponse;
export type DailyReportServiceListReportsApiArg = {
  groupId: string;
  limit?: number;
  page?: number;
};
export type DailyReportServiceGetDailyReportApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetDailyReportResponse;
export type DailyReportServiceGetDailyReportApiArg = {
  groupId: string;
  reportId: string;
};
export type ReportServiceCreateReportApiResponse =
  /** status 200 A successful response. */ V1Alpha1CreateReportResponse;
export type ReportServiceCreateReportApiArg = {
  groupId: string;
  buildingId: string;
  reportServiceCreateReportBody: ReportServiceCreateReportBody;
};
export type ReportServiceGetReportApiResponse =
  /** status 200 A successful response. */ V1Alpha1GetReportResponse;
export type ReportServiceGetReportApiArg = {
  groupId: string;
  buildingId: string;
  reportId: string;
};
export type ReportServiceDeleteReportApiResponse =
  /** status 200 A successful response. */ V1Alpha1DeleteReportResponse;
export type ReportServiceDeleteReportApiArg = {
  groupId: string;
  buildingId: string;
  reportId: string;
};
export type ReportServiceDownloadPdfApiResponse =
  /** status 200 A successful response. */ V1Alpha1DownloadPdfResponse;
export type ReportServiceDownloadPdfApiArg = {
  groupId: string;
  buildingId: string;
  reportId: string;
};
export type ReportServiceListReportsApiResponse =
  /** status 200 A successful response. */ V1Alpha1ListReportsResponse;
export type ReportServiceListReportsApiArg = {
  groupId: string;
  limit?: number;
  page?: number;
  reportType?:
    | 'REPORT_TYPE_UNSPECIFIED'
    | 'REPORT_TYPE_TRAP'
    | 'REPORT_TYPE_INSPECTION'
    | 'REPORT_TYPE_TEMPERATURE'
    | 'REPORT_TYPE_TREND_ANALYTICS'
    | 'REPORT_TYPE_HUMIDITY';
};
export type V1Alpha1ReportState =
  | 'REPORT_STATE_UNSPECIFIED'
  | 'REPORT_STATE_ENQUEUED'
  | 'REPORT_STATE_CREATING'
  | 'REPORT_STATE_CREATED'
  | 'REPORT_STATE_FAILED';
export type V1Alpha1Recipients = {
  userId?: string;
  mail?: string;
};
export type V1Alpha1DailyReport = {
  reportId?: string;
  groupId?: string;
  buildingId?: string;
  description?: string;
  reportState?: V1Alpha1ReportState;
  recipients?: V1Alpha1Recipients[];
  createdAt?: string;
  updatedAt?: string;
};
export type V1Alpha1CreateDailyReportResponse = {
  report?: V1Alpha1DailyReport;
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type DailyReportServiceCreateDailyReportBody = {
  description?: string;
  recipients?: V1Alpha1Recipients[];
};
export type V1Alpha1DeleteDailyReportResponse = {
  reportId?: string;
};
export type V1Alpha1UpdateReportMetadataResponse = {
  dailyReport?: V1Alpha1DailyReport;
};
export type V1Alpha1RecipientUpdates = {
  userId?: string;
  mail?: string;
  selected?: boolean;
};
export type DailyReportServiceUpdateReportMetadataBody = {
  description?: string;
  recipientUpdates?: V1Alpha1RecipientUpdates[];
};
export type V1Alpha1ListDailyReportsResponse = {
  limit?: number;
  page?: number;
  totalCount?: string;
  reports?: V1Alpha1DailyReport[];
};
export type V1Alpha1GetDailyReportResponse = {
  dailyReport?: V1Alpha1DailyReport;
};
export type V1Alpha1ReportType =
  | 'REPORT_TYPE_UNSPECIFIED'
  | 'REPORT_TYPE_TRAP'
  | 'REPORT_TYPE_INSPECTION'
  | 'REPORT_TYPE_TEMPERATURE'
  | 'REPORT_TYPE_TREND_ANALYTICS'
  | 'REPORT_TYPE_HUMIDITY';
export type V1Alpha1CreateReportResponse = {
  reportId?: string;
  type?: V1Alpha1ReportType;
  startTimestamp?: string;
  endTimestamp?: string;
  year?: number;
  buildingId?: string;
  groupId?: string;
  createdAt?: string;
  state?: V1Alpha1ReportState;
};
export type ReportServiceCreateReportBody = {
  type?: V1Alpha1ReportType;
  startTimestamp?: string;
  endTimestamp?: string;
  checkpointId?: string;
  year?: number;
};
export type V1Alpha1Report = {
  reportId?: string;
  type?: V1Alpha1ReportType;
  startTimestamp?: string;
  endTimestamp?: string;
  buildingId?: string;
  groupId?: string;
  createdAt?: string;
  state?: V1Alpha1ReportState;
  year?: number;
};
export type V1Alpha1GetReportResponse = {
  report?: V1Alpha1Report;
};
export type V1Alpha1DeleteReportResponse = {
  reportId?: string;
};
export type V1Alpha1DownloadPdfResponse = {
  data?: string;
};
export type V1Alpha1ListReportsResponse = {
  limit?: number;
  page?: number;
  totalCount?: string;
  reports?: V1Alpha1Report[];
};
export const {
  useDailyReportServiceCreateDailyReportMutation,
  useDailyReportServiceDeleteDailyReportMutation,
  useDailyReportServiceUpdateReportMetadataMutation,
  useDailyReportServiceListReportsQuery,
  useDailyReportServiceGetDailyReportQuery,
  useReportServiceCreateReportMutation,
  useReportServiceGetReportQuery,
  useReportServiceDeleteReportMutation,
  useReportServiceDownloadPdfQuery,
  useReportServiceListReportsQuery
} = injectedRtkApi;
