import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface PeriodLabelProps {
  startTimestamp?: string;
  endTimestamp?: string;
  className?: string;
}

function PeriodLabel({
  startTimestamp,
  endTimestamp,
  className = ''
}: PeriodLabelProps): ReactElement {
  const { t } = useTranslation();

  if (
    startTimestamp === null ||
    startTimestamp === '' ||
    startTimestamp === undefined
  ) {
    return <div className={className}>{t('date.noValue')}</div>;
  }

  if (
    endTimestamp === null ||
    endTimestamp === '' ||
    endTimestamp === undefined
  ) {
    return <div className={className}>{t('date.noValue')}</div>;
  }

  const parsedStartTimestamp = Date.parse(startTimestamp.replace('CEST', ''));
  const parsedEndTimestamp = Date.parse(endTimestamp.replace('CEST', ''));
  if (
    !Number.isNaN(parsedStartTimestamp) &&
    !Number.isNaN(parsedEndTimestamp)
  ) {
    const startDate = new Date(parsedStartTimestamp);
    const endDate = new Date(parsedEndTimestamp);
    return (
      <div className={`flex flex-col ${className}`}>
        <div>{`${startDate.toLocaleDateString(
          'de-DE'
        )} - ${endDate.toLocaleDateString('de-DE')}`}</div>
      </div>
    );
  }
  return <div className={className}>{t('date.invalid')}</div>;
}

export default PeriodLabel;
