/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactElement, ReactNode } from 'react';
import { GrClose } from 'react-icons/gr';
import IconButton from '../buttons/iconbutton';

interface ModalProps {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  closeHandler: () => void;
}

function Modal({
  isOpen = false,
  title,
  children,
  closeHandler
}: ModalProps): ReactElement {
  return (
    <div
      aria-hidden="true"
      tabIndex={-1}
      className={`overflow-x-hidden bg-black bg-opacity-50 backdrop-blur-sm fixed flex justify-center items-center z-50 w-full inset-0 h-modal  ${
        isOpen ? '' : 'hidden'
      }`}
    >
      <div className="flex p-4 w-full max-w-xl h-full justify-center items-center">
        <div className="w-full bg-white rounded-lg shadow flex flex-col max-h-full">
          <div className="w-full flex flex-col items-end">
            <IconButton
              className="w-12 h-12 "
              icon={<GrClose size={20} />}
              onClick={() => {
                closeHandler();
              }}
            />
          </div>
          <div className="px-6 items-start font-semibold text-2xl">{title}</div>
          <div className="px-6 py-4 items-start flex flex-col overflow-y-auto">
            <div className="w-full">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
