/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { V1Alpha1Building } from '../../../api/gen/buildings';
import { useBuildingServiceUpdateBuildingMutation } from '../../../api/enhanced/enhancedBuildings';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';

interface UpdateBuildingFormValues {
  description: string;
  street: string;
  houseNumber: string;
  city: string;
  zipCode: string;
}

interface UpdateBuildingModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  groupId: string;
  building: V1Alpha1Building;
}

function UpdateBuildingModal({
  closeHandler,
  isOpen,
  building,
  groupId
}: UpdateBuildingModalProps): ReactElement {
  const methods = useForm<UpdateBuildingFormValues>();
  const { t } = useTranslation();

  const [updateBuilding, { isLoading, isError, isSuccess, reset }] =
    useBuildingServiceUpdateBuildingMutation();

  const onSubmit = (data: UpdateBuildingFormValues): void => {
    const body: V1Alpha1Building = {
      id: building.id,
      description: data.description,
      street: data.street,
      houseNumber: data.houseNumber,
      city: data.city,
      zipCode: data.zipCode,
      groupId: building?.groupId
    };

    if (building.id != null && body.groupId != null && groupId != null) {
      updateBuilding({
        buildingId: building.id,
        groupId,
        body: { building: body }
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      methods.setValue('description', building?.description ?? '');
      methods.setValue('street', building?.street ?? '');
      methods.setValue('houseNumber', building?.houseNumber ?? '');
      methods.setValue('city', building?.city ?? '');
      methods.setValue('zipCode', building?.zipCode ?? '');
    }

    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [methods, reset, isSuccess, closeHandler, isOpen, building]);

  const close = (): void => {
    closeHandler();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createBuilding.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('modals.createBuilding.description.label')}
              errorMsg={t('modals.createBuilding.description.error') as string}
              placeholder={
                t('modals.createBuilding.description.label') as string
              }
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="street"
              label={t('modals.createBuilding.address.label')}
              errorMsg={t('modals.createBuilding.address.error') as string}
              placeholder={t('modals.createBuilding.address.label') as string}
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="houseNumber"
              label={t('modals.createBuilding.houseNumber.label')}
              errorMsg={t('modals.createBuilding.houseNumber.error') as string}
              placeholder={
                t('modals.createBuilding.houseNumber.label') as string
              }
              disabled={isLoading}
              required
            />

            <FormTextInput
              id="city"
              label={t('modals.createBuilding.city.label')}
              errorMsg={t('modals.createBuilding.city.error') as string}
              placeholder={t('modals.createBuilding.city.label') as string}
              disabled={isLoading}
              required
            />
            <FormTextInput
              id="zipCode"
              label={t('modals.createBuilding.postCode.label')}
              errorMsg={t('modals.createBuilding.postCode.error') as string}
              placeholder={t('modals.createBuilding.postCode.label') as string}
              disabled={isLoading}
              required
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                  type="submit"
                >
                  {t('modals.save')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default UpdateBuildingModal;
