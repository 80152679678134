import React, { ReactElement } from 'react';
import Pill from './pill';

interface DoorPillProps {
  state?: number;
  className?: string;
  isLoading?: boolean;
}

export function DoorPill({
  state,
  className = 'ml-5 w-24',
  isLoading = false
}: DoorPillProps): ReactElement {
  if (isLoading) {
    return (
      <Pill
        className={` flex items-center justify-center text-white ${className} animate-pulse`}
      />
    );
  }

  return state != null && state === 0 ? (
    <Pill
      label="Geschlossen"
      className={`bg-green-500 flex items-center justify-center text-white ${className}`}
    />
  ) : (
    <Pill
      label={state == null ? 'Fehler' : 'Offen'}
      className={`bg-red-500 flex items-center justify-center text-white  ${className}`}
    />
  );
}

export default DoorPill;
