import React, { ReactElement, useEffect, useState } from 'react';
import Pill from './pill';

interface OnlineStatePillProps {
  state?: boolean;
  checkpointId?: string;
  timestamp?: string;
  className?: string;
  isLoading?: boolean;
}

export function OnlineStatePill({
  state,
  checkpointId,
  timestamp,
  className = 'ml-5 w-24',
  isLoading = false
}: OnlineStatePillProps): ReactElement {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (timestamp != null) {
      const parsedTimestamp = Date.parse(timestamp);
      const now = Date.now();

      if (Math.abs(now - parsedTimestamp) / 36e5 > 200) {
        setIsValid(false);
      }
    }
  }, [timestamp, checkpointId]);

  if (isLoading) {
    return (
      <Pill
        className={` flex items-center justify-center text-white ${className} animate-pulse`}
      />
    );
  }

  return state && isValid ? (
    <Pill
      label="Online"
      className={`bg-green-500 flex items-center justify-center text-white ${className}`}
    />
  ) : (
    <Pill
      label="Offline"
      className={`bg-red-500 flex items-center justify-center text-white  ${className}`}
    />
  );
}

export default OnlineStatePill;
