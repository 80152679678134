import React, { ReactElement } from 'react';

interface PillProps {
  label?: string;
  className?: string;
}

function Pill({
  label = '',
  className = 'bg-gray-200 text-gray-800'
}: PillProps): ReactElement {
  return (
    <div
      className={`py-2 text-sm font-medium rounded-full text-center  ${className}`}
    >
      {label}
    </div>
  );
}

export default Pill;
