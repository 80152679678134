import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { IoMdAdd } from 'react-icons/io';
import {
  useIdentityServiceGetUserInfoQuery,
  useIdentityServiceListUsersQuery
} from '../../../../api/enhanced/enhancedIdentity';
import {
  V1Alpha1Inspection,
  V1Alpha1InspectionType
} from '../../../../api/gen/inspections';
import { useInspectionServiceListInspectionQuery } from '../../../../api/enhanced/enhancedInspection';
import DateTableLabel from '../../../../components/date/dateTableLabel';
import RowCell from '../../../../components/tables/rowCell';
import LabelButton from '../../../../components/buttons/labelButton';
import DataTable from '../../../../components/tables/dataTable';

interface InspectionTableProps {
  createInspectionModalHandler: (isOpen: boolean) => void;
  checkpointId?: string;
  groupId?: string;
  buildingId?: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_ANALOG_TRAP';
}

function InspectionTable({
  checkpointId,
  createInspectionModalHandler,
  groupId,
  buildingId,
  sensorType
}: InspectionTableProps): ReactElement {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const { data: activeUserData } = useIdentityServiceGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { userId: accounts[0].localAccountId }
      : skipToken
  );

  const {
    data: userData,
    isLoading: userLoading,
    isSuccess: userSuccess
  } = useIdentityServiceListUsersQuery({
    groupId,
    limit: 200,
    page: 0
  });

  const columns = useMemo<ColumnDef<V1Alpha1Inspection>[]>(() => {
    const getUserName = (userId: string): string => {
      if (
        !userLoading &&
        userSuccess &&
        userData.users != null &&
        userData.users.length > 0
      ) {
        const user = userData.users.find(
          (currentUser) => currentUser.userId === userId
        );

        if (user != null && user.userId != null && user.userName != null) {
          return user.userName;
        }
        return userId;
      }

      return userId;
    };

    return [
      {
        header: 'Zeitpunkt',
        cell: (info) =>
          DateTableLabel({
            timestamp: info.getValue<string>(),
            className: 'px-6'
          }),
        accessorFn: (row) => row.timestamp,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Prüfer',
        cell: (info) =>
          RowCell({ title: getUserName(info.getValue<string>()) }),
        accessorFn: (row) => row.inspector,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Art der Prüfung',
        cell: (info) =>
          RowCell({
            title: t(
              `trapDetails.inspection.type.${info.getValue<V1Alpha1InspectionType>()}`
            )
          }),
        accessorFn: (row) => row.inspectionType,
        footer: (props) => props.column.id,
        enableSorting: false
      },
      {
        header: 'Kommentar',
        cell: (info) => RowCell({ title: info.getValue<string>() }),
        accessorFn: (row) => row.comment,
        footer: (props) => props.column.id,
        enableSorting: false
      }
    ];
  }, [t, userData, userLoading, userSuccess]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });

  const queryValid =
    buildingId != null &&
    checkpointId != null &&
    groupId != null &&
    groupId !== '';

  const { data, isLoading, isFetching, isSuccess, isError } =
    useInspectionServiceListInspectionQuery(
      queryValid
        ? {
            groupId,
            buildingId,
            checkpointId,
            limit: pageSize,
            page: pageIndex,
            sensorType
          }
        : skipToken
    );

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageSize, pageIndex]
  );

  const total = Number(data?.total);
  const limit = data?.limit;
  const pageCount =
    total != null && limit != null ? Math.ceil(total / limit) : -1;

  const table = useReactTable({
    data: data?.inspections ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    manualPagination: true
  });

  return (
    <div className="flex flex-col ">
      {activeUserData != null &&
        activeUserData.user != null &&
        activeUserData.user.isAdmin && (
          <LabelButton
            label={t('trapDetails.buttons.addInspection')}
            className="bg-gray-200 rounded-lg h-12 w-60 text-gray-600 mb-4"
            icon={<IoMdAdd size={20} />}
            onClick={() => {
              if (checkpointId != null) {
                createInspectionModalHandler(true);
              }
            }}
          />
        )}
      <DataTable
        isLoading={isLoading || isFetching}
        isError={isError}
        isEmpty={
          data != null &&
          data.inspections != null &&
          data.inspections.length === 0
        }
        pageSize={pageSize}
        table={table}
      />
    </div>
  );
}

export default InspectionTable;
