import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCheckpointServiceGetCheckpointQuery } from '../../../../../api/enhanced/enhancedCheckpoints';
import CheckpointDetails from '../../../components/checkpointDetails';
import PageTitle from '../../../../../components/title/pageTitle';
import TrapStatePill from '../../../../../components/pill/trapStatePill';
import OnlineStatePill from '../../../../../components/pill/onlineStatePill';
import CheckpointState from '../../../components/checkpointState';
import TabBar, { TabContent } from '../../../../../components/tabs/tabBar';
import HistoryTable from '../../../tables/traps/historyTable';
import CommentModal from '../../../modals/commentModal';
import CreateInspectionModal from '../../../modals/createInspectionModal';
import InspectionTable from '../../../tables/traps/inspectionTable';

function TrapDetailPage(): ReactElement {
  const { t } = useTranslation();
  const { groupId, buildingId, checkpointId } = useParams();

  const [commentModalState, setCommentModalState] = useState({
    isOpen: false,
    entryId: ''
  });
  const [createInspectionState, setCreateInspectionState] = useState(false);

  const tabContent: TabContent[] = [
    {
      key: 0,
      tabLabel: t('trapDetails.tabs.stateChanges'),
      content: (
        <HistoryTable
          commentModalStateHandler={(isOpen: boolean, id: string) => {
            setCommentModalState({
              isOpen,
              entryId: id
            });
          }}
          checkpointId={checkpointId}
          groupId={groupId}
          buildingId={buildingId}
          onlyStateChanges
        />
      )
    },
    {
      key: 1,
      tabLabel: t('trapDetails.tabs.history'),
      content: (
        <HistoryTable
          commentModalStateHandler={(isOpen: boolean, id: string) => {
            setCommentModalState({
              isOpen,
              entryId: id
            });
          }}
          checkpointId={checkpointId}
          groupId={groupId}
          buildingId={buildingId}
        />
      )
    },
    {
      key: 2,
      tabLabel: t('trapDetails.tabs.inspection'),
      content: (
        <InspectionTable
          createInspectionModalHandler={(isOpen) =>
            setCreateInspectionState(isOpen)
          }
          sensorType="SENSOR_TYPE_TRAP"
          groupId={groupId}
          buildingId={buildingId}
          checkpointId={checkpointId}
        />
      )
    }
  ];

  const { data, isLoading, isFetching, isError, error, isSuccess } =
    useCheckpointServiceGetCheckpointQuery(
      buildingId != null && checkpointId != null && groupId != null
        ? {
            checkpointId,
            buildingId,
            groupId,
            sensorType: 'SENSOR_TYPE_TRAP'
          }
        : skipToken
    );

  return (
    <div className="flex flex-col px-8 w-full">
      <div className="flex flex-row">
        <PageTitle isLoading={isLoading} title={t('trapDetails.title')} />
        <TrapStatePill
          isLoading={isLoading}
          className="h-10 w-32 ml-5"
          state={data?.checkpoint?.trapPayload?.state}
        />
        <OnlineStatePill
          isLoading={isLoading}
          className="h-10 w-20 ml-2"
          checkpointId={checkpointId}
          state={data?.checkpoint?.loraMetadata?.isOnline}
          timestamp={data?.checkpoint?.loraMetadata?.lastSignal}
        />
      </div>
      {data != null && data.checkpoint != null && (
        <div className="flex lg:flex-row flex-col my-2">
          <CheckpointDetails checkpoint={data?.checkpoint} />
          <CheckpointState checkpoint={data?.checkpoint} />
        </div>
      )}

      <TabBar className="mt-4" tabs={tabContent} />

      {buildingId != null &&
        groupId != null &&
        groupId !== '' &&
        checkpointId != null && (
          <CommentModal
            entryId={commentModalState.entryId}
            buildingId={buildingId}
            groupId={groupId}
            checkpointId={checkpointId}
            sensorType="SENSOR_TYPE_TRAP"
            defaultComments={[
              {
                key: 'serviceCleanup',
                label: 'Fehlauslösung bei Reinigung'
              },
              {
                key: 'service',
                label: 'Fehlauslösung beim Service'
              },
              {
                key: 'auditor',
                label: 'Fehlauslösung durch Auditor'
              },
              {
                key: 'destroyed',
                label: 'Fehlauslösung durch Zerstörung'
              }
            ]}
            isOpen={commentModalState.isOpen}
            closeHandler={() => {
              setCommentModalState({
                isOpen: false,
                entryId: ''
              });
            }}
          />
        )}

      {buildingId != null &&
        groupId != null &&
        groupId !== '' &&
        checkpointId != null && (
          <CreateInspectionModal
            buildingId={buildingId}
            groupId={groupId}
            checkpointId={checkpointId}
            sensorType="SENSOR_TYPE_TRAP"
            isOpen={createInspectionState}
            closeHandler={() => {
              setCreateInspectionState(false);
            }}
          />
        )}
    </div>
  );
}

export default TrapDetailPage;
