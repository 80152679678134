import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { IoMdAdd } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import LabelButton from '../../../components/buttons/labelButton';
import TabBar, { TabContent } from '../../../components/tabs/tabBar';
import PageTitle from '../../../components/title/pageTitle';
import CreateReportModal from '../modals/createReportModal';
import DeleteReportModal from '../modals/deleteReportModal';
import TrapReportTable from '../tables/trapReportTable';
import DailyTrapReportTable from '../tables/dailyTrapReportTable';
import CreateDailyTrapReportModal from '../modals/createDailyTrapReportModal';
import DeleteDailyReportModal from '../modals/deleteDailyReportModal';

import { useIdentityServiceGetUserInfoQuery } from '../../../api/gen/identity';
import InspectionReportTable from '../tables/inspectionReportTable';
import TemperatureReportTable from '../tables/temperatureReportTable';
import TrendAnalyticsReportTable from '../tables/trendAnalyticsReportTable';
import CreateTrendAnalyticsReportModal from '../modals/createTrendAnalyticsReportModal';
import UpdateDailyReportModal from '../modals/updateDailyReportModal';
import CreateCSVExport from '../modals/createCSVExport';
import HumidityReportTable from '../tables/humidityReportTable';

interface CSVModalProps {
  isOpen: boolean;
  csvType: 'CSV_TYPE_TRAPS' | 'CSV_TYPE_TEMPERATURE' | 'CSV_TYPE_DOOR';
}

function Reports(): ReactElement {
  const { t } = useTranslation();
  const { groupId } = useParams();

  const { accounts } = useMsal();
  const { data: userData } = useIdentityServiceGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { userId: accounts[0].localAccountId }
      : skipToken
  );

  const [tabs, setTabs] = useState<TabContent[]>([]);

  const [createReportIsOpen, setCreateReportIsOpen] = useState({
    isOpen: false,
    reportType: ''
  });
  const [createDailyReportIsOpen, setCreateDailyReportIsOpen] = useState(false);

  const [
    createTrendAnalyticsReportIsOpen,
    setCreateTrendAnalyticsReportIsOpen
  ] = useState({
    isOpen: false,
    reportType: ''
  });

  const [deleteReportState, setDeleteReportState] = useState({
    isOpen: false,
    buildingId: '',
    reportId: ''
  });

  const [deleteDailyReportState, setDeleteDailyReportState] = useState({
    isOpen: false,
    buildingId: '',
    reportId: ''
  });

  const [createCSVModal, setCreateCSVModal] = useState<CSVModalProps>({
    isOpen: false,
    csvType: 'CSV_TYPE_TRAPS'
  });

  const [updateDailyReportState, setUpdateDailyReportState] = useState({
    isOpen: false,
    buildingId: '',
    reportId: ''
  });

  useEffect(() => {
    const addButton = (reportType: string): ReactElement => (
      <LabelButton
        label={t('reports.addReport')}
        className="bg-gray-200 rounded-lg h-12 w-52 text-gray-600"
        icon={<IoMdAdd size={20} />}
        onClick={() => setCreateReportIsOpen({ isOpen: true, reportType })}
      />
    );

    const createCSVButton = (
      csvType: 'CSV_TYPE_TRAPS' | 'CSV_TYPE_TEMPERATURE' | 'CSV_TYPE_DOOR'
    ): ReactElement => (
      <LabelButton
        label={t('CSV-Export')}
        className="bg-gray-200 rounded-lg h-12 w-52 text-gray-600"
        icon={<IoMdAdd size={20} />}
        onClick={() => setCreateCSVModal({ isOpen: true, csvType })}
      />
    );

    const addTrendAnalyticsButton = (reportType: string): ReactElement => (
      <LabelButton
        label={t('reports.addReport')}
        className="bg-gray-200 rounded-lg h-12 w-52 text-gray-600"
        icon={<IoMdAdd size={20} />}
        onClick={() =>
          setCreateTrendAnalyticsReportIsOpen({ isOpen: true, reportType })
        }
      />
    );

    const addDailyButton = (): ReactElement => (
      <LabelButton
        label={t('reports.addReport')}
        className="bg-gray-200 rounded-lg h-12 w-52 text-gray-600"
        icon={<IoMdAdd size={20} />}
        onClick={() => setCreateDailyReportIsOpen(true)}
      />
    );

    const createdTabs = [
      {
        key: 0,
        tabLabel: t('features.reports.tabs.trapReport'),
        content: (
          <div>
            <div className="flex flex-col space-y-2">
              {addButton('REPORT_TYPE_TRAP')}
              {userData != null &&
              userData.user != null &&
              (userData.user.isAdmin || userData.user.exportEnabled)
                ? createCSVButton('CSV_TYPE_TRAPS')
                : null}
            </div>
            {groupId != null && (
              <TrapReportTable
                groupId={groupId}
                deleteReportHandler={(
                  buildingId: string,
                  reportId: string,
                  isOpen: boolean
                ) => {
                  setDeleteReportState({ isOpen, buildingId, reportId });
                }}
              />
            )}
          </div>
        )
      },
      {
        key: 1,
        tabLabel: t('features.reports.tabs.dailyReport'),
        content: (
          <div>
            {userData != null &&
              userData.user != null &&
              userData.user.isAdmin &&
              addDailyButton()}
            {groupId != null && (
              <DailyTrapReportTable
                groupId={groupId}
                deleteReportHandler={(
                  buildingId: string,
                  reportId: string,
                  isOpen: boolean
                ) => {
                  setDeleteDailyReportState({ isOpen, buildingId, reportId });
                }}
                editReportHandler={(
                  buildingId: string,
                  reportId: string,
                  isOpen: boolean
                ) => {
                  setUpdateDailyReportState({ isOpen, buildingId, reportId });
                }}
              />
            )}
          </div>
        )
      },
      {
        key: 2,
        tabLabel: t('features.reports.tabs.inspectionReport'),
        content: (
          <div>
            {addButton('REPORT_TYPE_INSPECTION')}
            {groupId != null && (
              <InspectionReportTable
                groupId={groupId}
                deleteReportHandler={(
                  buildingId: string,
                  reportId: string,
                  isOpen: boolean
                ) => {
                  setDeleteReportState({ isOpen, buildingId, reportId });
                }}
              />
            )}
          </div>
        )
      },
      {
        key: 3,
        tabLabel: t('features.reports.tabs.temperatureReport'),
        content: (
          <div>
            <div className="flex flex-col space-y-2">
              {addButton('REPORT_TYPE_TEMPERATURE')}
              {userData != null &&
              userData.user != null &&
              (userData.user.isAdmin || userData.user.exportEnabled)
                ? createCSVButton('CSV_TYPE_TEMPERATURE')
                : null}
            </div>
            {groupId != null && (
              <TemperatureReportTable
                groupId={groupId}
                deleteReportHandler={(
                  buildingId: string,
                  reportId: string,
                  isOpen: boolean
                ) => {
                  setDeleteReportState({ isOpen, buildingId, reportId });
                }}
              />
            )}
          </div>
        )
      },
      {
        key: 4,
        tabLabel: t('features.reports.tabs.analysis'),
        content: (
          <div>
            {addTrendAnalyticsButton('REPORT_TYPE_TREND_ANALYTICS')}
            {groupId != null && (
              <TrendAnalyticsReportTable
                groupId={groupId}
                deleteReportHandler={(
                  buildingId: string,
                  reportId: string,
                  isOpen: boolean
                ) => {
                  setDeleteReportState({ isOpen, buildingId, reportId });
                }}
              />
            )}
          </div>
        )
      },
      {
        key: 5,
        tabLabel: t('features.reports.tabs.humidity'),
        content: (
          <div>
            {addButton('REPORT_TYPE_HUMIDITY')}
            {groupId != null && (
              <HumidityReportTable
                groupId={groupId}
                deleteReportHandler={(
                  buildingId: string,
                  reportId: string,
                  isOpen: boolean
                ) => {
                  setDeleteReportState({ isOpen, buildingId, reportId });
                }}
              />
            )}
          </div>
        )
      }
    ];

    if (
      userData != null &&
      userData.user != null &&
      (userData.user.isAdmin || userData.user.exportEnabled)
    ) {
      createdTabs.push({
        key: 6,
        tabLabel: t('features.reports.tabs.doors'),
        content: (
          <div>
            <div className="flex flex-col space-y-2">
              {userData.user.isAdmin || userData.user.exportEnabled
                ? createCSVButton('CSV_TYPE_DOOR')
                : null}
            </div>
          </div>
        )
      });
    }

    setTabs(createdTabs);
  }, [groupId, t, userData]);

  return (
    <div className="flex flex-col px-8 w-full">
      <PageTitle title={t('reports.title')} />
      <div className="flex flex-col">
        <TabBar tabs={tabs} />
      </div>

      {groupId != null && (
        <CreateReportModal
          groupId={groupId}
          isOpen={createReportIsOpen.isOpen}
          reportType={createReportIsOpen.reportType}
          closeHandler={() => {
            setCreateReportIsOpen({ isOpen: false, reportType: '' });
          }}
        />
      )}

      {groupId != null && (
        <CreateTrendAnalyticsReportModal
          groupId={groupId}
          isOpen={createTrendAnalyticsReportIsOpen.isOpen}
          reportType={createTrendAnalyticsReportIsOpen.reportType}
          closeHandler={() => {
            setCreateTrendAnalyticsReportIsOpen({
              isOpen: false,
              reportType: ''
            });
          }}
        />
      )}

      {groupId != null && (
        <DeleteReportModal
          groupId={groupId}
          isOpen={deleteReportState.isOpen}
          closeHandler={() => {
            setDeleteReportState({
              isOpen: false,
              reportId: '',
              buildingId: ''
            });
          }}
          reportId={deleteReportState.reportId}
          buildingId={deleteReportState.buildingId}
        />
      )}

      {groupId != null &&
        userData != null &&
        userData.user != null &&
        userData.user.isAdmin && (
          <CreateDailyTrapReportModal
            groupId={groupId}
            isOpen={createDailyReportIsOpen}
            closeHandler={() => {
              setCreateDailyReportIsOpen(false);
            }}
          />
        )}

      {groupId != null && (
        <DeleteDailyReportModal
          groupId={groupId}
          isOpen={deleteDailyReportState.isOpen}
          closeHandler={() => {
            setDeleteDailyReportState({
              isOpen: false,
              reportId: '',
              buildingId: ''
            });
          }}
          reportId={deleteDailyReportState.reportId}
          buildingId={deleteDailyReportState.buildingId}
        />
      )}

      {groupId != null && (
        <UpdateDailyReportModal
          groupId={groupId}
          isOpen={updateDailyReportState.isOpen}
          closeHandler={() => {
            setUpdateDailyReportState({
              isOpen: false,
              reportId: '',
              buildingId: ''
            });
          }}
          reportId={updateDailyReportState.reportId}
          buildingId={updateDailyReportState.buildingId}
        />
      )}

      {groupId != null && (
        <CreateCSVExport
          groupId={groupId}
          CSVType={createCSVModal.csvType}
          isOpen={createCSVModal.isOpen}
          closeHandler={() => {
            setCreateCSVModal({
              isOpen: false,
              csvType: 'CSV_TYPE_TRAPS'
            });
          }}
        />
      )}
    </div>
  );
}

export default Reports;
