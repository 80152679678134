import React, { ReactElement } from 'react';
import Pill from './pill';

interface DoorDistancePillProps {
  distance: number;
  openingDistance?: number;
  className?: string;
  isLoading?: boolean;
}

export function DoorDistancePill({
  distance,
  openingDistance,
  className = 'ml-5 w-24',
  isLoading = false
}: DoorDistancePillProps): ReactElement {
  if (isLoading) {
    return (
      <Pill
        className={` flex items-center justify-center text-white ${className} animate-pulse`}
      />
    );
  }

  return openingDistance != null &&
    distance > openingDistance &&
    openingDistance > 0 ? (
    <Pill
      label={`${distance} cm`}
      className={`bg-red-500 flex items-center justify-center text-white ${className}`}
    />
  ) : (
    <Pill
      label={`${distance} cm`}
      className={`bg-green-500 flex items-center justify-center text-white  ${className}`}
    />
  );
}

export default DoorDistancePill;
