import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { useBuildingServiceGetBuildingQuery } from '../../../api/enhanced/enhancedBuildings';
import Card from '../../../components/card/card';
import DataLabel from '../../../components/dataLabel/dataLabel';
import LabelButton from '../../../components/buttons/labelButton';
import UpdateBuildingModal from '../modals/updateBuildingModal';

interface BuildingDetailsProps {
  buildingId?: string;
  groupId?: string;
}

function BuildingDetails({
  buildingId,
  groupId
}: BuildingDetailsProps): ReactElement {
  const { data, isLoading, isError } = useBuildingServiceGetBuildingQuery(
    buildingId != null && groupId != null ? { buildingId, groupId } : skipToken
  );

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  if (isError) {
    return (
      <Card className="bg-red-200 flex justify-center items-center h-40">
        <div className="font-semibold text-xl">{t('table.error')}</div>
      </Card>
    );
  }

  return (
    <div className="flex flex-row w-full justify-center">
      <Card className="mr-1 w-full bg-white p-6 justify-center items-center">
        <h1 className="font-semibold text-xl">Details:</h1>
        <div className="flex lg:flex-row flex-col justify-between">
          <div className="flex flex-col w-4/12 ">
            <DataLabel
              className="justify-between text-lg"
              isLoading={isLoading}
              label="Beschreibung"
              data={data?.building?.description}
            />
            <DataLabel
              className="justify-between text-lg"
              isLoading={isLoading}
              label="Anschrift"
              data={`${data?.building?.street} ${data?.building?.houseNumber}`}
            />
            <DataLabel
              className="justify-between text-lg"
              isLoading={isLoading}
              label="Stadt"
              data={data?.building?.city}
            />
            <DataLabel
              className="justify-between text-lg"
              isLoading={isLoading}
              label="Postleitzahl"
              data={data?.building?.zipCode}
            />
          </div>
          <div className="flex flex-col">
            <LabelButton
              className="bg-gray-200 rounded-lg h-12 md:w-48 mb-2 mt-2"
              icon={<MdEdit size={20} />}
              label={t('administration.building.editBuilding')}
              onClick={() => setIsEditOpen(true)}
            />
            {/* <LabelButton
              className="rounded-lg h-12 md:w-48 bg-red-500 text-white"
              icon={<IoMdTrash size={20} />}
              label={t('administration.building.deleteBuilding')}
              onClick={() => setIsOpen(true)}
            /> */}
          </div>
        </div>
      </Card>

      {/* buildingId != null && groupId != null && (
        <DeleteBuildingModal
          isOpen={isOpen}
          closeHandler={() => setIsOpen(false)}
          buildingId={buildingId}
          groupId={groupId}
        />
      ) */}
      {data && data.building && groupId ? (
        <UpdateBuildingModal
          groupId={groupId}
          isOpen={isEditOpen}
          closeHandler={() => setIsEditOpen(false)}
          building={data.building}
        />
      ) : null}
    </div>
  );
}

export default BuildingDetails;
