/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';

import FormTextInput from '../../../components/form/formTextInput';
import MultiselectList, {
  MultiselectContent
} from '../../../components/multiselectList/multiselectList';
import { useIdentityServiceListUsersQuery } from '../../../api/gen/identity';
import {
  useDailyReportServiceGetDailyReportQuery,
  V1Alpha1RecipientUpdates
} from '../../../api/gen/reports';
import { useDailyReportServiceUpdateReportMetadataMutation } from '../../../api/enhanced/enhancedReports';

interface UpdateAlarmFormValues {
  description: string;
}

interface UpdateDailyReportModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  groupId: string;
  buildingId: string;
  reportId: string;
}

function UpdateDailyReportModal({
  closeHandler,
  isOpen,
  groupId,
  buildingId,
  reportId
}: UpdateDailyReportModalProps): ReactElement {
  const methods = useForm<UpdateAlarmFormValues>();
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<MultiselectContent[]>([]);
  const [deletedUsers, setDeletedUsers] = useState<MultiselectContent[]>([]);

  const [updateDailyReport, { isLoading, isError, isSuccess, reset }] =
    useDailyReportServiceUpdateReportMetadataMutation();

  const {
    data,
    isLoading: usersIsLoading,
    isSuccess: usersIsSuccess,
    isError: usersIsError
  } = useIdentityServiceListUsersQuery(
    groupId != null && groupId !== ''
      ? {
          groupId,
          sortBy: 'user_name:ASC'
        }
      : skipToken
  );

  const {
    data: reportData,
    isSuccess: reportIsSuccess,
    refetch
  } = useDailyReportServiceGetDailyReportQuery(
    groupId != null && groupId !== '' && reportId != null && reportId !== ''
      ? { groupId, reportId }
      : skipToken
  );

  useEffect(() => {
    if (
      reportData != null &&
      reportData.dailyReport != null &&
      reportData.dailyReport.description != null &&
      isOpen
    ) {
      refetch();
      methods.setValue('description', reportData.dailyReport.description);
    }
  }, [methods, reportData, isOpen, refetch]);

  useEffect(() => {
    if (
      data != null &&
      data.users != null &&
      data.users.length > 0 &&
      reportIsSuccess &&
      reportData != null &&
      reportData.dailyReport != null &&
      reportData.dailyReport.recipients != null
    ) {
      const content: MultiselectContent[] = [];
      data.users.forEach((user) => {
        content.push({
          selected: reportData!.dailyReport!.recipients!.some(
            (currentUser) => user.userId === currentUser.userId
          ),
          key: user.userId as string,
          label: `${user.userName as string} - ${
            user.isMailUser ? 'Nur Mails' : 'Portalnutzer'
          }`
        });
      });

      setSelectedUsers(content);
    }
  }, [data, reportIsSuccess, reportData]);

  useEffect(() => {
    if (
      data != null &&
      data.users != null &&
      data.users.length > 0 &&
      reportIsSuccess &&
      reportData != null &&
      reportData.dailyReport != null &&
      reportData.dailyReport.recipients != null
    ) {
      const content: MultiselectContent[] = [];
      reportData!.dailyReport!.recipients.forEach((recipient) => {
        const found = data!.users!.some(
          (user) => user.userId === recipient.userId
        );

        if (!found) {
          content.push({
            selected: true,
            key: recipient.userId as string,
            label: recipient.mail as string
          });
        }
      });
      setDeletedUsers(content);
    }
  }, [data, reportIsSuccess, reportData]);

  const onSubmit = (formData: UpdateAlarmFormValues): void => {
    let recipients: V1Alpha1RecipientUpdates[] = [];
    if (data != null && data.users != null && data.users.length > 0) {
      recipients = data.users.map((entry): V1Alpha1RecipientUpdates => {
        return {
          userId: entry.userId,
          mail: entry.mail,
          selected: selectedUsers.some(
            (user) => user.key === entry.userId && user.selected
          )
        };
      });
    }

    if (deletedUsers != null && deletedUsers.length > 0) {
      deletedUsers.forEach((recipient) => {
        recipients.push({
          userId: recipient.key,
          mail: recipient.label,
          selected: recipient.selected
        });
      });
    }

    updateDailyReport({
      groupId,
      buildingId,
      reportId,
      dailyReportServiceUpdateReportMetadataBody: {
        description: formData.description,
        recipientUpdates: recipients
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [methods, closeHandler, isSuccess, reset]);

  const close = (): void => {
    reset();
    closeHandler();
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('features.reports.modals.updateDailyReport.title')}
      >
        <div className="w-full flex flex-col">
          {isError && (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('features.reports.modals.updateDailyReport.error')}
            />
          )}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t(
                'features.reports.modals.updateDailyReport.description.label'
              )}
              errorMsg={
                t(
                  'features.reports.modals.updateDailyReport.description.error'
                ) as string
              }
              placeholder={
                t(
                  'features.reports.modals.updateDailyReport.description.label'
                ) as string
              }
              disabled={isLoading}
              required
            />

            <div>
              <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                {t('features.reports.modals.updateDailyReport.users.label')}
              </div>
              <MultiselectList
                className="max-h-40 overflow-auto"
                selectHandler={(id: string, checkedState: boolean) => {
                  setSelectedUsers(
                    selectedUsers.map((user) => {
                      if (user.key === id) {
                        return { ...user, selected: checkedState };
                      }

                      return user;
                    })
                  );
                }}
                content={selectedUsers}
              />
            </div>

            {deletedUsers.length > 0 && (
              <div className="mt-8">
                <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                  {t(
                    'features.reports.modals.updateDailyReport.unassignedUsers.label'
                  )}
                </div>
                <MultiselectList
                  className="max-h-40 overflow-auto"
                  selectHandler={(id: string, checkedState: boolean) => {
                    setDeletedUsers(
                      deletedUsers.map((user) => {
                        if (user.key === id) {
                          return { ...user, selected: checkedState };
                        }

                        return user;
                      })
                    );
                  }}
                  content={deletedUsers}
                />
              </div>
            )}

            <div className="flex items-end flex-col w-full mt-5">
              <button
                className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                type="submit"
              >
                {t('modals.save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default UpdateDailyReportModal;
