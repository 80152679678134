import React, { ReactElement, ReactNode } from 'react';

interface ButtonProps {
  className?: string;
  children: ReactNode;
  onClick: () => void;
}

export function Button({
  children,
  className = '',
  onClick
}: ButtonProps): ReactElement {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${className}`}
    >
      {children}
    </button>
  );
}

export function PageButton({
  children,
  className = '',
  onClick
}: ButtonProps): ReactElement {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${className}`}
    >
      {children}
    </button>
  );
}
