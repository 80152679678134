import React, { ReactElement } from 'react';
import { FormSelectOption } from '../form/formSelect';

interface SelectProps {
  placeholder: string;
  disabled: boolean;
  label: string;
  value: string;
  changeHandler: (key: string) => void;
  options: FormSelectOption[];
}

function Select({
  placeholder,
  disabled,
  value,
  changeHandler,
  options,
  label
}: SelectProps): ReactElement {
  return (
    <div className="flex flex-col w-full mt-2">
      <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
      </div>

      <select
        disabled={disabled}
        value={value}
        className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none"
        onChange={(event) => {
          changeHandler(event.target.value);
        }}
      >
        {options.map((option: FormSelectOption) => (
          <option key={option.value} value={option.value}>
            {option.description}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
