import React, { ReactElement } from 'react';
import Pill from './pill';

interface OnlineStatePillProps {
  state?: boolean;
  className?: string;
  isLoading?: boolean;
}

export function BooleanStatePill({
  state,
  className = 'ml-5 w-24',
  isLoading = false
}: OnlineStatePillProps): ReactElement {
  if (isLoading) {
    return (
      <Pill
        className={` flex items-center justify-center text-white ${className} animate-pulse`}
      />
    );
  }

  return state ? (
    <Pill
      label="Ja"
      className={`bg-green-500 flex items-center justify-center text-white ${className}`}
    />
  ) : (
    <Pill
      label="Nein"
      className={`bg-red-500 flex items-center justify-center text-white  ${className}`}
    />
  );
}

export default BooleanStatePill;
