/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormTextInput from '../../../components/form/formTextInput';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { useIdentityServiceDeleteUserMutation } from '../../../api/enhanced/enhancedIdentity';

interface FormValues {
  mail: string;
}

interface DeleteUserModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  userId: string;
  mail: string;
}

function DeleteUserModal({
  closeHandler,
  isOpen,
  userId,
  mail
}: DeleteUserModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const watchMail = methods.watch('mail');

  const [deleteUser, { isLoading, isSuccess, isError, reset }] =
    useIdentityServiceDeleteUserMutation();

  useEffect(() => {
    if (isSuccess) {
      methods.reset();
      reset();
      closeHandler();
    }
  }, [reset, methods, closeHandler, isSuccess]);

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  const onSubmit = (formData: FormValues): void => {
    if (mail === formData.mail) {
      deleteUser({ userId });
    }
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.deleteUser.title', { mail })}
      >
        <div className="w-full flex flex-col">
          <div>{t('modals.deleteUser.description')}</div>

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="mail"
              label={t('modals.deleteUser.mail.label')}
              errorMsg={t('modals.deleteUser.mail.error') as string}
              placeholder={t('modals.deleteUser.mail.label') as string}
              disabled={isLoading}
              required
            />

            <div className="flex items-end flex-col w-full mt-5">
              {!isLoading ? (
                <button
                  disabled={mail !== watchMail}
                  className=" rounded-lg shadow-md w-24 h-10 bg-red-400 text-white disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  type="submit"
                >
                  {t('modals.delete')}
                </button>
              ) : (
                <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-red-400 text-white flex justify-center items-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default DeleteUserModal;
