import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import TabBar, { TabContent } from '../../../../components/tabs/tabBar';
import TrapAdminPage from './traps/trapAdminPage';
import TemperatureAdminPage from './temperature/temperatureAdminPage';
import DoorAdminPage from './doors/doorAdminPage';
import CarbonDioxideAdminPage from './carbonDioxide/carbonDioxideAdminPage';
import AnalogTrapAdminPage from './traps/analogTrapAdminPage';

interface DeviceDetailsProps {
  buildingId?: string;
  groupId?: string;
}

function CheckpointAdminPage({
  buildingId,
  groupId
}: DeviceDetailsProps): ReactElement {
  const { t } = useTranslation();

  const tabContent: TabContent[] = [
    {
      key: 0,
      tabLabel: t('tabs.traps'),
      content: <TrapAdminPage buildingId={buildingId} groupId={groupId} />
    },
    {
      key: 1,
      tabLabel: t('tabs.temp'),
      content: (
        <TemperatureAdminPage buildingId={buildingId} groupId={groupId} />
      )
    },
    {
      key: 2,
      tabLabel: t('tabs.door'),
      content: <DoorAdminPage buildingId={buildingId} groupId={groupId} />
    },
    {
      key: 4,
      tabLabel: t('tabs.co2'),
      content: (
        <CarbonDioxideAdminPage buildingId={buildingId} groupId={groupId} />
      )
    },
    {
      key: 5,
      tabLabel: t('tabs.analogTraps'),
      content: <AnalogTrapAdminPage buildingId={buildingId} groupId={groupId} />
    }
  ];

  return (
    <div className="flex flex-col w-full">
      <TabBar tabs={tabContent} />
    </div>
  );
}

export default CheckpointAdminPage;
