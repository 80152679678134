/* eslint-disable react/jsx-props-no-spreading */
import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useReportServiceCreateReportMutation } from '../../../api/enhanced/enhancedReports';
import { useBuildingServiceListBuildingsQuery } from '../../../api/gen/buildings';
import FormDatePicker from '../../../components/form/formDatePicker';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormSelect, {
  FormSelectOption
} from '../../../components/form/formSelect';
import Modal from '../../../components/modal/modal';
import LoadingSpinner from '../../../components/spinner/loadingSpinner';
import { V1Alpha1ReportType } from '../../../api/gen/reports';
import { useCheckpointServiceListCheckpointsForBuildingQuery } from '../../../api/enhanced/enhancedCheckpoints';

interface FormValues {
  buildingId: string;
  checkpointId: string;
  periodType: string;
  startDate: Date;
  endDate: Date;
}

interface CreateReportModalProps {
  groupId: string;
  closeHandler: () => void;
  isOpen: boolean;
  reportType: string;
}

function CreateReportModal({
  groupId,
  closeHandler,
  isOpen,
  reportType
}: CreateReportModalProps): ReactElement {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const periodType = methods.watch('periodType');
  const checkpointId = methods.watch('checkpointId');
  const buildingId = methods.watch('buildingId');

  const [createReport, { isLoading, isSuccess, isError, reset }] =
    useReportServiceCreateReportMutation();

  const { data: buildingData } = useBuildingServiceListBuildingsQuery(
    groupId !== ''
      ? {
          groupId,
          limit: 200,
          page: 0
        }
      : skipToken
  );

  const { data: checkpointData } =
    useCheckpointServiceListCheckpointsForBuildingQuery(
      groupId !== '' &&
        methods.getValues('buildingId') !== '' &&
        methods.getValues('buildingId') != null
        ? {
            groupId,
            buildingId: methods.watch('buildingId'),
            sensorType: 'SENSOR_TYPE_TEMP'
          }
        : skipToken
    );

  useEffect(() => {
    methods.resetField('checkpointId');
  }, [buildingId, methods]);

  useEffect(() => {
    if (checkpointData != null && checkpointData.checkpoints != null) {
      if (checkpointData.checkpoints.length > 0) {
        methods.setValue('checkpointId', checkpointData.checkpoints[0].id!);
      }
    }
  }, [checkpointData, methods]);

  useEffect(() => {
    if (isSuccess) {
      methods.reset();
      reset();
      closeHandler();
    }
  }, [reset, methods, closeHandler, isSuccess]);

  const periods: FormSelectOption[] = [
    {
      value: 'currentDay',
      description: t('modals.createReport.periodSelection.currentDay')
    },
    {
      value: 'currentWeek',
      description: t('modals.createReport.periodSelection.currentWeek')
    },
    {
      value: 'currentMonth',
      description: t('modals.createReport.periodSelection.currentMonth')
    },
    /* {
      value: 'lastDay',
      description: t('modals.createReport.periodSelection.lastDay')
    },
    {
      value: 'lastWeek',
      description: t('modals.createReport.periodSelection.lastWeek')
    },
    {
      value: 'lastMonth',
      description: t('modals.createReport.periodSelection.lastMonth')
    }, */
    {
      value: 'userdefined',
      description: t('modals.createReport.periodSelection.userdefined')
    }
  ];

  const onSubmit = (formData: FormValues): void => {
    const endTime = new Date(Date.now());
    let startTime = new Date(Date.now());

    switch (formData.periodType) {
      case 'currentDay':
        startTime.setHours(endTime.getHours() - 24);
        break;
      case 'currentWeek':
        startTime.setHours(endTime.getHours() - 168);
        break;
      case 'currentMonth':
        startTime.setMonth(endTime.getMonth() - 1);
        break;
      case 'userdefined':
        startTime.setTime(formData.startDate.setHours(0, 0, 0, 0));
        endTime.setTime(formData.endDate.setHours(23, 59, 59, 59));
        break;
      default:
        startTime = formData.startDate;
        endTime.setTime(formData.endDate.setHours(23, 59, 59, 59));
    }

    if (startTime != null && endTime != null) {
      createReport({
        groupId,
        buildingId: formData.buildingId,
        reportServiceCreateReportBody: {
          checkpointId:
            formData.checkpointId != null ? formData.checkpointId : undefined,
          type: reportType as V1Alpha1ReportType,
          startTimestamp: startTime?.toISOString(),
          endTimestamp: endTime?.toISOString()
        }
      });
    }
  };

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.createReport.title')}
      >
        <div className="w-full flex flex-col">
          {isError ? (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          ) : null}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {buildingData != null &&
              buildingData.buildings != null &&
              buildingData.buildings.length > 0 && (
                <FormSelect
                  id="buildingId"
                  label={t('modals.createReport.building.label')}
                  errorMsg={t('modals.createReport.building.error') as string}
                  placeholder={
                    t('modals.createReport.building.label') as string
                  }
                  disabled={isLoading}
                  options={buildingData.buildings?.map(
                    (option): FormSelectOption => {
                      return {
                        value: option?.id != null ? option.id : '',
                        description:
                          option?.description != null ? option.description : ''
                      };
                    }
                  )}
                  required
                />
              )}

            {reportType === 'REPORT_TYPE_TEMPERATURE' &&
              checkpointData != null &&
              checkpointData.checkpoints != null &&
              checkpointData.checkpoints.length > 0 && (
                <FormSelect
                  id="checkpointId"
                  label="Prüfpunkt"
                  errorMsg="Ein Prüfpunkt muss ausgewählt werden!"
                  placeholder="Prüfpunkt"
                  disabled={isLoading}
                  options={checkpointData.checkpoints?.map(
                    (option): FormSelectOption => {
                      return {
                        value: option?.id != null ? option.id : '',
                        description:
                          option?.description != null ? option.description : ''
                      };
                    }
                  )}
                  required
                />
              )}

            {reportType === 'REPORT_TYPE_HUMIDITY' &&
              checkpointData != null &&
              checkpointData.checkpoints != null &&
              checkpointData.checkpoints.length > 0 && (
                <FormSelect
                  id="checkpointId"
                  label="Prüfpunkt"
                  errorMsg="Ein Prüfpunkt muss ausgewählt werden!"
                  placeholder="Prüfpunkt"
                  disabled={isLoading}
                  options={checkpointData.checkpoints?.map(
                    (option): FormSelectOption => {
                      return {
                        value: option?.id != null ? option.id : '',
                        description:
                          option?.description != null ? option.description : ''
                      };
                    }
                  )}
                  required
                />
              )}

            <FormSelect
              id="periodType"
              label={t('modals.createReport.periodSelection.label')}
              errorMsg={
                t('modals.createReport.periodSelection.error') as string
              }
              placeholder={
                t('modals.createReport.periodSelection.label') as string
              }
              disabled={isLoading}
              value={periodType}
              options={periods.map((option): FormSelectOption => {
                return { value: option.value, description: option.description };
              })}
              required
            />

            {periodType === 'userdefined' && (
              <FormDatePicker
                id="startDate"
                label="Startzeit"
                placeholder="Startzeit auswählen"
                required={periodType === 'userdefined'}
              />
            )}

            {periodType === 'userdefined' && (
              <FormDatePicker
                id="endDate"
                label="Endzeit"
                placeholder="Endzeit auswählen"
                required={periodType === 'userdefined'}
              />
            )}

            {reportType === 'REPORT_TYPE_TEMPERATURE' &&
              checkpointId != null &&
              checkpointId !== '' && (
                <div className="flex items-end flex-col w-full mt-5">
                  {!isLoading ? (
                    <button
                      className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                      type="submit"
                    >
                      {t('modals.save')}
                    </button>
                  ) : (
                    <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              )}

            {reportType === 'REPORT_TYPE_HUMIDITY' &&
              checkpointId != null &&
              checkpointId !== '' && (
                <div className="flex items-end flex-col w-full mt-5">
                  {!isLoading ? (
                    <button
                      className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                      type="submit"
                    >
                      {t('modals.save')}
                    </button>
                  ) : (
                    <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              )}

            {reportType !== 'REPORT_TYPE_TEMPERATURE' &&
              reportType !== 'REPORT_TYPE_HUMIDITY' && (
                <div className="flex items-end flex-col w-full mt-5">
                  {!isLoading ? (
                    <button
                      className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                      type="submit"
                    >
                      {t('modals.save')}
                    </button>
                  ) : (
                    <div className="rounded-lg shadow-md w-24  h-10  p-4 bg-blue-400 text-white flex justify-center items-center">
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              )}
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateReportModal;
