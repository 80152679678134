import { inspectionApi } from '../gen/inspections';

export const enhancedInspectionApi = inspectionApi.enhanceEndpoints({
  addTagTypes: [
    'SENSOR_TYPE_UNSPECIFIED',
    'SENSOR_TYPE_TRAP',
    'SENSOR_TYPE_TEMP',
    'SENSOR_TYPE_CARBON_DIOXIDE',
    'SENSOR_TYPE_DOOR',
    'SENSOR_TYPE_ANALOG_TRAP'
  ],
  endpoints: {
    inspectionServiceListInspection: {
      providesTags: (result, error, arg) => [arg.sensorType]
    },
    inspectionServiceCreateInspection: {
      invalidatesTags: (result, error, arg) => [arg.sensorType]
    },
    inspectionServiceGetInspection: {
      providesTags: (result, error, arg) => [arg.sensorType]
    },
    inspectionServiceUpdateInspection: {
      invalidatesTags: (result, error, arg) => [arg.sensorType]
    }
  }
});

export const {
  useInspectionServiceListInspectionQuery,
  useInspectionServiceCreateInspectionMutation,
  useInspectionServiceGetInspectionQuery,
  useInspectionServiceUpdateInspectionMutation
} = enhancedInspectionApi;
