import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import StatePieChart, { PieData } from '../../../pages/dashboard/statGraphs';
import PageTitle from '../../../components/title/pageTitle';
import BuildingTable from '../../buildings/tables/buildingTable';
import { useStatisticsServiceGetStatisticsQuery } from '../../../api/enhanced/enhancedStatistics';
import Card from '../../../components/card/card';

function Dashboard(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  const {
    data: trapStatsData,
    isLoading: trapIsLoading,
    isFetching: trapIsFetching
  } = useStatisticsServiceGetStatisticsQuery(
    groupId != null && groupId !== ''
      ? {
          groupId,
          sensorType: 'SENSOR_TYPE_TRAP'
        }
      : skipToken
  );

  const {
    data: carbonDioxideStatsData,
    isLoading: carbonDioxideIsLoading,
    isFetching: carbonDioxideIsFetching
  } = useStatisticsServiceGetStatisticsQuery(
    groupId != null && groupId !== ''
      ? {
          groupId,
          sensorType: 'SENSOR_TYPE_CARBON_DIOXIDE'
        }
      : skipToken
  );

  const {
    data: tempStatsData,
    isLoading: tempIsLoading,
    isFetching: tempIsFetching
  } = useStatisticsServiceGetStatisticsQuery(
    groupId != null && groupId !== ''
      ? {
          groupId,
          sensorType: 'SENSOR_TYPE_TEMP'
        }
      : skipToken
  );

  const {
    data: doorStatsData,
    isLoading: doorIsLoading,
    isFetching: doorIsFetching
  } = useStatisticsServiceGetStatisticsQuery(
    groupId != null && groupId !== ''
      ? {
          groupId,
          sensorType: 'SENSOR_TYPE_DOOR'
        }
      : skipToken
  );

  const {
    data: humidityStatsData,
    isLoading: humidityIsLoading,
    isFetching: humidityIsFetching
  } = useStatisticsServiceGetStatisticsQuery(
    groupId != null && groupId !== ''
      ? {
          groupId,
          sensorType: 'SENSOR_TYPE_HUMIDITY'
        }
      : skipToken
  );

  let pieTrapChartData: PieData[] = [];
  if (trapStatsData != null && !trapIsLoading && !trapIsFetching) {
    pieTrapChartData = [
      {
        name: 'Aktiv',
        value: Number(trapStatsData.trapStats?.active),
        color: '#2fbb59'
      },
      {
        name: 'Auslösung mit Fang',
        value: Number(trapStatsData.trapStats?.trapped),
        color: '#e64144'
      },
      {
        name: 'Auslösung ohne Fang',
        value: Number(trapStatsData.trapStats?.failed),
        color: '#e2ab2c'
      },
      {
        name: 'Offline',
        value: Number(trapStatsData.trapStats?.offline),
        color: '#a3a0a0'
      }
    ];
  }

  let carbonDioxidePieChartData: PieData[] = [];
  if (
    carbonDioxideStatsData != null &&
    !carbonDioxideIsLoading &&
    !carbonDioxideIsFetching
  ) {
    carbonDioxidePieChartData = [
      {
        name: 'Werte im Normbereich',
        value: Number(carbonDioxideStatsData.carbonDioxideStats?.valid),
        color: '#2fbb59'
      },
      {
        name: 'Werte über dem Warnlimit',
        value: Number(
          carbonDioxideStatsData.carbonDioxideStats?.invalidWarningLimit
        ),
        color: '#e2ab2c'
      },
      {
        name: 'Werte über dem Alarmlimit',
        value: Number(
          carbonDioxideStatsData.carbonDioxideStats?.invalidAlarmLimit
        ),
        color: '#e64144'
      },
      {
        name: 'Offline',
        value: Number(carbonDioxideStatsData.carbonDioxideStats?.offline),
        color: '#a3a0a0'
      }
    ];
  }

  let temperaturePieChartData: PieData[] = [];
  if (tempStatsData != null && !tempIsLoading && !tempIsFetching) {
    temperaturePieChartData = [
      {
        name: 'Werte im Normbereich',
        value: Number(tempStatsData.tempStats?.valid),
        color: '#2fbb59'
      },
      {
        name: 'Werte außerhalb des Normbereichs',
        value: Number(tempStatsData.tempStats?.invalid),
        color: '#e64144'
      },
      {
        name: 'Offline',
        value: Number(tempStatsData.tempStats?.offline),
        color: '#a3a0a0'
      }
    ];
  }

  let humidityPieChartData: PieData[] = [];
  if (humidityStatsData != null && !humidityIsFetching && !humidityIsLoading) {
    humidityPieChartData = [
      {
        name: 'Werte im Normbereich',
        value: Number(humidityStatsData.humidityStats?.valid),
        color: '#2fbb59'
      },
      {
        name: 'Werte außerhalb des Normbereichs',
        value: Number(humidityStatsData.humidityStats?.invalid),
        color: '#e64144'
      },
      {
        name: 'Offline',
        value: Number(humidityStatsData.humidityStats?.offline),
        color: '#a3a0a0'
      }
    ];
  }

  let doorPieChartData: PieData[] = [];
  if (doorStatsData != null && !doorIsLoading && !doorIsFetching) {
    doorPieChartData = [
      {
        name: 'Geschlossen',
        value: Number(doorStatsData.doorStats?.closed),
        color: '#2fbb59'
      },
      {
        name: 'Offen',
        value: Number(doorStatsData.doorStats?.open),
        color: '#e64144'
      },
      {
        name: 'Offline',
        value: Number(doorStatsData.doorStats?.offline),
        color: '#a3a0a0'
      }
    ];
  }

  return (
    <div className="flex flex-col px-8 w-full">
      <PageTitle title={t('dashboard.title')} />

      <Card className="flex flex-row mb-4 bg-white overflow-x-auto snap-x space-x-5 mb-2">
        {trapStatsData != null &&
          Number(trapStatsData.trapStats?.total) > 0 &&
          pieTrapChartData.length !== 0 && (
            <StatePieChart
              className="lg:w-4/12 md:w-6/12 w-full shrink-0 snap-start"
              data={pieTrapChartData}
              total={Number(trapStatsData.trapStats?.total)}
              label="Fallenstatus"
            />
          )}
        {carbonDioxideStatsData != null &&
          Number(carbonDioxideStatsData.carbonDioxideStats?.total) > 0 &&
          carbonDioxidePieChartData.length !== 0 && (
            <StatePieChart
              className="lg:w-4/12 md:w-6/12 w-full shrink-0 snap-start"
              data={carbonDioxidePieChartData}
              total={Number(carbonDioxideStatsData.carbonDioxideStats?.total)}
              label="Kohlenstoffdioxid"
            />
          )}
        {humidityStatsData != null &&
          Number(humidityStatsData.humidityStats?.total) > 0 &&
          humidityPieChartData.length !== 0 && (
            <StatePieChart
              className="lg:w-4/12 md:w-6/12 w-full shrink-0 snap-start"
              data={humidityPieChartData}
              total={Number(humidityStatsData.humidityStats?.total)}
              label="Luftfeuchtigkeit"
            />
          )}
        {tempStatsData != null &&
          Number(tempStatsData.tempStats?.total) > 0 &&
          temperaturePieChartData.length !== 0 && (
            <StatePieChart
              className="lg:w-4/12 md:w-6/12 w-full shrink-0 snap-start"
              data={temperaturePieChartData}
              total={Number(tempStatsData.tempStats?.total)}
              label="Temperaturen"
            />
          )}
        {doorStatsData != null &&
          Number(doorStatsData.doorStats?.total) > 0 &&
          doorPieChartData.length !== 0 && (
            <StatePieChart
              className="lg:w-4/12 md:w-6/12 w-full shrink-0 snap-start"
              data={doorPieChartData}
              total={Number(doorStatsData.doorStats?.total)}
              label="Türen"
            />
          )}
      </Card>

      <BuildingTable
        groupId={groupId}
        clickHandler={(buildingId: string) =>
          navigate(`building/${buildingId}`, {})
        }
      />
    </div>
  );
}

export default Dashboard;
