import React, { ReactElement } from 'react';
import { SortDirection } from '@tanstack/react-table';
import { HiChevronDown, HiChevronUp, HiChevronUpDown } from 'react-icons/hi2';

interface HeaderProps {
  title: string;
  sortDirection?: false | SortDirection;
  onClick?: (event: unknown) => void;
  canSort?: boolean;
}

function HeaderCell({
  title,
  sortDirection,
  onClick,
  canSort
}: HeaderProps): ReactElement {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      className={`px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider flex flex-row ${
        canSort ? 'cursor-pointer select-none' : ''
      }`}
      onClick={(e) => {
        if (canSort && onClick != null) {
          onClick(e);
        }
      }}
    >
      {title}
      {canSort
        ? {
            asc: <HiChevronUp size={16} />,
            desc: <HiChevronDown size={16} />
          }[sortDirection as string] ?? <HiChevronUpDown size={16} />
        : null}
    </div>
  );
}

export default HeaderCell;
