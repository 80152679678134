/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAlarmServiceCreateAlarmMutation } from '../../../api/enhanced/enhancedAlarms';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormTextInput from '../../../components/form/formTextInput';

import MultiselectList, {
  MultiselectContent
} from '../../../components/multiselectList/multiselectList';
import { useIdentityServiceListUsersQuery } from '../../../api/gen/identity';
import { useCheckpointServiceListCheckpointsForBuildingQuery } from '../../../api/enhanced/enhancedCheckpoints';
import FormSelect, {
  FormSelectOption
} from '../../../components/form/formSelect';
import { useBuildingServiceListAreasForBuildingQuery } from '../../../api/enhanced/enhancedBuildings';
import {
  V1Alpha1AssignedCheckpoint,
  V1Alpha1Recipient,
  V1Alpha1Weekday
} from '../../../api/gen/alarms';

interface CreateAlarmFormValues {
  description: string;
  areaId: string;

  repetitionInterval: number;
  delayInterval: number;
}

interface CreateAlarmModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  groupId: string;
  buildingId: string;
  sensorType:
    | 'SENSOR_TYPE_UNSPECIFIED'
    | 'SENSOR_TYPE_TRAP'
    | 'SENSOR_TYPE_TEMP'
    | 'SENSOR_TYPE_CARBON_DIOXIDE'
    | 'SENSOR_TYPE_DOOR'
    | 'SENSOR_TYPE_HUMIDITY';
}

function CreateAlarmModal({
  closeHandler,
  isOpen,
  groupId,
  buildingId,
  sensorType
}: CreateAlarmModalProps): ReactElement {
  const methods = useForm<CreateAlarmFormValues>();
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<MultiselectContent[]>([]);

  const [selectedCheckpoints, setSelectedCheckpoints] = useState<
    MultiselectContent[]
  >([]);

  const [selectedWeekdays, setSelectedWeekdays] = useState<
    MultiselectContent[]
  >([
    {
      key: 'monday',
      label: 'Montag',
      selected: true
    },
    {
      key: 'tuesday',
      label: 'Dienstag',
      selected: true
    },
    {
      key: 'wednesday',
      label: 'Mittwoch',
      selected: true
    },
    {
      key: 'thursday',
      label: 'Donnerstag',
      selected: true
    },
    {
      key: 'friday',
      label: 'Freitag',
      selected: true
    },
    {
      key: 'saturday',
      label: 'Samstag',
      selected: true
    },
    {
      key: 'sunday',
      label: 'Sonntag',
      selected: true
    }
  ]);

  const [areaSelectOptions, setAreaSelectOptions] = useState<
    FormSelectOption[]
  >([]);

  const [alarmForBuilding, setAlarmForBuilding] = useState(false);
  const [alarmForArea, setAlarmForArea] = useState(false);
  const [isRepeated, setIsRepeated] = useState(false);
  const [startTime, setStartTime] = useState<TimePickerValue>('10:00');
  const [endTime, setEndTime] = useState<TimePickerValue>('10:00');
  const [isTimeInterval, setIsTimeInterval] = useState(false);
  const [limitWeekdays, setLimitWeekdays] = useState(false);

  const areaId = methods.watch('areaId');

  const [createAlarm, { isLoading, isError, isSuccess, reset }] =
    useAlarmServiceCreateAlarmMutation();

  const {
    data,
    isLoading: usersIsLoading,
    isSuccess: usersIsSuccess,
    isError: usersIsError
  } = useIdentityServiceListUsersQuery({
    groupId,
    sortBy: 'user_name:ASC'
  });

  const { data: areaData, isSuccess: areaIsSuccess } =
    useBuildingServiceListAreasForBuildingQuery(
      buildingId != null && groupId != null && groupId !== ''
        ? {
            groupId,
            buildingId
          }
        : skipToken
    );

  const { data: checkpointData, isSuccess: checkpointIsSuccess } =
    useCheckpointServiceListCheckpointsForBuildingQuery(
      buildingId != null && groupId != null && groupId !== ''
        ? {
            groupId,
            buildingId,
            areaId,
            sensorType:
              sensorType !== 'SENSOR_TYPE_HUMIDITY'
                ? sensorType
                : 'SENSOR_TYPE_TEMP',
            sortBy: 'checkpoint_number:ASC'
          }
        : skipToken
    );

  useEffect(() => {
    if (data != null && data.users != null && data.users.length > 0) {
      const content: MultiselectContent[] = [];
      data.users.forEach((user) => {
        content.push({
          selected: false,
          key: user.userId as string,
          label: `${user.userName as string} - ${
            user.isMailUser ? 'Nur Mails' : 'Portalnutzer'
          }`
        });
      });
      setSelectedUsers(content);
    }
  }, [data]);

  useEffect(() => {
    if (areaData != null && areaData.areas != null && areaIsSuccess) {
      const content: FormSelectOption[] = [
        { value: '', description: 'Alle Bereiche' }
      ];
      areaData.areas.forEach((area) => {
        content.push({
          value: area.id != null ? area.id : '',
          description: area.description != null ? area.description : ''
        });
      });
      setAreaSelectOptions(content);
    }
  }, [areaData, areaId, areaIsSuccess]);

  useEffect(() => {
    if (
      checkpointData != null &&
      checkpointData.checkpoints != null &&
      checkpointIsSuccess
    ) {
      const content: MultiselectContent[] = [];
      checkpointData.checkpoints.forEach((checkpoint) => {
        content.push({
          selected: false,
          key: checkpoint.id as string,
          label: `${checkpoint.checkpointNumber} - ${checkpoint.description}`
        });
      });
      setSelectedCheckpoints(content);
    }
  }, [checkpointData, checkpointIsSuccess, data]);

  const onSubmit = (formData: CreateAlarmFormValues): void => {
    const assignedCheckpoint: V1Alpha1AssignedCheckpoint[] = [];
    if (
      checkpointData != null &&
      checkpointData.checkpoints != null &&
      selectedCheckpoints.length > 0
    ) {
      checkpointData.checkpoints.forEach((checkpoint) => {
        const selected = selectedCheckpoints.some(
          (currCheckpoint) =>
            currCheckpoint.key === checkpoint.id && currCheckpoint.selected
        );
        if (selected) {
          assignedCheckpoint.push({
            checkpointId: checkpoint.id,
            description: `${checkpoint.checkpointNumber} - ${checkpoint.description}`
          });
        }
      });
    }

    const recipients: V1Alpha1Recipient[] = [];
    if (data != null && data.users != null && selectedUsers.length > 0) {
      data.users.forEach((user) => {
        const selected = selectedUsers.some(
          (currUser) => currUser.key === user.userId && currUser.selected
        );
        if (selected) {
          recipients.push({
            userId: user.userId,
            mail: user.mail
          });
        }
      });
    }

    const weekdays: V1Alpha1Weekday[] = [];
    selectedWeekdays.forEach((value) => {
      weekdays.push({
        key: value.key,
        checked: value.selected
      });
    });

    createAlarm({
      groupId,
      buildingId,
      alarmServiceCreateAlarmBody: {
        alarm: {
          areaId,
          description: formData.description,
          sensorType,
          repeatedMessage: isRepeated,
          repetitionInterval: formData.repetitionInterval,
          delayInterval: formData.delayInterval,
          recipients,
          assignedCheckpoint,
          isActive: true,
          isAreaAlarm: alarmForArea,
          isBuildingAlarm: alarmForBuilding,
          weekdays,
          startTime: startTime != null ? startTime.toString() : 'CLEARED',
          endTime: endTime != null ? endTime.toString() : 'CLEARED',
          selectedWeekdays: limitWeekdays,
          selectedIntervals: isTimeInterval
        }
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      methods.reset();
      closeHandler();
    }
  }, [isSuccess, methods, reset, closeHandler]);

  const close = (): void => {
    closeHandler();
    methods.reset();
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t(`features.alarms.modals.create.title.${sensorType}`)}
      >
        <div className="w-full flex flex-col">
          {isError && (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('features.alarms.modals.create.error')}
            />
          )}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormTextInput
              id="description"
              label={t('features.alarms.modals.create.description.label')}
              errorMsg={
                t('features.alarms.modals.create.description.error') as string
              }
              placeholder={
                t('features.alarms.modals.create.description.label') as string
              }
              disabled={isLoading}
              required
            />
            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setAlarmForBuilding(!alarmForBuilding);
                  setAlarmForArea(false);
                }}
                checked={alarmForBuilding}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('features.alarms.modals.create.alarmForBuilding.label')}
              </div>
            </div>

            {!alarmForBuilding && (
              <FormSelect
                id="areaId"
                label={t('modals.createAlarm.area.label')}
                errorMsg={t('modals.createAlarm.area.error') as string}
                placeholder={t('modals.createAlarm.area.label') as string}
                disabled={isLoading}
                options={areaSelectOptions}
                required={alarmForArea}
              />
            )}

            {!alarmForBuilding && areaId !== '' && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  onChange={() => {
                    setAlarmForArea(!alarmForArea);
                    setAlarmForBuilding(false);
                  }}
                  checked={alarmForArea}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
                />
                <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                  {t('features.alarms.modals.create.alarmForArea.label')}
                </div>
              </div>
            )}

            {!alarmForBuilding &&
              !alarmForArea &&
              selectedCheckpoints.length > 0 && (
                <div>
                  <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                    {t('features.alarms.modals.create.checkpoints')}
                  </div>
                  <MultiselectList
                    className="max-h-40 overflow-auto"
                    selectHandler={(id: string, checkedState: boolean) => {
                      setSelectedCheckpoints(
                        selectedCheckpoints.map((checkpoint) => {
                          if (checkpoint.key === id) {
                            return { ...checkpoint, selected: checkedState };
                          }

                          return checkpoint;
                        })
                      );
                    }}
                    content={selectedCheckpoints}
                  />
                </div>
              )}

            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setIsRepeated(!isRepeated);
                }}
                checked={isRepeated}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('modals.createAlarm.isRepeated.label')}
              </div>
            </div>

            {isRepeated && (
              <FormTextInput
                type="number"
                id="repetitionInterval"
                label={t('modals.createAlarm.repetitionInterval.label')}
                errorMsg={
                  t('modals.createAlarm.repetitionInterval.error') as string
                }
                placeholder={
                  t('modals.createAlarm.repetitionInterval.label') as string
                }
                required={isRepeated}
              />
            )}

            {sensorType === 'SENSOR_TYPE_DOOR' && (
              <FormTextInput
                type="number"
                id="delayInterval"
                label={t('modals.createAlarm.delayInterval.label')}
                errorMsg={t('modals.createAlarm.delayInterval.error') as string}
                placeholder={
                  t('modals.createAlarm.delayInterval.label') as string
                }
              />
            )}

            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setIsTimeInterval(!isTimeInterval);
                }}
                checked={isTimeInterval}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('features.alarms.modals.create.activeForInterval')}
              </div>
            </div>

            {isTimeInterval && (
              <div className="flex flex-col w-full mt-2">
                <h2 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  {t('features.alarms.modals.create.startTime')}
                </h2>
                <div className="rounded-lg w-full appearance-none block w-full text-gray-700 border rounded py-2 px-2 mb-1 leading-tight focus:outline-none">
                  <TimePicker
                    disableClock
                    className="w-full border-none"
                    onChange={setStartTime}
                    value={startTime}
                  />
                </div>
              </div>
            )}

            {isTimeInterval && (
              <div className="flex flex-col w-full mt-2">
                <h2 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  {t('features.alarms.modals.create.endTime')}
                </h2>
                <div className="rounded-lg w-full appearance-none block w-full text-gray-700 border rounded py-2 px-2 mb-1 leading-tight focus:outline-none">
                  <TimePicker
                    disableClock
                    className="w-full border-none"
                    onChange={setEndTime}
                    value={endTime}
                  />
                </div>
              </div>
            )}

            <div className="flex items-center">
              <input
                type="checkbox"
                onChange={() => {
                  setLimitWeekdays(!limitWeekdays);
                }}
                checked={limitWeekdays}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-200  focus:ring-2"
              />
              <div className="py-3 ml-2 w-full text-sm font-medium text-gray-900">
                {t('features.alarms.modals.create.limitWeekdays')}
              </div>
            </div>

            {limitWeekdays && (
              <div>
                <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                  {t('features.alarms.modals.create.weekdays')}
                </div>
                <MultiselectList
                  className="max-h-40 overflow-auto"
                  selectHandler={(id: string, checkedState: boolean) => {
                    setSelectedWeekdays(
                      selectedWeekdays.map((weekday) => {
                        if (weekday.key === id) {
                          return { ...weekday, selected: checkedState };
                        }

                        return weekday;
                      })
                    );
                  }}
                  content={selectedWeekdays}
                />
              </div>
            )}

            <div>
              <div className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2">
                {t('features.alarms.modals.create.users')}
              </div>
              <MultiselectList
                className="max-h-40 overflow-auto"
                selectHandler={(id: string, checkedState: boolean) => {
                  setSelectedUsers(
                    selectedUsers.map((user) => {
                      if (user.key === id) {
                        return { ...user, selected: checkedState };
                      }

                      return user;
                    })
                  );
                }}
                content={selectedUsers}
              />
            </div>

            <div className="flex items-end flex-col w-full mt-5">
              <button
                className=" rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white"
                type="submit"
              >
                {t('modals.save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default CreateAlarmModal;
