import React, { ReactElement, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import OfflineAlarmTable from '../table/offline-alarm-table';
import LabelButton from '../../../components/buttons/labelButton';
import CreateOfflineAlarmModal from '../modals/create-offline-alarm-modal';
import DeleteOfflineAlarmModal from '../modals/delete-offline-alarm-modal';
import Select from '../../../components/select/select';
import EditOfflineAlarmModal from '../modals/edit-offline-alarm-modal';

type SensorType =
  | 'SENSOR_TYPE_UNSPECIFIED'
  | 'SENSOR_TYPE_TRAP'
  | 'SENSOR_TYPE_TEMP'
  | 'SENSOR_TYPE_CARBON_DIOXIDE'
  | 'SENSOR_TYPE_DOOR'
  | 'SENSOR_TYPE_HUMIDITY';

interface ModalState {
  isOpen: boolean;
  alarmId: string;
}

interface EditModalState {
  isOpen: boolean;
  id: string;
  sensorType: SensorType;
}

interface CreateModalState {
  isOpen: boolean;
  sensorType: SensorType;
}

function OfflineAlarmPage(): ReactElement {
  const { t } = useTranslation();
  const [sensorType, setSensorType] = useState('SENSOR_TYPE_TRAP');
  const [createAlarmState, setCreateAlarmState] = useState<CreateModalState>({
    isOpen: false,
    sensorType: 'SENSOR_TYPE_UNSPECIFIED'
  });
  const [editAlarmState, setEditAlarmState] = useState<EditModalState>({
    isOpen: false,
    id: '',
    sensorType: 'SENSOR_TYPE_UNSPECIFIED'
  });
  const [deleteAlarmState, setDeleteAlarmState] = useState<ModalState>({
    isOpen: false,
    alarmId: ''
  });
  return (
    <div className="w-full flex-col ">
      <LabelButton
        label={t('alarms.addAlarm')}
        className="bg-gray-200 rounded-lg h-12 w-48 text-gray-600 mt-3"
        icon={<IoMdAdd size={20} />}
        onClick={() =>
          setCreateAlarmState({
            isOpen: true,
            sensorType: 'SENSOR_TYPE_TRAP'
          })
        }
      />
      <Select
        placeholder="Sensortyp auswählen"
        disabled={false}
        label="Sensortyp"
        value={sensorType}
        changeHandler={(value) => setSensorType(value)}
        options={[
          {
            description: 'Falle',
            value: 'SENSOR_TYPE_TRAP'
          },
          {
            description: 'Temperatur',
            value: 'SENSOR_TYPE_TEMP'
          },
          {
            description: 'Kohlenstoffdioxid',
            value: 'SENSOR_TYPE_CARBON_DIOXIDE'
          },
          {
            description: 'Tür',
            value: 'SENSOR_TYPE_DOOR'
          }
        ]}
      />

      <OfflineAlarmTable
        sensorType={sensorType as SensorType}
        deleteAlarmHandler={(isOpen: boolean, alarmId: string) =>
          setDeleteAlarmState({
            isOpen,
            alarmId
          })
        }
        editAlarmHandler={(
          isOpen: boolean,
          alarmId: string,
          currentSensorType: string
        ) =>
          setEditAlarmState({
            isOpen,
            id: alarmId,
            sensorType: currentSensorType as SensorType
          })
        }
      />

      <CreateOfflineAlarmModal
        isOpen={createAlarmState.isOpen}
        closeHandler={() => {
          setCreateAlarmState({
            isOpen: false,
            sensorType: 'SENSOR_TYPE_UNSPECIFIED'
          });
        }}
      />

      <DeleteOfflineAlarmModal
        alarmId={deleteAlarmState.alarmId}
        isOpen={deleteAlarmState.isOpen}
        closeHandler={() =>
          setDeleteAlarmState({
            isOpen: false,
            alarmId: ''
          })
        }
      />

      <EditOfflineAlarmModal
        alarmId={editAlarmState.id}
        sensorType={editAlarmState.sensorType}
        isOpen={editAlarmState.isOpen}
        closeHandler={() =>
          setEditAlarmState({
            isOpen: false,
            id: '',
            sensorType: 'SENSOR_TYPE_UNSPECIFIED'
          })
        }
      />
    </div>
  );
}

export default OfflineAlarmPage;
