import { offlineAlarmsApi } from '../gen/offline-alarms';

export const enhancedOfflineAlarms = offlineAlarmsApi.enhanceEndpoints({
  addTagTypes: ['OfflineAlarms'],
  endpoints: {
    offlineAlarmServiceGetOfflineAlarm: {
      providesTags: ['OfflineAlarms']
    },
    offlineAlarmServiceListOfflineAlarm: {
      providesTags: ['OfflineAlarms']
    },
    offlineAlarmServiceDeleteOfflineAlarm: {
      invalidatesTags: ['OfflineAlarms']
    },
    offlineAlarmServiceCreateOfflineAlarm: {
      invalidatesTags: ['OfflineAlarms']
    },
    offlineAlarmServiceUpdateOfflineAlarm: {
      invalidatesTags: ['OfflineAlarms']
    }
  }
});

export const {
  useOfflineAlarmServiceCreateOfflineAlarmMutation,
  useOfflineAlarmServiceDeleteOfflineAlarmMutation,
  useOfflineAlarmServiceGetOfflineAlarmQuery,
  useOfflineAlarmServiceListOfflineAlarmQuery,
  useOfflineAlarmServiceUpdateOfflineAlarmMutation
} = enhancedOfflineAlarms;
