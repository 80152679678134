import React, { Fragment, ReactElement } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { HiChevronDown } from 'react-icons/hi';
import { IoMdCheckmark } from 'react-icons/io';

export interface DropDownItem {
  key: string;
  label: string;
}

interface DropDownProps {
  className?: string;
  selectedKey: string;
  items: DropDownItem[];
  setSelectedKey: (key: string) => void;
}

function DropDown({
  className,
  selectedKey,
  items,
  setSelectedKey
}: DropDownProps): ReactElement {
  let selectedItem = items.find((item) => item.key === selectedKey);

  if (selectedItem == null && items.length > 0) {
    const [item] = items;
    selectedItem = item;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`${className} inline-flex items-center w-64 justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}
        >
          {selectedItem != null ? selectedItem.label : 'Keine Rolle ausgewählt'}
          <HiChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
          {items.map((item: DropDownItem) => (
            <div className="py-1 px-1" key={item.key}>
              <Menu.Item>
                {() => (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedKey(item.key);
                    }}
                    className=" text-gray-900 flex px-4 py-2 text-sm w-full outline-0 flex-start hover:bg-gray-100 hover:rounded-md flex-row justify-between"
                  >
                    {item.label}
                    {item.key === selectedKey ? (
                      <IoMdCheckmark size={20} />
                    ) : null}
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default DropDown;
