import React, { ReactElement } from 'react';
import Pill from './pill';

interface BatteryPill {
  value: number;
}

function BatteryPill({ value }: BatteryPill): ReactElement {
  if (value <= 2.3) {
    return (
      <Pill
        label={`${value.toString()} V`}
        className="ml-5 w-24 bg-red-500 flex items-center justify-center text-white"
      />
    );
  }

  if (value >= 2.3 && value <= 2.5) {
    return (
      <Pill
        label={`${value.toString()} V`}
        className="ml-5 w-24 bg-yellow-500 flex items-center justify-center text-white"
      />
    );
  }

  if (value > 2.5) {
    return (
      <Pill
        label={`${value.toString()} V`}
        className="ml-5 w-24 bg-green-500 flex items-center justify-center text-white"
      />
    );
  }

  return (
    <Pill
      label={`${value.toString()} V`}
      className="ml-5 w-24 bg-gray-500 flex items-center justify-center text-white"
    />
  );
}

export default BatteryPill;
