import { RiAlertFill } from 'react-icons/ri';
import { AiFillAlert } from 'react-icons/ai';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import DateTableLabel from '../date/dateTableLabel';
import { useNotificationServiceListNotificationsQuery } from '../../api/enhanced/enhancedNotification';

interface NotificationMenuProps {
  groupId: string;
  children: ReactNode;
}

const NotificationHeader = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  return (
    <div className="px-5 flex flex-row justify-between">
      <div className="text-lg font-semibold">Benachrichtigungen</div>
      <button
        type="button"
        className="text-green-500"
        onClick={() => navigate('notifications')}
      >
        Alle ansehen
      </button>
    </div>
  );
});

interface TrapBodyProps {
  groupId: string;
}

const TrapBody = React.forwardRef(({ groupId }: TrapBodyProps, ref) => {
  const navigate = useNavigate();
  const { data, isLoading } = useNotificationServiceListNotificationsQuery({
    groupId,
    page: 0,
    limit: 3,
    sensorType: 'SENSOR_TYPE_TRAP'
  });

  const getStateLabel = (state: string | undefined): string => {
    if (state === 'failed') {
      return 'Kein Fang';
    }

    if (state === 'trapped') {
      return 'Fang';
    }

    if (state === 'normal') {
      return 'Aktiv';
    }

    return '';
  };

  if (isLoading) {
    return (
      <div className="h-16 flex mt-3  items-center  rounded-lg shadow-md text-lg font-semibold text-white bg-gray-50 animate-pulse" />
    );
  }

  if (data == null || data.data == null) {
    return (
      <div>
        <div className="text-lg font-semibold mb-2">Fallen:</div>
        <div className="text-md font-semibold w-full ">
          Keine neuen Einträge vorhanden
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-lg font-semibold">Fallen:</div>
      {data.data.map((event) => {
        let icon = null;
        let color = 'bg-gray-50';
        if (event.trapEvent?.state === 'failed') {
          color = 'bg-yellow-400';
          icon = <RiAlertFill size={25} />;
        } else if (event.trapEvent?.state === 'trapped') {
          color = 'bg-red-400';
          icon = <AiFillAlert size={25} />;
        }

        return (
          <button
            type="button"
            onClick={() => {
              navigate(
                `building/${event.buildingId}/checkpoints/${event.checkpointId}/trap?notification=${event.entryId}`
              );
            }}
            key={event.entryId}
            className={`h-16 flex mt-3 w-full  items-center  rounded-lg shadow-md text-md font-semibold text-white ${color}`}
          >
            <div className="flex p-4 flex-row w-full justify-between ">
              <div className="flex flex-row items-center">
                {icon}
                <div className="ml-2 flex flex-col items-start">
                  <div>{`${event.checkpointNumber} - ${event.checkpointDescription}`}</div>
                  <div className="text-sm">
                    {getStateLabel(event.trapEvent?.state)}
                  </div>
                </div>
              </div>

              <DateTableLabel
                className="flex items-end"
                timestamp={event.timestamp}
              />
            </div>
          </button>
        );
      })}
    </div>
  );
});

function NotificationMenu({
  children,
  groupId
}: NotificationMenuProps): ReactElement {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>{children}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute max-h-96 right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none overflow-auto z-50">
          <div className="py-3 ">
            <Menu.Item>
              <NotificationHeader />
            </Menu.Item>
          </div>
          <div className="px-5 py-3">
            <Menu.Item>
              <TrapBody groupId={groupId} />
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default NotificationMenu;
