/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { RootState } from '../../../api/store';
import { useFloorPlanServiceSaveFloorPlanMutation } from '../../../api/enhanced/enhancedFloorPlan';
import Modal from '../../../components/modal/modal';
import FormErrorLabel from '../../../components/form/formErrorLabel';
import FormSubmitButton from '../../../components/form/formSubmitButton';

interface AddFloorPlanModalProps {
  closeHandler: () => void;
  isOpen: boolean;
  buildingId?: string;
  areaId?: string;
  groupId?: string;
}

function AddFloorPlanModal({
  closeHandler,
  isOpen,
  areaId,
  groupId,
  buildingId
}: AddFloorPlanModalProps): ReactElement {
  const methods = useForm();
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const [saveFloorPlan, { isLoading, isError, isSuccess, reset }] =
    useFloorPlanServiceSaveFloorPlanMutation();

  const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = async (): Promise<void> => {
    if (
      selectedFile != null &&
      groupId != null &&
      buildingId != null &&
      areaId != null
    ) {
      try {
        const file = (await toBase64(selectedFile)) as string;
        const fileData = file.split(',')[1];

        saveFloorPlan({
          groupId,
          buildingId,
          areaId,
          body: {
            imageType: selectedFile.type,
            data: fileData
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const changeHandler = (event: any): void => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const close = (): void => {
    setSelectedFile(undefined);
    setIsFilePicked(false);
    closeHandler();

    if (fileInput.current != null) {
      fileInput.current.value = '';
    }
  };

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeHandler={() => {
          close();
        }}
        title={t('modals.addFloorPlan.title')}
      >
        <div className="w-full flex flex-col">
          {isError && (
            <FormErrorLabel
              className="mb-3"
              icon={<RiAlertFill size={20} />}
              label={t('modals.error')}
            />
          )}

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {isFilePicked && selectedFile != null && (
              <div className="mb-8">
                <p className="mt-4">Dateiname: {selectedFile.name}</p>
                <p>Dateityp: {selectedFile.type}</p>
              </div>
            )}

            <div>
              <input
                id="file"
                className="hidden"
                type="file"
                onChange={changeHandler}
                ref={fileInput}
              />
              <label
                className="rounded-lg shadow-md w-24 h-10 bg-blue-400 text-white p-3 cursor-pointer"
                htmlFor="file"
              >
                {t('modals.addFloorPlan.selectFile')}
              </label>
            </div>

            <FormSubmitButton label={t('modals.save')} isLoading={isLoading} />
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
}

export default AddFloorPlanModal;
