import React, { Fragment, ReactElement, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { MdLogout } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useIdentityServiceGetUserInfoQuery } from '../../api/gen/identity';

interface MenuComponentProps {
  groupId: string;
  children: ReactNode;
}

function UserMenu({ groupId, children }: MenuComponentProps): ReactElement {
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const { data, isError } = useIdentityServiceGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { userId: accounts[0].localAccountId }
      : skipToken
  );

  const { data: activeUserData } = useIdentityServiceGetUserInfoQuery(
    accounts.length > 0 && accounts[0].localAccountId != null
      ? { userId: accounts[0].localAccountId }
      : skipToken
  );

  const handleLogout = (): void => {
    instance
      .logoutRedirect({
        account: instance.getAccountByHomeId(accounts[0].homeAccountId),
        postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI
      })
      .then()
      .catch((e) => console.log(e));
  };

  const getRole = (): string => {
    if (
      activeUserData != null &&
      activeUserData.user != null &&
      activeUserData.user.isAdmin
    ) {
      return t('role.ROLE_GLOBAL_ADMIN');
    }

    if (
      activeUserData != null &&
      activeUserData.user != null &&
      activeUserData.user.isTechnician
    ) {
      return t('role.ROLE_TECHNICIAN');
    }

    return 'Nutzer';
  };

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button>{children}</Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                <button
                  type="button"
                  onClick={() => handleLogout()}
                  className="group flex w-full items-center rounded-md px-2 py-2 text-sm"
                >
                  <div className=" items-center flex flex-row">
                    <MdLogout className="mr-3" />
                    {t('userMenu.signOut')}
                  </div>
                </button>
              </Menu.Item>
            </div>
            {data != null && (
              <div className="text-sm px-2 py-2 text-gray-500">
                <Menu.Item>
                  <div className="flex flex-col">
                    <div>{t('userMenu.signedInUser')}:</div>
                    <div>{data?.user?.userName}</div>
                  </div>
                </Menu.Item>
                {activeUserData != null &&
                  activeUserData.user != null &&
                  activeUserData.user.groups != null && (
                    <Menu.Item>
                      <div className="flex flex-col mt-1">
                        <div>{t('userMenu.role')}:</div>
                        <div>{getRole()}</div>
                      </div>
                    </Menu.Item>
                  )}
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default UserMenu;
